import React from 'react';
import TextField from '@mui/material/TextField';
import { Box, Typography, useTheme, MenuItem, InputLabel } from '@mui/material';
import { tokens } from '../theme';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import Header from '../components/Header';
import SendIcon from '@mui/icons-material/Send';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const typesList = ['By Role/County', 'Custom'];

const AddMappingSchemaByRole = Yup.object().shape({
  id: Yup.string(),
  mappingType: Yup.string().required('Required'),
  trade: Yup.string().required('Required'),
  classification: Yup.string().required('Required'),
  county: Yup.string().required('Required'),
  project: Yup.string(),
  fullName: Yup.string(),
  isCustom: Yup.bool(),
  isEditable: Yup.bool(),
  book: Yup.string().required('Required'),
  order: Yup.number().required('Required'),
});

const AddMappingSchemaCustom = Yup.object().shape({
  id: Yup.string(),
  mappingType: Yup.string().required('Required'),
  trade: Yup.string(),
  classification: Yup.string(),
  county: Yup.string(),
  project: Yup.string(),
  fullName: Yup.string().required('Required'),
  isCustom: Yup.bool(),
  isEditable: Yup.bool(),
  book: Yup.string().required('Required'),
  //employeeID: Yup.number().required('Required'),
  //order: Yup.number().required('Required'),
});

const AddMappingSchemaByProject = Yup.object().shape({
  id: Yup.string(),
  mappingType: Yup.string().required('Required'),
  trade: Yup.string().required('Required'),
  classification: Yup.string().required('Required'),
  county: Yup.string(),
  project: Yup.string().required('Required'),
  fullName: Yup.string(),
  isCustom: Yup.bool(),
  isEditable: Yup.bool(),
  book: Yup.string().required('Required'),
  order: Yup.number().required('Required'),
});

const details = {
  id: '',
  classification: '',
  county: '',
  fullName: 'Christopher Chan',
  isCustom: false,
  isEditable: false,
  mappingType: 'By Role/County',
  project: '',
  trade: '',
  book: 'BUT-2023-01',
  employeeID: '',
};

const AddMappingForm = ({
  listData,
  filteredCounties,
  pageState,
  setsentMapping,
  setOpen,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { api } = useAuth();
  const [mappingDetails, setMappingDetails] = useState(details);
  const [isCustom, setIsCustom] = useState(false);
  const [isByRole, setIsByRole] = useState(true);
  const [isByProject, setIsByProject] = useState(false);
  const [mappingType, setMappingType] = useState('By Role/County');
  const [validationSchema, setValidationSchema] = useState(
    AddMappingSchemaCustom
  );
  const [statusMessage, setStatusMessage] = useState(null);

  useEffect(() => {
    if (pageState)
      setMappingDetails({
        id: '',
        classification: '',
        county: '',
        fullName: '',
        isCustom: false,
        isEditable: false,
        mappingType: 'By Role/County',
        project: '',
        trade: '',
        book: '',
        employeeID: '',
        order: pageState.data.length + 1,
      });
  }, [pageState]);

  // useEffect(() => {
  //   if (mappingType === 'By Role/County') {
  //     setValidationSchema(AddMappingSchemaByRole);
  //     setMappingType('By Role/County');
  //   } else if (mappingType === 'Custom') {
  //     setValidationSchema(AddMappingSchemaCustom);
  //     setMappingType('Custom');
  //   } else if (mappingType === 'By Project') {
  //     setValidationSchema(AddMappingSchemaByProject);
  //   }
  // }, [mappingType]);

  const formik = useFormik({
    initialValues: mappingDetails,
    validationSchema: AddMappingSchemaCustom,
    onSubmit: (values) => {
      console.log(values, 'values');
      createMapping(values);
    },
  });

  // Mapping Create Stuff
  const createMapping = async (details) => {
    await api
      .post('/api/v1/mapping', JSON.stringify(details))
      .then(() => {
        // const updatedPayroll = [...allTimecards, response.data.data];
        // setBookDetails(updatedPayroll);
        setStatusMessage('RateBook Created!');
        setsentMapping(true);
        setOpen(false);
      })
      .catch((error) => {
        setStatusMessage('An Error Occurred!');
      });

    // const updatedPayroll = [...allTimecards, response.data.data];
    // setBookDetails(updatedPayroll);
  };

  const handleMappingTypeChange = (update) => {
    if (update.mappingType === 'Custom') {
      setMappingDetails({ ...mappingDetails, ...update });
      setIsCustom(true);
      setIsByRole(false);
      setIsByProject(false);
      setMappingType('Custom');
      setValidationSchema(AddMappingSchemaCustom);
      console.log(update, 'Custom Update');
    } else if (update.mappingType === 'By Role/County') {
      setMappingDetails({ ...mappingDetails, ...update });
      setIsCustom(false);
      setIsByRole(true);
      setIsByProject(false);
      setMappingType('By Role/County');
      setValidationSchema(AddMappingSchemaByRole);
    } else if (update.mappingType === 'By Project') {
      setMappingDetails({ ...mappingDetails, ...update });
      setIsCustom(false);
      setIsByRole(false);
      setIsByProject(true);
      setMappingType('By Project');
      setValidationSchema(AddMappingSchemaByRole);
    }
  };

  return (
    <Box>
      <Header title={'Create A New Mapping'} subtitle={'Enter Below'} />

      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ width: '99%' }}>
          <InputLabel id='mappingTypeLabel'>Mapping Type</InputLabel>
          <Select
            labelId='mappingTypeLabel'
            size='small'
            id='mappingType'
            name='mappingType'
            value={formik?.values.mappingType ? formik.values.mappingType : ''}
            onChange={(e) => {
              handleMappingTypeChange({ mappingType: e.target.value });
              formik.handleChange(e);
            }}
            error={
              formik.touched.mappingType && Boolean(formik.errors.mappingType)
            }
            label={formik.touched.mappingType && formik.errors.mappingType}>
            {typesList.map((obj, index) => (
              <MenuItem key={index} name='mappingType' value={obj}>
                {obj}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isCustom || isByRole || isByProject ? (
          <Typography
            variant='h6'
            sx={{ mt: 2, color: colors.greenAccent[400] }}>
            {' '}
            Input Parameters
          </Typography>
        ) : null}

        {isCustom ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='fullNameLabel'>Full Name</InputLabel>
            <Select
              labelId='fullNameLabel'
              size='small'
              id='fullName'
              name='fullName'
              value={formik.values.fullName || ''}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              label={formik.touched.fullName && formik.errors.fullName}
              onChange={formik.handleChange}>
              {listData.personnel.data.data.map((personnel, index) => (
                <MenuItem key={index} value={personnel.fullName}>
                  {personnel.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {isByRole || isByProject ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='tradeLabel'>Trade</InputLabel>
            <Select
              labelId='tradeLabel'
              size='small'
              id='trade'
              name='trade'
              value={formik.values.trade || ''}
              error={formik.touched.trade && Boolean(formik.errors.trade)}
              label={formik.touched.trade && formik.errors.trade}
              onChange={formik.handleChange}>
              {listData.craft.data.data.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {isByRole || isByProject ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='classificationLabel'>
              Enter A Classification
            </InputLabel>
            <Select
              labelId='classificationLabel'
              size='small'
              id='classification'
              name='classification'
              value={
                formik.values.classification ? formik.values.classification : ''
              }
              onChange={(e) => {
                const newValue = e.target.value;
                console.log(newValue, 'new value');
                formik.setFieldValue('classification', newValue);
              }}
              error={
                formik.touched.classification &&
                Boolean(formik.errors.classification)
              }
              label={
                formik.touched.classification && formik.errors.classification
              }>
              {listData.classification.data.data.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {isByRole ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='countyLabel'>County</InputLabel>
            <Select
              labelId='countyLabel'
              size='small'
              id='county'
              name='county'
              value={formik.values.county || ''}
              onChange={formik.handleChange}
              error={formik.touched.county && Boolean(formik.errors.county)}
              label={formik.touched.county && formik.errors.county}>
              {filteredCounties.map((county, index) => (
                <MenuItem key={index} value={county.county}>
                  {county.county}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {isByProject ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='projectLabel'>Project</InputLabel>
            <Select
              labelId='projectLabel'
              size='small'
              id='project'
              name='project'
              value={formik.values.project || ''}
              onChange={formik.handleChange}
              error={formik.touched.project && Boolean(formik.errors.project)}
              label={formik.touched.project && formik.errors.project}>
              {listData.project.data.data.map((obj, index) => (
                <MenuItem key={index} value={obj}>
                  {obj}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        <Typography variant='h6' sx={{ mt: 2, color: colors.greenAccent[400] }}>
          {' '}
          Maps To
        </Typography>

        <FormControl sx={{ width: '99%' }}>
          <InputLabel id='bookLabel'>Book</InputLabel>
          <Select
            labelId='bookLabel'
            size='small'
            id='book'
            name='book'
            value={formik.values.book || ''}
            onChange={formik.handleChange}
            error={formik.touched.book && Boolean(formik.errors.book)}
            label={formik.touched.book && formik.errors.book}>
            {listData.book.data.data.map((obj, index) => (
              <MenuItem key={index} value={obj}>
                {obj}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box textAlign='center'>
          <Typography
            variant='h6'
            sx={{ mt: 2, color: colors.greenAccent[400] }}>
            {statusMessage}
          </Typography>
          <Button
            variant='contained'
            sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
            onClick={formik.handleSubmit}
            endIcon={<SendIcon />}>
            Send
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddMappingForm;
