import LandingPageNavBar from '../components/LandingPageNavBar';
import LandingPageFooter from '../components/LandingPageFooter';
import { Box } from '@mui/material';

const LandingLayout = ({ children }) => {
  return (
    <>
      <LandingPageNavBar />
      <Box> {children}</Box>
     
      <LandingPageFooter/>
    </>
  );
};

export default LandingLayout;
