import { Container, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import OutlinedButton from "../components/OutlinedButton";
import Title from "../components/title";
// Section4
import NewsImg from "../assets/images/news-image.webp";
import Phone1Img from "../assets/payroll-images/payroll-app1.png";
import Phone2Img from "../assets/payroll-images/timecard-app.png";
import Features from "../assets/payroll-images/features.png";
import Phone4Img from "../assets/images/wallet-swap.webp";
import { useNavigate } from 'react-router-dom';

  const section4Content = {
    top: {
      title: "Automate Your Entire Payroll Process",
      subtitle:
        "Labor Rates can change across multiple projects, area codes, and time. This tool was designed to eliminate these complexities.",
      image: NewsImg,
    },
    bottom: {
      title: "Manage Timecards",
      TABS: [
        {
          name: "Create",
          image: Phone2Img,
          subtitle:
            "Digitally create timecards to keep track of your labor force.",
        },
        {
          name: "Manage",
          image: Phone1Img,
          subtitle:
            "Company Admins and Project Managers can approve, edit, and delete timecards.",
        },
        {
          name: "Security",
          image: Features,
          subtitle: "Secure Payments Through Stripe",
        },
        {
          name: "Mapping and Payroll",
          image: Phone4Img,
          subtitle: "Swap any amount of tokens at the best rates.",
        }
      ],
    },
  };

  const Section4 = () => {
    const { top, bottom } = section4Content;
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();
    const handleLearnClick = () => {
      navigate('/features');
    };

    return (
      <Container sx={{ mt: { xs: 15, md: 20, lg: 25} }}>
        {/* TOP */}
        <Grid container spacing={10} flexWrap="wrap-reverse" alignItems="center" >
          {/* Left */}
          <Grid item xs={12} md={6}>
            <Stack spacing={2} sx={{ maxWidth: 480}}>
              <Title variant={{ xs: "h3", md: "h2" }}>{top.title}</Title>
  
              <Typography variant="body2" color="text.secondary" sx={{ pb: 2 }}>
                {top.subtitle}
              </Typography>
  
              <OutlinedButton arrow fit onClick={handleLearnClick}>
                Features
              </OutlinedButton>
            </Stack>
          </Grid>
  
          {/* Right */}
          <Grid item xs={12} md={6}>
            <img
              src={top.image}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Grid>
        </Grid>
  
        {/* BOTTOM */}
  
        <Grid
          container
          spacing={10}
          flexWrap="wrap-reverse"
          alignItems="center"
          sx={{ mt: { xs: 10, md: 15 } }}
        >
          {/* Left */}
          <Grid item xs={12} md={6}>
            <img
              src={bottom.TABS[tabValue].image}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Grid>
  
          {/* Right */}
          <Grid item xs={12} md={6}>
            <Stack spacing={2} sx={{ maxWidth: 480 }}>
              <Title variant={{ xs: "h3", md: "h2" }}>{bottom.title}</Title>
  
              <Tabs
                value={tabValue}
                onChange={(e, v) => setTabValue(v)}
                variant="scrollable"
                scrollButtons="auto"
              >
                {bottom.TABS.map(({ name }) => (
                  <Tab
                    label={name}
                    key={name}
                    sx={{
                      minWidth: 60,
                      "&.Mui-selected": { color: "blue" },
                    }}
                  />
                ))}
              </Tabs>
  
              <Typography
                variant="body2"
                color="white"
                sx={{ pb: 2, minHeight: 70 }}
              >
                {bottom.TABS[tabValue].subtitle}
              </Typography>
  
              <OutlinedButton arrow fit onClick={handleLearnClick}>
                Learn more
              </OutlinedButton>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    );
  };
  
  export default Section4;
  