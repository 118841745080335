import { Box } from '@mui/material';
import FAQ from '../faq';
import LandingPageNavBar from '../components/LandingPageNavBar';
import LandingPageFooter from '../components/LandingPageFooter';

function FAQPage() {
  return (
    <Box>
      <LandingPageNavBar />
      <FAQ />
      <LandingPageFooter />
    </Box>
  );
}

export default FAQPage;
