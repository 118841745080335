import { useState, useEffect } from 'react';
import MultipleSelect from '../components/MultiSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { tokens } from '../theme';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import { Stack, Box, Typography, Grid, Button, useTheme, TextField } from '@mui/material';
import { useAuth } from '../context/AuthProvider';
import usePath from '../hooks/fetchPath';
import { compileRecurringEmail } from '../utils/compileRecurringEmail';

const Email = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [time, setTime] = useState(dayjs());
  const [users, setusers] = useState([]);
  const [cronName, setcronName] = useState();
  const [subject, setSubject] = useState();
  const [body, setBody] = useState();
  const [day, setday] = useState('');
  const [details, setDetails] = useState('');
  const [placeholder] = useState('Select a Day');
  const { api, success } = useAuth();
  const { baseURL } = usePath('/api/v1/projects/');
  const [emailArray, setemailArray] = useState([]);

  const handleChange = (event) => {
    setday(event.target.value);
  };

  async function sendRequest() {
    try {
      await api.post(`/api/v1/email/createRecurringEmail`, details);
    } catch (err) {}
  }

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/users/?sort=lastName`)
        .then((response) => {
          setusers(response.data.data);
        })
        .catch((err) => {
          console.log('FAILED', { err });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated, baseURL]);

  useEffect(() => {
    setDetails(compileRecurringEmail(emailArray, cronName, subject, body, day, time));
  }, [body, cronName, day, emailArray, subject, time]);

  const handleBody = (e) => {
    setBody(e.target.value);
  };

  const handleSubject = (e) => {
    setSubject(e.target.value);
  };

  const handleCronName = (e) => {
    setcronName(e.target.value);
  };

  return (
    <Box sx={{ height: '100dvh' }}>
      <Grid
        container
        spacing={1}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '100vh' }}
      >
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: colors.primary[400],
            borderRadius: '5px',
            width: '48ch',
          }}
        >
          <Box
            component='form'
            sx={{
              '& .MuiTextField-root': { m: 1, width: '95%' },
            }}
            noValidate
            autoComplete='off'
          >
            <MultipleSelect users={users} setusers={setusers} emailArray={emailArray} setemailArray={setemailArray} />
            <TextField
              id='outlined-multiline-subject'
              label='Reference ID'
              value={cronName}
              onChange={handleCronName}
              size='small'
              sx={{
                input: { m: 0, color: colors.greenAccent[500] },
                label: { m: 0, color: colors.greenAccent[500] },
              }}
            />
            <TextField
              id='outlined-multiline-subject'
              label='Subject'
              value={subject}
              onChange={handleSubject}
              size='small'
              sx={{
                input: { m: 0, color: colors.greenAccent[500] },
                label: { m: 0, color: colors.greenAccent[500] },
              }}
            />

            <TextField
              id='outlined-multiline-static'
              label='Email Body'
              value={body}
              multiline
              rows={8}
              onChange={handleBody}
            />
          </Box>
          <Stack direction='row' sx={{ p: 1 }}>
            <Box
              sx={{
                width: '50%',
                '& .MuiInputBase-input': {
                  m: 0.1,
                  p: 0.1,
                  color: colors.greenAccent[500],
                },
              }}
            >
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    marginLeft: '0.71em',
                    marginTop: '-0.71em',
                    paddingLeft: '0.44em',
                    paddingRight: '0.44em',
                    zIndex: 2,
                    position: 'absolute',
                    fontSize: '0.75em',
                  }}
                >
                  {placeholder}
                </FormLabel>
                <Select id='demo-simple-select' value={day} onChange={handleChange} displayEmpty>
                  <MenuItem value={'Sunday'}>Every Sunday at</MenuItem>
                  <MenuItem value={'Monday'}>Every Monday at</MenuItem>
                  <MenuItem value={'Tuesday'}>Every Tuesday at</MenuItem>
                  <MenuItem value={'Wednesday'}>Every Wednesday at</MenuItem>
                  <MenuItem value={'Thursday'}>Every Thursday at</MenuItem>
                  <MenuItem value={'Friday'}>Every Friday at</MenuItem>
                  <MenuItem value={'Saturday'}>Every Saturday at</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: '50%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  {...time}
                  value={time}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        sx={{
                          svg: { color: colors.greenAccent[500] },
                          input: { color: colors.greenAccent[500] },
                          label: { color: colors.greenAccent[500] },
                        }}
                      />
                    );
                  }}
                  components={{
                    OpenPickerIcon: (props) => <AccessTimeIcon {...props} />,
                  }}
                  referenceDate={time}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  onChange={(e) => {
                    setTime(e);
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      m: 0.1,
                      p: 0.1,
                      color: 'black',
                    },
                    '& .MuiOutlinedInput-input': {
                      color: colors.greenAccent[300],
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Stack>
          <Typography
            sx={{
              p: 1,
              color: colors.grey[200],
              width: '100%',
            }}
          >
            {' '}
            {`Send Recurring Email on ${day} at ${dayjs(time).format('LT')}`}
          </Typography>
        </Grid>
        <Grid item xs={3} alignItems='center' justifyContent='center' textAlign='center'>
          <Button onClick={sendRequest} sx={{ backgroundColor: colors.greenAccent[500] }}>
            {' '}
            Send
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Email;
