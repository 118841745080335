import * as React from "react";
import { Box } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import TopBarAdmin from "../components/TopBarAdmin";
import PayrollProcess from "../payroll";
import usePath from "../hooks/fetchPath";
import { useAuth } from "../context/AuthProvider";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function PayrollProcessPage({ auth }) {
	const { api, success } = useAuth();
	const [setIsSidebar] = useState(true);
	const [listData, setListData] = useState([]);
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [data, setData] = useState([]);
	const [tcLoadComplete, setTcLoadComplete] = useState(false);
	const [listLoadComplete, setListLoadComplete] = useState(false);
	const { baseURL } = usePath("/api/v1/personnel/");
	const [personnelList, setPersonnelList] = useState(null);
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 0,
		pageSize: 100,
	});
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");

	//get unapproved timecards
	useMemo(() => {
		if (api.authenticated) {
			api
				.get(
					`/api/v1/timecardsv2/unpaid/?page=${pageState.page + 1}&limit=${
						pageState.pageSize
					}`
				)
				.then((response) => {
					const data = response.data.data[0].results;
					const filtered = formatData(data);
					const total = response.data.data[0].pagination[0].totaldocuments;
					const update = {
						data: filtered,
						pageSize: pageState.pageSize,
						total: total,
					};
					setnotificationsNumber(response.data.data[0].results.length);
					setPageState((old) => ({
						...old,
						...update,
					}));

					setTcLoadComplete(true);
				})
				.catch((err) => {
					console.log("FAILED", { err });
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
	}, [api, pageState.page, pageState.pageSize]);

	//get mapping
	useMemo(() => {
		if (success) {
			function checkFailed(then) {
				return function (responses) {
					const someFailed = responses.some((response) => response.error);

					if (someFailed) {
						throw responses;
					}

					return then(responses);
				};
			}

			async function getListData() {
				const promises = [
					api.get("/api/v1/ratebook/craftlist"),
					api.get("/api/v1/ratebook/classificationlist"),
					api.get("/api/v1/ratebook/booklist"),
					api.get("/api/v1/mapping"),
					api.get("/api/v1/projects/list"),
				];

				const promisesResolved = promises.map((promise) =>
					promise.catch((error) => ({ error }))
				);

				Promise.all(promisesResolved)
					.then(
						checkFailed(([craft, classification, book, mapping, project]) => {
							setListLoadComplete(true);
							setListData((prev) => ({
								...prev,
								craft,
								classification,
								book,
								mapping,
								project,
							}));
						})
					)
					.catch((err) => {
						console.log("FAIL", err);
						setErrState(true);
						seterrMsg("Error Loading Data");
					});
			}

			getListData();
		}
	}, [api, success]);

	//get personnel
	useEffect(() => {
		if (api.authenticated) {
			api
				.get(`/api/v1/personnel?sort=fullName&limit=1000`)
				.then((response) => {
					setPersonnelList(response.data.data);
				})
				.catch((err) => {
					console.log("FAILED", { err });
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
	}, [api, baseURL, success]);

	//do logic - create a list of promises
	useEffect(() => {
		//return an object
		const queryRateBook = async (timecard, map) => {
			const bookName = map.book;
			let output;
			if (api.authenticated) {
				await api
					.get(`/api/v1/ratebook?book=${bookName}`)
					.then((response) => {
						console.log("found rate book", response.data.data);
						const consolidated = {
							regRate: response.data.data[0].regRate,
							OTRate: response.data.data[0].OTRate,
							satRate: response.data.data[0].satRate,
							sunRate: response.data.data[0].sunRate,
							trainingRate: response.data.data[0].training,
							mapping: map,
						};
						const final = { ...timecard, ...consolidated };
						return (output = final);
					})
					.catch((err) => {
						console.log(err);
						setErrState(true);
						seterrMsg("Error Loading Data");
					});
			}
			return output;
		};

		const runAlgo = async () => {
			//inital values
			let Mapping;
			if (listData.mapping) {
				Mapping = listData.mapping.data.data;
			} else {
				Mapping = [];
			}
			const newData = [];
			let remainingItems = pageState.data;

			for (let i = 0; i < Mapping.length; i++) {
				//check mapping type
				if (Mapping[i].mappingType === "Custom") {
					//search for one person
					var result = pageState.data.find((timecard) => {
						return timecard.employeeID === Mapping[i].employeeID;
					});
					var index = pageState.data.findIndex(
						(timecard) => timecard.employeeID === Mapping[i].employeeID
					);

					if (result) {
						//fetch data
						const responseData = await queryRateBook(result, Mapping[i]);
						//update final array
						newData.push(responseData);
						//remove item from array
						if (remainingItems.length > 0) {
							remainingItems.splice(index, 1);
						}
						//increase index
					} else {
						//if no result, increase index
					}
				}
			}

			const finalData = [...newData, ...remainingItems];
			setData(finalData);
			setPageState((old) => ({
				...old,
				data: finalData,
			}));
		};
		if (tcLoadComplete && listLoadComplete) {
			runAlgo();
		}
	}, [listLoadComplete, tcLoadComplete]);

	function formatData(data) {
		const output = data.map((timecard) => ({
			id: timecard._id,
			approved: timecard.approved,
			employeeID: timecard.employeeID,
			trade: timecard.trade,
			classification: timecard.classification,
			weekEnd: dayjs(timecard.weekEnd).format("MM-DD-YYYY"),
			regularHours: timecard.regHours,
			OTHours: timecard.overtimeHours,
			project: timecard.project,
			firstName: timecard.firstName,
			lastName: timecard.lastName,
			sundayHours: timecard.sunHours,
			mondayHours: timecard.monday[0].hours[0].regDayTotal,
			monOTHours: timecard.monday[0].hours[0].OTDayTotal,
			tuesdayHours: timecard.tuesday[0].hours[0].regDayTotal,
			tueOTHours: timecard.tuesday[0].hours[0].OTDayTotal,
			wednesdayHours: timecard.wednesday[0].hours[0].regDayTotal,
			wedOTHours: timecard.wednesday[0].hours[0].OTDayTotal,
			thursdayHours: timecard.thursday[0].hours[0].regDayTotal,
			thurOTHours: timecard.thursday[0].hours[0].OTDayTotal,
			fridayHours: timecard.friday[0].hours[0].regDayTotal,
			friOTHours: timecard.friday[0].hours[0].OTDayTotal,
			saturdayHours: timecard.satHours,
			paid: timecard.paid,
			isEditable: false,
		}));
		return output;
	}

	return (
		<div>
			<Box sx={{ height: "100dvh" }}>
				{errState ? (
					<Alert
						icon={<RunningWithErrorsIcon fontSize="inherit" />}
						severity="error"
					>
						{errMsg}
					</Alert>
				) : null}
				<TopBarAdmin
					notificationsNumber={notificationsNumber}
					setIsSidebar={setIsSidebar}
					pageState={pageState}
				/>
				<PayrollProcess
					pageState={pageState}
					setPageState={setPageState}
					personnel={personnelList}
					listData={listData}
					updatedTimecards={data}
				/>
			</Box>
		</div>
	);
}

export default PayrollProcessPage;
