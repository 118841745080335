import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';

export default function DropdownAdminQry({
  allProjects,
  project,
  setProject,
  handleChange,
  startQry,
  handleStart,
  endQry,
  handleEnd,
  sixWkBkDesc,
  editMode,
  setEditMode,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleCBChange = (event) => {
    setEditMode(event.target.checked);
  };
  //radio button
  const [showEndDate, setShowEndDate] = useState(true);
  const handleRadioChange = (event) => {
    if (event.target.value === 'Week Range') {
      setShowEndDate(true);
    } else if (event.target.value === 'Single Week') {
      setShowEndDate(false);
    }
  };

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
      ...theme.applyStyles('dark', {
        backgroundColor: '#30404d',
      }),
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
      ...theme.applyStyles('dark', {
        background: 'rgba(57,75,89,.5)',
      }),
    },
    ...theme.applyStyles('dark', {
      boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
      backgroundColor: '#394b59',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color='default'
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        onChange={handleRadioChange}
        {...props}
      />
    );
  }
  return (
    <div>
      <div>
        <FormControl
          variant='standard'
          sx={{
            m: 1,
            minWidth: 200,
            '& .MuiInputLabel-formControl': {
              color: colors.grey[100],
            },
          }}>
          <Box>
            <FormLabel id='demo-customized-radios'>Mode</FormLabel>
            <RadioGroup
              defaultValue='Week Range'
              aria-labelledby='demo-customized-radios'
              name='customized-radios'>
              <FormControlLabel
                value='Week Range'
                control={<BpRadio />}
                label='Week Range'
              />
              <FormControlLabel
                value='Single Week'
                control={<BpRadio />}
                label='Single Week'
              />
            </RadioGroup>
          </Box>
        </FormControl>
      </div>

      <div>
        <FormControl
          variant='standard'
          sx={{
            m: 1,
            minWidth: 200,
            '& .MuiInputLabel-formControl': {
              color: colors.grey[100],
            },
          }}>
          <InputLabel id='demo-simple-select-standard-label'>Start</InputLabel>
          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            value={startQry}
            onChange={handleStart}
            label='Start'>
            {sixWkBkDesc.map((week, index) => {
              return (
                <MenuItem key={'endDate' + index} value={week}>
                  {week}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div>
        {showEndDate && (
          <FormControl
            variant='standard'
            sx={{
              m: 1,
              minWidth: 200,
              '& .MuiInputLabel-formControl': {
                color: colors.grey[100],
              },
            }}>
            <InputLabel id='demo-simple-select-standard-label'>End</InputLabel>
            <Select
              labelId='demo-simple-select-standard-label'
              id='demo-simple-select-standard'
              value={endQry}
              onChange={handleEnd}
              label='End'>
              {sixWkBkDesc.map((week, index) => {
                return (
                  <MenuItem key={index} value={week}>
                    {week}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </div>
      <div>
        <FormControl
          variant='standard'
          sx={{
            m: 1,
            minWidth: 200,
            '& .MuiInputLabel-formControl': {
              color: colors.grey[100],
            },
          }}>
          <InputLabel id='demo-simple-select-standard-label'>
            Project (Optional)
          </InputLabel>

          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            value={project}
            onChange={handleChange}
            label='Start'>
            {allProjects.map((project, index) => {
              return (
                <MenuItem key={'startDate' + index} value={project}>
                  {project}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div>
        <Checkbox
          size='small'
          checked={editMode}
          onChange={handleCBChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography variant='caption' sx={{ color: colors.grey[100] }}>
          Show All Timecards
        </Typography>
      </div>
    </div>
  );
}
