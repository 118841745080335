import { createContext, useEffect, useState } from "react";
import { useAuth } from "./AuthProvider";

const SubscriptionContext = createContext({});

export const SubscriptionProvider = ({ children }) => {
	const [products, setProducts] = useState(null);
	const [prices, setPrices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { api } = useAuth();

	useEffect(() => {
		if (!products) {
			api
				.get("/api/v1/subscription/products")
				.then(({ data }) => {
					console.log(data);
					setProducts(data.products);
					setPrices(data.prices);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products]);

	return (
		<SubscriptionContext.Provider
			value={{
				products,
				prices,
				isLoading,
			}}
		>
			{children}
		</SubscriptionContext.Provider>
	);
};

export default SubscriptionContext;
