import TopBarAdmin from '../components/TopBarAdmin';
import { Box, Typography } from '@mui/material';
import Dashboard2 from './Dashboard2';
import HomePage from './HomePage';
import useMediaQuery from '@mui/material/useMediaQuery';

function UserPage({ success }) {
  const isAboveMediumScreens = useMediaQuery('(min-width: 1200px)');

  return (
    <div>
      {success ? (
        <div className='Main'>
          <main className='adminContentMain'>
            <Box
              sx={
                isAboveMediumScreens ? { p: 3, marginLeft: 2 } : { p: 0, m: 0 }
              }>
              <TopBarAdmin />
              <Dashboard2 />
              <Typography color='black'>Test</Typography>
            </Box>
          </main>
        </div>
      ) : (
        <div className='vertical-center'>
          <HomePage />
        </div>
      )}
    </div>
  );
}

export default UserPage;
