import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Stack,
  TextField,
} from '@mui/material';

import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../context/AuthProvider';

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required().label('First Name'),
  lastName: Yup.string().required().label('Last Name'),
  email: Yup.string().email().required().label('Email'),
});

const Settings = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { api, user } = useAuth();

  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });

  let formik = useFormik({
    initialValues: userData,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      setIsProcessing(true);

      api
        .put(`/api/v1/auth/updatedetails/user`, values)
        .then(({ data }) => {
          setUserData({
            ...data.data,
            password: '',
            passwordConfirmation: '',
          });
        })
        .catch((err) => {
          console.log('FAIL', err);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    formik.handleSubmit(formik.values);
  };

  return (
    <Card raised>
      <CardHeader title='User Profile'></CardHeader>
      <Divider sx={{ marginBottom: '16px' }} />
      <CardContent>
        <FormControl sx={{ width: '100%' }}>
          <Stack spacing={4}>
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              gap='32px'
            >
              <TextField
                id='firstName'
                label='First Name'
                variant='outlined'
                name='firstName'
                required
                disabled={isProcessing}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                width='100%'
                sx={{ flexGrow: 1 }}
              />

              <TextField
                id='lastName'
                label='Last Name'
                variant='outlined'
                name='lastName'
                required
                disabled={isProcessing}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.firstName && formik.errors.lastName}
                width='100%'
                sx={{ flexGrow: 1 }}
              />
            </Box>

            <TextField
              id='email'
              label='Email'
              variant='outlined'
              name='email'
              type='email'
              required
              disabled={isProcessing}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <Button
              onClick={handleSubmit}
              disabled={isProcessing || !formik.dirty || !formik.isValid}
              variant='contained'
              color='primary'
              type='submit'
              size='large'
            >
              {isProcessing ? (
                <CircularProgress size={24} color='primary' />
              ) : (
                'Update User Profile'
              )}
            </Button>
          </Stack>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Settings;
