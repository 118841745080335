import * as React from 'react';
import dayjs from 'dayjs';
import { useState, useContext, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { Box, Typography, useTheme } from '@mui/material';
import DateBox from './DateBox';
import Divider from '@mui/material/Divider';
import createInitialObj from '../hooks/initial-obj';
import payrollDetailsHook from '../hooks/payroll-details-dayjs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import threeWeekLookBack from '../hooks/three-week-lookback';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { tokens } from '../theme';
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

function PayrollCreate2({ onPayrollCreate, user, payrollz }) {
  //get Initial Object Values add back -->       initialObj,
  const { projectOptions } = createInitialObj(user);

  //three week look back
  const { threeWkBk } = threeWeekLookBack();
  const [weekSelect, setWeekSelect] = useState(threeWkBk[0]);
  const [prevWeekDetected, setPrevWeekDetected] = useState(false);
  //const [errMsg, setErrMsg] = useState([]);
  const { initialObj } = payrollDetailsHook(user, weekSelect);
  const [details, setDetails] = useState(initialObj);
  const [errStartEndFx, seterrStartEndFx] = useState(false);
  const [errOverlap, seterrOverlap] = useState(false);
  const [errDailyHourLimit, seterrDailyHourLimit] = useState(false);

  // error handling
  // const [errMonday, setErrMonday] = useState(false);
  // const [errTuesday, setErrTuesday] = useState(false);
  // const [errWednesday, setErrWednesday] = useState(false);
  // const [errThursday, setErrThursday] = useState(false);
  // const [errFriday, setErrFriday] = useState(false);
  // const [errSaturday, setErrSaturday] = useState(false);
  // const [errSunday, setErrSunday] = useState(false);

  //themes
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //Dropdown Selected Piece of State
  const [projectSelect, setProjectSelect] = useState([]);

  useEffect(() => {
    checkPreviousPayroll(weekSelect);
  }, []);

  //function 1 - handle week select
  const handleWeekSelect = (option) => {
    const updateWeek = option.target.value;
    setWeekSelect(updateWeek);
    const { initialObj } = payrollDetailsHook(user, updateWeek);
    setDetails(initialObj);

    //set additional piece of state to run in details initial obj hook
    checkPreviousPayroll(updateWeek);
  };

  //create a piece of state for previous payroll
  //create a piece of state for overtime
  //create a piece of state for holiday
  //function #2 - check for previous payroll
  const checkPreviousPayroll = (updateWeek) => {
    console.log(payrollz);
    for (let i = 0; i < payrollz.length; i++) {
      console.log(payrollz[i]);
      const previous_week_ending = dayjs(payrollz[i].weekEnd).format(
        'MM/DD/YYYY'
      );
      setPrevWeekDetected(false);
      if (previous_week_ending === updateWeek) {
        setPrevWeekDetected(true);
        console.log('Previous PAYROLLZ detected');
      }
    }
  };

  console.log('Start Of File ------>');

  //Function 3 - Dropdown handle select
  const handleProjectSelect = (option) => {
    console.log(option.target.value);
    setProjectSelect(option.target.value);
    setDetails((prev) => {
      return { ...prev, ['project']: option.target.value };
    });
  };

  //Class Constructor #1
  //for RemainingOTHours and RemainingRegHours, we need the other days hours total, besides the updated one
  class PrevPayroll {
    constructor(
      start,
      end,
      prev,
      queryStart,
      queryEnd,
      regHours,
      OTHours,
      calcOTStart,
      RemainingOTHours,
      RemainingRegHours
    ) {
      this.start = start;
      this.end = end;
      this.prevPayroll = prev;
      this.queryStart = queryStart;
      this.queryEnd = queryEnd;
      this.calcOTStart = calcOTStart;
      this.regHours = regHours;
      this.OTHours = OTHours;
      this.RemainingOTHours = RemainingOTHours;
      this.RemainingRegHours = RemainingRegHours;
    }
  }

  //class constructor #2
  class noPrevPayroll {
    constructor(
      start,
      end,
      queryStart,
      queryEnd,
      calcOTStart,
      regHours,
      OTHours
    ) {
      this.start = start;
      this.end = end;
      this.queryStart = queryStart;
      this.queryEnd = queryEnd;
      this.calcOTStart = calcOTStart;
      this.regHours = regHours;
      this.OTHours = OTHours;
    }
  }

  class SatSun {
    constructor(start, end, prev, satsunHours) {
      this.start = start;
      this.end = end;
      this.prevPayroll = prev;
      this.satsunHours = satsunHours;
    }
  }

  // 2.0 ------ Works for both prev and no prev payroll
  //Function #4
  function CheckStartAndEndTime(obj) {
    console.log('------> checking for start and end times');
    if (obj.end - obj.start < 0) {
      seterrStartEndFx(true);
    } else {
      seterrStartEndFx(false);
    }
  }

  //Function #5
  function CheckDailyHourLimit(obj) {
    // 12 hours =  43200000 milliseconds
    if (obj.end - obj.start > 43200000) {
      seterrDailyHourLimit(true);
    } else {
      seterrDailyHourLimit(false);
    }
  }

  //2.1 ----FUNCTIONS FOR NO PREV PAYROLL
  //calculating OT Start for no Previous Payroll
  //Function #6
  function setOTStartTimeNoPrev(obj) {
    var minWorkStart = dayjs(obj.start);
    obj.calcOTStart = minWorkStart.add(8.5, 'hours').$d;
    console.log('setOT Start For No Previous Case', obj.calcOTStart);
  }

  //Function 7 - calculates M-F hours
  const calculateHours = (obj) => {
    console.log('-----> calculating Hours for No Prev Payroll Case', obj);
    // Start with the No OT Case
    if (
      //No OT, greater than 5.5 but less than 8.5 hours
      //8.5 hours = 30600000 milliseconds
      //5 hours = 18000000 milliseconds
      obj.start < obj.calcOTStart &&
      obj.end <= obj.calcOTStart &&
      obj.end > obj.start &&
      obj.end - obj.start <= 30600000 &&
      obj.end - obj.start >= 18000000
    ) {
      //Only Regular Hours minus 30 min break
      obj.regHours = (obj.end - obj.start - 1800000) / (1000 * 60 * 60);
      obj.OTHours = 0;
      console.log('case 1 - Only Regular Hours', obj.regHours);
    } else if (
      // mixed = RegHours > 0, OTHours > 0
      obj.start < obj.calcOTStart &&
      obj.end > obj.calcOTStart &&
      obj.end > obj.start &&
      obj.end - obj.start > 30600000
    ) {
      //subtract out 30 minute break in milliseconds
      obj.regHours = (obj.calcOTStart - obj.start - 1800000) / (1000 * 60 * 60);
      obj.OTHours = (obj.end - obj.calcOTStart) / (1000 * 60 * 60);
      console.log(
        'case 2 - mixxed, regular and ot horus',
        obj.regHours,
        obj.OTHours
      );
    } else if (
      // RegHours > 0, OTHours = 0, no break
      //5 hours = 18000000 milliseconds
      obj.start < obj.calcOTStart &&
      obj.end <= obj.calcOTStart &&
      obj.end > obj.start &&
      obj.end - obj.start <= 18000000
    ) {
      //do not subtract out 30 minute break in milliseconds
      obj.regHours = (obj.end - obj.start) / (1000 * 60 * 60);
      obj.OTHours = 0;
      console.log(obj.end - obj.start);
      console.log(
        'case #3 no OT, reg less than 5 hoiurs',
        obj.regHours,
        obj.OTHours
      );
    } else if (
      //Work duration occurs after OT Start
      obj.start > obj.calcOTStart &&
      obj.end > obj.calcOTStart &&
      obj.end > obj.start
    ) {
      obj.OTHours = (obj.end - obj.start) / (1000 * 60 * 60);
      obj.regHours = 0;
      console.log('case 4 - only ot horus', obj.regHours, obj.OTHours);
    }
  };

  // 2.2 --------- Callback Functions to calculate hours containing prev payroll
  //find the minimum start time within array

  //Function #8
  const CheckOverLapOfPayrollzWithPrev = (obj) => {
    console.log('Checking Overlap');
    //Create an array of the days within the payroll objects
    var day = [];
    for (var i = 0; i < obj.prevPayroll.length; i++) {
      console.log(obj);
      if (
        obj.prevPayroll[i][obj.queryStart] === null ||
        obj.prevPayroll[i][obj.queryEnd] === null
      ) {
        continue;
      }
      day.push({
        start: new Date(obj.prevPayroll[i][obj.queryStart]),
        end: new Date(obj.prevPayroll[i][obj.queryEnd]),
      });
    }

    //iterate over new array and test for overlap
    for (var j = 0; j < day.length; j++) {
      console.log(day[j]);
      const testStart = day[j].start;
      const testEnd = day[j].end;
      const test1 = obj.start;
      const test2 = obj.end;
      console.log(test1);
      console.log(test2);
      if (
        (testStart <= test1 && test1 <= testEnd) ||
        (testStart <= test2 && test2 <= testEnd)
      ) {
        seterrOverlap(true);
        return;
      } else {
        seterrOverlap(false);
      }
    }
  };

  //Function #9
  function setOTStartTimeWithPrev(obj) {
    var min_start_array = [];
    console.log(obj.prevPayroll);
    //match to week select

    //creating 2 arrays 1) all start time for day in query 2) all end times
    for (var i = 0; i < obj.prevPayroll.length; i++) {
      if (obj.prevPayroll[i][obj.queryStart] !== null) {
        min_start_array.push(new Date(obj.prevPayroll[i][obj.queryStart]));
      }
    }

    //previous payroll has values, find the min start time
    const minWeeklyWorkStart = dayjs(Math.min.apply(null, min_start_array));
    obj.calcOTStart = minWeeklyWorkStart.add(8.5, 'hours').$d;
  }

  //Function 10 - calculates sat-sun hours
  function calculateSatSunHours(obj) {
    if (obj.end - obj.start > 18000000) {
      obj.satsunHours = (obj.end - obj.start - 1800000) / (1000 * 60 * 60);
    } else if (obj.end - obj.start <= 18000000) {
      obj.satsunHours = (obj.end - obj.start) / (1000 * 60 * 60);
    } else if (obj.end - obj.start <= 0) {
      obj.satsunHours = 0;
    }
  }

  //3.3 Callbacks for Date Time Picker
  // Recalculate OT Hours
  // Recalculate Regular Hours
  function sundayStartCB(value) {
    setDetails((prev) => {
      var obj1 = new SatSun(
        new Date(value.$d),
        new Date(prev.sundayEnd),
        payrollz,
        //sunHours
        0
      );
      console.log(obj1);
      //check if end time > start time
      CheckStartAndEndTime(obj1);
      //check if daily hour limit is greater than 12 hours
      CheckDailyHourLimit(obj1);
      calculateSatSunHours(obj1);

      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours +
        prev.saturdayHours;

      var update = {
        sundayStart: obj1.start,
        sundayHours: obj1.satsunHours,
        weeklyHours: obj1.satsunHours + RemainingRegHours,
      };
      console.log(update);
      return { ...prev, ...update };
    });
  }

  function sundayEndCB(value) {
    setDetails((prev) => {
      var obj1 = new SatSun(
        new Date(prev.sundayStart),
        new Date(value.$d),
        payrollz,
        //satHours
        0
      );
      console.log(obj1);
      //check if end time > start time
      CheckStartAndEndTime(obj1);
      //check if daily hour limit is greater than 12 hours
      CheckDailyHourLimit(obj1);
      calculateSatSunHours(obj1);

      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours +
        prev.saturdayHours;

      var update = {
        sundayEnd: obj1.end,
        sundayHours: obj1.satsunHours,
        weeklyHours: obj1.satsunHours + RemainingRegHours,
      };

      console.log(update);
      return { ...prev, ...update };
    });
  }

  //inputs 1. value, 2. prev
  // find min start date
  // find OT Start
  // determine if 1) work start is < OT start && work end is > OT start
  // determine if 1) work start is > OT start
  // determine if 3) work start and work end < OT start
  function mondayStartCB(value) {
    // 1. prev payroll details 2. query 3.workStart 4. workEnd, Hours, overTime
    setDetails((prev) => {
      const RemainingOTHours =
        prev.tueOTHours + prev.wedOTHours + prev.thurOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours;

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(value.$d),
          new Date(prev.mondayEnd),
          'mondayStart',
          'mondayEnd',
          prev.mondayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);
        console.log(dayjs(value.$d).local().format());
        var update = {
          mondayStart: dayjs(value.$d).local().format(),
          mondayOTStart: dayjs(obj1.calcOTStart).local().format(),
          mondayHours: obj1.regHours,
          monOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected && value) {
        //Create Initial Object
        // start, end, prevPayroll, queryStart, queryEnd, calcOTStart, reg, OT,
        //Remaining OT, Remaining Reg
        obj1 = new PrevPayroll(
          new Date(value.$d),
          new Date(prev.mondayEnd),
          payrollz,
          'mondayStart',
          'mondayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.mondayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );
        console.log(obj1);
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        console.log(dayjs(value.$d).local().format());
        update = {
          mondayStart: dayjs(value.$d).local().format(),
          mondayOTStart: dayjs(obj1.workOTStart).local().format(),
          mondayHours: obj1.regHours,
          monOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }

      return { ...prev, ...update };
    });
  }

  function mondayEndCB(value) {
    setDetails((prev) => {
      const RemainingOTHours =
        prev.tueOTHours + prev.wedOTHours + prev.thurOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours;
      console.log(prev);

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(prev.mondayStart),
          new Date(value.$d),
          'mondayStart',
          'mondayEnd',
          prev.mondayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          mondayEnd: value.$d,
          mondayOTStart: obj1.calcOTStart,
          mondayHours: obj1.regHours,
          monOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(prev.mondayStart),
          new Date(value.$d),
          payrollz,
          'mondayStart',
          'mondayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.mondayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );
        console.log(obj1);
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        update = {
          mondayEnd: value.$d,
          mondayOTStart: obj1.calcOTStart,
          mondayHours: obj1.regHours,
          monOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }

      //console.log(update);
      return { ...prev, ...update };
    });
  }

  function tuesdayStartCB(value) {
    setDetails((prev) => {
      const RemainingOTHours =
        prev.monOTHours + prev.wedOTHours + prev.thurOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours;

      if (prevWeekDetected === false && value) {
        var obj1 = new noPrevPayroll(
          new Date(value.$d),
          new Date(prev.tuesdayEnd),
          'tuesdayStart',
          'tuesdayEnd',
          prev.tuesdayOTStart,
          0,
          0
        );

        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);
        console.log(obj1);
        var update = {
          tuesdayStart: value.$d,
          tuesdayOTStart: obj1.calcOTStart,
          tuesdayHours: obj1.regHours,
          tueOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(value.$d),
          new Date(prev.tuesdayEnd),
          payrollz,
          'tuesdayStart',
          'tuesdayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.tuesdayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );

        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        console.log(obj1);
        update = {
          tuesdayStart: value.$d,
          tuesdayOTStart: obj1.calcOTStart,
          tuesdayHours: obj1.regHours,
          tueOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }
      return { ...prev, ...update };
    });
  }

  function tuesdayEndCB(value) {
    setDetails((prev) => {
      const RemainingOTHours =
        prev.monOTHours + prev.wedOTHours + prev.thurOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours;

      if (prevWeekDetected === false && value) {
        var obj1 = new noPrevPayroll(
          new Date(prev.tuesdayStart),
          new Date(value.$d),
          'tuesdayStart',
          'tuesdayEnd',
          prev.tuesdayOTStart,
          0,
          0
        );
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          tuesdayEnd: value.$d,
          tuesdayOTStart: obj1.calcOTStart,
          tuesdayHours: obj1.regHours,
          tueOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(prev.tuesdayStart),
          new Date(value.$d),
          payrollz,
          'tuesdayStart',
          'tuesdayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.tuesdayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1, seterrOverlap);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        update = {
          tuesdayEnd: value.$d,
          tuesdayOTStart: obj1.calcOTStart,
          tuesdayHours: obj1.regHours,
          tueOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }
      return { ...prev, ...update };
    });
  }

  function wednesdayStartCB(value) {
    setDetails((prev) => {
      const RemainingOTHours =
        prev.monOTHours + prev.tueOTHours + prev.thurOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.thursdayHours +
        prev.fridayHours;

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(value.$d),
          new Date(prev.wednesdayEnd),
          'wednesdayStart',
          'wednesdayEnd',
          prev.wednesdayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          wednesdayStart: value.$d,
          wednesdayOTStart: obj1.calcOTStart,
          wednesdayHours: obj1.regHours,
          wedOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(value.$d),
          new Date(prev.wednesdayEnd),
          payrollz,
          'wednesdayStart',
          'wednesdayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.wednesdayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );
        console.log(obj1);
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1, seterrOverlap);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        update = {
          wednesdayStart: value.$d,
          wednesdayOTStart: obj1.calcOTStart,
          wednesdayHours: obj1.regHours,
          wedOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }
      return { ...prev, ...update };
    });
  }

  function wednesdayEndCB(value) {
    setDetails((prev) => {
      const RemainingOTHours =
        prev.monOTHours + prev.tueOTHours + prev.thurOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.thursdayHours +
        prev.fridayHours;

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(prev.wednesdayStart),
          new Date(value.$d),
          'wednesdayStart',
          'wednesdayEnd',
          prev.wednesdayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          wednesdayEnd: value.$d,
          wednesdayOTStart: obj1.calcOTStart,
          wednesdayHours: obj1.regHours,
          wedOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(prev.wednesdayStart),
          new Date(value.$d),
          payrollz,
          'wednesdayStart',
          'wednesdayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.wednesdayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );
        console.log(obj1);
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1, seterrOverlap);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        update = {
          wednesdayEnd: value.$d,
          wednesdayOTStart: obj1.calcOTStart,
          wednesdayHours: obj1.regHours,
          wedOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }

      return { ...prev, ...update };
    });
  }

  function thursdayStartCB(value) {
    setDetails((prev) => {
      const RemainingOTHours =
        prev.monOTHours + prev.tueOTHours + prev.wedOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.fridayHours;

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(value.$d),
          new Date(prev.thursdayEnd),
          'thursdayStart',
          'thursdayEnd',
          prev.thursdayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          thursdayStart: value.$d,
          thursdayOTStart: obj1.calcOTStart,
          thursdayHours: obj1.regHours,
          thurOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(value.$d),
          new Date(prev.thursdayEnd),
          payrollz,
          'thursdayStart',
          'thursdayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.thursdayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1, seterrOverlap);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);

        update = {
          thursdayStart: value.$d,
          thursdayOTStart: obj1.calcOTStart,
          thursdayHours: obj1.regHours,
          thurOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }

      return { ...prev, ...update };
    });
  }

  function thursdayEndCB(value) {
    setDetails((prev) => {
      console.log(value);
      console.log(prev);
      //figure out remaining hours from other days
      const RemainingOTHours =
        prev.monOTHours + prev.tueOTHours + prev.wedOTHours + prev.friOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.fridayHours;

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(prev.thursdayStart),
          new Date(value.$d),
          'thursdayStart',
          'thursdayEnd',
          prev.thursdayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          thursdayEnd: value.$d,
          thursdayOTStart: obj1.calcOTStart,
          thursdayHours: obj1.regHours,
          thurOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(prev.thursdayStart),
          new Date(value.$d),
          payrollz,
          'thursdayStart',
          'thursdayEnd',
          //reg hours
          0,
          //OT Hours
          0,
          prev.thursdayOTStart,
          RemainingOTHours,
          RemainingRegHours
        );
        console.log(obj1);
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1, seterrOverlap);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        update = {
          thursdayEnd: value.$d,
          thursdayOTStart: obj1.calcOTStart,
          thursdayHours: obj1.regHours,
          thurOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }

      return { ...prev, ...update };
    });
  }

  function fridayStartCB(value) {
    setDetails((prev) => {
      const RemainingOTHours =
        prev.monOTHours + prev.tueOTHours + prev.wedOTHours + prev.thurOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours;

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(value.$d),
          new Date(prev.fridayEnd),
          'fridayStart',
          'fridayEnd',
          prev.fridayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          fridayStart: value.$d,
          fridayOTStart: obj1.calcOTStart,
          fridayHours: obj1.regHours,
          friOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        //Create Initial Object
        // start, end, prevPayroll, queryStart, queryEnd, calcOTStart, reg, OT,
        //Remaining OT, Remaining Reg
        obj1 = new PrevPayroll(
          new Date(value.$d),
          new Date(prev.fridayEnd),
          payrollz,
          'fridayStart',
          'fridayEnd',
          prev.fridayOTStart,
          //reg hours
          0,
          //OT Hours
          0,
          RemainingOTHours,
          RemainingRegHours
        );
        console.log(obj1);
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1, seterrOverlap);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        update = {
          fridayStart: value.$d,
          fridayOTStart: obj1.calcOTStart,
          fridayHours: obj1.regHours,
          friOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }

      return { ...prev, ...update };
    });
  }

  function fridayEndCB(value) {
    setDetails((prev) => {
      console.log(value);
      console.log(prev);
      //figure out remaining hours from other days
      const RemainingOTHours =
        prev.monOTHours + prev.tueOTHours + prev.wedOTHours + prev.thurOTHours;
      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours;

      if (prevWeekDetected === false && value) {
        //create initial object
        // start, end, queryStart, queryEnd, calcOTStart, reg, OT
        var obj1 = new noPrevPayroll(
          new Date(prev.fridayStart),
          new Date(value.$d),
          'fridayStart',
          'fridayEnd',
          prev.fridayOTStart,
          0,
          0
        );
        console.log(obj1);
        //check if end time > start time
        CheckStartAndEndTime(obj1);
        //check if daily hour limit is greater than 12 hours
        CheckDailyHourLimit(obj1);
        //Calculate OT Start
        setOTStartTimeNoPrev(obj1);
        //Calculate the Reg and OT Hours
        calculateHours(obj1);

        var update = {
          fridayEnd: value.$d,
          fridayOTStart: obj1.calcOTStart,
          fridayHours: obj1.regHours,
          friOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      } else if (prevWeekDetected === true && value) {
        obj1 = new PrevPayroll(
          new Date(prev.fridayStart),
          new Date(value.$d),
          payrollz,
          'fridayStart',
          'fridayEnd',
          prev.fridayOTStart,
          //reg hours
          0,
          //OT Hours
          0,
          RemainingOTHours,
          RemainingRegHours
        );
        console.log(obj1);
        //check end times > start time
        CheckStartAndEndTime(obj1);
        //check daily limit
        CheckDailyHourLimit(obj1);
        //check overlap
        CheckOverLapOfPayrollzWithPrev(obj1, seterrOverlap);
        //calculate OT Start
        setOTStartTimeWithPrev(obj1);
        //calculate hours with Previous
        calculateHours(obj1);
        update = {
          fridayEnd: value.$d,
          fridayOTStart: obj1.calcOTStart,
          fridayHours: obj1.regHours,
          friOTHours: obj1.OTHours,
          OTHours: obj1.OTHours + RemainingOTHours,
          weeklyHours: obj1.regHours + RemainingRegHours,
        };
      }

      return { ...prev, ...update };
    });
  }

  //check start and end
  //check overlap
  //check if saturdayend is entered
  //must be greater than 0
  function saturdayStartCB(value) {
    setDetails((prev) => {
      var obj1 = new SatSun(
        new Date(value.$d),
        new Date(prev.saturdayEnd),
        payrollz,
        //satHours
        0
      );
      //check if end time > start time
      CheckStartAndEndTime(obj1);
      //check if daily hour limit is greater than 12 hours
      CheckDailyHourLimit(obj1);
      calculateSatSunHours(obj1);

      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours;

      var update = {
        saturdayStart: obj1.start,
        saturdayHours: obj1.satsunHours,
        weeklyHours: obj1.satsunHours + RemainingRegHours,
      };
      return { ...prev, ...update };
    });
  }

  function saturdayEndCB(value) {
    setDetails((prev) => {
      var obj1 = new SatSun(
        new Date(prev.saturdayStart),
        new Date(value.$d),
        payrollz,
        //satHours
        0
      );
      console.log(obj1);
      //check if end time > start time
      CheckStartAndEndTime(obj1);
      //check if daily hour limit is greater than 12 hours
      CheckDailyHourLimit(obj1);
      calculateSatSunHours(obj1);

      const RemainingRegHours =
        prev.mondayHours +
        prev.tuesdayHours +
        prev.wednesdayHours +
        prev.thursdayHours +
        prev.fridayHours +
        prev.saturdayHours;

      var update = {
        saturdayEnd: obj1.end,
        saturdayHours: obj1.satsunHours,
        weeklyHours: obj1.satsunHours + RemainingRegHours,
      };

      console.log(update);
      return { ...prev, ...update };
    });
  }

  //handling submit of form, reset object
  const handleSubmit = (event) => {
    event.preventDefault();
    onPayrollCreate(details);
    setDetails({
      name: '',
      firstName: '',
      lastName: '',
      project: '',
      mondayStart: '',
      mondayEnd: '',
      tuesdayStart: '',
      tuesdayEnd: '',
      wednesdayStart: '',
      wednesdayEnd: '',
      thursdayStart: '',
      thursdayEnd: '',
      fridayStart: '',
      fridayEnd: '',
      saturdayStart: '',
      saturdayEnd: '',
      sundayStart: '',
      sundayEnd: '',
      MonOTStart: '',
      TueOTStart: '',
      WedOTStart: '',
      ThurOTStart: '',
      FriOTStart: '',
      weekStart: '',
      weekEnd: '',
      weekNumber: '',
      createdAt: '',
      approvedBy: {
        user: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          role: '',
          classification: '',
          createdAt: '',
          payType: '',
          management: '',
        },
      },
      weeklyHours: '',
      OTHours: '',
      mondayHours: '',
      monOTHours: '',
      tuesdayHours: '',
      tueOTHours: '',
      wednesdayHours: '',
      wedOTHours: '',
      thursdayHours: '',
      thurOTHours: '',
      fridayHours: '',
      friOTHours: '',
      saturdayHours: '',
      sundayHours: '',
      approved: 'false',
      createdBy: {
        user: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          role: '',
          classification: '',
          createdOn: '',
          payType: '',
          management: '',
        },
      },
    });
  };

  console.log(details);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            overflow: 'hidden',
            height: '85vh',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              height: '85vh',
              width: '100%',
              flexWrap: 'wrap',
              // p: 1,
              // m: 1,
              bgcolor: colors.primary[400],
              borderRadius: 1,
            }}>
            <Box
              sx={{
                width: '100%',
                borderRadius: 1,
                padding: 2,
                overflow: 'auto',
                backgroundColor: colors.primary[400],
              }}>
              <Stack spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className='three-eighty-px'>
                    <Box>
                      <span>
                        Welcome,{' '}
                        <b>
                          {user.firstName} {user.lastName}
                        </b>
                      </span>
                    </Box>
                    <Box>
                      <Select
                        labelId='week-select-label'
                        id='week select'
                        value={weekSelect}
                        onChange={handleWeekSelect}
                        label='Start'>
                        {threeWkBk.map((week, index) => {
                          return (
                            <MenuItem key={index} value={week}>
                              {week}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Select
                        displayEmpty
                        labelId='project-select-label'
                        id='project select'
                        value={projectSelect}
                        onChange={handleProjectSelect}
                        label='Project Select'>
                        {projectOptions.map((project, index) => {
                          return (
                            <MenuItem key={index} value={project.value}>
                              {project.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                  </div>
                  <Box style={{ maxHeight: 500, overflow: 'auto' }}>
                    <Divider sx={{ mb: 2 }} />
                    {/* Start of Sunday  */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='75px'
                      gap='20px'>
                      <Box
                        gridColumn='span 3'
                        backgroundColor={colors.blueAccent[500]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        sx={{ borderRadius: 1 }}>
                        Sun
                      </Box>
                      <Box
                        gridColumn='span 6'
                        backgroundColor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Stack spacing={2}>
                          <DateBox
                            name={details.sundayStart}
                            value={details.sundayStart}
                            field='sundayStart'
                            label='Sunday Start'
                            callBack={sundayStartCB}
                          />
                          <DateBox
                            name={details.sundayEnd}
                            value={details.sundayEnd}
                            field='sundayEnd'
                            label='Sunday End'
                            callBack={sundayEndCB}
                          />
                        </Stack>
                      </Box>
                      <Box gridColumn='span 3'>
                        <Stack>
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.sundayHours} Sun{' '}
                          </Typography>{' '}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  sundayStart: '',
                                  sundayEnd: '',
                                  sundayHours: '',
                                };
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                    {/* End of Sunday  */}
                    {/* Start of Monday  */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='75px'
                      gap='20px'>
                      <Box
                        gridColumn='span 3'
                        backgroundColor={colors.blueAccent[500]}
                        sx={{ borderRadius: 1 }}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        Mon
                      </Box>
                      <Box
                        gridColumn='span 6'
                        backgroundColor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Stack spacing={2}>
                          <DateBox
                            name={details.mondayStart}
                            value={details.mondayStart}
                            field='mondayStart'
                            label='Monday Start'
                            callBack={mondayStartCB}
                          />
                          <DateBox
                            name={details.mondayEnd}
                            value={details.mondayEnd}
                            field='mondayEnd'
                            label='Monday End'
                            callBack={mondayEndCB}
                          />
                        </Stack>
                      </Box>
                      <Box gridColumn='span 3'>
                        <Stack>
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.mondayHours} Reg{' '}
                          </Typography>{' '}
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.monOTHours} OT{' '}
                          </Typography>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  mondayStart: '',
                                  mondayEnd: '',
                                  mondayHours: 0,
                                  MonOTStart: '',
                                  monOTHours: 0,
                                };
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                    {/* End of Monday  */}

                    {/* Start of Tues  */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='75px'
                      gap='20px'>
                      <Box
                        gridColumn='span 3'
                        backgroundColor={colors.blueAccent[500]}
                        sx={{ borderRadius: 1 }}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        Tues
                      </Box>
                      <Box
                        gridColumn='span 6'
                        backgroundColor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Stack spacing={2}>
                          <DateBox
                            name={details.tuesdayStart}
                            value={details.tuesdayStart}
                            field='tuesdayStart'
                            label='Tuesday Start'
                            callBack={tuesdayStartCB}
                          />
                          <DateBox
                            name={details.tuesdayEnd}
                            value={details.tuesdayEnd}
                            field='tuesdayEnd'
                            label='Tuesday End'
                            callBack={tuesdayEndCB}
                          />
                        </Stack>
                      </Box>
                      <Box gridColumn='span 3'>
                        <Stack>
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.tuesdayHours} Reg{' '}
                          </Typography>{' '}
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {details.tueOTHours} OT
                          </Typography>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  tuesdayStart: '',
                                  tuesdayEnd: '',
                                  tuesdayHours: 0,
                                  TueOTStart: '',
                                  tueOTHours: 0,
                                  weeklyHours:
                                    prev.weeklyHours - prev.tuesdayHours,
                                };
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                    {/* End of Tues  */}

                    {/* Start of Wed  */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='75px'
                      gap='20px'>
                      <Box
                        gridColumn='span 3'
                        backgroundColor={colors.blueAccent[500]}
                        sx={{ borderRadius: 1 }}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        Wed
                      </Box>
                      <Box
                        gridColumn='span 6'
                        backgroundColor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Stack spacing={2}>
                          <DateBox
                            name={details.wednesdayStart}
                            value={details.wednesdayStart}
                            field='wednesdayStart'
                            label='Wednesday Start'
                            callBack={wednesdayStartCB}
                          />
                          <DateBox
                            name={details.wednesdayEnd}
                            value={details.wednesdayEnd}
                            field='wednesdayEnd'
                            label='Wednesday End'
                            callBack={wednesdayEndCB}
                          />
                        </Stack>
                      </Box>
                      <Box gridColumn='span 3'>
                        <Stack>
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.wednesdayHours} Reg{' '}
                          </Typography>{' '}
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {details.wedOTHours} OT
                          </Typography>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  wednesdayStart: '',
                                  wednesdayEnd: '',
                                  wednesdayHours: 0,
                                  WedOTStart: '',
                                  wedOTHours: 0,
                                  weeklyHours:
                                    prev.weeklyHours - prev.wednesdayHours,
                                };
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                    {/* End of Wed  */}

                    {/* Start of Thur  */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='75px'
                      gap='20px'>
                      <Box
                        gridColumn='span 3'
                        backgroundColor={colors.blueAccent[500]}
                        sx={{ borderRadius: 1 }}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        Thur
                      </Box>
                      <Box
                        gridColumn='span 6'
                        backgroundColor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Stack spacing={2}>
                          <DateBox
                            name={details.thursdayStart}
                            value={details.thursdayStart}
                            field='thursdayStart'
                            label='Thursday Start'
                            callBack={thursdayStartCB}
                          />
                          <DateBox
                            name={details.thursdayEnd}
                            value={details.thursdayEnd}
                            field='thursdayEnd'
                            label='Thursday End'
                            callBack={thursdayEndCB}
                          />
                        </Stack>
                      </Box>
                      <Box gridColumn='span 3'>
                        <Stack>
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.thursdayHours} Reg{' '}
                          </Typography>{' '}
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {details.thurOTHours} OT
                          </Typography>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  thursdayStart: '',
                                  thursdayEnd: '',
                                  thursdayHours: 0,
                                  ThurOTStart: '',
                                  thurOTHours: 0,
                                  weeklyHours:
                                    prev.weeklyHours - prev.thursdayHours,
                                };
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                    {/* End of Thur  */}

                    {/* Start of Fri  */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='75px'
                      gap='20px'>
                      <Box
                        gridColumn='span 3'
                        backgroundColor={colors.blueAccent[500]}
                        sx={{ borderRadius: 1 }}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        Fri
                      </Box>
                      <Box
                        gridColumn='span 6'
                        backgroundColor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Stack spacing={2}>
                          <DateBox
                            name={details.fridayStart}
                            value={details.fridayStart}
                            field='fridayStart'
                            label='Friday Start'
                            callBack={fridayStartCB}
                          />
                          <DateBox
                            name={details.fridayEnd}
                            value={details.fridayEnd}
                            field='fridayEnd'
                            label='Friday End'
                            callBack={fridayEndCB}
                          />
                        </Stack>
                      </Box>
                      <Box gridColumn='span 3'>
                        <Stack>
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.fridayHours} Reg{' '}
                          </Typography>{' '}
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {details.friOTHours} OT
                          </Typography>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  fridayStart: '',
                                  fridayEnd: '',
                                  fridayHours: 0,
                                  FriOTStart: '',
                                  friOTHours: 0,
                                };
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                    {/* End of Fri  */}

                    {/* Start of Sat  */}
                    <Box
                      display='grid'
                      gridTemplateColumns='repeat(12, 1fr)'
                      gridAutoRows='75px'
                      gap='20px'>
                      <Box
                        gridColumn='span 3'
                        backgroundColor={colors.blueAccent[500]}
                        sx={{ borderRadius: 1 }}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        Sat
                      </Box>
                      <Box
                        gridColumn='span 6'
                        backgroundColor={colors.primary[400]}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Stack spacing={2}>
                          <DateBox
                            name={details.saturdayStart}
                            value={details.saturdayStart}
                            field='saturdayStart'
                            label='Saturday Start'
                            callBack={saturdayStartCB}
                          />
                          <DateBox
                            name={details.saturdayEnd}
                            value={details.saturdayEnd}
                            field='saturdayEnd'
                            label='Saturday End'
                            callBack={saturdayEndCB}
                          />
                        </Stack>
                      </Box>
                      <Box gridColumn='span 3'>
                        <Stack>
                          <Typography
                            sx={{
                              color: colors.blueAccent[500],
                              fontSize: '14px',
                            }}>
                            {' '}
                            {details.saturdayHours} Sat{' '}
                          </Typography>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  saturdayStart: '',
                                  saturdayEnd: '',
                                };
                              });
                            }}>
                            <RiDeleteBin6Line />
                          </button>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                  </Box>
                  {/* End of Sat  */}

                  {errDailyHourLimit || errStartEndFx || errOverlap ? null : (
                    <button className='bg-blue-400 hover:shadow-md font-bold three-eighty-px'>
                      {' '}
                      Submit{' '}
                    </button>
                  )}
                  {prevWeekDetected ? (
                    <Typography
                      sx={{
                        color: colors.redAccent[500],
                        fontSize: '14px',
                      }}>
                      {' '}
                      - Another Timecard for Week Ending {
                        weekSelect
                      } Detected{' '}
                    </Typography>
                  ) : null}
                  <div>
                    {errDailyHourLimit ? (
                      <Typography
                        sx={{
                          color: colors.redAccent[500],
                          fontSize: '14px',
                        }}>
                        Exceed Daily Hour Limit
                      </Typography>
                    ) : null}

                    {errStartEndFx ? (
                      <Typography
                        sx={{
                          color: colors.redAccent[500],
                          fontSize: '14px',
                        }}>
                        Start Time Must Come Before End Time
                      </Typography>
                    ) : null}

                    {errOverlap ? (
                      <Typography
                        sx={{
                          color: colors.redAccent[500],
                          fontSize: '14px',
                        }}>
                        Time Overlaps With Previous Hours
                      </Typography>
                    ) : null}
                  </div>
                </LocalizationProvider>
              </Stack>
            </Box>
          </Box>
        </Box>
      </form>
    </div>
  );
}

export default PayrollCreate2;
