import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import searchImages from './api';
import { AuthProvider } from './context/AuthProvider';
import { SubscriptionProvider } from './context/SubscriptionProvider';
import { NavigationProviderCC } from './context/navigation';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter } from 'react-router-dom';

searchImages();

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
  <AuthProvider>
    <SubscriptionProvider>
      <NavigationProviderCC>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </NavigationProviderCC>
    </SubscriptionProvider>
  </AuthProvider>
);
