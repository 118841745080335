import React, { useState, useEffect, useMemo } from "react";
import { Box, Stack, Typography, useTheme, TextField } from "@mui/material";
import TimeClockNumberPad from "./TimeClockNumberPad";
import TimeClockDisplay from "./TimeClockDisplay";
import TimeClockElaspedDisplay2 from "./TimeClockElaspedDisplay2";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
//dropdown
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
//data
import { useAuth } from "../context/AuthProvider";
//error
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import TimeClockPopover from "./TimeClockPopover";

const TimeClock = ({
	projectList,
	personnelList,
	setTimecardCreated,
	setTimecardCreatedMsg,
	errMsg,
	seterrMsg,
	errState,
	seterrState,
}) => {
	var weekYear = require("dayjs/plugin/weekYear");
	dayjs.extend(weekYear);
	var weekOfYear = require("dayjs/plugin/weekOfYear");
	dayjs.extend(weekOfYear);
	var utc = require("dayjs/plugin/utc");
	dayjs.extend(utc);
	var weekday = require("dayjs/plugin/weekday");
	dayjs.extend(weekday);
	// set Start Time
	const theme = useTheme();
	const { api, token } = useAuth();
	const colors = tokens(theme.palette.mode);
	const [today] = useState(dayjs().format("MM/DD/YYYY"));
	const [isWorkingHours, setisWorkingHours] = useState(false);
	const [display, setDisplay] = useState("");
	const [project1, setProject1] = useState("");
	const [project1Error, setProject1Error] = useState(false);
	const [status, setStatus] = useState("Active");
	const [projectStartTime, setprojectStartTime] = useState(
		dayjs().hour(7).minute(0)
	);
	const [projectEndTime, setprojectEndTime] = useState(
		dayjs().hour(15).minute(30)
	);
	const [person, setPerson] = useState("");
	const [weekEnding] = useState(dayjs().day(6).format("MM/DD/YYYY"));
	const [weekNumber] = useState(dayjs().week());
	const [timeClock, setTimeClock] = useState([]);
	var LocalizedFormat = require("dayjs/plugin/localizedFormat");
	dayjs.extend(LocalizedFormat);

	//notifications popout
	const [anchorElPopOut, setAnchorElPopOut] = useState(null);
	const openPopOut = Boolean(anchorElPopOut);
	const idPopOut = openPopOut ? "Pop Out" : undefined;

	const timeclockColumns = [
		{
			field: "employeeID",
			headerName: "ID",
			minWidth: 25,
			width: 25,
		},
		{
			field: "startTime",
			headerName: "Hours",
			width: 75,
			renderCell: ({ row: { startTime }, row: { status } }, i) => {
				return (
					<Box
						key={i}
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
						backgroundColor={
							(status = "Active"
								? colors.greenAccent[600]
								: colors.redAccent[700])
						}
						borderRadius="4px"
					>
						{<TimeClockElaspedDisplay2 start={startTime} />}
					</Box>
				);
			},
		},

		{
			field: "status",
			headerName: "Status",
			width: 75,
			renderCell: ({ row: { status } }) => {
				return (
					<Box sx={{ m: 0 }}>
						{status === "Active" ? (
							<Chip label="Active" color="primary" size="small" />
						) : (
							<Chip label="Inactive" color="danger" size="small" />
						)}
					</Box>
				);
			},
		},
		{
			field: "more",
			headerName: "",
			width: 25,
			minWidth: 25,
			renderCell: ({ row: { startTime }, row: { status } }, i) => {
				return (
					<Box
						key={i}
						m="-7.5px"
						display="flex"
						justifyContent="center"
						onClick={handleMoreClick}
					>
						{<MoreVertIcon start={startTime} />}
					</Box>
				);
			},
		},
		{
			field: "createdAt",
			headerName: "Created At",
			width: 135,
			renderCell: ({ row: { createdAt } }, i) => {
				return (
					<Typography variant="h9" sx={{ color: "grey" }}>
						{" "}
						{dayjs(createdAt).format("llll")};
					</Typography>
				);
			},
		},
	];

	//more click
	const handleMoreClick = (event) => {
		console.log("Clicked");
		setAnchorElPopOut(event.currentTarget);
		//return {test:"Click"}
	};

	//Create dropdown function for time pickers
	const CreateDropdownComponent = ({
		error,
		data,
		state,
		label,
		callBack,
		id,
	}) => {
		return (
			<FormControl
				variant="standard"
				error={error}
				sx={{
					minWidth: "90%",
					"& .MuiInputLabel-formControl": {
						color: "grey",
					},
					"& .MuiInputLabel-root": {
						backgroundColor: "red",
					},
				}}
			>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					size="small"
					value={state}
					onChange={(prev) => {
						const newValue = prev.target.value;
						callBack(newValue);
					}}
					label="Start"
					sx={{ m: 0, p: 0, height: "1rem" }}
				>
					{data &&
						data.map((value, index) => {
							return (
								<MenuItem key={index} value={value}>
									{value}
								</MenuItem>
							);
						})}
				</Select>
			</FormControl>
		);
	};

	// //update status
	useEffect(() => {
		checkStatus(projectStartTime, projectEndTime);
	}, [projectEndTime, projectStartTime]);

	//takes in dayjs objects
	function checkStatus(start, end) {
		if (dayjs().isBefore(start)) {
			setisWorkingHours(false);
			setStatus("Closed");
		} else if (dayjs().isAfter(start) && dayjs().isBefore(end)) {
			setStatus("Active");
			setisWorkingHours(true);
		} else if (dayjs().isAfter(end)) {
			setStatus("Closed");
			setisWorkingHours(false);
		}
	}

	function changeStatus(obj) {
		if (obj.type === "Start") {
			checkStatus(obj.value, projectEndTime);
		} else if (obj.type === "End") {
			checkStatus(projectStartTime, obj.value);
		}
	}

	useEffect(() => {
		if (api.authenticated) {
			api
				.get("/api/v1/timeclock/unresolved")
				.then((response) => {
					console.log(response);
					let renderedData;
					renderedData = response.data.data[0].results.map((timeclock, i) => ({
						id: timeclock.employeeID + i,
						employeeID: timeclock.employeeID,
						startTime: timeclock.startTime,
						endTime: timeclock.endTime,
						referenceDate: timeclock.referenceDate,
						creditedTime: timeclock.creditedTime,
						deviceName: timeclock.deviceName,
						fullName: timeclock.fullName,
						project: timeclock.project,
						dayOfTheWeek: timeclock.dayOfTheWeek,
						status: timeclock.status,
						createdAt: timeclock.createdAt,
					}));

					setTimeClock(renderedData);
				})
				.catch((err) => {
					setTimeClock([]);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api?.authenticated]);

	//compile time clock details
	useEffect(() => {
		//determine whether post request or put request
		if (timeClock.length > 0) {
			// setUpdateAPI(compileTimeClock())
		} else if (timeClock.length === 0) {
			return;
		}
	}, [timeClock.length]);

	//Number Pad Function
	const onSubmit = (value) => {
		//check if value in array
		console.log("clicked");
		let updatedDisplayString;
		if (value === "Clear") {
			updatedDisplayString = "";
		} else if (value === "Del") {
			updatedDisplayString = display.slice(0, -1);
		} else if (value === "Start") {
			let found;
			if (display) {
				found = personnelList.find(
					(elem) => elem.employeeID === Number(display)
				);
			}

			const newEntry = {
				id: `${timeClock.length}-${today}` + Number(display),
				personnel: found,
				employeeID: Number(display),
				startTime: dayjs(),
				endTime: null,
				weekNumber: weekNumber,
				weekEnding: dayjs().weekday(6).format("MM-DD-YYYY"),
				referenceDate: today,
				creditedTime: 0,
				deviceName: "iPad#3",
				project: project1,
				dayOfTheWeek: dayjs().day(),
				status: "Active",
			};

			const error = checkErrors(newEntry);
			console.log(error);
			console.log(status);
			console.log(newEntry);
			if (
				!error &&
				status === "Active" &&
				typeof newEntry.personnel !== "undefined"
			) {
				setTimeClock((timeClock) => [...timeClock, newEntry]);
				submitTimeClock(newEntry);
				getAllTimeClock();
			}
		} else if (value === "Stop") {
			setDisplay("");
			const foundID = (elem) => elem.employeeID === Number(display);
			const indexToDrop = timeClock.findIndex(foundID);

			if (indexToDrop >= 0) {
				const arrayCopy = timeClock;

				//drop from active list
				const updatedArray = arrayCopy.slice(indexToDrop, -1);

				setTimeClock(updatedArray);
				//update backend
				const updateEntry = timeClock[indexToDrop];

				const start = dayjs(updateEntry.startTime);
				let personfound;
				let timeclockfound;
				if (display) {
					personfound = personnelList.find(
						(elem) => elem.employeeID === Number(display)
					);
					timeclockfound = timeClock.find(
						(elem) => elem.employeeID === Number(display)
					);
					setPerson(personfound);
				}

				const update = {
					employeeID: display,
					personnel: personfound,
					timeclock: timeclockfound,
					weekEnding: dayjs().weekday(6).format("MM-DD-YYYY"),
					endTime: dayjs().format("MMMM D, YYYY h:mm A"),
					creditedTime: dayjs().diff(start, "hour", true),
					dayOfTheWeek: dayjs().day(),
					status: "Closed",
				};
				const final = { ...updateEntry, ...update };
				updateTimeClock(final);
			} else if (indexToDrop > 0) {
				//run code
				const arrayCopy = timeClock;

				//drop from active list
				const updatedArray = arrayCopy.splice(indexToDrop, 1);
				setTimeClock(updatedArray);
				//update backend
				const updateEntry = timeClock[indexToDrop];
				const start = dayjs(updateEntry.startTime);

				const update = {
					employeeID: display,
					endTime: dayjs().format("MMMM D, YYYY h:mm A"),
					creditedTime: dayjs().diff(start, "hour", true),
				};
				const final = { ...updateEntry, ...update };
				updateTimeClock(final);
			}
		} else {
			if (display) {
				updatedDisplayString = display + value;
			} else {
				updatedDisplayString = value;
			}
		}

		//add value to array
		setDisplay(updatedDisplayString);
	};

	// TimeClock Post
	const submitTimeClock = async (details) => {
		console.log("clicked");
		try {
			const timeclock = await api.post("/api/v1/timeclock", details);
			setTimecardCreated(true);
			setTimecardCreatedMsg(`Timecard Created for ${person?.fullName}`);
		} catch (error) {
			seterrState(true);
			seterrMsg(error.response?.data?.error);
			const foundIndex = timeClock.findIndex((elem) => elem.id === details.id);
			const updatedArray = timeClock.slice(foundIndex, -1);

			setTimeClock(updatedArray);
		}
	};

	// TimeClock Post
	const getAllTimeClock = async () => {
		try {
			await api
				.get(`/api/v1/timeclock?project=${project1}&status=Active`)
				.then((response) => {
					let renderedData;
					renderedData = response.data.data.map((timeclock, i) => ({
						id: timeclock.employeeID + i,
						mongoObjectID: timeclock._id,
						employeeID: timeclock.employeeID,
						startTime: timeclock.startTime,
						endTime: timeclock.endTime,
						referenceDate: timeclock.referenceDate,
						creditedTime: timeclock.creditedTime,
						deviceName: timeclock.deviceName,
						fullName: timeclock.fullName,
						project: timeclock.project,
						dayOfTheWeek: timeclock.dayOfTheWeek,
						status: timeclock.status,
					}));

					setTimeClock(renderedData);
				});
		} catch (error) {}
	};

	// Update Post - url, data, config
	const updateTimeClock = async (details) => {
		try {
			await api.put("/api/v1/timeclock", details);
		} catch (error) {}
	};

	//Error Handling
	const checkErrors = (newEntry) => {
		setProject1Error(false);
		let error = false;
		//check if project empty
		if (newEntry.project === "") {
			setProject1Error(true);
			seterrState(true);
			seterrMsg("Please select a project ");
			error = true;
		} else if (
			//check if display empty
			newEntry.employeeID === "" ||
			newEntry.employeeID === undefined ||
			newEntry.employeeID === null
		) {
			seterrState(true);
			seterrMsg("Please Enter An ID ");
			error = true;
		} else if (status === "Closed") {
			seterrState(true);
			seterrMsg("Timeclock is now closed");
		} else {
			const result = timeClock.find(
				(elem) =>
					elem.employeeID === Number(display) && elem.status === "Active"
			);

			if (result) {
				seterrState(true);
				seterrMsg(
					"There is already an active timeclock with that Employee ID "
				);
				error = true;
			}
		}
		return error;
	};

	return (
		<Box>
			<Stack direction="row" spacing={1}>
				<Stack>
					<Box sx={{ border: "solid", p: 1 }}>
						<Stack direction="row" spacing={1}>
							<Box align="right" sx={{ color: "grey" }}>
								<Typography> Device Name </Typography>
								<Typography> Project </Typography>
								<Typography> Date </Typography>
								<Typography> Project Start </Typography>
								<Typography> Project End </Typography>
								<Typography> Week Ending </Typography>
								<Typography> Max Time </Typography>
								<Typography> Current Time </Typography>
								<Typography> Status </Typography>
								<Typography> Display </Typography>
							</Box>
							<Box sx={{ width: "110px" }}>
								<Typography> iPad #3 </Typography>
								<CreateDropdownComponent
									error={project1Error}
									data={projectList}
									state={project1}
									label="Project"
									callBack={setProject1}
									id="project1"
								/>
								<Typography> {today} </Typography>
								<Box>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<TimePicker
											{...projectStartTime}
											value={projectStartTime}
											renderInput={(params) => {
												return <TextField {...params} />;
											}}
											slots={{
												OpenPickerIcon: (props) => (
													<AccessTimeIcon {...props} />
												),
											}}
											referenceDate={projectStartTime}
											slotsProps={{
												actionBar: {
													actions: ["clear"],
												},
											}}
											onChange={(e) => {
												const obj = { type: "Start", value: e };
												changeStatus(obj);
												setprojectStartTime(e);
											}}
											sx={{
												"& .MuiInputBase-input": {
													m: 0,
													p: 0,
													color: "black",
												},
												"& .MuiOutlinedInput-input": {
													color: colors.greenAccent[300],
												},
											}}
										/>
									</LocalizationProvider>
								</Box>
								<Box>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<TimePicker
											{...projectEndTime}
											value={projectEndTime}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														sx={{
															svg: { color: colors.redAccent[500] },
															input: { color: colors.redAccent[500] },
															label: { color: colors.redAccent[500] },
														}}
													/>
												);
											}}
											slots={{
												OpenPickerIcon: (props) => (
													<AccessTimeIcon {...props} />
												),
											}}
											referenceDate={projectEndTime}
											slotsProps={{
												actionBar: {
													actions: ["clear"],
												},
											}}
											onChange={(e) => {
												const obj = { type: "End", value: e };
												changeStatus(obj);
												setprojectEndTime(e);
											}}
											sx={{
												"& .MuiInputBase-input": {
													m: 0,
													p: 0,
													color: "black",
												},
												"& .MuiOutlinedInput-input": {
													color: colors.redAccent[300],
												},
											}}
										/>
									</LocalizationProvider>
								</Box>
								<Typography> {weekEnding} </Typography>
								<Typography> 8 Hours </Typography>
								<TimeClockDisplay
									sx={{
										color:
											isWorkingHours === true
												? colors.greenAccent[500]
												: colors.redAccent[500],
									}}
								/>
								<Typography
									sx={{
										color:
											isWorkingHours === true
												? colors.greenAccent[500]
												: colors.redAccent[500],
									}}
								>
									{isWorkingHours === true ? "Active" : "Closed"}
								</Typography>
								<Typography> {display} </Typography>
							</Box>
						</Stack>
					</Box>
					<TimeClockNumberPad
						color={colors.greenAccent[500]}
						onSubmit={onSubmit}
					/>{" "}
				</Stack>

				<Box mt="0.5rem" width="240px">
					<DataGrid
						rowHeight={35}
						columnHeaderHeight={25}
						hideFooter={true}
						rows={timeClock}
						columns={timeclockColumns}
					/>
					<TimeClockPopover
						message={"Hi"}
						anchorElPopOut={anchorElPopOut}
						openPopOut={openPopOut}
						idPopOut={idPopOut}
						setAnchorElPopOut={setAnchorElPopOut}
					/>
				</Box>
			</Stack>
		</Box>
	);
};

export default TimeClock;
