import { Outlet, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { useAuth } from '../context/AuthProvider';

const PrivatePaidRoutes = () => {
  let { success, subscription } = useAuth();

  const hasActiveOrTrialSubscription = ['active', 'trialing'].includes(subscription?.status);

  return <Outlet />;
};

export default PrivatePaidRoutes;
