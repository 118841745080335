import { Box } from '@mui/material';
import LandingPageNavBar from '../components/LandingPageNavBar';
import LandingPageFooter from '../components/LandingPageFooter';
import SubscriptionOptions from '../components/SubscriptionOptions';
// import { useNavigate } from 'react-router';
import SignUpModal from '../components/SignUpModal';
import { useState } from 'react';

const LandingFeaturesPage = () => {
  // const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null)

  const handleSubscriptionSelect = (price) => {
    setSelectedPrice(price)
    setShowModal(true)
  };

  const handleCancelSignup = () => {
    setShowModal(false);
  }


  return (
    <Box>
      <LandingPageNavBar />
      <Box sx={{ my: 20, mx: 2 }}>
        <SubscriptionOptions onSelect={handleSubscriptionSelect} />
        <SignUpModal open={showModal} onCancel={handleCancelSignup} price={selectedPrice} />
      </Box>
      <LandingPageFooter />
    </Box>
  );
};

export default LandingFeaturesPage;
