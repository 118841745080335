import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens, useMode } from "../theme";
import { InputBase, Button } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkMode";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import NotificationsPopover from "./NotificationsPopover";
import LogoutPopover from "./LogoutPopover";
import { useAuth } from "../context/AuthProvider";

const TopBarAdmin = ({ notificationsNumber }) => {
	const [theme, colorMode] = useMode();
	// const theme = useTheme();
	// const colors = tokens(theme.palette.mode);
	const { user } = useAuth();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	//side bar menu
	const [anchorElMenu, setAnchorElMenu] = useState(null);
	//notifications popout
	const [anchorElPopOut, setAnchorElPopOut] = useState(null);
	//logout popout
	const [anchorElLogOutPopOut, setAnchorElLogOutPopOut] = useState(null);
	const [options, setoptions] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(1);

	//sidebar menu popout
	const handleClick = (event) => {
		setAnchorElMenu(event.currentTarget);
	};
	const open = Boolean(anchorElMenu);
	const handleClose = () => {
		setAnchorElMenu(null);
	};

	//Notifications Popover
	const openPopOut = Boolean(anchorElPopOut);
	const idPopOut = openPopOut ? "Pop Out" : undefined;

	//logout popout
	const openPopOut2 = Boolean(anchorElLogOutPopOut);
	const idPopOut2 = openPopOut2 ? "Pop Out" : undefined;

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setAnchorElMenu(null);
	};

	useEffect(() => {
		const userOptions = [
			{ name: "Home", path: "/" },
			{ name: "Create A Timecard", path: "/PayrollCreatePage" },
			{ name: "My TimeCards", path: "/UserPageTimecards" },
			{ name: "LogOut", path: "/LogOut" },
		];

		const PMOptions = [
			{ name: "Home", path: "/" },
			{ name: "Create A Timecard", path: "/PayrollCreatePage" },
			{ name: "All TimeCards", path: "/PMPageTimecards" },
			{ name: "LogOut", path: "/LogOut" },
		];

		const AdminOptions = [
			{ name: "Home", path: "/" },
			{ name: "Create A Timecard", path: "/PayrollCreatePage" },
			{ name: "All TimeCards", path: "/AdminPageTimecards" },
			{ name: "Users", path: "/AdminPageUsers" },
			{ name: "Projects", path: "/AdminPageProjects" },
			{ name: "Personnel", path: "/AdminPagePersonnel" },
			{ name: "Mappings", path: "/MappingPage" },
			{ name: "Ratebook", path: "/RatebookPage" },
			{ name: "Payroll", path: "/PayrollProcessPage" },
			{ name: "LogOut", path: "/LogOut" },
		];

		if (user.role === "Company Admin") {
			setoptions(AdminOptions);
		} else if (user.role === "Project Manager") {
			setoptions(PMOptions);
		} else {
			setoptions(userOptions);
		}
	}, [user.role]);

	//notifications
	const handlePopOutClick = (event) => {
		setAnchorElPopOut(event.currentTarget);
	};

	//logout
	const handleLogOutPopOutClick = (event) => {
		setAnchorElLogOutPopOut(event.currentTarget);
	};

	return (
		<Box display="flex" justifyContent="space-between" p={2}>
			{/* SEARCH BAR */}
			{!isSmallScreen ? (
				<Box display="flex" backgroundColor="#444969" borderRadius="3px">
					<InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
					<IconButton type="button" sx={{ p: 1 }}>
						<SearchOutlinedIcon />
					</IconButton>
				</Box>
			) : (
				<Box sx={{ px: ".5rem", pt: "5px", display: "flex" }}>
					<MenuIcon onClick={handleClick} />
					<Box sx={{ position: "fixed" }}>
						<Menu
							id="lock-menu"
							anchorEl={anchorElMenu}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								"aria-labelledby": "lock-button",
								role: "listbox",
							}}
						>
							{options.map((option, index) => (
								<MenuItem
									key={option.name}
									selected={index === selectedIndex}
									onClick={(event) => handleMenuItemClick(event, index)}
								>
									<Link to={option.path}> {option.name} </Link>
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Box>
			)}

			{/* ICONS */}
			<Box display="flex" alignItems="right" sx={{ px: "1rem" }}>
				<IconButton onClick={colorMode.toggleColorMode}>
					{theme.palette.mode === "dark" ? (
						<DarkModeOutlinedIcon />
					) : (
						<LightModeIcon />
					)}
				</IconButton>
				<IconButton onClick={handlePopOutClick}>
					<Badge
						aria-describedby={idPopOut}
						badgeContent={notificationsNumber}
						color={"error"}
					>
						<NotificationsNoneOutlinedIcon />
					</Badge>
				</IconButton>
				<NotificationsPopover
					message={notificationsNumber}
					anchorElPopOut={anchorElPopOut}
					openPopOut={openPopOut}
					idPopOut={idPopOut}
					setAnchorElPopOut={setAnchorElPopOut}
				/>
				<IconButton component={Link} to="/settings">
					<SettingsOutlinedIcon />
				</IconButton>
				<IconButton onClick={handleLogOutPopOutClick}>
					<PersonOutlineOutlinedIcon />
				</IconButton>
				<LogoutPopover
					message={notificationsNumber}
					anchorElPopOut={anchorElLogOutPopOut}
					openPopOut={openPopOut2}
					idPopOut={idPopOut2}
					setAnchorElPopOut={setAnchorElLogOutPopOut}
				/>
			</Box>
		</Box>
	);
};

export default TopBarAdmin;
