import { Grid, Stack, Box } from "@mui/material";
import Register from "../components/Register";
import RegisterTable from "../components/RegisterTable";
import { Navigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

function RegisterPage({ success }) {
	const isAboveMediumScreens = useMediaQuery("(min-width: 800px)");

	return (
		<Box>
			{success ? (
				<Navigate replace to="/Dashboard" />
			) : (
				<Box sx={isAboveMediumScreens ? null : { py: "20px" }}>
					<Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justifyContent="center"
						style={{ minHeight: "100vh" }}
					>
						<Stack
							direction={isAboveMediumScreens ? "row" : "column"}
							alignItems="center"
							justifyContent="center"
							spacing={5}
						>
							<Grid
								item
								xs={isAboveMediumScreens ? 5 : 8}
								sx={{ width: "350px" }}
								elevation={3}
							>
								<RegisterTable />{" "}
							</Grid>
							<Grid
								item
								xs={isAboveMediumScreens ? 4 : 7}
								sx={
									isAboveMediumScreens ? { width: "305px" } : { width: "430px" }
								}
								elevation={3}
							>
								<Register />{" "}
							</Grid>
						</Stack>
					</Grid>
				</Box>
			)}
		</Box>
	);
}

export default RegisterPage;
