import dayjs from 'dayjs';

//future - get user information from axios request

//function goes back three weeks from current and creates a list of weekending dates
function threeWeekLookBack() {
  const currentWeekEnding = dayjs()
    .day(6)
    .hour(15)
    .minute(30)
    .format('MM/DD/YYYY');
  const oneWeekEnding = dayjs()
    .day(-1)
    .hour(15)
    .minute(30)
    .format('MM/DD/YYYY');
  const twoWeekEnding = dayjs()
    .day(-8)
    .hour(15)
    .minute(30)
    .format('MM/DD/YYYY');
  const threeWeekEnding = dayjs()
    .day(-15)
    .hour(15)
    .minute(30)
    .format('MM/DD/YYYY');
  const fourWeekEnding = dayjs()
    .day(-22)
    .hour(15)
    .minute(30)
    .format('MM/DD/YYYY');
  const fiveWeekEnding = dayjs()
    .day(-29)
    .hour(15)
    .minute(30)
    .format('MM/DD/YYYY');
  const sixWeekEnding = dayjs()
    .day(-36)
    .hour(15)
    .minute(30)
    .format('MM/DD/YYYY');

  const threeWkBk = [
    currentWeekEnding,
    oneWeekEnding,
    twoWeekEnding,
    threeWeekEnding,
  ];

  const sixWkBkDesc = [
    sixWeekEnding,
    fiveWeekEnding,
    fourWeekEnding,
    threeWeekEnding,
    twoWeekEnding,
    oneWeekEnding,
    currentWeekEnding,
  ];

  return {
    threeWkBk,
    sixWkBkDesc,
  };
}

export default threeWeekLookBack;
