//returns 1) boolean for loading 2) data 3) error
import { useState } from 'react';

function usePath(path) {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL
  const [fullPath] = useState(`${baseURL}` + path);

  //console.log('Full Path = ', fullPath);
  return { fullPath, baseURL };
}

export default usePath;
