import * as React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import TopBarAdmin from "../components/TopBarAdmin";
import RateBook from "../ratebook";
import usePath from "../hooks/fetchPath";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function RatebookPage() {
	const { api } = useAuth();
	const { baseURL } = usePath("/api/v1/personnel/");
	const [ratebook, setRatebook] = useState([]);
	const [listData, setListData] = useState([]);
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [sentMapping, setsentMapping] = useState(false);
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");

	function checkFailed(then) {
		return function (responses) {
			const someFailed = responses.some((response) => response.error);

			if (someFailed) {
				throw responses;
			}

			return then(responses);
		};
	}

	useEffect(() => {
		if (api.authenticated) {
			api
				.get(`/api/v1/ratebook/?sort=lastName&limit=1000`)
				.then((response) => {
					setRatebook(response.data.data);
					setsentMapping(false);
				})
				.catch((err) => {
					console.log("FAILED", { err });
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseURL, api?.authenticated]);

	useEffect(() => {
		async function GetResults(data) {
			try {
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);

				setnotificationsNumber(response.data.data[0].results.length);
			} catch (err) {
				console.log("Failed", { err });
				setErrState(true);
				seterrMsg("Error Loading Data");
			}
		}
		GetResults();
	}, []);

	useEffect(() => {
		if (api.authenticated) {
			Promise.all([
				api.get("/api/v1/ratebook/craftlist"),
				api.get("/api/v1/ratebook/classificationlist"),
				api.get("/api/v1/ratebook/booklist"),
				api.get("/api/v1/projects/list"),
			])
				.then(
					checkFailed(([craft, classification, book, project]) => {
						setListData((prev) => ({
							...prev,
							craft,
							classification,
							book,
							project,
						}));
					})
				)
				.catch((err) => {
					console.log("FAIL", err);
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api?.authenticated]);

	return (
		<div>
			<Box sx={{ height: "100dvh" }}>
				{errState ? (
					<Alert
						icon={<RunningWithErrorsIcon fontSize="inherit" />}
						severity="error"
					>
						{errMsg}
					</Alert>
				) : null}
				<TopBarAdmin notificationsNumber={notificationsNumber} />
				<RateBook
					data={ratebook}
					listData={listData}
					setsentMapping={setsentMapping}
				/>
			</Box>
		</div>
	);
}

export default RatebookPage;
