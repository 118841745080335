import React, { useState } from 'react';
import {
  Stack,
  Grid,
  Typography,
  Box,
  Button,
  useTheme,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { tokens } from '../theme';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { updateArrayByIndexStart } from '../utils/replaceObjectInArray';
import {
  calculateObjectsInArray,
  calculateWeekendHoursArray,
} from '../utils/calculateObjectsInArray';

const PayrollCreateRow = ({
  day,
  date,
  alternateColors,
  log,
  setLog,
  format,
  shorthand,
  showRowColor1,
  showRowColor2,
  regHours,
  setRegHours,
  OTHours,
  setOTHours,
  SatHours,
  setSatHours,
  SunHours,
  setSunHours,
}) => {
  //themes
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const altColor1 =
    alternateColors === 'true' ? colors.grey[900] : colors.grey[800];
  const grey = alternateColors === 'true' ? colors.grey[900] : colors.grey[800];
  const [subtotal, setSubtotal] = useState(8.5);

  //index of array [log]
  const CreateTimePickerComponent = ({
    day,
    label,
    index,
    value,
    setvalue,
    log,
    setLog,
    format,
    referenceDate,
  }) => {
    const referenceDateFormatted = dayjs(referenceDate, 'MM/DD/YYYY');
    return (
      <Stack direction='row'>
        <Typography
          sx={{
            pt: 1,
            color: label === 'Start:' ? colors.grey[400] : colors.grey[500],
          }}>
          {' '}
          {label}
        </Typography>
        <DemoContainer components={['TimePicker']} sx={{ p: 1.25 }}>
          <DemoItem>
            <TimePicker
              {...value}
              value={value}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{
                      svg: { color: colors.greenAccent[500] },
                      input: { color: colors.greenAccent[500] },
                      label: { color: colors.greenAccent[500] },
                    }}
                  />
                );
              }}
              slots={{
                OpenPickerIcon: (props) => (
                  <AccessTimeIcon
                    {...props}
                    sx={{
                      m: 0,
                      p: 0,
                      '&:hover': {
                        color: colors.greenAccent[500],
                      },
                    }}
                  />
                ),
              }}
              referenceDate={referenceDateFormatted}
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              onChange={(e) => {
                //updates day log ie sunday [{sun1}, {sun2},]

                const update = updateArrayByIndexStart(log, label, index, e);
                console.log(update);
                //passing in update [array] that contain new datetime in the start and end fields
                switch (format) {
                  case 'weekday':
                    const [sum, reg, OT, finalWeekdayLog] =
                      calculateObjectsInArray(update);
                    setSubtotal(`${Math.round(sum * 100) / 100}`);
                    setRegHours(`${Math.round(reg * 100) / 100}`);
                    setOTHours(`${Math.round(OT * 100) / 100}`);
                    setLog(finalWeekdayLog);

                    break;

                  case 'saturday':
                    const [saturdayHours, finalSaturdayLog] =
                      calculateWeekendHoursArray(update);
                    setSatHours(`${Math.round(saturdayHours * 100) / 100}`);
                    setLog(finalSaturdayLog);
                    break;

                  case 'sunday':
                    const [sundayHours, finalSundayLog] =
                      calculateWeekendHoursArray(update);
                    setSunHours(`${Math.round(sundayHours * 100) / 100}`);
                    setLog(finalSundayLog);
                    break;

                  default:
                    break;
                }
              }}
              sx={{
                '& .MuiInputBase-input': {
                  m: 0.1,
                  p: 0.1,
                  color: 'black',
                },
                '& .MuiOutlinedInput-input': {
                  m: 0,
                  p: 0,
                  color: colors.grey[100],
                },
                m: 0,
                p: 0,
              }}
            />
          </DemoItem>
        </DemoContainer>
      </Stack>
    );
  };

  //function to return Typography based on format
  function formatTypography() {
    switch (format) {
      case 'weekday':
        return (
          <Box>
            <Stack direction='row'>
              <Typography sx={{ color: colors.grey[300], pr: 0.5 }}>
                Gross:{' '}
              </Typography>
              <Typography
                sx={{
                  color:
                    subtotal > 0 ? colors.greenAccent[500] : colors.grey[300],
                }}>
                {subtotal}
              </Typography>
            </Stack>
            <Stack direction='row'>
              <Typography sx={{ color: colors.grey[300], pr: 0.5 }}>
                Reg:{' '}
              </Typography>
              <Typography
                sx={{
                  color:
                    regHours > 0 ? colors.greenAccent[500] : colors.grey[300],
                  pr: 0.5,
                }}>
                {' '}
                {regHours}
              </Typography>
            </Stack>
            <Stack direction='row'>
              <Typography sx={{ color: colors.grey[300], pr: 0.5 }}>
                OT:{' '}
              </Typography>
              <Typography
                sx={{
                  color:
                    OTHours > 0 ? colors.greenAccent[500] : colors.grey[300],
                  pr: 0.5,
                }}>
                {' '}
                {OTHours}{' '}
              </Typography>
            </Stack>
          </Box>
        );
      case 'saturday':
        return (
          <Box>
            <Stack direction='row'>
              <Typography sx={{ color: colors.grey[300], pr: 0.5 }}>
                Sat:{' '}
              </Typography>
              <Typography
                sx={{
                  color:
                    SatHours > 0 ? colors.greenAccent[500] : colors.grey[300],
                  pr: 0.5,
                }}>
                {' '}
                {SatHours}{' '}
              </Typography>
            </Stack>
          </Box>
        );
      case 'sunday':
        return (
          <Box>
            <Stack direction='row'>
              <Typography sx={{ color: colors.grey[300], pr: 0.5 }}>
                Sun:{' '}
              </Typography>
              <Typography
                sx={{
                  color:
                    SunHours > 0 ? colors.greenAccent[500] : colors.grey[300],
                  pr: 0.5,
                }}>
                {' '}
                {SunHours}{' '}
              </Typography>
            </Stack>
          </Box>
        );
      default:
        break;
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent='center'
      wrap='nowrap'
      sx={{ mx: 1, px: 1, py: 0.3, minWidth: '125px' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* Column 1 Start */}
        <Grid
          item
          xs={2}
          sx={{ backgroundColor: altColor1, minWidth: '125px' }}>
          <Stack direction='row' spacing={1}>
            <Box>
              <Typography>{day}</Typography>
              <Typography sx={{ color: colors.greenAccent[500] }}>
                {date}
              </Typography>
            </Box>
            <Box>
              <Button
                variant='text'
                size={'small'}
                sx={{
                  color: colors.blueAccent[500],
                  p: 0,
                  m: 0,
                  minWidth: 0,
                }}
                onClick={(e) => {
                  const update = log.map(
                    ({ reg, OT, start, end, id, referenceDate, project }) => ({
                      id: id,
                      referenceDate: referenceDate,
                      project: project,
                      reg: 0,
                      OT: 0,
                      start: null,
                      end: null,
                    })
                  );
                  setSubtotal(0);
                  setRegHours(0);
                  setOTHours(0);
                  setLog(update);
                }}>
                Clear
              </Button>
            </Box>
          </Stack>
        </Grid>
        {/* Column 2 Start */}
        <Grid item xs={2.5} sx={{ minWidth: '135px' }}>
          <CreateTimePickerComponent
            label='Start:'
            index={0}
            value={log[0].start}
            log={log}
            setLog={setLog}
            format={format}
            referenceDate={date}
            day={day}
          />
          <CreateTimePickerComponent
            label='End:'
            index={0}
            value={log[0].end}
            log={log}
            setLog={setLog}
            format={format}
            referenceDate={date}
            day={day}
          />
        </Grid>
        <Grid item xs={2.5} sx={showRowColor1}>
          <CreateTimePickerComponent
            label='Start:'
            index={1}
            value={log[1].start}
            log={log}
            setLog={setLog}
            format={format}
            referenceDate={date}
            day={day}
          />
          <CreateTimePickerComponent
            label='End:'
            index={1}
            value={log[1].end}
            log={log}
            setLog={setLog}
            format={format}
            referenceDate={date}
            day={day}
          />
        </Grid>

        <Grid item xs={2.5} sx={showRowColor2}>
          <CreateTimePickerComponent
            label='Start:'
            index={2}
            value={log[2].start}
            log={log}
            setLog={setLog}
            format={format}
            referenceDate={date}
            day={day}
          />
          <CreateTimePickerComponent
            label='End:'
            index={2}
            value={log[2].end}
            log={log}
            setLog={setLog}
            format={format}
            referenceDate={date}
            day={day}
          />
        </Grid>

        <Grid item xs={1.5} sx={{ backgroundColor: grey, minWidth: '125px' }}>
          {formatTypography()}
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default PayrollCreateRow;
