import dayjs, { Dayjs } from 'dayjs';
//must take an x axis array, and query API data for y axis array

function filteredBarGraphData(xAxisData, ApiData) {
  if (ApiData.length === 0) {
    return;
  }
  //convert all ApiData to correct date format
  for (let i = 0, len = ApiData.length; i < len; i++) {
    const editWeekend = dayjs(ApiData[i].weekEnd).format('MM/DD/YYYY');
    ApiData[i].weekEnd = editWeekend;
  }

  var filteredBarArray = [];
  //loop through the weekEnding dates
  for (let i = 0, len = xAxisData.length; i < len; i++) {
    //filter the API data by weekEnding
    const result2 = ApiData.filter((e) => e.weekEnd === xAxisData[i]);

    //add over different properties of multiple instances of filter
    const regHours = result2.reduce((accumulator, object) => {
      return accumulator + object.regHours;
    }, 0);

    const OTHours = result2.reduce((accumulator, object) => {
      return accumulator + object.overtimeHours;
    }, 0);

    const saturdayHours = result2.reduce((accumulator, object) => {
      return accumulator + object.satHours;
    }, 0);

    const sundayHours = result2.reduce((accumulator, object) => {
      return accumulator + object.sunHours;
    }, 0);

    //
    const date = xAxisData[i];
    const regHoursColor = 'hsl(72, 70%, 50%)';
    const OTHoursColor = 'hsl(96, 70%, 50%)';
    const saturdayHoursColor = 'hsl(106, 70%, 50%)';
    const sundayHoursColor = 'hsl(256, 70%, 50%)';
    const obj = {
      date,
      regHours,
      regHoursColor,
      OTHours,
      OTHoursColor,
      saturdayHours,
      saturdayHoursColor,
      sundayHours,
      sundayHoursColor,
    };
    filteredBarArray.push(obj);
  }

  return filteredBarArray;
}

export default filteredBarGraphData;
