import { Box, Stack, Grid, Typography, useTheme } from '@mui/material';
import LandingPageNavBar from '../components/LandingPageNavBar';
import LandingPageFooter from '../components/LandingPageFooter';
import EmailIcon from '@mui/icons-material/Email';
import { tokens } from '../theme';

function ContactPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //list item hover
  // let item =
  // react functional component

  return (
    <Box>
      <LandingPageNavBar />
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'>
        <Grid
          item
          xs={3}
          sx={{
            mt: '20%',
          }}
          elevation={3}>
          <Box
            sx={{
              width: '90%',
              minHeight: '45dvh',
              height: '90%',
              mt: '0.5rem',
              p: '0 0.5rem',
            }}>
            <Typography variant='h2' sx={{ color: colors.greenAccent[500] }}>
              {' '}
              Contact Us{' '}
            </Typography>
            <Stack direction='row' justifyContent='space-between'>
              <Box>
                <Stack direction='row' spacing={1}>
                  <EmailIcon />
                  <Typography> daily.timecard.info@gmail.com </Typography>
                </Stack>
              </Box>
            </Stack>
            <Typography sx={{ color: colors.grey[500] }}>
              {' '}
              We will typically respond within 48 hours{' '}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <LandingPageFooter />
    </Box>
  );
}

export default ContactPage;
