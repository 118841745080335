export const billingScheduleOptions = [
  { label: 'Monthly', key: 'monthly' },
  { label: 'Semi-Annually', key: 'semiAnnually' },
  { label: 'Annually', key: 'annually' },
];

export const getBillingSchedule = (plan) => {
  if (!plan) {
    return billingScheduleOptions[0];
  }

  if (plan.interval === 'month' && plan.interval_count === 1) {
    return billingScheduleOptions[0];
  } else if (plan.interval === 'month' && plan.interval_count === 6) {
    return billingScheduleOptions[1];
  } else if (plan.interval === 'year') {
    return billingScheduleOptions[2];
  }
};
