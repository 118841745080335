import { useState, useEffect } from "react";
import Team from "../team";
import { Box } from "@mui/material";
import TopBarAdmin from "../components/TopBarAdmin";
import useFetch from "../hooks/fetchAPI";
import usePath from "../hooks/fetchPath";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function AdminPageUsers({ auth }) {
	const { api } = useAuth();
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");
	const [selectedDataID, setselectedDataID] = useState([]);
	const [notificationsNumber, setnotificationsNumber] = useState(0);

	const { baseURL } = usePath("/api/v1/users");
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 0,
		pageSize: 50,
	});

	//load all user data
	const { data, loading, error } = useFetch("/api/v1/projects/list");

	useEffect(() => {
		async function GetResults(data) {
			try {
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);

				setnotificationsNumber(response.data.data[0].results.length);
			} catch (err) {
				console.log("Failed", { err });
				setErrState(true);
				seterrMsg("Error Loading Data");
			}
		}
		GetResults();
	}, []);

	//updating state
	const deleteById = (id) => {
		const copy = [...pageState.data];
		//filter keeps all id thats not selected
		const filteredData = copy.filter((item) => item._id !== id);
		// Do something with the filtered data
		setPageState({ ...pageState, data: filteredData });
	};

	//update state
	const handleRemoveMultipleItems = () => {
		selectedDataID.forEach((id) => deleteById(id));
	};

	//create call back to edit payrollz using an array of id
	async function deleteManyUsers() {
		var cannotDelete = false;
		//Check if any of the selected are already approved
		selectedDataID.map((id) => {
			const search = pageState.data.find((user) => user._id === id);
			if (!search) {
				seterrMsg("Cannot Delete Approved Timecards");
				cannotDelete = true;
			}
			return search;
		});
		if (!cannotDelete) {
			await Promise.all(
				selectedDataID.map((id) => api.delete(`/api/v1/users/${id}`))
			)
				.catch(function (err) {})
				.finally(handleRemoveMultipleItems());
		}
	}

	useEffect(() => {
		if (api.authenticated) {
			const pageLimit = `&limit=${pageState.pageSize}`;
			api
				.get(
					`/api/v1/users/?sort=lastName&page=${pageState.page + 1}` + pageLimit
				)
				.then((response) => {
					const update = {
						data: response.data.data,
						pageSize: pageState.pageSize,
						total: response.data.total,
					};

					setPageState((old) => ({
						...old,
						...update,
					}));
				})
				.catch((err) => {
					console.log("Failed", { err });
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseURL, pageState.page, pageState.pageSize, api?.authenticated]);

	return (
		<Box sx={{ height: "100dvh" }}>
			{errState ? (
				<Alert
					icon={<RunningWithErrorsIcon fontSize="inherit" />}
					severity="error"
				>
					{errMsg}
				</Alert>
			) : null}
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<Team
				selectedDataID={selectedDataID}
				setselectedDataID={setselectedDataID}
				deleteManyUsers={deleteManyUsers}
				pageState={pageState}
				setPageState={setPageState}
				projectList={data || []}
			/>
		</Box>
	);
}

export default AdminPageUsers;
