import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat');

//creates n objects
export const CreateObjectsInArray = (n, label) => {
  const array = [];

  for (let i = 1; i <= 3; i++) {
    const obj = {
      id: label + i,
      project: '',
      start: '',
      end: '',
      reg: '',
      OT: '',
    };

    array.push(obj);
  }
  return array;
};

export const CreateStandardWorkWeek = (n, label, weekend) => {
  dayjs.extend(customParseFormat);
  const weekEnd = dayjs(weekend, 'MM/DD/YYYY').format('YYYYMMDD');
  let defaultStart;
  let defaultEnd;
  let referenceDate;
  let defaultReg;
  let defaultOT;

  switch (label) {
    case 'sun':
      // defaultStart = dayjs(weekEnd + 'T7:00').day(0);
      // defaultEnd = dayjs(weekEnd + 'T15:30').day(0);
      defaultStart = null;
      defaultEnd = null;
      referenceDate = dayjs(weekEnd + 'T7:00').day(0);
      defaultReg = 0;
      defaultOT = 0;
      // code block
      break;
    case 'mon':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(1);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(1);
      referenceDate = dayjs(weekEnd + 'T7:00').day(1);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'tue':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(2);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(2);
      referenceDate = dayjs(weekEnd + 'T7:00').day(2);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'wed':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(3);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(3);
      referenceDate = dayjs(weekEnd + 'T7:00').day(3);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'thur':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(4);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(4);
      referenceDate = dayjs(weekEnd + 'T7:00').day(4);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'fri':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(5);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(5);
      referenceDate = dayjs(weekEnd + 'T7:00').day(5);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'sat':
      // code block
      // defaultStart = dayjs(weekEnd + 'T7:00').day(6);
      // defaultEnd = dayjs(weekEnd + 'T15:30').day(6);
      defaultStart = null;
      defaultEnd = null;
      referenceDate = dayjs(weekEnd + 'T7:00').day(6);
      defaultReg = 0;
      defaultOT = 0;
      break;
    default:
      break;
    // code block
  }

  const array = [];

  for (let i = 1; i <= 3; i++) {
    let obj;
    switch (i) {
      case 1:
        obj = {
          id: label + i,
          project: '',
          start: defaultStart,
          end: defaultEnd,
          reg: defaultReg,
          OT: defaultOT,
          referenceDate: referenceDate,
        };
        break;
      case 2:
        obj = {
          id: label + i,
          project: '',
          start: (defaultStart = null),
          end: (defaultEnd = null),
          reg: 0,
          OT: 0,
          referenceDate: referenceDate,
        };
        break;
      case 3:
        obj = {
          id: label + i,
          project: '',
          start: (defaultStart = null),
          end: (defaultEnd = null),
          reg: 0,
          OT: 0,
          referenceDate: referenceDate,
        };
        break;

      default:
        break;
    }
    array.push(obj);
  }

  return array;
};

export const CreateCustomWorkWeek = (label, weekend, start, end) => {
  dayjs.extend(customParseFormat);
  const weekEnd = dayjs(weekend, 'MM/DD/YYYY').format('YYYYMMDD');
  let defaultStart;
  let defaultEnd;
  let referenceDate;
  let defaultReg;
  let defaultOT;

  switch (label) {
    case 'sun':
      // defaultStart = dayjs(weekEnd + 'T7:00').day(0);
      // defaultEnd = dayjs(weekEnd + 'T15:30').day(0);
      defaultStart = null;
      defaultEnd = null;
      referenceDate = dayjs(weekEnd + 'T7:00').day(0);
      defaultReg = 0;
      defaultOT = 0;
      // code block
      break;
    case 'mon':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(1);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(1);
      referenceDate = dayjs(weekEnd + 'T7:00').day(1);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'tue':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(2);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(2);
      referenceDate = dayjs(weekEnd + 'T7:00').day(2);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'wed':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(3);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(3);
      referenceDate = dayjs(weekEnd + 'T7:00').day(3);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'thur':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(4);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(4);
      referenceDate = dayjs(weekEnd + 'T7:00').day(4);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'fri':
      // code block
      defaultStart = dayjs(weekEnd + 'T7:00').day(5);
      defaultEnd = dayjs(weekEnd + 'T15:30').day(5);
      referenceDate = dayjs(weekEnd + 'T7:00').day(5);
      defaultReg = 8;
      defaultOT = 0;
      break;
    case 'sat':
      // code block
      // defaultStart = dayjs(weekEnd + 'T7:00').day(6);
      // defaultEnd = dayjs(weekEnd + 'T15:30').day(6);
      defaultStart = null;
      defaultEnd = null;
      referenceDate = dayjs(weekEnd + 'T7:00').day(6);
      defaultReg = 0;
      defaultOT = 0;
      break;
    default:
      break;
    // code block
  }

  const array = [];

  for (let i = 1; i <= 3; i++) {
    let obj;
    switch (i) {
      case 1:
        obj = {
          id: label + i,
          project: '',
          start: defaultStart,
          end: defaultEnd,
          reg: defaultReg,
          OT: defaultOT,
          referenceDate: referenceDate,
        };
        break;
      case 2:
        obj = {
          id: label + i,
          project: '',
          start: (defaultStart = null),
          end: (defaultEnd = null),
          reg: 0,
          OT: 0,
          referenceDate: referenceDate,
        };
        break;
      case 3:
        obj = {
          id: label + i,
          project: '',
          start: (defaultStart = null),
          end: (defaultEnd = null),
          reg: 0,
          OT: 0,
          referenceDate: referenceDate,
        };
        break;

      default:
        break;
    }
    array.push(obj);
  }

  return array;
};

export const formatWeekArrayForDayjs = (weekArray) => {
  const unique = [
    ...new Set(
      weekArray.map((item) => ({
        ...item,
        referenceDate: item.referenceDate ? dayjs(item.referenceDate) : null,
        start: item.start ? dayjs(item.start) : null,
        end: item.end ? dayjs(item.end) : null,
      }))
    ),
  ];
  console.log(unique);
  return unique;
};

export const getRandomInt = (min, max) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
};
