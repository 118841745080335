import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext({});

//set state
//pass auth Prop to children components
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  console.log('token', token);
  const [auth, setAuth] = useState(
    JSON.parse(localStorage.getItem('auth')) || {}
  );
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || {}
  );
  const [success, setSuccess] = useState(Boolean(token));
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [subscription, setSubscription] = useState({});

  const [mode] = useState(process.env.REACT_APP_NODE_ENV);
  console.log('mode', mode);
  console.log(
    'process.env.REACT_APP_FRONTEND_BASE_URL',
    process.env.REACT_APP_FRONTEND_BASE_URL
  );
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
    withCredentials: true,
    crossDomain: true,
    headers: {
      'Access-Control-Allow-Origin': process.env.REACT_APP_FRONTEND_BASE_URL,
      'Content-Type': 'application/json',
    },
  });
  api.authenticated = false;

  const setApiBearerToken = (token) => {
    if (token) {
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      api.authenticated = true;
    } else {
      delete api.defaults.headers.common['Authorization'];
      api.authenticated = false;
    }
  };

  setApiBearerToken(token);

  api.interceptors.response.use(
    function (response) {
      // Optional: Do something with response data
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Interceptor to automatically refresh token if expired
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { response, config } = error;

      if (
        response?.data?.error === 'Authentication token is expired' &&
        !config.retried
      ) {
        config.retried = true;

        try {
          await api.post('/api/v1/auth/refresh').then((response) => {
            const newToken = response.data.token;

            if (newToken) {
              login(newToken);

              config.headers = {
                ...config.headers,
                authorization: `Bearer ${newToken}`,
              };
              return api(config);
            } else {
              console.log(auth);
              console.log('token missing from refresh response');
            }
          });
        } catch (error) {
          console.log('token refresh failed', { error });
          logout();
        }
      } else {
        console.log('request failed', error.message);
        return Promise.reject(error);
      }
    }
  );

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      setApiBearerToken(token);

      api
        .get('/api/v1/auth/me')
        .then(({ data }) => {
          const _user = data.data;
          const _auth = { email: _user.email, accessToken: token };
          setAuth(_auth);
          setUser(_user);
          setSuccess(true);

          // TODO: Remove this after all components using auth to get token are updated
          // We're already setting the token in the axios.defaults.headers.common
          localStorage.setItem('auth', JSON.stringify(_auth));
          localStorage.setItem('user', JSON.stringify(_user));

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token;

          api
            .get('/api/v1/subscription')
            .then((res) => {
              setSubscription(res.data.subscription);
            })
            .catch((err) => {
              console.log('Error getting subscription', err);
              setSubscription({});
            });
        })
        .catch((err) => {});
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('auth');
      localStorage.removeItem('user');

      delete axios.defaults.headers.common['Authorization'];

      setUser({});
      setAuth({});
      setSuccess(false);
      setSubscription({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const login = (accessToken) => setToken(accessToken);

  const logout = () => {
    console.log('logout');

    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        api,
        auth,
        setAuth,
        success,
        setSuccess,
        user,
        setUser,
        login,
        logout,
        mode,
        isLandingPage,
        setIsLandingPage,
        subscription,
        setSubscription,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export default AuthContext;

export { useAuth };
