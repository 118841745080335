import { Box, Stack, Tab, Tabs } from '@mui/material';
import TopBarAdmin from '../components/TopBarAdmin';
import Header from '../components/Header';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import CompanySubscription from '../components/Settings/CompanySubscription';
import CompanySettings from '../components/Settings/CompanySettings';
import UserProfile from '../components/Settings/UserProfile';
import SettingsInfo from '../components/Settings/SettingsInfo';
import UserChangePassword from '../components/Settings/UserChangePassword';
import Alert from '@mui/material/Alert';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

const SettingsTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ maxWidth: 'sm', marginX: 'auto' }}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

const SettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [notificationsNumber, setnotificationsNumber] = useState(0);
  const handleChange = (event, newValue) => setSelectedTab(newValue);
  const { api } = useAuth();
  const [errState, seterrState] = useState(false);

  useEffect(() => {
    async function GetResults(data) {
      try {
        const response = await api.get(
          `/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
        );
        setnotificationsNumber(response?.data.data[0].results.length);
      } catch (err) {
        console.log('Failed', { err });
        seterrState(true);
      }
    }
    GetResults();
  }, []);

  return (
    <Box sx={{ height: '160dvh' }} m='10px 20px 20px 20px'>
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize='inherit' />}
          severity='error'>
          {'Data Not Loaded!  Please refresh'}
        </Alert>
      ) : null}
      <TopBarAdmin notificationsNumber={notificationsNumber} />
      <Header title='Settings' subtitle='Update Your Settings' />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label='Company Settings' />
          <Tab label='User Profile' />
          <Tab label='Info' />
        </Tabs>
      </Box>
      <SettingsTabPanel value={selectedTab} index={0}>
        <Stack spacing={4}>
          <CompanySettings />
          <CompanySubscription />
        </Stack>
      </SettingsTabPanel>
      <SettingsTabPanel value={selectedTab} index={1}>
        <Stack spacing={4}>
          <UserProfile />
          <UserChangePassword />
        </Stack>
      </SettingsTabPanel>

      <SettingsTabPanel value={selectedTab} index={2}>
        <Stack spacing={4}>
          <SettingsInfo />
        </Stack>
      </SettingsTabPanel>
    </Box>
  );
};

export default SettingsPage;
