import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

function createData(code, trade, classification) {
  return { code, trade, classification };
}

const rows = [
  createData('1', 'Carpenter', 'Journeyman'),
  createData('2', 'Cement Mason', 'Cement Mason'),
  createData('3', 'Drywall', 'Journeyman'),
  createData('4', 'Electrician', 'Inside Wireman'),
  createData('5', 'Electrical Communications', 'Comm Tech Installer'),
  createData('6', 'Laborer', 'Group 3; Group 3 (A)'),
  createData('7', 'Laborer', 'Group 7 - Stage 3 (3rd 6 months)'),
  createData('8', 'Plasterer', 'Plasterer'),
  createData('9', 'Taper', 'Taper'),
  createData('10', 'Carpenter Apprentice', 'Apprentice 1'),
  createData('11', 'Carpenter Apprentice', 'Apprentice 2'),
  createData('12', 'Carpenter Apprentice', 'Apprentice 3'),
  createData('13', 'Carpenter Apprentice', 'Apprentice 4'),
  createData('14', 'Carpenter Apprentice', 'Apprentice 5'),
  createData('15', 'Carpenter Apprentice', 'Apprentice 6'),
  createData('16', 'Carpenter Apprentice', 'Apprentice 7'),
  createData('17', 'Carpenter Apprentice', 'Apprentice 8'),
  createData('18', 'Carpenter Apprentice', 'Apprentice 9'),
  createData('19', 'Electrical Apprentice', 'Apprentice 1'),
  createData('20', 'Electrical Apprentice', 'Apprentice 1'),
  createData('21', 'Electrical Apprentice', 'Apprentice 2'),
  createData('22', 'Electrical Apprentice', 'Apprentice 3'),
  createData('23', 'Electrical Apprentice', 'Apprentice 4'),
  createData('24', 'Electrical Apprentice', 'Apprentice 5'),
  createData('25', 'Electrical Apprentice', 'Apprentice 6'),
  createData('26', 'Electrical Apprentice', 'Apprentice 7'),
  createData('27', 'Electrical Apprentice', 'Apprentice 8'),
  createData('28', 'Electrical Apprentice', 'Apprentice 9'),
  createData('29', 'Office Admin', 'Salary'),
  createData('30', 'Office Admin', 'Hourly'),
];

export default function BasicTable() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <TableContainer
      sx={{
        height: '50vh',
        backgroundColor: colors.primary[400],
        border: '3px solid',
        borderColor: colors.greenAccent[500],
        borderRadius: '5px',
      }}>
      <Table sx={{ minWidth: 200 }} aria-label='simple table'>
        <TableHead sx={{ tr: { width: '10px' } }}>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell align='left'>Trade</TableCell>
            <TableCell align='left'>Classification</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                tr: { height: '10px' },
              }}>
              <TableCell
                size='small'
                sx={{ th: { width: '10px' } }}
                component='th'
                scope='row'>
                {row.code}
              </TableCell>
              <TableCell size='small' align='left'>
                {row.trade}
              </TableCell>
              <TableCell size='small' align='left'>
                {row.classification}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
