import { Box, useTheme, Typography, Grid, useMediaQuery} from '@mui/material';
import Header from '../components/Header';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tokens } from '../theme';

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const AccordianComponent = ({ question, answer }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ color: colors.greenAccent[500] }} variant='h5'>
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'>
      <Grid
        item
        xs={3}
        sx={{
          width: isMobile ? "80%":'50%',
          mt: '75px',
          mb: '35px',
        }}
        elevation={3}>
        <Box sx={{ height: '70dvh' }}>
          <Header title='FAQ' subtitle='Frequently Asked Questions' />

          <AccordianComponent
            question={'What Does Daily Timecard Do?'}
            answer={
              'We are currently a workforce timetracking application that calculates rates based on prevailing wages.  In the future we plan to offer additional construction management tools.'
            }
          />

          <AccordianComponent
            question={'How Many Accounts Is Recommended?'}
            answer={
              'It is recommended that each employee gets their own account in which they can enter and keep track of their own hours. The minimum would be one admin and one account per project manager.'
            }
          />
          <AccordianComponent
            question={'Where Do You Get Your Rates From?'}
            answer={'Department of Industrial Relations (DIR)'}
          />
          <AccordianComponent
            question={'What Determines the Wage Rate of An Employee?'}
            answer={
              'A combination of factors lead to a rate determination.  Role, classification, county, start date of the project'
            }
          />
          <AccordianComponent
            question={'Does Daily Timecard Do Taxes?'}
            answer={
              'At the moment, we are only a rate calculation tool but plan to add taxes in the distant future.'
            }
          />
          <AccordianComponent
            question={'Should Each Project Manager Get An Account?'}
            answer={'Yes'}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FAQ;
