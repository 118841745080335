import Timecard from "../timecard";
import * as React from "react";
import { Box } from "@mui/material";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect } from "react";
import TopBarAdmin from "../components/TopBarAdmin";
import Alert from "@mui/material/Alert";
import dayjs from "dayjs";
import usePath from "../hooks/fetchPath";

function PMPageTimecards({ user }) {
	const { api, success } = useAuth();
	const [errState, setErrState] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const [project, setProject] = React.useState("");
	const [startQry, setStartQry] = React.useState("");
	const [endQry, setEndQry] = React.useState("");
	const [editMode, setEditMode] = React.useState(false);
	const { baseURL } = usePath("/api/v1/timecardsv2");
	const [notificationsNumber, setnotificationsNumber] = useState(1);
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 0,
		pageSize: 100,
	});

	useEffect(() => {
		async function GetResults(data) {
			let results = await data.filter((x) => x.approved === false);
			setnotificationsNumber(results.length);
		}
		GetResults(pageState.data);
	}, [pageState.data]);

	// useEffect(() => {
	//   if (api.authenticated) {
	//     const pageLimit = `&limit=${pageState.pageSize}`;
	//     api
	//       .get(
	//         `/api/v1/timecardsv2/?sort=lastName&page=${pageState.page + 1}` +
	//           pageLimit
	//       )
	//       .then((response) => {
	//         const update = {
	//           data: response.data.data,
	//           pageSize: pageState.pageSize,
	//           total: response.data.total,
	//         };

	//         setPageState((old) => ({
	//           ...old,
	//           ...update,
	//         }));
	//       })
	//       .catch((err) => {
	//         console.log('FAILED', { err });
	//       });
	//   }
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [pageState.page, pageState.pageSize, api?.authenticated]);

	useEffect(() => {
		async function getPersonnel() {
			const pagelimit = `&limit=${pageState.pageSize}`;
			let projectqryRender = "";
			let startQryRender = "";
			let endQryRender = "";
			if (project) {
				let string = JSON.stringify(project);
				projectqryRender = ``;
			}
			if (startQry) {
				let date = dayjs(startQry).format("YYYY-MM-DDTHH:mm:ss");
				startQryRender = `&weekEnd[gte]=${date}`;
			}
			if (endQry) {
				let date2 = dayjs(endQry).format("YYYY-MM-DDTHH:mm:ss");
				endQryRender = `&endQry[lte]=${date2}`;
			}

			if (editMode === true) {
				try {
					const response = await api.get(
						`/api/v1/timecardsv2/?sort=lastName&page=${pageState.page + 1}` +
							pagelimit +
							projectqryRender +
							startQryRender +
							endQryRender
					);

					const update = {
						data: response.data.data,
						pageSize: pageState.pageSize,
						total: response.data.total,
					};

					setPageState((old) => ({
						...old,
						...update,
					}));
				} catch (err) {
					console.log("Error", err);
					setErrState(true);
					setErrMsg("Error Loading Data");
				}
			} else if (editMode === false && typeof pagelimit !== "undefined") {
				try {
					const response = await api.get(
						`/api/v1/timecardsv2/unapproved/?page=${pageState.page + 1}` +
							pagelimit
					);

					const update = {
						data: response.data.data[0].results,
						pageSize: pageState.pageSize,
						total: response.data.data[0].pagination[0].totaldocuments,
					};

					setPageState((old) => ({
						...old,
						...update,
					}));
				} catch (err) {}
			}
		}
		getPersonnel();
	}, [
		api,
		baseURL,
		editMode,
		endQry,
		pageState.page,
		pageState.pageSize,
		project,
		startQry,
		success,
	]);

	return (
		<div>
			<Box sx={{ height: "100dvh" }}>
				<TopBarAdmin notificationsNumber={notificationsNumber} />
				{errState ? <Alert severity="error">{errMsg}</Alert> : null}

				<Timecard
					user={user}
					project={project}
					setProject={setProject}
					startQry={startQry}
					setStartQry={setStartQry}
					endQry={endQry}
					setEndQry={setEndQry}
					errMsg={errMsg}
					setErrMsg={setErrMsg}
					pageState={pageState}
					setPageState={setPageState}
					editMode={editMode}
					setEditMode={setEditMode}
				/>
			</Box>
		</div>
	);
}

export default PMPageTimecards;
