import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';

//future - get user information from axios request

//function takes in a week number, and fills out the rest of the week by numbers
//mongo adds 8 hours because it is in pacific but logs into utc
function payrollDetailsHook(user, date) {
  var year = date.substring(6, 10);
  var month = date.substring(0, 2);
  var days = date.substring(3, 5);
  var d = new Date(year, month - 1, days);
  const weekEnding = dayjs(d).day(6).format('MM-DD-YYYY');
  const initialObj = {
    name: `${user.firstName} + ${user.lastName} + ${weekEnding}`,
    firstName: user.firstName,
    lastName: user.lastName,
    project: '75 - Palo Alto HS Tower',
    mondayStart: dayjs(d)
      .day(1)
      .hour(7)
      .minute(0)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    mondayEnd: dayjs(d)
      .day(1)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    mondayOTStart: dayjs(d)
      .day(1)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    tuesdayStart: dayjs(d)
      .day(2)
      .hour(7)
      .minute(0)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    tuesdayEnd: dayjs(d)
      .day(2)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    tuesdayOTStart: dayjs(d)
      .day(2)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    wednesdayStart: dayjs(d)
      .day(3)
      .hour(7)
      .minute(0)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    wednesdayEnd: dayjs(d)
      .day(3)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    wednesdayOTStart: dayjs(d)
      .day(3)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    thursdayStart: dayjs(d)
      .day(4)
      .hour(7)
      .minute(0)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    thursdayEnd: dayjs(d)
      .day(4)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    thursdayOTStart: dayjs(d)
      .day(4)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    fridayStart: dayjs(d)
      .day(5)
      .hour(7)
      .minute(0)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    fridayEnd: dayjs(d)
      .day(5)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    fridayOTStart: dayjs(d)
      .day(5)
      .hour(15)
      .minute(30)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    saturdayStart: '',
    saturdayEnd: '',
    sundayStart: '',
    sundayEnd: '',
    weekStart: dayjs(d)
      .day(-1)
      .hour(0)
      .minute(0)
      .format('YYYY-MM-DDTHH:mm:ssZ'),
    weekEnd: dayjs(d).day(6).hour(0).minute(0).format('YYYY-MM-DDTHH:mm:ssZ'),
    //.format('{YYYY} MM-DDTHH
    weekNumber: dayjs().week(),
    createdAt: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 8,
    monOTHours: 0,
    tuesdayHours: 8,
    tueOTHours: 0,
    wednesdayHours: 8,
    wedOTHours: 0,
    thursdayHours: 8,
    thurOTHours: 0,
    fridayHours: 8,
    friOTHours: 0,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    createdBy: {
      user: {
        id: `${user._id}`,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        classification: user.classification,
        createdOn: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        payType: user.payType,
      },
    },
  };

  return {
    initialObj,
  };
}

export default payrollDetailsHook;
