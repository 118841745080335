import dayjs from 'dayjs';
import { getAnalytics } from '../utils/getAnalytics';
export const compilePayrollCreateDetails = (
  user,
  person,
  weekSelect,
  employeeID,
  project1,
  project2,
  project3,
  sundayLog,
  mondayLog,
  tuesdayLog,
  wednesdayLog,
  thursdayLog,
  fridayLog,
  saturdayLog,
  sundayHours,
  mondayRegHours,
  mondayOTHours,
  tuesdayRegHours,
  tuesdayOTHours,
  wednesdayRegHours,
  wednesdayOTHours,
  thursdayRegHours,
  thursdayOTHours,
  fridayRegHours,
  fridayOTHours,
  saturdayHours,
  trade,
  classification
) => {
  function makeNumber(someNumber) {
    someNumber = Math.round(someNumber * 1e2) / 1e2;
    return someNumber;
  }
  sundayHours = makeNumber(sundayHours);

  mondayRegHours = makeNumber(mondayRegHours);
  tuesdayRegHours = makeNumber(tuesdayRegHours);
  wednesdayRegHours = makeNumber(wednesdayRegHours);
  thursdayRegHours = makeNumber(thursdayRegHours);
  fridayRegHours = makeNumber(fridayRegHours);

  mondayOTHours = makeNumber(mondayOTHours);
  tuesdayOTHours = makeNumber(tuesdayOTHours);
  wednesdayOTHours = makeNumber(wednesdayOTHours);
  thursdayOTHours = makeNumber(thursdayOTHours);
  fridayOTHours = makeNumber(fridayOTHours);

  saturdayHours = makeNumber(saturdayHours);

  const separatedName = separateName(person);

  var regHoursSum =
    mondayRegHours +
    tuesdayRegHours +
    wednesdayRegHours +
    thursdayRegHours +
    fridayRegHours;

  var OTHoursSum =
    mondayOTHours +
    tuesdayOTHours +
    wednesdayOTHours +
    thursdayOTHours +
    fridayOTHours;

  var weeklyLog = [].concat.apply(sundayLog, [
    mondayLog,
    tuesdayLog,
    wednesdayLog,
    thursdayLog,
    fridayLog,
    saturdayLog,
  ]);

  const analytics = getAnalytics(weeklyLog);

  const renderedProjects = () => {
    var arr = [project1, project2, project3];
    arr = arr.filter(function (element) {
      return element !== '';
    });
    const out = arr.map((elem) => {
      return { project: elem };
    });

    return out;
  };

  let renderedFirstName;
  let renderedLastName;
  let renderedFullName;
  if (person) {
    renderedFirstName = separatedName[1];
    renderedLastName = separatedName[0];
    renderedFullName = person;
  } else {
    renderedFirstName = user.firstName;
    renderedLastName = user.lastName;
    renderedFullName = user.lastName + ', ' + user.firstName;
  }

  return {
    name: `${renderedFirstName} + ${renderedLastName} + ${weekSelect}`,
    weekEnd: `${weekSelect}`,
    weekNumber: dayjs(weekSelect).week(),
    employeeID: employeeID,
    firstName: `${renderedFirstName}`,
    lastName: `${renderedLastName}`,
    fullName: `${renderedFullName}`,
    project: renderedProjects(),
    paid: false,
    paidBy: { user: null },
    sunday: [
      {
        log: sundayLog,
        hours: [
          {
            satDayTotal: 0,
            sunDayTotal: sundayHours,
          },
        ],
      },
    ],
    monday: [
      {
        log: mondayLog,
        hours: [
          {
            regDayTotal: mondayRegHours,
            OTDayTotal: mondayOTHours,
          },
        ],
      },
    ],
    tuesday: [
      {
        log: tuesdayLog,
        hours: [
          {
            regDayTotal: tuesdayRegHours,
            OTDayTotal: tuesdayOTHours,
          },
        ],
      },
    ],

    wednesday: [
      {
        log: wednesdayLog,
        hours: [
          {
            regDayTotal: wednesdayRegHours,
            OTDayTotal: wednesdayOTHours,
          },
        ],
      },
    ],
    thursday: [
      {
        log: thursdayLog,
        hours: [
          {
            regDayTotal: thursdayRegHours,
            OTDayTotal: thursdayOTHours,
          },
        ],
      },
    ],
    friday: [
      {
        log: fridayLog,
        hours: [
          {
            regDayTotal: fridayRegHours,
            OTDayTotal: fridayOTHours,
          },
        ],
      },
    ],
    saturday: [
      {
        log: saturdayLog,
        hours: [
          {
            satDayTotal: saturdayHours,
            sunDayTotal: 0,
          },
        ],
      },
    ],
    weeklyLog: weeklyLog,
    //analytics args: 1) array 2) prop 3) qry (string or number)
    analytics: analytics,
    regHours: regHoursSum,
    overtimeHours: OTHoursSum,
    satHours: saturdayHours,
    sunHours: sundayHours,
    trade: trade,
    classification: classification,
    approved: false,
    approvedBy: { user: null },
    createdBy: { user: user._id },
  };
};

function separateName(person) {
  if (person) {
    const separatedName = person.split(', ');

    return separatedName;
  }
}
