import { useAuth } from '../context/AuthProvider';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';
import Link2 from './Link2';

const Logout = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { success, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      {success ? (
        <Button variant='contained' sx={{ backgroundColor: colors.greenAccent[500] }} onClick={handleLogout}>
          Logout
        </Button>
      ) : (
        <Box>
          <Typography sx={{ color: colors.grey[500] }}>You Are Logged Out </Typography>
          <Link2 className='center' key='Home' to={`/`}>
            Back to Login
          </Link2>
        </Box>
      )}
    </Box>
  );
};

export default Logout;
