import LandingPageNavBar from '../components/LandingPageNavBar';
import LandingPageFooter from '../components/LandingPageFooter';
import React from 'react';
import {
  Box,
  useTheme,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  useMediaQuery
} from '@mui/material';
import { tokens } from '../theme';
import CircleIcon from '@mui/icons-material/Circle';

const AboutPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box>
      <Box>
        <LandingPageNavBar />
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'>
          <Grid
            item
            xs={3}
            sx={{
              width: isMobile ? "80%":'50%',
              mt: '75px',
              mb: '35px',
            }}
            elevation={3}>
            <Box
              sx={{
                width: '100%',
                minHeight: '65dvh',
                height: '90%',
                mt: '0.5rem',
                p: '0 0.5rem',

              }}>
              <Typography variant='h3' sx={{ color: colors.greenAccent[500] }}>
                Mission
              </Typography>
              <Typography>
                {' '}
                Daily Timecard aims to provide prevailing wage automation from
                the instant labor hours are entered. Daily Timecard was started
                because we were tired of looking up prevailing wage rates at the
                DIR website.
              </Typography>

              <Typography
                variant='h3'
                sx={{ color: colors.greenAccent[500], mt: '20px' }}>
                How It Works
              </Typography>
              <Typography>
                Entering laborers and employee labor hours will be the
                responsibility of the project manager. Each project manager will
                get their own account and they will enter their crews labor
                hours on a weekly basis. The company admin account will approve
                the hours and mark them as paid.
              </Typography>

              <Typography
                variant='h3'
                sx={{ color: colors.greenAccent[500], mt: '20px' }}>
                How We Use Your Information
              </Typography>
              <List sx={{ listStyleType: 'disc' }}>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Calculate Labor Hours
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  Improve Upon The App
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Process Payment Through Stripe (We Do Not Store Sensitive
                  Financial Data)
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Reset Passwords
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Send Emails
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LandingPageFooter />
    </Box>
  );
};

export default AboutPage;
