import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

const PrivateRoutes = () => {
  const { success } = useAuth();

  return success ? <Outlet /> : <Navigate to='/Login' />;
};

export default PrivateRoutes;
