import { useState, useEffect } from "react";
import TopBarAdmin from "../components/TopBarAdmin";
import { Box } from "@mui/material";
import Dashboard2 from "./Dashboard2";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function PMHomePage() {
	const { api } = useAuth();
	const isAboveMediumScreens = useMediaQuery("(min-width: 1200px)");
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");

	useEffect(() => {
		async function GetResults(data) {
			try {
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);
				console.log(response);
				setnotificationsNumber(response.data.data[0].results.length);
			} catch (err) {
				console.log("Failed", { err });
				setErrState(true);
				seterrMsg("Error Loading Data");
			}
		}
		GetResults();
	}, []);
	return (
		<div className="Main">
			<main className="adminContentMain">
				<Box
					sx={isAboveMediumScreens ? { p: 3, marginLeft: 2 } : { p: 0, m: 0 }}
				>
					{errState ? (
						<Alert
							icon={<RunningWithErrorsIcon fontSize="inherit" />}
							severity="error"
						>
							{errMsg}
						</Alert>
					) : null}
					<TopBarAdmin notificationsNumber={notificationsNumber} />
					<Dashboard2 />
				</Box>
			</main>
		</div>
	);
}

export default PMHomePage;
