import { Box, Typography, useTheme, Stack, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useContext, useEffect } from 'react';
import { tokens } from '../theme';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ViewPayrollDataGrid = ({ details }) => {
  const [viewPayrollDetails, setViewPayrollDetails] = useState(details);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [status, setStatus] = useState('Not Approved');
  const [regTotal, setregTotal] = useState(0);
  const [otTotal, setotTotal] = useState(0);
  const [satTotal, setsatTotal] = useState(0);
  const [sunTotal, setsunTotal] = useState(0);

  function CustomFooterStatusComponent(props) {
    return (
      <Box sx={{ p: 1, display: 'flex', alignContent: 'space-between' }}>
        <FiberManualRecordIcon
          fontSize='small'
          sx={{
            mr: 1,
            color: props.status === 'Approved' ? '#4caf50' : '#d9182e',
          }}
        />
        {props.status}
        {details.project[0] ? (
          <Box sx={{ ml: '1rem', display: 'flex' }}>
            <Typography sx={{ color: colors.grey[300], mr: '2px' }}>
              {`P1:`}{' '}
            </Typography>
            <Typography> {details.project[0]?.project} </Typography>
          </Box>
        ) : null}

        {details.project[1] ? (
          <Box sx={{ ml: '1rem', display: 'flex' }}>
            <Typography sx={{ color: colors.grey[300], mr: '2px' }}>
              {`P2:`}{' '}
            </Typography>
            <Typography> {details.project[1]?.project} </Typography>
          </Box>
        ) : null}

        {details.project[2] ? (
          <Box sx={{ ml: '1rem', display: 'flex' }}>
            <Typography sx={{ color: colors.grey[300], mr: '2px' }}>
              {`P3:`}{' '}
            </Typography>
            <Typography> {details.project[2]?.project} </Typography>
          </Box>
        ) : null}

        <Grid container justifyContent='flex-end'>
          <Stack
            direction='row'
            spacing={2}
            sx={{ mx: 5, justifyContent: 'flex-end' }}>
            <Typography variant='h7'> Reg: {regTotal} </Typography>
            <Typography variant='h7'> OT: {otTotal} </Typography>
            <Typography variant='h7'> Sat:{satTotal} </Typography>
            <Typography variant='h7'> Sun: {sunTotal} </Typography>
          </Stack>
        </Grid>
      </Box>
    );
  }

  useEffect(() => {
    console.log(details);

    if (details.approved === true) {
      setStatus('Approved');
    }
    if (details.regularHours) {
      setregTotal(details.regularHours);
    }
    if (details.OTHours) {
      setotTotal(details.OTHours);
    }
    if (details.saturdayHours) {
      setsatTotal(details.saturdayHours);
    }
    if (details.sundayHours) {
      setsunTotal(details.sundayHours);
    }
    const compileArray = (details) => {
      const sunday = new Day(
        0,
        'Sun',
        details.sunday[0].log[0].referenceDate,
        details.sunday[0].log[0].start,
        details.sunday[0].log[1].start,
        details.sunday[0].log[2].start,
        details.sunday[0].log[0].end,
        details.sunday[0].log[1].end,
        details.sunday[0].log[2].end,
        0,
        0,
        details.saturday[0].hours[0].satDayTotal,
        details.saturday[0].hours[0].sunDayTotal
      );
      const monday = new Day(
        1,
        'Mon',
        details.monday[0].log[0].referenceDate,
        details.monday[0].log[0].start,
        details.monday[0].log[1].start,
        details.monday[0].log[2].start,
        details.monday[0].log[0].end,
        details.monday[0].log[1].end,
        details.monday[0].log[2].end,
        details.monday[0].hours[0].regDayTotal,
        details.monday[0].hours[0].OTDayTotal,
        0,
        0
      );
      const tuesday = new Day(
        2,
        'Tue',
        details.tuesday[0].log[0].referenceDate,
        details.tuesday[0].log[0].start,
        details.tuesday[0].log[1].start,
        details.tuesday[0].log[2].start,
        details.tuesday[0].log[0].end,
        details.tuesday[0].log[1].end,
        details.tuesday[0].log[2].end,
        details.tuesday[0].hours[0].regDayTotal,
        details.tuesday[0].hours[0].OTDayTotal,
        0,
        0
      );
      const wednesday = new Day(
        3,
        'Wed',
        details.wednesday[0].log[0].referenceDate,
        details.wednesday[0].log[0].start,
        details.wednesday[0].log[1].start,
        details.wednesday[0].log[2].start,
        details.wednesday[0].log[0].end,
        details.wednesday[0].log[1].end,
        details.wednesday[0].log[2].end,
        details.wednesday[0].hours[0].regDayTotal,
        details.wednesday[0].hours[0].OTDayTotal,
        0,
        0
      );
      const thursday = new Day(
        4,
        'Thur',
        details.thursday[0].log[0].referenceDate,
        details.thursday[0].log[0].start,
        details.thursday[0].log[1].start,
        details.thursday[0].log[2].start,
        details.thursday[0].log[0].end,
        details.thursday[0].log[1].end,
        details.thursday[0].log[2].end,
        details.thursday[0].hours[0].regDayTotal,
        details.thursday[0].hours[0].OTDayTotal,
        0,
        0
      );
      const friday = new Day(
        5,
        'Fri',
        details.friday[0].log[0].referenceDate,
        details.friday[0].log[0].start,
        details.friday[0].log[1].start,
        details.friday[0].log[2].start,
        details.friday[0].log[0].end,
        details.friday[0].log[1].end,
        details.friday[0].log[2].end,
        details.friday[0].hours[0].regDayTotal,
        details.friday[0].hours[0].OTDayTotal,
        0,
        0
      );
      const saturday = new Day(
        6,
        'Sat',
        details.saturday[0].log[0].referenceDate,
        details.saturday[0].log[0].start,
        details.saturday[0].log[1].start,
        details.saturday[0].log[2].start,
        details.saturday[0].log[0].end,
        details.saturday[0].log[1].end,
        details.saturday[0].log[2].end,
        0,
        0,
        details.saturday[0].hours[0].satDayTotal,
        details.saturday[0].hours[0].sunDayTotal
      );
      const array = [
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
      ];
      console.log(array);
      const renderedData = array.map((timecard) => ({
        id: timecard.id,
        day: timecard.day,
        date: timecard.date,
        project1start: timecard.project1start,
        project2start: timecard.project2start,
        project3start: timecard.project3start,
        project1end: timecard.project1end,
        project2end: timecard.project2end,
        project3end: timecard.project3end,
        regsubtotal: timecard.regsubtotal,
        otsubtotal: timecard.otsubtotal,
        satsubtotal: timecard.satsubtotal,
        sunsubtotal: timecard.sunsubtotal,
      }));
      return renderedData;
    };

    setViewPayrollDetails(compileArray(details));
  }, [details]);

  console.log(viewPayrollDetails);
  //Constructor function
  function Day(
    id,
    day,
    date,
    project1start,
    project2start,
    project3start,
    project1end,
    project2end,
    project3end,
    regsubtotal,
    otsubtotal,
    satsubtotal,
    sunsubtotal
  ) {
    this.id = id;
    this.day = day;
    this.date = date;
    this.project1start = project1start;
    this.project2start = project2start;
    this.project3start = project3start;
    this.project1end = project1end;
    this.project2end = project2end;
    this.project3end = project3end;
    this.regsubtotal = regsubtotal;
    this.otsubtotal = otsubtotal;
    this.satsubtotal = satsubtotal;
    this.sunsubtotal = sunsubtotal;
  }

  const columns = [
    {
      field: 'day',
      headerName: 'day',
      width: 20,
    },
    {
      field: 'date',
      headerName: 'date',
      width: 70,
      renderCell: ({ row: { date } }) => {
        if (date) {
          return dayjs(date).format('MM/DD/YY');
        }
      },
    },
    {
      field: 'project1start',
      headerName: 'P1 Start',
      flex: 0.3,
      renderCell: ({ row: { project1start } }) => {
        if (project1start) {
          return dayjs(project1start).format('h:mm A');
        }
      },
    },
    {
      field: 'project1end',
      headerName: 'P1 End',
      flex: 0.3,
      renderCell: ({ row: { project1end } }) => {
        if (project1end) {
          return dayjs(project1end).format('h:mm A');
        }
      },
    },
    {
      field: 'project2start',
      headerName: 'P2 Start',
      flex: 0.2,
      renderCell: ({ row: { project2start } }) => {
        if (project2start) {
          return dayjs(project2start).format('h:mm A');
        }
      },
    },
    {
      field: 'project2end',
      headerName: 'P2 End',
      flex: 0.3,
      renderCell: ({ row: { project2end } }) => {
        if (project2end) {
          return dayjs(project2end).format('h:mm A');
        }
      },
    },
    {
      field: 'project3start',
      headerName: 'P3 Start',
      flex: 0.2,
      renderCell: ({ row: { project3start } }) => {
        if (project3start) {
          return dayjs(project3start).format('h:mm A');
        }
      },
    },
    {
      field: 'project3end',
      headerName: 'P3 End',
      flex: 0.2,
      renderCell: ({ row: { project3end } }) => {
        if (project3end) {
          return dayjs(project3end).format('h:mm A');
        }
      },
    },
    {
      field: 'regsubtotal',
      headerName: 'Regular',
      flex: 0.2,
      renderCell: ({ row: { regsubtotal } }) => {
        return (
          <Typography
            sx={{
              color:
                regsubtotal > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: regsubtotal > 0 ? 'bold' : 'regular',
            }}>
            {regsubtotal}
          </Typography>
        );
      },
    },
    {
      field: 'otsubtotal',
      headerName: 'OT',
      flex: 0.2,
      renderCell: ({ row: { otsubtotal } }) => {
        return (
          <Typography
            sx={{
              color: otsubtotal > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: otsubtotal > 0 ? 'bold' : 'regular',
            }}>
            {otsubtotal}
          </Typography>
        );
      },
    },
    {
      field: 'satsubtotal',
      headerName: 'Sat',
      flex: 0.2,
      renderCell: ({ row: { satsubtotal } }) => {
        return (
          <Typography
            sx={{
              color:
                satsubtotal > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: satsubtotal > 0 ? 'bold' : 'regular',
            }}>
            {satsubtotal}
          </Typography>
        );
      },
    },
    {
      field: 'sunsubtotal',
      headerName: 'Sun',
      flex: 0.2,
      renderCell: ({ row: { sunsubtotal } }) => {
        return (
          <Typography
            sx={{
              color:
                sunsubtotal > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: sunsubtotal > 0 ? 'bold' : 'regular',
            }}>
            {sunsubtotal}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 400,
        width: 650,
        '& .data-grid-footer-panel': {
          display: `none !important`,
        },
      }}>
      <DataGrid
        rows={viewPayrollDetails}
        columns={columns}
        hideFooterPagination
        hideFooterSelectedRowCount
        components={{
          Footer: CustomFooterStatusComponent,
        }}
        componentsProps={{
          footer: { status },
        }}
      />
    </Box>
  );
};

export default ViewPayrollDataGrid;
