//searches an array full of elements by property
//creates an array of unique values
//creates an output array by the unique values
export const getAnalytics = (array) => {
  console.log(array);
  const unique = [...new Set(array.map((item) => item.project))];
  const projectArray = unique.filter((n) => n);
  console.log(projectArray);
  const output = projectArray.map((elem) => {
    let regArr = [];
    let OTArr = [];
    let satArr = [];
    let sunArr = [];

    array.map((i) => {
      // console.log(i);
      if (
        (i.project === elem && i.id === 'sun1') ||
        (i.project === elem && i.id === 'sun2') ||
        (i.project === elem && i.id === 'sun3')
      ) {
        if (i.reg) {
          sunArr.push(i.reg);
        }
      } else if (
        (i.project === elem && i.id === 'sat1') ||
        (i.project === elem && i.id === 'sat2') ||
        (i.project === elem && i.id === 'sat3')
      ) {
        if (i.reg) {
          satArr.push(i.reg);
        }
      } else {
        if (i.project === elem) {
          //console.log('check this', i.project);
          //console.log('check this 2', elem);
          regArr.push(i.reg);
          OTArr.push(i.OT);
        }
      }

      return i;
    });
    // console.log(regArr);
    const regTotal = regArr.reduce((partialSum, a) => partialSum + a, 0);
    const OTTotal = OTArr.reduce((partialSum, a) => partialSum + a, 0);
    const satTotal = satArr.reduce((partialSum, a) => partialSum + a, 0);
    const sunTotal = sunArr.reduce((partialSum, a) => partialSum + a, 0);
    return {
      project: elem,
      regTotal: regTotal,
      OTTotal: OTTotal,
      satTotal: satTotal,
      sunTotal: sunTotal,
    };
  });
  console.log(output);

  return output;
};
