import LandingLayout from '../layouts/LandingLayout';
import LandingSection from '../modules/landing/LandingSection';
import Section1 from '../landingpage/Section1';
import Section2 from '../landingpage/Section2';
import { Box } from '@mui/material';

const LandingPage = () => {
  return (
    <LandingLayout>
      <LandingSection />
      <Box sx={{ bgcolor: 'transparent', position: 'relative' }}>
        {/*Count Down*/}
        <Section1 />

        {/*Learn More*/}
        <Section2 />
      </Box>
    </LandingLayout>
  );
};

export default LandingPage;
