import Popover from "@mui/material/Popover";
import { Box, Typography } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Check from "@mui/icons-material/Check";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import StopIcon from "@mui/icons-material/Stop";

const TimeClockPopover = ({
	idPopOut,
	openPopOut,
	anchorElPopOut,
	setAnchorElPopOut,
	message,
}) => {
	const handlePopOutClose = () => {
		setAnchorElPopOut(null);
	};

	//   const id = open ? 'simple-popover' : undefined;
	return (
		<Box sx={{ ml: "-350px" }}>
			{/* <Button aria-describedby={id} onClick={handleClick}>
        Query
      </Button> */}
			<Popover
				id={idPopOut}
				open={openPopOut}
				anchorEl={anchorElPopOut}
				onClose={handlePopOutClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Paper sx={{ width: 100 }}>
					<MenuList dense>
						<MenuItem>
							<ListItemIcon>
								<PlayArrowIcon />
							</ListItemIcon>
							<ListItemText>Resume</ListItemText>
						</MenuItem>
						<MenuItem>
							<ListItemIcon>
								<StopIcon />
							</ListItemIcon>
							<ListItemText>End</ListItemText>
						</MenuItem>
						<MenuItem>
							<ListItemIcon>
								<ClearIcon />
							</ListItemIcon>
							<ListItemText>Del</ListItemText>
						</MenuItem>
						<MenuItem>
							<ListItemIcon>
								<PendingActionsIcon />
							</ListItemIcon>
							<ListItemText>Approve</ListItemText>
						</MenuItem>
					</MenuList>
				</Paper>
			</Popover>
		</Box>
	);
};

export default TimeClockPopover;
