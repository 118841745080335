import React from "react";
import TextField from "@mui/material/TextField";
import {
	Box,
	Typography,
	useTheme,
	Stack,
	MenuItem,
	InputLabel,
} from "@mui/material";
import { tokens } from "../theme";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import Header from "../components/Header";
import SendIcon from "@mui/icons-material/Send";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DateField } from "@mui/x-date-pickers/DateField";
import { states } from "../assets/states";
import { cities } from "../assets/cities";
import { counties } from "../assets/counties";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";

const details = {
	project: "",
	city: "",
	county: "",
	state: "California",
	projectcode: "",
	projectmanager: {},
	biddate: null,
	book: "",
	address: "",
	start: null,
	end: null,
	id: "1",
};

const AddProjectSchema = Yup.object().shape({
	project: Yup.string().required("Required"),
	city: Yup.string().required("Required"),
	county: Yup.string().required("Required"),
	projectcode: Yup.number().integer().required("Required"),
	biddate: Yup.date().nullable(true).required("Required"),
	address: Yup.string().required("Required"),
	start: Yup.date().nullable(true).required("Required"),
	end: Yup.date().nullable(true).required("Required"),
	id: Yup.number().integer().required("Required"),
});

const AddProjectForm = ({ open, handleCloseBook, setAllProjects }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const { api } = useAuth();
	const [projectDetails] = useState(details);
	const [statusMessage, setStatusMessage] = useState(null);
	const [isErr, setIsErr] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const [bookList, setBookList] = useState([]);

	const formik = useFormik({
		initialValues: projectDetails,
		validationSchema: AddProjectSchema,
		onSubmit: (values) => {
			createProject(values);
		},
	});

	// Mapping Create Stuff
	const createProject = async (details) => {
		setIsErr(false);
		setIsSent(false);
		api
			.post("/api/v1/projects", JSON.stringify(details))
			.then(() => {
				setStatusMessage("Success!");
				setIsSent(true);
				handleCloseBook();
				getProjects();
			})
			.catch((error) => {
				setStatusMessage("Error Occurred");
				setIsErr(true);
			});
	};

	useEffect(() => {
		api
			.get("/api/v1/ratebook/booklist")
			.then(({ data }) => {
				setBookList(data.data);
				//setIsLoading(false);
			})
			.catch(() => {
				//setIsLoading(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getProjects = async () => {
		await api
			.get("/api/v1/projects")
			.then(({ data }) => {
				setAllProjects(data.data);
			})
			.catch(() => {});
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Header title={"Create A New Project"} subtitle={"Enter Below"} />
			<Typography variant="h6" sx={{ mt: 2, color: colors.greenAccent[400] }}>
				{" "}
				Required
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<FormControl sx={{ width: "99%" }}>
					{/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
					<TextField
						id="outlined-basic"
						label="Enter Project Name"
						name="project"
						variant="outlined"
						inputProps={{
							style: {
								padding: 8,
							},
						}}
						onChange={formik.handleChange}
						value={formik.values.project}
						error={formik.touched.project && Boolean(formik.errors.project)}
						helperText={formik.touched.project && formik.errors.project}
					/>
				</FormControl>
				<FormControl sx={{ width: "99%" }}>
					{/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
					<TextField
						id="outlined-basic"
						label="Project Address"
						name="address"
						variant="outlined"
						inputProps={{
							style: {
								padding: 8,
							},
						}}
						onChange={formik.handleChange}
						value={formik.values.address}
						error={formik.touched.address && Boolean(formik.errors.address)}
						helperText={formik.touched.address && formik.errors.address}
					/>
				</FormControl>

				<FormControl sx={{ width: "33.3%" }}>
					<InputLabel id="cityLabel">City</InputLabel>
					<Select
						labelId="cityLabel"
						size="small"
						id="city"
						value={formik.values.city}
						onChange={(e) => {
							formik.setFieldValue("city", e.target.value);
						}}
						error={formik.touched.city && Boolean(formik.errors.city)}
						helperText={formik.touched.city && formik.errors.city}
					>
						{cities.map((city, index) => (
							<MenuItem key={index} value={city}>
								{city}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ width: "33.3%" }}>
					<InputLabel id="countyLabel">County</InputLabel>
					<Select
						labelId="countyLabel"
						size="small"
						id="county"
						value={formik.values.county}
						onChange={(e) => {
							formik.setFieldValue("county", e.target.value);
						}}
						format="MM-DD-YYYY"
						error={formik.touched.county && Boolean(formik.errors.county)}
					>
						{counties.map((county, index) =>
							county.state === "CA" ? (
								<MenuItem key={index} value={county.county}>
									{county.county}
								</MenuItem>
							) : null
						)}
					</Select>
				</FormControl>

				<FormControl sx={{ width: "33.3%" }}>
					<InputLabel id="stateLabel">State</InputLabel>
					<Select
						labelId="stateLabel"
						size="small"
						id="state"
						value={formik.values.state}
						onChange={(newValue) => {
							formik.setFieldValue("state", newValue);
						}}
						format="MM-DD-YYYY"
						error={formik.touched.state && Boolean(formik.errors.state)}
					>
						{states.map((state, index) => (
							<MenuItem key={index} value={state.name}>
								{state.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl sx={{ width: "49.5%" }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateField
							label="Project Start"
							name="start"
							slotProps={{
								textField: {
									size: "small",
								},
							}}
							value={formik.values.start}
							onChange={(newValue) => {
								formik.setFieldValue("start", newValue);
							}}
							format="MM-DD-YYYY"
							error={formik.touched.start && Boolean(formik.errors.start)}
						/>
					</LocalizationProvider>
				</FormControl>
				<FormControl sx={{ width: "49.5%" }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateField
							label="Project End"
							name="end"
							slotProps={{
								textField: {
									size: "small",
								},
							}}
							value={formik.values.end}
							onChange={(newValue) => {
								formik.setFieldValue("end", newValue);
							}}
							format="MM-DD-YYYY"
							error={formik.touched.end && Boolean(formik.errors.end)}
						/>
					</LocalizationProvider>
				</FormControl>
				<FormControl sx={{ width: "49.5%" }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateField
							label="Bid Date"
							name="biddate"
							slotProps={{
								textField: {
									size: "small",
								},
							}}
							value={formik.values.biddate}
							onChange={(newValue) => {
								formik.setFieldValue("biddate", newValue);
							}}
							format="MM-DD-YYYY"
							error={formik.touched.biddate && Boolean(formik.errors.biddate)}
						/>
					</LocalizationProvider>
				</FormControl>

				<FormControl sx={{ width: "49.5%" }}>
					{/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
					<TextField
						id="outlined-basic"
						label="Enter Project Code"
						name="projectcode"
						variant="outlined"
						inputProps={{
							style: {
								padding: 8,
							},
						}}
						onChange={formik.handleChange}
						value={formik.values.projectcode}
						error={
							formik.touched.projectcode && Boolean(formik.errors.projectcode)
						}
						helperText={formik.touched.projectcode && formik.errors.projectcode}
					/>
				</FormControl>

				<FormControl sx={{ width: "50%" }}>
					<InputLabel id="bookLabel">Book</InputLabel>
					<Select
						labelId="bookLabel"
						size="small"
						id="book"
						value={formik.values.book}
						onChange={(e) => {
							formik.setFieldValue("book", e.target.value);
						}}
						error={formik.touched.book && Boolean(formik.errors.book)}
						helperText={formik.touched.book && formik.errors.book}
					>
						{bookList.map((book, index) => (
							<MenuItem key={index} value={book}>
								{book}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</form>
			<Box sx={{ margin: "auto", width: "25%", mt: "10px" }}>
				<Button
					variant="contained"
					sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
					onClick={formik.handleSubmit}
					endIcon={<SendIcon />}
				>
					Send
				</Button>
				{isSent ? (
					<Box>
						<Stack direction="row">
							<DoneOutlineIcon sx={{ color: colors.greenAccent[500] }} />
							<Typography> {statusMessage} </Typography>
						</Stack>
					</Box>
				) : null}
				{isErr ? (
					<Stack direction="row" textAlign="center">
						<ClearIcon sx={{ color: colors.redAccent[500] }} />
						<Typography> {statusMessage} </Typography>
					</Stack>
				) : null}
			</Box>
		</Box>
	);
};

export default AddProjectForm;
