import { useState, useEffect } from 'react';
import { BsKey } from 'react-icons/bs';
import { Box, Typography, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { tokens } from '../theme';
import CommonButton from './ButtonMui';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

const ResetPassword = ({ resetToken }) => {
  const { api } = useAuth();
  const [sentResetRequest, setsentResetRequest] = useState(false);
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [pwdConfirm, setPwdConfirm] = useState('');
  const [pwdError, setPwdError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [pwdMatchPwdError, setMatchPwdError] = useState(false);
  const [pwdBlankError, setPwdBlankError] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pwdRegError, setRegPwdError] = useState(false);

  //const [success, setSuccess] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setErrMsg('');
  }, [user]);

  const checkPasswords = () => {
    console.log(pwd, pwdConfirm);
    const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (pwd === '' || pwdConfirm === '') {
      setErrMsg('Please Enter A Password');
    } else if (!regularExpression.test(pwd)) {
      setErrMsg('Password should contain at least 1) a number 2) special character and 3) between 6 and 16 characters');
    } else if (pwd !== pwdConfirm) {
      console.log(pwd);
      console.log(pwdConfirm);
      setPwdError(true);
      setErrMsg('Passwords do not match');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    checkPasswords();

    console.log(!pwdError);
    if (!pwdError) {
      await api
        .put(`/api/v1/auth/resetpassword/${resetToken}`, JSON.stringify({ password: pwd }))
        .then((response) => {
          console.log(response);
          //setUser('');
          // setPwd('');
          setsentResetRequest(true);
        })
        .catch((err) => {
          if (!err?.response) {
            setErrMsg('No Server Response');
          } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
          } else if (err.response?.status === 401) {
            setErrMsg('UnAuthorized');
          } else {
            setErrMsg('Login Failed');
          }
        });
    }
  };

  //Form Styles
  const formStyles = {
    backgroundColor: colors.primary[400],
    '&:hover': {
      border: '.1px solid',
      borderRadius: 1,
      borderColor: colors.greenAccent[200],
    },
    input: { color: colors.greenAccent[300] },
    label: { color: colors.greenAccent[300] },
    width: '185px',
  };

  //Button Styles
  const buttonStyles = {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: colors.blueAccent[700],
    '&:hover': {
      backgroundColor: colors.blueAccent[600],
    },
  };

  const checkPwdIsBlank = (pwd, pwdConfirm) => {
    if (pwd === '' || pwdConfirm === '') {
      setPwdBlankError(true);
    } else {
      setPwdBlankError(false);
    }
  };

  const handlePasswordChange = (e) => {
    const updatedPwd = e.target.value;
    setPwd(updatedPwd);
    checkPwdIsBlank(updatedPwd);
    // checkPwdMatch(updatedPwd, pwdConfirm);
    // checkPasswords(updatedPwd);
  };

  const handlePasswordConfirmChange = (e) => {
    const updatedPwdConfirm = e.target.value;
    setPwdConfirm(updatedPwdConfirm);
    //checkPwdMatch(updatedPwdConfirm, pwd);
    // checkPasswords(updatedPwdConfirm);
  };

  const inputLabelStyles = {
    style: {
      color: colors.greenAccent[300],
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.primary[400],
        width: '300px',
        justifyContent: 'center',
        textAlign: 'center',
        m: 'auto',
        p: 2,
      }}
    >
      {sentResetRequest ? (
        <section>
          <h1> Password Has Been Reset. Please Log In </h1>
          <br></br>
          <Link component='button' variant='body2' key='Login' to={`/Login`}>
            <Typography
              sx={{
                pt: '10px',
                color: colors.blueAccent[500],
                textDecoration: 'underline',
              }}
            >
              Login
            </Typography>
          </Link>
        </section>
      ) : (
        <Box>
          <FormControl onSubmit={handleSubmit}>
            <h1>
              <b>Reset Password </b>
            </h1>

            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              {' '}
              <BsKey size={25} />
              <FormControl variant='outlined' size='small'>
                <OutlinedInput
                  id='outlined-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  value={pwd}
                  onChange={handlePasswordChange}
                  sx={formStyles}
                  error={pwdBlankError || pwdRegError || pwdMatchPwdError}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                />
              </FormControl>
            </Box>

            <Box component='div' sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <label htmlFor='password confirm'>
                <BsKey size={25} />
              </label>
              <FormControl variant='outlined' size='small'>
                <InputLabel htmlFor='outlined-adornment-password confirm' size='small'>
                  Password Confirm
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password confirm'
                  type={showPassword ? 'text' : 'password'}
                  value={pwdConfirm}
                  onChange={handlePasswordConfirmChange}
                  sx={formStyles}
                  InputLabelProps={inputLabelStyles}
                  error={pwdBlankError || pwdRegError || pwdMatchPwdError}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password Confirm'
                />
              </FormControl>
            </Box>

            <CommonButton size='large' variant='contained' onClick={handleSubmit} sx={buttonStyles}>
              {' '}
              Submit{' '}
            </CommonButton>
            <div className='error-message'>
              <p className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
                {errMsg}
              </p>
            </div>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default ResetPassword;
