import { Box, Typography, useTheme, Button } from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { tokens } from '../theme';
import Header from '../components/Header';
import dayjs from 'dayjs';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import AddMappingForm from '../components/AddMappingForm';
import AddBookForm from '../components/AddBookForm';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { counties } from '../assets/counties';

const filteredCounties = counties.filter((county) => county.state === 'CA');

const RateBook = ({ data, listData, setsentMapping }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openBook, setOpenBook] = useState(false);
  const handleOpenBook = () => setOpenBook(true);
  const handleCloseBook = () => setOpenBook(false);

  let renderedData;
  //map through array of objectsw
  if (data) {
    renderedData = data.map((ratebook) => ({
      id: ratebook._id,
      craft: ratebook.craft,
      classification: ratebook.classification,
      book: ratebook.book,
      regRate: ratebook.regRate,
      OTRate: ratebook.OTRate,
      satRate: ratebook.satRate,
      sunRate: ratebook.sunRate,
      training: ratebook.training,
      expirationDate: ratebook.expirationDate,
      dateInc1: ratebook.dateInc1,
      increase1: ratebook.increase1,
      dateInc2: ratebook.dateInc2,
      increase2: ratebook.increase2,
      dateInc3: ratebook.dateInc3,
      increase3: ratebook.increase3,
      dateInc4: ratebook.dateInc4,
      increase4: ratebook.increase4,
      dateInc5: ratebook.dateInc5,
      increase5: ratebook.increase5,
    }));
  }

  const hoverStyles = [
    {
      '&:hover': {
        backgroundColor: colors.greenAccent[600],
      },
    },
  ];

  function checkIncreases(
    baseRate,
    date1,
    increase1,
    date2,
    increase2,
    date3,
    increase3,
    date4,
    increase4,
    date5,
    increase5
  ) {
    let currentRate = baseRate;
    let rateSum = [];
    let increaseArray = [increase1, increase2, increase3, increase4, increase5];
    let dateArray = [date1, date2, date3, date4, date5];

    for (let i = 0; i < dateArray.length; i++) {
      if (dayjs().isAfter(dateArray[i]) > 0) {
        rateSum.push(increaseArray[i]);
      }
    }

    const output = rateSum.reduce((a, b) => a + b, currentRate);
    return output.toFixed(2);
  }

  //Modal Stuff
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    // p: 4,
    overflowY: 'auto',
    p: 1,
  };

  const columns = [
    {
      field: 'craft',
      headerName: 'Craft',
      renderCell: ({ row: { craft } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12 }}>{craft}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'classification',
      headerName: 'Classification',
      renderCell: ({ row: { classification } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12 }}>{classification}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'book',
      headerName: 'Book',
      width: 70,
      renderCell: ({ row: { book } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.grey[300] }}>
              {book}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'regRate',
      headerName: 'Regular Rate',
      flex: 0.1,
      renderCell: ({ row: { regRate } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.greenAccent[600] }}>
              {regRate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'OTRate',
      headerName: 'OTRate',
      flex: 0.1,
      renderCell: ({ row: { OTRate } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.greenAccent[500] }}>
              {OTRate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'satRate',
      headerName: 'satRate',
      flex: 0.1,
      renderCell: ({ row: { satRate } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.greenAccent[400] }}>
              {satRate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'sunRate',
      headerName: 'sunRate',
      flex: 0.1,
      renderCell: ({ row: { sunRate } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.greenAccent[300] }}>
              {sunRate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'training',
      headerName: 'training',
      width: 25,
      renderCell: ({ row: { training } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12 }}>{training}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'expirationDate',
      headerName: 'expirationDate',
      width: 80,
      renderCell: ({ row: { expirationDate } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isAfter(expirationDate) > 0
                    ? colors.redAccent[400]
                    : colors.greenAccent[300],
              }}>
              {dayjs(expirationDate).format('MM-DD-YYYY')}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'dateInc1',
      headerName: 'dateInc1',
      width: 70,
      renderCell: ({ row: { dateInc1 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc1) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {dateInc1 ? dayjs(dateInc1).format('MM-DD-YYYY') : null}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'increase1',
      headerName: 'increase1',
      flex: 0.1,
      renderCell: ({ row: { increase1, dateInc1 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc1) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {increase1}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'dateInc2',
      headerName: 'dateInc2',
      width: 70,
      renderCell: ({ row: { dateInc2 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc2) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {dateInc2 ? dayjs(dateInc2).format('MM-DD-YYYY') : null}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'increase2',
      headerName: 'increase2',
      flex: 0.1,
      renderCell: ({ row: { increase2, dateInc2 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc2) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {increase2}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'dateInc3',
      headerName: 'dateInc3',
      width: 70,
      renderCell: ({ row: { dateInc3 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc3) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {dateInc3 ? dayjs(dateInc3).format('MM-DD-YYYY') : null}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'increase3',
      headerName: 'increase3',
      flex: 0.1,
      renderCell: ({ row: { increase3, dateInc3 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc3) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {increase3}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'dateInc4',
      headerName: 'dateInc4',
      width: 70,
      renderCell: ({ row: { dateInc4 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc4) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {dateInc4 ? dayjs(dateInc4).format('MM-DD-YYYY') : null}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'increase4',
      headerName: 'increase4',
      flex: 0.1,
      renderCell: ({ row: { increase4, dateInc4 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc4) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {increase4}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'dateInc5',
      headerName: 'dateInc5',
      width: 70,
      renderCell: ({ row: { dateInc5 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc5) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {dateInc5 ? dayjs(dateInc5).format('MM-DD-YYYY') : null}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'increase5',
      headerName: 'increase5',
      flex: 0.1,
      renderCell: ({ row: { increase5, dateInc5 } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography
              sx={{
                fontSize: 12,
                color:
                  dayjs().isBefore(dateInc5) > 0
                    ? colors.grey[400]
                    : colors.greenAccent[500],
              }}>
              {increase5}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'currentRate',
      headerName: 'Reg',
      flex: 0.1,
      renderCell: ({
        row: {
          regRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5,
        },
      }) => {
        const currentRate = checkIncreases(
          regRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5
        );
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.blueAccent[500] }}>
              {currentRate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'currentOT',
      headerName: 'OT',
      flex: 0.1,
      renderCell: ({
        row: {
          OTRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5,
        },
      }) => {
        const currentOT = checkIncreases(
          OTRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5
        );
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.blueAccent[400] }}>
              {currentOT}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'currentSat',
      headerName: 'Sat',
      flex: 0.1,
      renderCell: ({
        row: {
          satRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5,
        },
      }) => {
        const currentSat = checkIncreases(
          satRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5
        );
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.blueAccent[300] }}>
              {currentSat}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'currentSun',
      headerName: 'Sun',
      flex: 0.1,
      renderCell: ({
        row: {
          sunRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5,
        },
      }) => {
        const currentSun = checkIncreases(
          sunRate,
          dateInc1,
          increase1,
          dateInc2,
          increase2,
          dateInc3,
          increase3,
          dateInc4,
          increase4,
          dateInc5,
          increase5
        );
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12, color: colors.blueAccent[200] }}>
              {currentSun}
            </Typography>
          </Box>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={handleOpen}>
          Create Map
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={handleOpenBook}>
          Create Book
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            {listData.project &&
            listData.craft &&
            listData.classification &&
            listData.book ? (
              <div className='center'>
                <AddMappingForm
                  listData={listData}
                  filteredCounties={filteredCounties}
                  setsentMapping={setsentMapping}
                  setOpen={setOpen}
                />
              </div>
            ) : null}
          </Box>
        </Modal>
        <Modal
          open={openBook}
          onClose={handleCloseBook}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            {listData.project &&
            listData.craft &&
            listData.classification &&
            listData.book ? (
              <div className='center'>
                <AddBookForm
                  listData={listData}
                  filteredCounties={filteredCounties}
                />
              </div>
            ) : null}
          </Box>
        </Modal>
      </GridToolbarContainer>
    );
  }

  return (
    <Box m='10px 20px 20px 20px'>
      <Header title='Ratebook' subtitle='Create A New Ratebook' />
      <Box
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .name=column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          showCellBottomBorder={true}
          showCellRightBorder={true}
          rows={renderedData}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          rowHeight={25}
        />
      </Box>
    </Box>
  );
};

export default RateBook;
