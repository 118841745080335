export const cities = [
  'Alameda',
  'Alhambra',
  'Aliso Viejo',
  'Anaheim',
  'Antioch',
  'Apple Valley',
  'Arcadia',
  'Azusa',
  'Bakersfield',
  'Baldwin Park',
  'Banning',
  'Beaumont',
  'Bell',
  'Bellflower',
  'Bell Gardens',
  'Berkeley',
  'Brentwood',
  'Buena Park',
  'Burbank',
  'Calexico',
  'Camarillo',
  'Campbell',
  'Carlsbad',
  'Carson',
  'Cathedral City',
  'Ceres',
  'Cerritos',
  'Chico',
  'Chino',
  'Chino Hills',
  'Chula Vista',
  'Citrus Heights',
  'Clovis',
  'Coachella',
  'Colton',
  'Compton',
  'Concord',
  'Corona',
  'Costa Mesa',
  'Covina',
  'Culver City',
  'Cupertino',
  'Daly City',
  'Dana Point',
  'Danville',
  'Davis',
  'Delano',
  'Diamond Bar',
  'Downey',
  'Dublin',
  'Eastvale',
  'El Cajon',
  'El Centro',
  'Elk Grove',
  'El Monte',
  'Encinitas',
  'Escondido',
  'Eureka',
  'Fairfield',
  'Folsom',
  'Fontana',
  'Fountain Valley',
  'Fremont',
  'Fresno',
  'Fullerton',
  'Garden Grove',
  'Gardena',
  'Gilroy',
  'Glendale',
  'Glendora',
  'Hanford',
  'Hawthorne',
  'Hayward',
  'Hemet',
  'Hesperia',
  'Highland',
  'Huntington Beach',
  'Huntington Park',
  'Indio',
  'Inglewood',
  'Irvine',
  'Jurupa Valley',
  'La Habra',
  'La Mesa',
  'La Mirada',
  'La Puente',
  'La Quinta',
  'Laguna Niguel',
  'Lake Elsinore',
  'Lake Forest',
  'Lakewood',
  'Lancaster',
  'Lincoln',
  'Livermore',
  'Lodi',
  'Lompoc',
  'Long Beach',
  'Los Angeles',
  'Los Banos',
  'Lynwood',
  'Madera',
  'Manteca',
  'Martinez',
  'Menifee',
  'Merced',
  'Milpitas',
  'Mission Viejo',
  'Modesto',
  'Montclair',
  'Montebello',
  'Monterey Park',
  'Moorpark',
  'Moreno Valley',
  'Morgan Hill',
  'Mountain View',
  'Murrieta',
  'Napa',
  'National City',
  'Newark',
  'Newport Beach',
  'Norwalk',
  'Novato',
  'Oakland',
  'Oakley',
  'Oceanside',
  'Ontario',
  'Orange',
  'Oxnard',
  'Palm Desert',
  'Palm Springs',
  'Palo Alto',
  'Palmdale',
  'Palo Alto',
  'Paradise',
  'Paramount',
  'Pasadena',
  'Perris',
  'Petaluma',
  'Pico Rivera',
  'Pittsburg',
  'Placentia',
  'Pleasanton',
  'Pomona',
  'Porterville',
  'Poway',
  'Rancho Cordova',
  'Rancho Cucamonga',
  'Rancho Palos Verdes',
  'Rancho Santa Margarita',
  'Redding',
  'Redlands',
  'Redondo Beach',
  'Redwood City',
  'Rialto',
  'Richmond',
  'Riverside',
  'Rocklin',
  'Rohnert Park',
  'Rosemead',
  'Roseville',
  'Sacramento',
  'Salinas',
  'San Bernardino',
  'San Buenaventura (Ventura)',
  'San Clemente',
  'San Diego',
  'San Fernando',
  'San Francisco',
  'San Gabriel',
  'San Jacinto',
  'San Jose',
  'San Leandro',
  'San Luis Obispo',
  'San Marcos',
  'San Mateo',
  'San Rafael',
  'San Ramon',
  'Santa Ana',
  'Santa Barbara',
  'Santa Clara',
  'Santa Clarita',
  'Santa Cruz',
  'Santa Maria',
  'Santa Monica',
  'Santa Rosa',
  'Santee',
  'Sunnyvale',
  'Simi Valley',
  'South Gate',
  'South San Francisco',
  'Stanton',
  'Stockton',
  'Temecula',
  'Thousand Oaks',
  'Torrance',
  'Tracy',
  'Tulare',
  'Turlock',
  'Tustin',
  'Union City',
  'Upland',
  'Vacaville',
  'Vallejo',
  'Victorville',
  'Visalia',
  'Vista',
  'Walnut Creek',
  'Watsonville',
  'West Covina',
  'Westminster',
  'Whittier',
  'Woodland',
  'Yorba Linda',
  'Yuba City',
  'Yucaipa',
];
