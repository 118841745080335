import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Container, Hidden, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import CityImage from '../../assets/City.png';
import MainBG from '../../assets/RedOrangeBg.png';
import MobileBG from '../../assets/iPhoneMagicBackground.svg';
import LaunchButton from '../../components/LaunchButton';
import Title from '../../components/title';
import { tokens } from '../../theme';
import SignUpModal from '../../components/SignUpModal';
import Phone1Img from "../../assets/payroll-images/payroll-app1.png";


const CustomButton = ({ children, colors, ...props }) => (
  <Button
    variant='contained'
    sx={{
      borderRadius: 4,
      color: 'black',
      backgroundColor: colors.blueAccent[500],
      border: 'none',
      borderColor: 'none',
      height: 58,
      px: 2,
    }}
    {...props}
  >
    {children}
  </Button>
);

const LandingSection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [title, setTitle] = useState('Time Keeping/Payroll Automation');
  const [subtitle, setsubtitle] = useState('Finds Prevailing Wage Rates');
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const navigate = useNavigate();
  const [ref, { height }] = useMeasure();
  const handleClick = () => {
    navigate('/Login');
  };
  const handleLearnClick = () => {
    navigate('/features');
  };
  const handleTrialClick = () => {
    // navigate('/start-free-trial');
    setShowSignUpModal(true);
  };
  const handleCancelSignUp = () => {
    setShowSignUpModal(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Main Background */}
      <Box sx={{ position: 'fixed', zIndex: -10, top: 0, left: 0, right: 0 }}>
   <img src={MainBG} style={{ width: '100%' }} alt='Background' />
      </Box>

      {/* backgrounds elements */}
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          width: '100%',
          zIndex: -1,
          top: 0,
          left: 0,
          right: 0,
        }}
      >
         <Hidden mdDown>
         <img src={MainBG} style={{ height: '100%', opacity: 0 }} alt='Background' />
         </Hidden>

        {/* Star */}
        {/* <img
          src={ShootingStarImage}
          style={{
            position: 'absolute',
            top: '30px',
            right: '15%',
            width: '500px',
          }}
          alt='Shooting Star'
        /> */}

        {/* City */}
        <Hidden mdDown>
          <img
            src={CityImage}
            style={{
              position: 'absolute',
              width: '100%',
              right: 0,
              left: 0,
              bottom: '13%',
            }}
            alt='City'
          />
        </Hidden>

        {/* Cliff */}
        <img
          src={Phone1Img}
          style={{
            height: '70%',
            position: 'absolute',
            right: '5%',
            top: 0,
            backgroundSize: 'cover',
          }}
          alt='Cliff'
        />

        {/* Horse */}
        {/* <img
          src={HorseImage}
          style={{
            position: 'absolute',
            height: '38%',
            right: '14%',
            bottom: '45%',
            transform: 'rotate(7deg)',
          }}
          alt='Horse'
        /> */}

        <Box
          sx={{
            bgcolor: 'black',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: {xs: '2800px', sm: '2800px', md:'1800px', lg:'1400px'},
            top: `calc(${height}px - 13%)`,
          }}
        ></Box>
      </Box>

      {/* Content */}
      <Container
        sx={{
          height: '80vh',
          mt: 8,
          [theme.breakpoints.up('md')]: { mt: 6 },
        }}
      >
        <Stack sx={{ height: '100%' }} justifyContent='center'>
          <Title variant={{ xs: 'h3', sm: 'h2', md: 'h1' }} sx={{ letterSpacing: '0.02em', mb: 1 }} >
            {title}
          </Title>

          <Title variant={{ xs: 'h4', sm: 'h3', md: 'h2' }} sx={{ fontWeight: 500, letterSpacing: '0.05em', mb: 5 }} color={colors.grey[200]}>
            {subtitle}
          </Title>

          <Stack direction={{ xs: 'column', md: 'row' }} alignItems='center' spacing={4}>
            <LaunchButton
              fullWidth={isSmallScreen}
              onClick={handleClick}
              sx={{
                height: 58,
                px: 3,
                backgroundColor: colors.blueAccent[500],
              }}
            />

            <CustomButton fullWidth={isSmallScreen} colors={colors} onClick={handleLearnClick}>
              <PlayArrowIcon sx={{ fontSize: 26, ml: -1 }} />

              <Stack sx={{ textAlign: 'left', ml: 1 }}>
                <Typography variant='h7'> Learn More</Typography>
              </Stack>
            </CustomButton>

            <CustomButton fullWidth={isSmallScreen} colors={colors} onClick={handleTrialClick} >
              <DomainVerificationIcon sx={{ fontSize: 26, ml: -1 }} />

              <Stack sx={{ textAlign: 'left', ml: 1 }}>
                <Typography variant='h7'>Start Free Trial</Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Stack>
      </Container>

      <SignUpModal open={showSignUpModal} onCancel={handleCancelSignUp} />
    </Box>
  );
};

export default LandingSection;
