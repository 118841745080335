import React from "react";
import TextField from "@mui/material/TextField";
import {
	Box,
	Typography,
	useTheme,
	Stack,
	MenuItem,
	InputLabel,
} from "@mui/material";
import { tokens } from "../theme";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import Header from "../components/Header";
import SendIcon from "@mui/icons-material/Send";
import { DateField } from "@mui/x-date-pickers/DateField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../context/AuthProvider";

const typesList = ["By Role", "Custom"];

const details = {
	craft: "",
	classification: "",
	expirationDate: null,
	basicRate: "",
	healthAndWelfare: "",
	pension: "",
	vacationAndHoliday: "",
	training: "",
	otherPayments: "",
	hours: "",
	regRate: "",
	OTRate: "",
	satRate: "",
	sunRate: "",
	dateInc1: null,
	increase1: "",
	dateInc2: null,
	increase2: "",
	dateInc3: null,
	increase3: "",
	dateInc4: null,
	increase4: "",
	dateInc5: null,
	increase5: "",
	book: "",
	issuedate: null,
	mappingType: "By Role",
};

const AddBookSchema = Yup.object().shape({
	craft: Yup.string().required("Required"),
	classification: Yup.string().required("Required"),
	book: Yup.string().required("Required"),
	issuedate: Yup.date().required("Required"),
	expirationDate: Yup.date().required("Required"),
	training: Yup.number().required("Required"),
	basicRate: Yup.number().required("Required"),
	regRate: Yup.number().required("Required"),
	OTRate: Yup.number().required("Required"),
	satRate: Yup.number().required("Required"),
	sunRate: Yup.number().required("Required"),
	dateInc1: Yup.date().nullable(true),
	increase1: Yup.number().nullable(true),
	dateInc2: Yup.date().nullable(true),
	increase2: Yup.number().nullable(true),
	dateInc3: Yup.date().nullable(true),
	increase3: Yup.number().nullable(true),
	dateInc4: Yup.date().nullable(true),
	increase4: Yup.number().nullable(true),
	dateInc5: Yup.date().nullable(true),
	increase5: Yup.number().nullable(true),
});

const AddCustomBookSchema = Yup.object().shape({
	craft: Yup.string().nullable(true),
	classification: Yup.string().nullable(true),
	book: Yup.string().required("Required"),
	issuedate: Yup.date().required("Required"),
	expirationDate: Yup.date().required("Required"),
	training: Yup.number().required("Required"),
	basicRate: Yup.number().required("Required"),
	regRate: Yup.number().required("Required"),
	OTRate: Yup.number().required("Required"),
	satRate: Yup.number().required("Required"),
	sunRate: Yup.number().required("Required"),
	dateInc1: Yup.date().nullable(true),
	increase1: Yup.number().nullable(true),
	dateInc2: Yup.date().nullable(true),
	increase2: Yup.number().nullable(true),
	dateInc3: Yup.date().nullable(true),
	increase3: Yup.number().nullable(true),
	dateInc4: Yup.date().nullable(true),
	increase4: Yup.number().nullable(true),
	dateInc5: Yup.date().nullable(true),
	increase5: Yup.number().nullable(true),
});

const AddBookForm = ({ open, handleClose, listData }) => {
	const { api } = useAuth();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [mappingDetails, setMappingDetails] = useState(details);
	const [isCustom, setIsCustom] = useState(false);
	const [statusMessage, setStatusMessage] = useState(null);
	const [bookDetails, setBookDetails] = useState(details);
	const [validationSchema, setValidationSchema] = useState(AddBookSchema);

	const handleMappingTypeChange = (update) => {
		if (update.mappingType === "Custom") {
			const newUpdate = { ...update, ...{ craft: "", classification: "" } };
			setMappingDetails({ ...mappingDetails, newUpdate });
			setIsCustom(true);
			setValidationSchema(AddCustomBookSchema);
		} else if (update.mappingType === "By Role") {
			setMappingDetails({ ...mappingDetails, ...update });
			setIsCustom(false);
			setValidationSchema(AddBookSchema);
		}
	};

	const updateBookDetails = (update) => {
		setBookDetails({ ...bookDetails, ...update });
	};

	const formik = useFormik({
		initialValues: bookDetails,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			createRatebook(values);
		},
	});

	// Mapping Create Stuff
	const createRatebook = async (details) => {
		api
			.post("/api/v1/ratebook", JSON.stringify(details))
			.then(() => {
				// const updatedPayroll = [...allTimecards, response.data.data];
				// setBookDetails(updatedPayroll);
				setStatusMessage("RateBook Created!");
			})
			.catch((error) => {
				setStatusMessage("An Error Occurred!");
			});
	};

	return (
		<Box>
			<Header title={"Create A New Ratebook"} subtitle={"Enter Below"} />
			<Typography variant="h6" sx={{ mt: 2, color: colors.greenAccent[400] }}>
				{" "}
				Required
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<FormControl sx={{ width: "99%" }}>
					{/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
					<TextField
						id="outlined-basic"
						label="Enter BookName"
						name="book"
						variant="outlined"
						inputProps={{
							style: {
								padding: 8,
							},
						}}
						onChange={formik.handleChange}
						value={formik.values.book}
						error={formik.touched.book && Boolean(formik.errors.book)}
					/>
				</FormControl>
				<FormControl sx={{ width: "99%" }}>
					<InputLabel id="mappingTypeLabel">Type</InputLabel>
					<Select
						labelId="mappingTypeLabel"
						size="small"
						id="mappingType"
						name="mappingType"
						displayEmpty
						value={formik.values.mappingType}
						onChange={(e) => {
							handleMappingTypeChange({ mappingType: e.target.value });
							formik.handleChange(e);
						}}
						error={
							formik.touched.mappingType && Boolean(formik.errors.mappingType)
						}
						helperText={formik.touched.mappingType && formik.errors.mappingType}
					>
						{typesList.map((obj, index) => (
							<MenuItem key={index} name="mappingType" value={obj}>
								{obj}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{isCustom ? null : (
					<FormControl sx={{ width: "99%" }}>
						<InputLabel id="tradeLabel">Craft</InputLabel>
						<Select
							labelId="tradeLabel"
							size="small"
							id="craft"
							name="craft"
							value={formik.values.craft}
							error={formik.touched.craft && Boolean(formik.errors.craft)}
							helperText={formik.touched.craft && formik.errors.craft}
							onChange={formik.handleChange}
						>
							{listData.craft.data.data.map((name, index) => (
								<MenuItem key={index} value={name}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}

				{isCustom ? null : (
					<FormControl sx={{ width: "99%" }}>
						<InputLabel id="classificationLabel">Classification</InputLabel>
						<Select
							labelId="classificationLabel"
							size="small"
							id="classification"
							name="classification"
							value={formik.values.classification}
							error={
								formik.touched.classification &&
								Boolean(formik.errors.classification)
							}
							helperText={
								formik.touched.classification && formik.errors.classification
							}
							onChange={formik.handleChange}
						>
							{listData.classification.data.data.map((name, index) => (
								<MenuItem key={index} value={name}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<Stack direction="row">
					<FormControl sx={{ width: "49.5%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateField
								label="Issue Date"
								name="issuedate"
								slotProps={{
									textField: {
										size: "small",
									},
								}}
								value={formik.values.issuedate}
								onChange={(newValue) => {
									formik.setFieldValue("issuedate", newValue);
								}}
								format="MM-DD-YYYY"
								error={
									formik.touched.issuedate && Boolean(formik.errors.issuedate)
								}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ width: "49.5%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateField
								name="expirationDate"
								label="Expiration Date"
								slotProps={{
									textField: { size: "small" },
								}}
								// onChange={(value) =>
								//   updateBookDetails({
								//     expirationDate: value,
								//   })
								// }
								value={formik.values.expirationDate}
								onChange={(newValue) => {
									formik.setFieldValue("expirationDate", newValue);
								}}
								format="MM-DD-YYYY"
								error={
									formik.touched.expirationDate &&
									Boolean(formik.errors.expirationDate)
								}
							/>
						</LocalizationProvider>
					</FormControl>
				</Stack>
				<Stack direction="row">
					<FormControl sx={{ width: "33%" }}>
						<TextField
							id="outlined-basic"
							label="Training Rate"
							name="training"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							// onChange={(e) => updateBookDetails({ training: e.target.value })}
							value={formik.values.training}
							error={formik.touched.training && Boolean(formik.errors.training)}
							onChange={formik.handleChange}
						/>
					</FormControl>
					<FormControl sx={{ width: "33%" }}>
						<TextField
							id="outlined-basic"
							label="Basic Rate"
							name="basicRate"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							value={formik.values.basicRate}
							error={
								formik.touched.basicRate && Boolean(formik.errors.basicRate)
							}
							onChange={formik.handleChange}
						/>
					</FormControl>
					<FormControl sx={{ width: "33%" }}>
						<TextField
							id="outlined-basic"
							label="Regular Rate"
							variant="outlined"
							name="regRate"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							value={formik.values.regRate}
							error={formik.touched.regRate && Boolean(formik.errors.regRate)}
							onChange={formik.handleChange}
						/>
					</FormControl>
				</Stack>
				<Stack direction="row">
					<FormControl sx={{ width: "33%" }}>
						<TextField
							id="outlined-basic"
							label="OT Rate"
							variant="outlined"
							name="OTRate"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							value={formik.values.OTRate}
							error={formik.touched.OTRate && Boolean(formik.errors.OTRate)}
							onChange={formik.handleChange}
						/>
					</FormControl>
					<FormControl sx={{ width: "33%" }}>
						<TextField
							id="outlined-basic"
							label="Saturday Rate"
							name="satRate"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							value={formik.values.satRate}
							error={formik.touched.satRate && Boolean(formik.errors.satRate)}
							onChange={formik.handleChange}
						/>
					</FormControl>
					<FormControl sx={{ width: "33%" }}>
						<TextField
							id="outlined-basic"
							label="Sunday Rate"
							variant="outlined"
							name="sunRate"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							value={formik.values.sunRate}
							error={formik.touched.sunRate && Boolean(formik.errors.sunRate)}
							onChange={formik.handleChange}
						/>
					</FormControl>
				</Stack>
				<Typography variant="h6" sx={{ mt: 2, color: colors.greenAccent[400] }}>
					{" "}
					Optional Predetermined Increases
				</Typography>
				<Stack direction="row">
					<FormControl sx={{ width: "30%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateField
								label="Date 1"
								slotProps={{
									textField: { size: "small" },
								}}
								// onChange={(value) => updateBookDetails({ dateInc1: value })}
								value={formik.values.dateInc1}
								onChange={formik.handleChange}
								format="MM-DD-YYYY"
								error={Boolean(formik.errors.dateInc1)}
							></DateField>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ width: "20%" }}>
						<TextField
							id="outlined-basic"
							label="Rate 1"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							name="increase1"
							value={formik.values.increase1}
							onChange={formik.handleChange}
							format="MM-DD-YYYY"
							error={Boolean(formik.errors.increase1)}
						/>
					</FormControl>
					<FormControl sx={{ width: "30%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateField
								label="Date 2"
								slotProps={{
									textField: { size: "small" },
								}}
								value={formik.values.dateInc2}
								onChange={formik.handleChange}
								format="MM-DD-YYYY"
								error={Boolean(formik.errors.dateInc2)}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ width: "20%" }}>
						<TextField
							id="outlined-basic"
							label="Rate 2"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							name="increase2"
							value={formik.values.increase2}
							onChange={formik.handleChange}
							format="MM-DD-YYYY"
							error={Boolean(formik.errors.increase2)}
						/>
					</FormControl>
				</Stack>
				<Stack direction="row">
					<FormControl sx={{ width: "30%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateField
								label="Date 3"
								value={bookDetails.dateInc3}
								slotProps={{
									textField: { size: "small" },
								}}
								error={false}
								onChange={(value) => updateBookDetails({ dateInc3: value })}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ width: "20%" }}>
						<TextField
							id="outlined-basic"
							label="Rate 3"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							onChange={(e) => updateBookDetails({ increase3: e.target.value })}
							value={bookDetails.increase3}
						/>
					</FormControl>
					<FormControl sx={{ width: "30%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateField
								label="Date 4"
								value={bookDetails.dateInc4}
								slotProps={{
									textField: { size: "small" },
								}}
								error={false}
								onChange={(value) => updateBookDetails({ dateInc4: value })}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ width: "20%" }}>
						<TextField
							id="outlined-basic"
							label="Rate 4"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							onChange={(e) => updateBookDetails({ increase4: e.target.value })}
							value={bookDetails.increase4}
						/>
					</FormControl>
				</Stack>
				<Stack direction="row">
					<FormControl sx={{ width: "30%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateField
								label="Date 5"
								value={bookDetails.dateInc5}
								slotProps={{
									textField: { size: "small" },
								}}
								error={false}
								onChange={(value) => updateBookDetails({ dateInc5: value })}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ width: "20%" }}>
						<TextField
							id="outlined-basic"
							label="Rate 5"
							variant="outlined"
							inputProps={{
								style: {
									padding: 9,
								},
							}}
							onChange={(e) => updateBookDetails({ increase5: e.target.value })}
							value={bookDetails.increase5}
						/>
					</FormControl>
				</Stack>
				<Box textAlign="center">
					<Typography
						variant="h6"
						sx={{ mt: 2, color: colors.greenAccent[400] }}
					>
						{statusMessage}
					</Typography>
					<Button
						variant="contained"
						sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
						onClick={formik.handleSubmit}
						endIcon={<SendIcon />}
					>
						Send
					</Button>
				</Box>
			</form>
		</Box>
	);
};

export default AddBookForm;
