import { Button } from '@mui/material';
import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const LaunchButton = ({ sx = {}, onClick, ...props }) => {
  return (
    <Button
      variant='contained'
      sx={{ borderRadius: 4, ...sx }}
      {...props}
      onClick={onClick}>
      Log In
      <KeyboardArrowRightIcon />
    </Button>
  );
};

export default LaunchButton;
