import Field from '../components/translate/field';
import Languages from '../components/translate/languages';
import Translate from '../components/translate/translation';
import React, { useState } from 'react';

const LanguagePage = () => {
  const [language, setLanguage] = useState('ru');
  const [text, setText] = useState('');
  return (
    <div>
      <Field label='Enter English' onChange={setText} value={text} />
      <Languages language={language} onLanguageChange={setLanguage} />
      <hr />
      <Translate text={text} language={language} />
    </div>
  );
};

export default LanguagePage;
