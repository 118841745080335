import { useMemo, useState, useEffect } from 'react';
import DashBoardBox from '../components/DashBoardBox';
import AnalyticsBoxHeader from '../components/AnalyticsBoxHeader';
import { useTheme, Box, Stack, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../theme';
import Alert from '@mui/material/Alert';
import { ResponsiveContainer } from 'recharts';
import useFetch from '../hooks/fetchAPI';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const fakedata = [
  {
    name: 'May',
    rfis: 4000,
    change_orders: 2400,
    non_compliance_reports: 2400,
  },
  {
    name: 'Jun',
    rfis: 3000,
    change_orders: 1398,
    non_compliance_reports: 2210,
  },
  {
    name: 'Jul',
    rfis: 2000,
    change_orders: 9800,
    non_compliance_reports: 2290,
  },
  {
    name: 'Aug',
    rfis: 2780,
    change_orders: 3908,
    non_compliance_reports: 2000,
  },
  {
    name: 'Sep',
    rfis: 1890,
    change_orders: 4800,
    non_compliance_reports: 2181,
  },
  {
    name: 'Oct',
    rfis: 1590,
    change_orders: 9000,
    non_compliance_reports: 3500,
  },
];

const fakedata2 = [
  {
    id: 1,
    name: 'May',
    rfis: 4000,
    change_orders: 2400,
    non_compliance_reports: 2400,
  },
  {
    id: 2,
    name: 'Jun',
    rfis: 3000,
    change_orders: 1398,
    non_compliance_reports: 2210,
  },
  {
    id: 3,
    name: 'Jul',
    rfis: 2000,
    change_orders: 9800,
    non_compliance_reports: 2290,
  },
  {
    id: 4,
    name: 'Aug',
    rfis: 2780,
    change_orders: 3908,
    non_compliance_reports: 2000,
  },
  {
    id: 5,
    name: 'Sep',
    rfis: 1890,
    change_orders: 4800,
    non_compliance_reports: 2181,
  },
  {
    id: 6,
    name: 'Oct',
    rfis: 1590,
    change_orders: 9000,
    non_compliance_reports: 3500,
  },
];

const fakeColumns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.4,
  },
  {
    field: 'rfis',
    headerName: 'RFIs',
    flex: 0.4,
  },
  {
    field: 'change_orders',
    headerName: 'Change Orders',
    flex: 0.4,
  },
  {
    field: 'non_compliance_reports',
    headerName: 'Non Compliance Reports',
    flex: 0.4,
  },
];

const COColumns = [
  {
    field: 'project',
    headerName: 'Project',
    flex: 0.4,
  },
  {
    field: 'scope',
    headerName: 'Scope',
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
  {
    field: 'background',
    headerName: 'Background',
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
];

const RFIColumns = [
  {
    field: 'to',
    headerName: 'To',
    flex: 0.4,
  },
  {
    field: 'from',
    headerName: 'From',
    flex: 0.4,
  },
  {
    field: 'question',
    headerName: 'Question',
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
  {
    field: 'project',
    headerName: 'Project',
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
];

const Row3 = ({
  COProject,
  setCOProject,
  RFIProject,
  setRFIProject,
  listData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { data, loading, error } = useFetch('/api/v1/timecardsv2/');
  const [errMsg, setErrMsg] = useState(error);
  const [renderedCOData, setRenderedCOData] = useState([]);
  const [renderedRFIData, setRenderedRFIData] = useState([]);
  const handleChange = (event) => {
    setCOProject(event.target.value);
  };

  const handleRFIChange = (event) => {
    setRFIProject(event.target.value);
  };
  const weeklyHours = useMemo(() => {
    return (
      data &&
      data?.map(({ weekEnd, regHours }) => {
        return {
          weekEnd: dayjs(weekEnd).format('MM/DD/YYYY'),
          regHours: regHours,
        };
      })
    );
  }, [data]);

  const analytics = useMemo(() => {
    return (
      data &&
      data.map(({ analytics }) => {
        let res = analytics.reduce(
          (a, b) =>
            a.set(b.project, (a.get(b.project) || 0) + Number(b.regTotal)),
          new Map()
        );
        return {
          project: res.keys().next().value,
          hours: res.values().next().value,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    //setAllTimecards(data);
    let renderedData = [];
    let renderedData2 = [];
    //map through array of objectsw
    if (listData?.co?.data.data) {
      renderedData = listData?.co?.data.data[0].results.map((co) => ({
        id: co._id,
        project: co.project,
        scope: co.scope,
        reason: co.reason,
        background: co.background,
        createdAt: dayjs(co.createdAt).format('MM-DD-YYYY'),
      }));

      renderedData2 = listData?.rfi?.data.data[0].results.map((rfi) => ({
        id: rfi._id,
        to: rfi.to,
        from: rfi.from,
        question: rfi.question,
        project: rfi.project,
        createdAt: dayjs(rfi.createdAt).format('MM-DD-YYYY'),
      }));

      setRenderedCOData(renderedData);
      setRenderedRFIData(renderedData2);
    }
  }, [listData?.co?.data.data, listData?.rfi?.data.data]);
  return (
    <>
      <DashBoardBox gridArea='g' theme={theme} colors={colors}>
        <Stack direction='row' spacing={2} alignItems='right'>
          <AnalyticsBoxHeader title='Change Order Table' subtitle='(Summary)' />
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            <Select
              labelId='demo-select-small-label'
              id='demo-select-small'
              value={COProject}
              onChange={handleChange}>
              <MenuItem value='All Projects'>
                <em>All Projects</em>
              </MenuItem>
              {listData?.projectList?.data.data.map((project) => (
                <MenuItem key={project} value={project}>
                  {project}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {errMsg ? <Alert severity='error'>{errMsg}</Alert> : null}
        <ResponsiveContainer width='95%' height='80%'>
          <Box
            p='0 0.5rem'
            height='85%'
            sx={{
              '& .MuiDataGrid-root': {
                color: `${colors.grey[300]}`,
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid ${colors.grey[800]} !important`,
              },
              '& .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
            }}>
            <DataGrid
              rowHeight={25}
              columnHeaderHeight={25}
              hideFooter={true}
              rows={renderedCOData || []}
              columns={COColumns}
            />
          </Box>
        </ResponsiveContainer>
      </DashBoardBox>
      <DashBoardBox gridArea='h' theme={theme} colors={colors}>
        <Stack direction='row' spacing={2} alignItems='right'>
          <AnalyticsBoxHeader title='RFI' subtitle='(Summary)' sidetext='+4%' />
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            <Select
              labelId='demo-select-small-label'
              id='demo-select-small'
              value={RFIProject}
              onChange={handleRFIChange}>
              <MenuItem value='All Projects'>
                <em>All Projects</em>
              </MenuItem>
              {listData?.projectList?.data.data.map((project) => (
                <MenuItem key={project} value={project}>
                  {project}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {errMsg ? <Alert severity='error'>{errMsg}</Alert> : null}
        <ResponsiveContainer width='95%' height='80%'>
          <Box
            p='0 0.5rem'
            height='85%'
            sx={{
              '& .MuiDataGrid-root': {
                color: `${colors.grey[300]}`,
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid ${colors.grey[800]} !important`,
              },
              '& .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
            }}>
            <DataGrid
              rowHeight={25}
              columnHeaderHeight={25}
              hideFooter={true}
              rows={renderedRFIData || []}
              columns={RFIColumns}
            />
          </Box>
        </ResponsiveContainer>
      </DashBoardBox>
      {/* <DashBoardBox gridArea='i' theme={theme} colors={colors}>
        <AnalyticsBoxHeader
          title='Training Funds'
          subtitle='(Trailing 6 Months)'
          sidetext='+0%'
        />
        {errMsg ? <Alert severity='error'>{errMsg}</Alert> : null}
        <ResponsiveContainer width='95%' height='80%'>
          <Box
            p='0 0.5rem'
            height='85%'
            sx={{
              '& .MuiDataGrid-root': {
                color: `${colors.grey[300]}`,
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid ${colors.grey[800]} !important`,
              },
              '& .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
            }}>
            <DataGrid
              rowHeight={25}
              columnHeaderHeight={25}
              hideFooter={true}
              rows={fakedata2 || []}
              columns={fakeColumns}
            />
          </Box>
        </ResponsiveContainer>
      </DashBoardBox> */}
    </>
  );
};

export default Row3;
