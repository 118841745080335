import React from 'react';
import TextField from '@mui/material/TextField';
import {
  Box,
  Typography,
  useTheme,
  Stack,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { tokens } from '../theme';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import Header from '../components/Header';
import SendIcon from '@mui/icons-material/Send';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearIcon from '@mui/icons-material/Clear';

const details = {
  to: '',
  from: '',
  project: '',
  subject: '',
  scope: '',
  reason: '',
  background: '',
  ron: '',
};

const AddProjectSchema = Yup.object().shape({
  to: Yup.string().required('Required'),
  from: Yup.string().required('Required'),
  project: Yup.string().required('Required'),
  subject: Yup.string().required('Required'),
  scope: Yup.string().required('Required'),
  reason: Yup.string().required('Required'),
  background: Yup.string().required('Required'),
  ron: Yup.string().required('Required'),
});

const AddChangeOrderForm = ({
  open,
  handleCloseBook,
  setAllProjects,
  listData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { api } = useAuth();
  const [changeOrderDetails] = useState(details);
  const [statusMessage, setStatusMessage] = useState(null);
  const [isErr, setIsErr] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const formik = useFormik({
    initialValues: changeOrderDetails,
    validationSchema: AddProjectSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      createCO(values);
    },
  });

  // Change Order Create Stuff
  const createCO = async (details) => {
    setIsErr(false);
    setIsSent(false);
    await api
      .post('/api/v1/changeorder', JSON.stringify(details))
      .then(() => {
        setStatusMessage('Success!');
        setIsSent(true);
        handleCloseBook();
        getProjects();
      })
      .catch((error) => {
        setStatusMessage('Error Occurred');
        setIsErr(true);
      });
  };

  const getProjects = async () => {
    await api
      .get('/api/v1/projects')
      .then(({ data }) => {
        setAllProjects(data.data);
      })
      .catch(() => {});
  };
  console.log(listData);
  return (
    <Box sx={{ width: '100%' }}>
      <Header title={'Create A New Change Order'} subtitle={'Enter Below'} />
      <Typography variant='h6' sx={{ mt: 2, color: colors.greenAccent[400] }}>
        {' '}
        Required Now
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ width: '33.3%' }}>
          <InputLabel id='toLabel'>To</InputLabel>
          <Select
            labelId='toLabel'
            size='small'
            id='to'
            value={formik.values.to}
            onChange={(e) => {
              formik.setFieldValue('to', e.target.value);
            }}
            error={formik.touched.to && Boolean(formik.errors.to)}
            helperText={formik.touched.to && formik.errors.to}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {listData?.personnel.data.data.map((elem, index) => (
              <MenuItem key={index} value={elem._id}>
                {elem.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '33.3%' }}>
          <InputLabel id='fromLabel'>From</InputLabel>
          <Select
            labelId='fromLabel'
            size='small'
            id='from'
            value={formik.values.from}
            onChange={(e) => {
              formik.setFieldValue('from', e.target.value);
            }}
            format='MM-DD-YYYY'
            error={formik.touched.from && Boolean(formik.errors.from)}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {listData?.personnel.data.data.map((elem, index) => (
              <MenuItem key={index} value={elem._id}>
                {elem.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '33.3%' }}>
          <InputLabel id='projectLabel'>Project</InputLabel>
          <Select
            labelId='projectLabel'
            size='small'
            id='project'
            value={formik.values.project}
            onChange={(e) => {
              formik.setFieldValue('project', e.target.value);
            }}
            format='MM-DD-YYYY'
            error={formik.touched.project && Boolean(formik.errors.project)}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {listData?.projects.data.data.map((project, index) => (
              <MenuItem key={index} value={project}>
                {project}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='subject'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Subject'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.subject}
            error={formik.touched.subject && Boolean(formik.errors.subject)}
            helperText={formik.touched.subject && formik.errors.subject}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='scope'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Scope'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.scope}
            error={formik.touched.scope && Boolean(formik.errors.scope)}
            helperText={formik.touched.scope && formik.errors.scope}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='background'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Background'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.background}
            error={
              formik.touched.background && Boolean(formik.errors.background)
            }
            helperText={formik.touched.background && formik.errors.background}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='reason'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Reason'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.reason}
            error={formik.touched.reason && Boolean(formik.errors.reason)}
            helperText={formik.touched.reason && formik.errors.reason}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='ron'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Record Of Negotiation'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.ron}
            error={formik.touched.ron && Boolean(formik.errors.ron)}
            helperText={formik.touched.ron && formik.errors.ron}
          />
        </FormControl>
      </form>
      <Box sx={{ margin: 'auto', width: '25%', mt: '10px' }}>
        <Button
          variant='contained'
          sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
          onClick={formik.handleSubmit}
          endIcon={<SendIcon />}>
          Send
        </Button>
        {isSent ? (
          <Box>
            <Stack direction='row'>
              <DoneOutlineIcon sx={{ color: colors.greenAccent[500] }} />
              <Typography> {statusMessage} </Typography>
            </Stack>
          </Box>
        ) : null}
        {isErr ? (
          <Stack direction='row' textAlign='center'>
            <ClearIcon sx={{ color: colors.redAccent[500] }} />
            <Typography> {statusMessage} </Typography>
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};

export default AddChangeOrderForm;
