import {createContext, useState, useEffect} from 'react';

const NavigationContextCC = createContext();

function NavigationProviderCC({children}) {
    const [currentPath, setCurrentPath] = useState(window.location.pathname)

    useEffect(() => {
       const handler = () => {
        setCurrentPath(window.location.pathname);
       };
       window.addEventListener('popstate', handler)

       return () => {
        window.removeEventListener('popstate', handler)
       };
    }, []);

    const navigate = (to) => {
        window.history.pushState({}, '', to);
        setCurrentPath(to)
    };

    return (
    <NavigationContextCC.Provider value={{currentPath, navigate}}>
    {children}
    </NavigationContextCC.Provider>
    )
}

export {NavigationProviderCC};
export default NavigationContextCC;