import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { createElement } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { getBillingSchedule } from '../utils/subscription';
import { useAuth } from '../context/AuthProvider';

const SubscriptionOption = ({
  billingSchedule,
  product,
  features,
  onSelect,
  isProcessing,
}) => {
  const theme = useTheme();

  const { success, subscription } = useAuth();

  const prices = {
    monthly: product.prices.find(
      (price) =>
        price.recurring.interval === 'month' &&
        price.recurring.interval_count === 1
    ),
    semiAnnually: product.prices.find(
      (price) =>
        price.recurring.interval === 'month' &&
        price.recurring.interval_count === 6
    ),
    annually: product.prices.find(
      (price) => price.recurring.interval === 'year'
    ),
  };

  const price = prices[billingSchedule];

  const amount = price?.unit_amount / 100;

  const hasActiveSubscription = ['trialing', 'active'].includes(
    subscription?.status
  );
  const hasIncompleteSubscription = subscription?.status === 'incomplete';

  const subscriptionBillingSchedule = getBillingSchedule(subscription?.plan);
  const priceForSimilarBillingSchedule =
    prices[subscriptionBillingSchedule.key];

  const isUpgrade =
    hasActiveSubscription &&
    subscription?.plan?.amount < priceForSimilarBillingSchedule?.unit_amount;

  const isCurrentProduct =
    (hasActiveSubscription || hasIncompleteSubscription) &&
    subscription?.plan?.product?.id === product.id;

  const isCurrentPlan =
    hasActiveSubscription && subscription?.plan?.id === price?.id;

  let buttonColor = 'info';
  let buttonVariant = 'contained';
  let buttonLabel = 'Subscribe';
  let isSuggestedProduct = false;

  if (!success) {
    buttonLabel = 'Start Free Trial';
    if (product.metadata?.plan_size === 'medium') {
      isSuggestedProduct = true;
      buttonColor = 'primary';
    }
  } else {
    if (hasActiveSubscription) {
      if (isCurrentProduct) {
        buttonLabel = 'Current Plan';
      } else if (isUpgrade) {
        buttonLabel = 'Upgrade';
        buttonColor = 'info';
      } else {
        buttonLabel = 'Downgrade';
        buttonColor = 'warning';
        buttonVariant = 'outlined';
      }
    } else if (subscription?.status === 'incomplete') {
      buttonLabel = isCurrentProduct ? 'Selected Plan' : 'Subscribe';
    }

    if (isCurrentProduct && !isCurrentPlan) {
      buttonLabel = `Switch to ${
        billingSchedule === 'annually'
          ? 'Annually'
          : billingSchedule === 'semiAnnually'
          ? 'Semi-Annually'
          : 'Monthly'
      }`;
      buttonColor = 'info';
    }
  }

  let featureIcon = CheckIcon;
  if (subscription?.plan?.amount < price?.unit_amount) {
    featureIcon = KeyboardDoubleArrowUpIcon;
  } else if (subscription?.plan?.amount > price?.unit_amount) {
    featureIcon = KeyboardDoubleArrowDownIcon;
  }

  const baseMonthlyAmount = (prices?.monthly?.unit_amount ?? 0) / 100;
  const monthlyAmount =
    billingSchedule === 'annually'
      ? amount / 12
      : billingSchedule === 'semiAnnually'
      ? amount / 6
      : amount;
  const monthlySavingsPercent =
    ((baseMonthlyAmount - monthlyAmount) / baseMonthlyAmount) * 100;

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'black',
      }}
      raised>
      <CardHeader
        sx={{ height: { sm: 90 }, mb: 0, pb: 0 }}
        title={
          <>
            <Typography variant='h3' component='div' textAlign='center'>
              {product.name}
            </Typography>
            {isSuggestedProduct && (
              <Typography variant='h3' component='div' textAlign='center'>
                (Best Value)
              </Typography>
            )}
            {isCurrentPlan && (
              <Typography variant='h4' component='div' textAlign='center'>
                (Your Current Plan)
              </Typography>
            )}
          </>
        }></CardHeader>
      <CardContent sx={{ width: '100%', flexGrow: 1 }}>
        <Box>
          <Typography
            gutterBottom
            variant='h3'
            component='div'
            textAlign='center'
            display='flex'
            justifyContent='center'
            alignItems='baseline'
            color='green'>
            {'$'}
            {monthlyAmount.toFixed(2)}{' '}
            <Typography variant='h5' component='span' color='grey'>
              / month
            </Typography>
          </Typography>
          <Typography sx={{ textDecoration: 'line-through', color: 'grey' }}>
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              textAlign='center'
              display='flex'
              justifyContent='center'
              alignItems='baseline'
              color='red'>
              {'$'}
              {baseMonthlyAmount.toFixed(2)}{' '}
              <Typography variant='h7' component='span' color='gray'>
                / month
              </Typography>
            </Typography>
          </Typography>
          {monthlySavingsPercent > 0 && (
            <>
              <Typography
                gutterBottom
                variant='h4'
                component='div'
                textAlign='center'>
                Save {monthlySavingsPercent.toFixed(0)}%!
              </Typography>
              <Typography
                gutterBottom
                variant='h5'
                component='div'
                textAlign='center'>
                ${amount.toFixed()} billed every{' '}
                {billingSchedule === 'annually'
                  ? '12 months'
                  : billingSchedule === 'semiAnnually'
                  ? '6 months'
                  : 'month'}
              </Typography>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: 1,
          }}>
          {features.map((feature, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
              {createElement(featureIcon, {
                sx: { color: 'lightgreen', pr: 1 },
              })}
              <div>{feature}</div>
            </div>
          ))}
          {product.features.map((feature, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
              {createElement(featureIcon, {
                sx: { color: 'lightgreen', pr: 1 },
              })}
              <div>{feature.name}</div>
            </div>
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 2,
          }}>
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size='large'
            sx={{ width: '100%' }}
            onClick={() => onSelect(price)}
            disabled={isProcessing || (isCurrentProduct && isCurrentPlan)}>
            {isProcessing ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              buttonLabel
            )}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default SubscriptionOption;
