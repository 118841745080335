import LandingPageNavBar from '../components/LandingPageNavBar';
import LandingPageFooter from '../components/LandingPageFooter';
import React from 'react';
import {
  Box,
  useTheme,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  useMediaQuery
} from '@mui/material';
import { tokens } from '../theme';
import CircleIcon from '@mui/icons-material/Circle';

const PrivacyPolicyPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box>
      <Box>
        <LandingPageNavBar />
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'>
          <Grid
            item
            xs={3}
            sx={{
              width: isMobile ? "80%":'50%',
              mt: '75px',
              mb: '35px',
            }}
            elevation={3}>
            <Box
              sx={{
                width: '90%',
                minHeight: '65dvh',
                height: '100%',
                mt: '0.5rem',
                p: '0 0.5rem',
              }}>
              <Typography variant='h3' sx={{ color: colors.greenAccent[500] }}>
                Privacy Policy
              </Typography>
              <Typography>
                {' '}
                This Privacy Policy outlines the manner in which
                dailytimecard.com ("we", "our", or "us") collects, uses,
                maintains, and discloses information collected from users
                ("user" or "you") of the dailytimecard.com website ("Site").
                This Privacy Policy applies to the Site and all products and
                services offered by dailytimecard.com.
              </Typography>

              <Typography
                variant='h3'
                sx={{ color: colors.greenAccent[500], mt: '20px' }}>
                Cookies
              </Typography>
              <Typography>
                Our websites may use “cookies” to enhance User experience.
                User’s web browsers places cookies on their device for
                record-keeping purposes and sometimes to track information about
                them. Users may choose to set their web browser to refuse
                cookies, or to alert you when cookies are being sent. If they do
                so, note that some parts of the websites may not function
                properly.
              </Typography>

              <Typography
                variant='h3'
                sx={{ color: colors.greenAccent[500], mt: '20px' }}>
                How We Use Your Information
              </Typography>
              <List sx={{ listStyleType: 'disc' }}>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Calculate Labor Hours
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  Improve Upon The App
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Process Payment Through Stripe (We Do Not Store Sensitive
                  Financial Data)
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Reset Passwords
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CircleIcon style={{ fontSize: 'small' }} />
                  </ListItemIcon>
                  To Send Emails
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LandingPageFooter />
    </Box>
  );
};

export default PrivacyPolicyPage;
