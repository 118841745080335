export const compileRecurringEmail = (
  emailArray,
  cronName,
  subject,
  body,
  day,
  time
) => {
  function createCron(day, time) {
    let hour;
    let minute;
    if (time) {
      hour = time.hour();
      minute = time.minute();
    }

    let cron;
    switch (day) {
      case 'Sunday':
        // code block
        cron = `${minute} ${hour} * * sun`;
        return cron;
      case 'Monday':
        // code block
        cron = `${minute} ${hour} * * mon`;
        return cron;
      case 'Tuesday':
        // code block
        cron = `${minute} ${hour} * * tue`;
        return cron;
      case 'Wednesday':
        // code block
        cron = `${minute} ${hour} * * wed`;
        return cron;
      case 'Thursday':
        // code block
        cron = `${minute} ${hour} * * thur`;
        return cron;
      case 'Friday':
        // code block
        cron = `${minute} ${hour} * * fri`;
        return cron;
      case 'Saturday':
        // code block
        cron = `${minute} ${hour} * * sat`;
        return cron;
      default:
        // code block
        return;
    }
  }
  const cron = createCron(day, time);
  return {
    emailArray,
    cronName,
    subject,
    body,
    day,
    time,
    cron,
  };
};
