import { Button } from '@mui/material';
import React from 'react';

const TimeClockButton = ({ sx = {}, onSubmit, number, ...props }) => {
  return (
    <Button
      variant='contained'
      sx={{ borderRadius: 3, ...sx }}
      {...props}
      onClick={(e) => onSubmit(number)}>
      {number}
    </Button>
  );
};

export default TimeClockButton;
