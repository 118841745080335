import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
} from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useAuth } from '../context/AuthProvider';

const SubscriptionCheckoutForm = ({ onSuccess, onCancel }) => {
  const { api } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { subscription, setSubscription } = useAuth();

  const paymentElementOptions = {};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const method = subscription?.status === 'trialing' ? 'confirmSetup' : 'confirmPayment';

    stripe[method]({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    })
      .then(({ error }) => {
        if (error) {
          if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
          } else {
            setMessage('An unexpected error occurred.');
          }
        } else {
          api.get('/api/v1/subscription').then((res) => {
            setSubscription(res.data.subscription);
            onSuccess();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage('An unexpected error occurred.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <CardHeader titleTypographyProps={{ variant: 'h3' }} title='Payment Information' />
      <CardContent>
        <Box sx={{ mb: 4 }}>Please provide your payment details is required to confirm your subscription.</Box>
        {message && (
          <Alert variant='filled' severity='error' sx={{ mb: 2 }}>
            <AlertTitle>Payment Error</AlertTitle>
            {message}
          </Alert>
        )}
        <PaymentElement options={paymentElementOptions} />
      </CardContent>
      <CardActions>
        <Box sx={{ display: 'flex', width: '100%', p: 1, gap: 2, flexDirection: 'column' }}>
          <Button
            variant='contained'
            size='large'
            type='submit'
            color='primary'
            sx={{ width: '100%' }}
            disabled={isLoading || !stripe || !elements}
            onClick={handleSubmit}
          >
            {isLoading ? <CircularProgress size={24} color='primary' /> : 'Submit Payment'}
          </Button>
          <Button variant='outlined' size='large' type='reset' color='error' sx={{ width: '100%' }} onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </CardActions>
    </FormControl>
  );
};

export default SubscriptionCheckoutForm;
