//rewrite get request
//Login with bearer token
// rewrite with a login request
const searchImages = async (term) => {
  //     const response = await axios.get('https://api.unsplash.com/search/photos', {
  //         headers: {
  //             Authorization: 'Client-ID tOZ8vkc5NLQSzT363wMWmmLHb2HCb_UFKEnX1592k_8'
  //         },
  //         params: {
  //             query: term
  //         }
  //     });
  //     console.log(response)
  // return response.data.results;
};

export default searchImages;
