import { useState, useEffect } from 'react';
import StickyHeader from '../components/StickyHeader';
import { Box, Grid } from '@mui/material';
import Dashboard2 from './Dashboard2';
import { useAuth } from '../context/AuthProvider';

function MobileAdminPage() {
  return (
    <Box sx={{ width: '100%', minWidth: '390px' }}>
      <StickyHeader />
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '100vh' }}>
        <Grid item xs={3}>
          <Box sx={{ width: '390px', p: 0, m: 0 }}>
            <Dashboard2 />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MobileAdminPage;
