import ForgotPassword from '../components/ForgotPassword';
import { Grid } from '@mui/material';
import { tokens } from '../theme';
import { useTheme } from '@mui/material';

function ForgotPasswordPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Grid
      justifyContent='center'
      alignItems='center'
      sx={{
        height: '100vh',
        overflow: 'hidden',
        color: colors.greenAccent[500],
      }}>
      <Grid item xs={3} sx={{ width: '100%', mt: '25%' }}>
        <ForgotPassword />
      </Grid>
    </Grid>
  );
}

export default ForgotPasswordPage;
