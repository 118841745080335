import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
} from '@mui/material';
import { useState } from 'react';
import SubscriptionOptions from './SubscriptionOptions';
import { useAuth } from '../context/AuthProvider';

const SubscriptionPaymentInformation = ({ onSelect, isProcessing }) => {
  const { subscription } = useAuth();

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleSelect = (price) => {
    onSelect(price);
    closeModal();
  };

  const style = {
    position: 'absolute',
    top: '100px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    boxShadow: 24,
    p: 1,
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={openModal}>
        Change Subscription
      </Button>
      <Modal open={showModal} onClose={closeModal} sx={{ m: 6 }}>
        <Card
          raised
          id='checkout'
          sx={{
            ...style,
            flexDirection: 'col',
            justifyContent: 'space-between',
            height: '90dvh',
            overflow: 'auto',
          }}>
          <CardHeader
            title='Subscription Options'
            titleTypographyProps={{ variant: 'h3' }}
          />
          <CardContent>
            <SubscriptionOptions
              currentSubscription={subscription}
              onSelect={handleSelect}
              isProcessing={isProcessing}
            />
          </CardContent>
          <CardActions>
            <Button
              variant='contained'
              color='error'
              size='large'
              onClick={closeModal}
              fullWidth>
              I Changed My Mind
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default SubscriptionPaymentInformation;
