import ResetPassword from '../components/ResetPassword';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { tokens } from '../theme';
import { useTheme } from '@mui/material';
import { useAuth } from '../context/AuthProvider';

function ResetPasswordPage() {
  // const [resetToken, setResetToken] = useState(null)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const { setSuccess } = useAuth();

  useEffect(() => {
    setSuccess('');
  }, [setSuccess]);

  return (
    <Grid
      justifyContent='center'
      alignItems='center'
      sx={{
        height: '100vh',
        overflow: 'hidden',
        color: colors.greenAccent[500],
      }}
    >
      <Grid item xs={3} sx={{ width: '100%', mt: '25%' }}>
        <ResetPassword resetToken={id} />
      </Grid>
    </Grid>
  );
}

export default ResetPasswordPage;
