import { useRef, useState, useEffect, useContext } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AiOutlineUser } from 'react-icons/ai';
import { BsKey } from 'react-icons/bs';
import { HiOutlineBriefcase, HiOutlineClipboardList } from 'react-icons/hi';
import TextField from '@mui/material/TextField';
import { Box, Typography, useTheme, Stack } from '@mui/material';
import { initialRole } from '../hooks/initial-class-role';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import CommonButton from './ButtonMui';
import { tokens } from '../theme';
import { Navigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import usePath from '../hooks/fetchPath';

const Register = () => {
  const { api, setAuth } = useAuth();
  const [registerComplete, setregisterComplete] = useState(false);
  const errRef = useRef();
  //get initial role and classification
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [pwdConfirm, setPwdConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  //password field
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [classification, setClassification] = useState('');
  const [filterClassification, setFilterClassification] = useState([]);
  const [errMsg, setErrMsg] = useState([]);
  //const [success, setSuccess] = useState(false);
  //get Initial class Values
  //Dropdown Selected Piece of State
  const [role, setRole] = useState('Carpenter');
  const [pwdError, setPwdError] = useState(false);
  const [pwdMatchPwdError, setMatchPwdError] = useState(false);
  const [pwdRegError, setRegPwdError] = useState(false);
  const [pwdBlankError, setPwdBlankError] = useState(false);
  const { mode } = useAuth();
  const { fullPath, baseURL } = usePath('/api/v1/auth/login');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //Button Styles
  const buttonStyles = {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: colors.blueAccent[700],
    '&:hover': {
      backgroundColor: colors.blueAccent[600],
    },
  };

  //Form Styles
  const formStyles = {
    backgroundColor: colors.primary[400],
    '&:hover': {
      border: '.1px solid',
      borderRadius: 1,
      borderColor: colors.greenAccent[200],
    },
    input: { color: colors.greenAccent[300] },
    label: { color: colors.greenAccent[300] },
    width: '185px',
  };

  const inputLabelStyles = {
    style: {
      color: colors.greenAccent[300],
    },
  };

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  //handle drop down select for role
  const handleSelect = (option) => {
    console.log(option.target.value);
    // setSelection(option.target.value)
    setRole(option.target.value);

    if (option !== role) {
      setClassification('');
    }
  };

  //handle drop down select for classification
  const handleClassificationSelect = (option) => {
    console.log(option.target.value);
    setClassification(option.target.value);
  };

  //front end check password validation
  const checkPasswords = (password) => {
    console.log('Checking Passwords');
    const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (!regularExpression.test(password)) {
      setRegPwdError(true);
    } else {
      setRegPwdError(false);
    }
  };

  const checkPwdIsBlank = (pwd, pwdConfirm) => {
    if (pwd === '' || pwdConfirm === '') {
      setPwdBlankError(true);
    } else {
      setPwdBlankError(false);
    }
  };

  const checkPwdMatch = (updatedPwd, updatedPwdConfirm) => {
    if (updatedPwd === updatedPwdConfirm) {
      console.log('Passwords Match');
      setMatchPwdError(false);
    } else if (updatedPwd !== updatedPwdConfirm) {
      console.log('Passwords do not match');
      setMatchPwdError(true);
    }
  };

  const handlePasswordChange = (e) => {
    const updatedPwd = e.target.value;
    setPwd(updatedPwd);
    checkPwdIsBlank(updatedPwd);
    checkPwdMatch(updatedPwd, pwdConfirm);
    checkPasswords(updatedPwd);
  };

  const handlePasswordConfirmChange = (e) => {
    const updatedPwdConfirm = e.target.value;
    setPwdConfirm(updatedPwdConfirm);
    checkPwdMatch(updatedPwdConfirm, pwd);
    checkPasswords(updatedPwdConfirm);
  };

  const url = usePath('/api/v1/auth/register');
  console.log(url);

  //handle form submissions
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submitting', user, pwd);
    checkPasswords();
    console.log(firstName, lastName, role, classification, user, pwd);

    if (!pwdMatchPwdError || !pwdRegError || !pwdBlankError) {
      try {
        const response = await api.post(
          '/api/v1/auth/register',
          JSON.stringify({
            firstName,
            lastName,
            role,
            classification,
            email: user,
            password: pwd,
          })
        );
        console.log(JSON.stringify(response?.data));
        const accessToken = response?.data?.token;
        //const roles = response?.data?.roles;
        setAuth({ user, pwd, accessToken });
        setFirstName('');
        setLastName('');
        setRole('');
        setClassification('');
        setUser('');
        setPwd('');
        setPwdConfirm('');
        setregisterComplete(true);
      } catch (err) {
        console.log(err);
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
          const error = err.response.data.error;
          setErrMsg(error);
        } else if (err.response?.status === 401) {
          setErrMsg('UnAuthorized');
        } else {
          setErrMsg('Login Failed');
        }
        errRef.current.focus();
      }
    }
  };

  let typeClassification = null;
  let optionsClassification = null;

  const carpenter = [
    'Carpenters: Journeyman',
    'Carpenters: Foreman',
    'Carpenters: Apprentice 1',
    'Carpenters: Apprentice 2',
    'Carpenters: Apprentice 3',
    'Carpenters: Apprentice 4',
    'Carpenters: Apprentice 5',
    'Carpenters: Apprentice 6',
    'Carpenters: Apprentice 7',
    'Carpenters: Apprentice 8',
  ];

  const electrician = ['Inside Wireman', 'Cable Splicer Welder', 'Comm Installer'];

  const laborer = [
    'Group 1 & 1B',
    'Group 1(A)',
    'Group 1(C)',
    'Group 1(E)',
    'Group 1(G)',
    'Group 2',
    'Group 3 & 3(A)',
    'Group 4 & 6(B)',
    'Group 6',
    'Group 6(A)',
    'Group 6(C)',
    'Group 6(D)',
    'Group 6(E)',
    'Group 7 - Stage 1 (1st 6 Months)',
    'Group 7 - Stage 2 (2nd 6 Months)',
    'Group 7 - Stage 3 (3rd 6 Months)',
  ];

  const electricianApprentice = [
    'Electrician Apprentice 1',
    'Electrician Apprentice 2',
    'Electrician Apprentice 3',
    'Electrician Apprentice 4',
    'Electrician Apprentice 5',
    'Electrician Apprentice 6',
    'Electrician Apprentice 7',
    'Electrician Apprentice 8',
    'Electrician Apprentice 9',
    'Electrician Apprentice 10',
  ];

  const electricalComm = ['Comm & System Installer', 'Comm & System Tech'];

  const taper = ['Taper'];

  const plasterer = ['Plasterer'];
  const drywall = ['Drywall'];

  const cementMason = ['Cement Mason'];
  const projectManager = ['Project Manager'];

  const officeAdmin = ['Office Admin'];

  if (role === 'Carpenter') {
    typeClassification = carpenter;
  } else if (role === 'Electrician') {
    typeClassification = electrician;
  } else if (role === 'Laborer') {
    typeClassification = laborer;
  } else if (role === 'Electrician Apprentice') {
    typeClassification = electricianApprentice;
  } else if (role === 'ElectricalComm') {
    typeClassification = electricalComm;
  } else if (role === 'Taper') {
    typeClassification = taper;
  } else if (role === 'Plasterer') {
    typeClassification = plasterer;
  } else if (role === 'Drywall') {
    typeClassification = drywall;
  } else if (role === 'Project Manager') {
    typeClassification = projectManager;
  } else if (role === 'Cement Mason') {
    typeClassification = cementMason;
  } else if (role === 'Office Admin') {
    typeClassification = officeAdmin;
  } else {
    typeClassification = [];
  }

  if (typeClassification) {
    optionsClassification = typeClassification;
  }

  return (
    <Box>
      {registerComplete ? (
        <Navigate replace to='/Login' />
      ) : (
        <Box>
          <form className='login-form' onSubmit={handleSubmit}>
            <Stack
              spacing={1}
              style={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto',
              }}
            >
              <input type='hidden' value='something' />
              <div>
                {' '}
                <b> Sign Up </b>
              </div>
              <Box component='div' sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <label htmlFor='firstName'>
                  {' '}
                  <AiOutlineUser size={25} />{' '}
                </label>
                <TextField
                  id='firstname'
                  label='First Name'
                  variant='outlined'
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  required
                  style={{ minWidth: '175px' }}
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  inputProps={{
                    style: {
                      padding: 8,
                    },
                  }}
                  sx={formStyles}
                ></TextField>
              </Box>

              <Box component='div' sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <label htmlFor='firstName'>
                  {' '}
                  <AiOutlineUser size={25} />{' '}
                </label>
                <TextField
                  id='lastname2'
                  label='Last Name'
                  variant='outlined'
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  style={{ minWidth: '185px' }}
                  inputProps={{
                    style: {
                      padding: 8,
                    },
                  }}
                  sx={formStyles}
                ></TextField>
              </Box>

              <Box component='div' sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <label htmlFor='role'>
                  {' '}
                  <HiOutlineBriefcase size={25} />{' '}
                </label>
                <Select
                  labelId='Role-select-label'
                  id='role select'
                  value={role}
                  onChange={handleSelect}
                  label='Start'
                  size='small'
                  defaultValue={role}
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  style={{ minWidth: '185px', textalign: 'left' }}
                  sx={formStyles}
                >
                  {initialRole.map((role, index) => {
                    return (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box
                component='div'
                sx={{
                  display: 'flex',
                  align: 'left',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <label htmlFor='role'>
                  {' '}
                  <HiOutlineClipboardList size={25} />{' '}
                </label>
                <Select
                  labelId='Classification-select-label'
                  id='classification select'
                  placeholder='Select a classification'
                  value={classification}
                  onChange={handleClassificationSelect}
                  label='Start'
                  size='small'
                  style={{ minWidth: '185px' }}
                  sx={formStyles}
                >
                  {optionsClassification.map((classificationitem, index) => {
                    return (
                      <MenuItem key={index} value={classificationitem}>
                        {classificationitem}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box component='div' sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <EmailIcon size={25} />{' '}
                <TextField
                  id='email2'
                  name='password'
                  type='text'
                  variant='outlined'
                  onChange={(e) => setUser(e.target.value)}
                  autoComplete='off'
                  value={user}
                  required
                  placeholder='user@calpac.com'
                  style={{ minWidth: '190px' }}
                  inputProps={{
                    style: {
                      padding: 8,
                      autoComplete: 'off',
                      form: {
                        autoComplete: 'off',
                      },
                    },
                  }}
                  label='Email'
                  sx={formStyles}
                ></TextField>
              </Box>

              <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                {' '}
                <BsKey size={25} />
                <FormControl variant='outlined' size='small'>
                  <OutlinedInput
                    id='outlined-adornment-password'
                    type={showPassword ? 'text' : 'password'}
                    value={pwd}
                    onChange={handlePasswordChange}
                    sx={formStyles}
                    error={pwdBlankError || pwdRegError || pwdMatchPwdError}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Password'
                  />
                </FormControl>
              </Box>

              <Box component='div' sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <label htmlFor='password confirm'>
                  <BsKey size={25} />
                </label>
                <FormControl variant='outlined' size='small'>
                  <InputLabel htmlFor='outlined-adornment-password confirm' size='small'>
                    Password Confirm
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-password confirm'
                    type={showPassword ? 'text' : 'password'}
                    value={pwdConfirm}
                    onChange={handlePasswordConfirmChange}
                    sx={formStyles}
                    error={pwdBlankError || pwdRegError || pwdMatchPwdError}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Password Confirm'
                  />
                </FormControl>
              </Box>
              <p
                ref={errRef}
                className={errMsg ? 'errmsg' : 'offscreen'}
                aria-live='assertive'
                style={{
                  width: '90%',
                }}
              >
                {errMsg}
              </p>

              <Box>
                {pwdMatchPwdError ? <Typography> - Passwords Do Not Match </Typography> : null}
                {pwdRegError ? (
                  <Typography>
                    {' '}
                    - Passwords Must Contain a capital letter, special character, & 6-16 characters long.{' '}
                  </Typography>
                ) : null}
                {pwdBlankError ? <Typography> - Passwords Must Not Be Blank </Typography> : null}
                <CommonButton size='large' variant='contained' onClick={handleSubmit} sx={buttonStyles}>
                  {' '}
                  Register{' '}
                </CommonButton>
              </Box>
            </Stack>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default Register;
