import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import MappingDraggable from "../mappingdraggable";
import TopBarAdmin from "../components/TopBarAdmin";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router";

const MappingPageDraggable = ({ auth }) => {
	const { api } = useAuth();
	const [pageState, setPageState] = useState({
		isLoading: true,
		data: [],
		total: 0,
		page: 0,
		pageSize: 100,
	});
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [listData, setListData] = useState([]);
	const [sentMapping, setsentMapping] = useState(false);
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");

	useEffect(() => {
		async function GetResults(data) {
			try {
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);

				setnotificationsNumber(response.data.data[0].results.length);
			} catch (err) {
				console.log("Failed", { err });
				setErrState(true);
				seterrMsg("Error Loading Data");
			}
		}
		if (api.authenticated) {
			GetResults();
		}
	}, []);

	useEffect(() => {
		if (api.authenticated) {
			function checkFailed(then) {
				return function (responses) {
					const someFailed = responses.some((response) => response.error);

					if (someFailed) {
						throw responses;
					}

					return then(responses);
				};
			}

			async function getListData() {
				const promises = [
					api.get("/api/v1/ratebook/craftlist"),
					api.get("/api/v1/ratebook/classificationlist"),
					api.get("/api/v1/ratebook/booklist"),
					api.get("/api/v1/projects/list"),
					api.get("/api/v1/personnel?sort=fullName&limit=1000"),
				];

				const promisesResolved = promises.map((promise) =>
					promise.catch((error) => ({ error }))
				);

				Promise.all(promisesResolved)
					.then(
						checkFailed(([craft, classification, book, project, personnel]) => {
							setListData((prev) => ({
								...prev,
								craft,
								classification,
								book,
								project,
								personnel,
							}));
						})
					)
					.catch((err) => {
						console.log("FAIL", err);
						setErrState(true);
						seterrMsg("Error Loading Data");
					});
			}

			getListData();
		}
	}, [api]);

	useEffect(() => {
		if (api.authenticated) {
			const pageLimit = `&limit=${pageState.pageSize}`;
			api
				.get(`/api/v1/mapping?page=${pageState.page + 1}` + pageLimit)
				.then((response) => {
					let renderedData;
					if (response) {
						renderedData = response.data.data.map((ratebook) => ({
							id: ratebook._id,
							fullName: ratebook.fullName,
							trade: ratebook.trade,
							classification: ratebook.classification,
							project: ratebook.project,
							county: ratebook.county,
							book: ratebook.book,
							isCustom: ratebook.isCustom,
							isEditable: ratebook.isEditable,
							mappingType: ratebook.mappingType,
							state: ratebook.state,
							jurisdiction: ratebook.jurisdiction,
							mappingID: ratebook.mappingID,
							order: ratebook.order,
						}));
					}

					setsentMapping(false);

					const update = {
						data: renderedData,
						pageSize: pageState.pageSize,
						total: response.data.total,
						isLoading: false,
					};

					setPageState((old) => ({
						...old,
						...update,
					}));
				})
				.catch((err) => {
					console.log(err);
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api?.authenticated, pageState.page, pageState.pageSize, sentMapping]);

	return (
		<Box sx={{ height: "100dvh" }}>
			{errState ? (
				<Alert
					icon={<RunningWithErrorsIcon fontSize="inherit" />}
					severity="error"
				>
					{errMsg}
				</Alert>
			) : null}
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<MappingDraggable
				pageState={pageState}
				setPageState={setPageState}
				data={pageState.data}
				listData={listData}
				setsentMapping={setsentMapping}
			/>
		</Box>
	);
};

export default MappingPageDraggable;
