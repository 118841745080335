import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //themes
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          sx={{
            '& button': { borderRadius: 1 },
            '& button:hover': { backgroundColor: colors.blueAccent[500] },
            '& button:focus': { backgroundColor: colors.blueAccent[500] },
            '& button:active': { backgroundColor: colors.blueAccent[500] },
          }}
          indicatorColor='primary'>
          <Tab label='1 Project' {...a11yProps(0)} />
          <Tab label='2 Projects' {...a11yProps(1)} />
          <Tab label='3 Projects' {...a11yProps(2)} />
        </Tabs>
      </Box>
    </Box>
  );
}
