import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { Box, Typography, useTheme, Stack } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../context/AuthProvider";

const LogoutPopover = ({
	idPopOut,
	openPopOut,
	anchorElPopOut,
	setAnchorElPopOut,
	message,
}) => {
	const handlePopOutClose = () => {
		setAnchorElPopOut(null);
	};

	const { logout } = useAuth();
	const handleClick = () => {
		console.log("Logged Out");
		logout();
	};

	//   const id = open ? 'simple-popover' : undefined;
	return (
		<Box>
			{/* <Button aria-describedby={id} onClick={handleClick}>
        Query
      </Button> */}
			<Popover
				id={idPopOut}
				open={openPopOut}
				anchorEl={anchorElPopOut}
				onClose={handlePopOutClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box>
					<Button aria-describedby={"ID"} onClick={handleClick}>
						Logout
					</Button>
				</Box>
			</Popover>
		</Box>
	);
};

export default LogoutPopover;
