import {
  Box,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import SubscriptionOptionProduct from './SubscriptionOptionProduct';
import { useState, useContext } from 'react';
import { billingScheduleOptions } from '../utils/subscription';
import SubscriptionContext from '../context/SubscriptionProvider';

const featureList = {
  small: [
    'Timecard Entry',
    'Regular Rate Calculations',
    'Overtime Rate Calculations',
    'Saturday Rate Calculations',
    'Sunday Rate Calculations',
    '9000+ Prevailing Wage Rates (California)',
    'Payroll Tracking',
    'California Labor Compliance',
    'Admin Dashboard',
  ],
  medium: [
    'Timecard Entry',
    'Regular Rate Calculations',
    'Overtime Rate Calculations',
    'Saturday Rate Calculations',
    'Sunday Rate Calculations',
    '9000+ Prevailing Wage Rates (California)',
    'Payroll Tracking',
    'California Labor Compliance',
    'Admin Dashboard',
  ],
  large: [
    'Timecard Entry',
    'Regular Rate Calculations',
    'Overtime Rate Calculations',
    'Saturday Rate Calculations',
    'Sunday Rate Calculations',
    '9000+ Prevailing Wage Rates (California)',
    'Payroll Tracking',
    'California Labor Compliance',
    'Admin Dashboard',
  ],
};

const SubscriptionOptions = ({ onSelect, isProcessing }) => {
  const { products, isLoading } = useContext(SubscriptionContext);

  const [billingSchedule, setBillingSchedule] = useState('annually');

  const handleBillingScheduleChange = (event, newBillingSchedule) => {
    setBillingSchedule(newBillingSchedule);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        justifyItems: 'center',

        padding: '20px',
      }}>
      <Typography variant='h5' component='div' color='green'>
        Choose a subscription billing schedule
      </Typography>
      <ToggleButtonGroup
        color='primary'
        size='large'
        value={billingSchedule}
        exclusive
        onChange={handleBillingScheduleChange}>
        {billingScheduleOptions.map((option, index) => (
          <ToggleButton key={index} value={option.key}>
            <strong>{option.label}</strong>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}>
          <CircularProgress size={30} color='primary' />
          <div>Loading subscription options...</div>
        </Box>
      ) : (
        <>
          <Typography variant='h5' component='div' sx={{ mt: 2 }}>
            Choose a subscription plan
          </Typography>
          <Grid container alignItems='stretch' spacing={2}>
            {products.map((product, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <SubscriptionOptionProduct
                  key={index}
                  product={product}
                  features={featureList[product.metadata.plan_size]}
                  onSelect={onSelect}
                  isProcessing={isProcessing}
                  billingSchedule={billingSchedule}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default SubscriptionOptions;
