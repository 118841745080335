
import {useContext} from 'react'
import NavigationContextCC from '../context/navigation'

//Link for Sign In Page
function Link2({to, children}){
    const {navigate} = useContext(NavigationContextCC);

    const handleClick = (event) => {
        console.log(event)
        if (event.metaKey || event.ctrlKey){
            return;
        }
         
        // event.preventDefault()

        navigate(to);
    };

    return <div><b><a className="px-1" href={to} onClick={handleClick}> {children} </a></b></div>
}

export default Link2;