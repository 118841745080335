import { Grid } from "@mui/material";
import TimeClock from "../components/TimeClock";
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthProvider";
//error
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function TimeClockPage() {
	const { api } = useAuth();
	const [errMsg, seterrMsg] = useState("");
	const [errState, seterrState] = useState(false);
	const [timecardCreated, setTimecardCreated] = useState(false);
	const [timecardCreatedMsg, setTimecardCreatedMsg] =
		useState("Timecard Created");
	const [projectList, setProjectList] = useState([]);
	const [personnelList, setPersonnelList] = useState([]);

	useEffect(() => {
		async function getProjects() {
			try {
				const response = await api.get("/api/v1/projects/list");
				setProjectList(response.data.data);
			} catch (err) {
				seterrState(true);
				seterrMsg("Data Not Loaded");
			}
		}

		getProjects();
	}, [api?.authenticated]);

	useEffect(() => {
		async function getPersonnel() {
			try {
				const response = await api.get(
					`/api/v1/personnel?sort=fullName&limit=1000`
				);
				console.log(response.data.data);

				setPersonnelList(response.data.data);
			} catch (err) {
				seterrState(true);
				seterrMsg("Error Loading Data");
			}
		}

		getPersonnel();
	}, [api?.authenticated]);

	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: "100vh" }}
		>
			<Grid item xs={3}>
				{errState ? (
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						<strong>{errMsg}!</strong>
					</Alert>
				) : null}
				{timecardCreated ? (
					<Alert severity="filled">
						<strong>
							<AlertTitle>{timecardCreatedMsg}</AlertTitle>
						</strong>
					</Alert>
				) : null}
				<TimeClock
					projectList={projectList}
					personnelList={personnelList}
					setTimecardCreated={setTimecardCreated}
					setTimecardCreatedMsg={setTimecardCreatedMsg}
					errState={errState}
					seterrState={seterrState}
					errMsg={errMsg}
					seterrMsg={seterrMsg}
				/>
			</Grid>
		</Grid>
	);
}

export default TimeClockPage;

//Problems to solve
//1) data loading timeclocks
//2) edit timecards clocks for timeclock start
