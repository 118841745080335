import Popover from "@mui/material/Popover";
import { Box } from "@mui/material";
import { useState } from "react";
import ViewPayrollDataGrid from "../components/ViewPayrollDataGrid";

const ViewRFIPopover = ({ id, open, anchor, setAnchor, message }) => {
	const handlePopOutClose = () => {
		setAnchor(null);
	};

	return (
		<Box sx={{ height: 100 }}>
			<Popover
				id={id}
				open={open}
				anchorEl={anchor}
				onClose={handlePopOutClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box>
					{message}
					{/* <ViewPayrollDataGrid details={message} /> */}
				</Box>
			</Popover>
		</Box>
	);
};

export default ViewRFIPopover;
