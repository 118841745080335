import { useState, useEffect } from 'react';
import TopBarAdmin from '../components/TopBarAdmin';
import { Box, Grid } from '@mui/material';
import Dashboard2 from './Dashboard2';
import { useAuth } from '../context/AuthProvider';

function MobilePMPage() {
  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const { api } = useAuth();

  useEffect(() => {
    async function GetResults(data) {
      try {
        const response = await api.get(
          `/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
        );
        console.log(response);
        setNotificationsNumber(response.data.data[0].results.length);
      } catch (err) {
        console.log('Failed', { err });
      }
    }
    GetResults();
  }, []);

  return (
    <Box sx={{ width: '100%', minWidth: '390px' }}>
      <TopBarAdmin notificationsNumber={notificationsNumber} />
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '100vh' }}>
        <Grid item xs={3}>
          <Box sx={{ width: '390px', p: 0, m: 0 }}>
            <Dashboard2 />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MobilePMPage;
