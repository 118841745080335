import Projects from "../projects";
import { Box } from "@mui/material";
import TopBarAdmin from "../components/TopBarAdmin";
import useFetch from "../hooks/fetchAPI";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import usePath from "../hooks/fetchPath";

function PMPageProjects() {
	const { api, auth } = useAuth();
	const token = auth.accessToken;
	const [allProjects, setAllProjects] = useState([]);
	const [selectedDataID, setselectedDataID] = useState([]);
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [errState, setErrState] = useState(false);
	const [errType, setErrType] = useState("error");
	const { baseURL } = usePath("/api/v1/auth/updatedetails/admin");
	const [errMsg, seterrMsg] = useState("");
	const [variablePageHeight, setvariablePageHeight] = useState("100dvh");
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 0,
		pageSize: 100,
	});

	useEffect(() => {
		async function GetResults(data) {
			try {
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);
				console.log(response);
				setnotificationsNumber(response.data.data[0].results.length);
			} catch (err) {
				console.log("Failed", { err });
				setErrState(true);
				seterrMsg("Error Loading Data");
			}
		}
		GetResults();
	}, []);

	useEffect(() => {
		async function getProjects() {
			const pagelimit = `&limit=${pageState.pageSize}`;
			try {
				const response = await api.get(
					`/api/v1/projects/pm/?page=${pageState.page + 1}` + pagelimit
				);

				console.log(response, "Response");
				const projectResponselist = response.data.data[0].results;
				setAllProjects(projectResponselist);

				if (projectResponselist.length === 0) {
					seterrMsg("No Projects Assigned, Contact Company Admin For Access");
					setErrState(true);
					setErrType("warning");
					setvariablePageHeight("110dvh");
				}

				const update = {
					data: response.data.data[0].results,
					pageSize: pageState.pageSize,
					total: response.data.data[0].pagination[0]?.totaldocuments,
				};

				setPageState((old) => ({
					...old,
					...update,
				}));
			} catch (err) {
				console.log(err);
				setErrState(true);
				seterrMsg("Error Loading Data");
				setvariablePageHeight("110dvh");
			}
		}
		getProjects();
	}, [api]);

	//delete functions
	//Delete Many Projects By Id
	async function deleteManyProjects(...prevProjects) {
		await Promise.all(
			selectedDataID.map((id) =>
				api.delete(`/api/v1/projects/${id}`, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					baseURL: baseURL,
				})
			)
		)
			.then(handleRemoveMultipleItems())
			.catch(function (err) {});
	}

	const deleteById = (id) => {
		//filter keeps all id thats not selected
		const filteredData = allProjects.filter((item) => item._id !== id);
		// Do something with the filtered data
		setAllProjects(filteredData);
	};

	const handleRemoveMultipleItems = () => {
		selectedDataID.forEach((id) => deleteById(id));
	};

	return (
		<Box sx={{ height: variablePageHeight }}>
			{errState ? (
				<Alert
					icon={<RunningWithErrorsIcon fontSize="inherit" />}
					severity={errType}
				>
					{errMsg}
				</Alert>
			) : null}
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<Projects
				selectedDataID={selectedDataID}
				setselectedDataID={setselectedDataID}
				data={allProjects}
				setAllProjects={setAllProjects}
				deleteManyProjects={deleteManyProjects}
				pageState={pageState}
				setPageState={setPageState}
			/>
		</Box>
	);
}

export default PMPageProjects;
