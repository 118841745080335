const roles = [
  '',
  'Carpenter',
  'Electrician',
  'ElectricalComm',
  'Electrician Apprentice',
  'Laborer',
  'Office Admin',
  'Plumber',
  'Plasterer',
  'Cement Mason',
  'Drywall',
  'Project Manager',
  'Taper',
];

export const initialRole = roles.sort((a, b) => a.localeCompare(b));

export const initialClassification = [
  '',
  'Carpenters: Journeyman',
  'Carpenters: Foreman',
  'Carpenters: Apprentice 1',
  'Carpenters: Apprentice 2',
  'Carpenters: Apprentice 3',
  'Carpenters: Apprentice 4',
  'Carpenters: Apprentice 5',
  'Carpenters: Apprentice 6',
  'Carpenters: Apprentice 7',
  'Carpenters: Apprentice 8',
  'Drywall',
  'Cement Mason',
  'Construction Specialist',
  'Group 1 & 1B',
  'Group 1(A)',
  'Group 1(C)',
  'Group 1(E)',
  'Group 1(G)',
  'Group 2',
  'Group 3 & 3(A)',
  'Group 4 & 6(B)',
  'Group 6',
  'Group 6(A)',
  'Group 6(C)',
  'Group 6(D)',
  'Group 6(E)',
  'Group 7 - Stage 1 (1st 6 Months)',
  'Group 7 - Stage 2 (2nd 6 Months)',
  'Group 7 - Stage 3 (3rd 6 Months)',
  'Electrician Apprentice 1',
  'Electrician Apprentice 2',
  'Electrician Apprentice 3',
  'Electrician Apprentice 4',
  'Electrician Apprentice 5',
  'Electrician Apprentice 6',
  'Electrician Apprentice 7',
  'Electrician Apprentice 8',
  'Electrician Apprentice 9',
  'Electrician Apprentice 10',
  'Comm & System Installer',
  'Comm & System Tech',
  'Inside Wireman',
  'Cable Splicer-Welder',
  'Taper',
  'Plasterer',
];
