import * as React from 'react';
import { Box, Typography, useTheme, Stack } from '@mui/material';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { tokens } from '../theme';
import DropdownAdminQry from './DropdownAdminQry';
import { useState } from 'react';
import threeWeekLookBack from '../hooks/three-week-lookback';
import useFetch from '../hooks/fetchAPI';

const QryPopOut = ({
  project,
  setProject,
  startQry,
  setStartQry,
  endQry,
  setEndQry,
  editMode,
  setEditMode,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [errMsg, setErrMsg] = useState([]);

  const { sixWkBkDesc } = threeWeekLookBack();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const hoverStyles = [
    {
      '&:hover': {
        backgroundColor: colors.greenAccent[600],
      },
    },
  ];

  const handleChange = (event) => {
    const projectqry = event.target.value;
    setProject(projectqry);
  };

  const handleStart = (event) => {
    const startqry = event.target.value;
    setStartQry(startqry);
  };

  const handleEnd = (event) => {
    const endqry = event.target.value;
    setEndQry(endqry);
  };

  //load all user data
  const { data, loading, error } = useFetch('/api/v1/projects/list');

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        sx={hoverStyles}
        startIcon={<FilterAltIcon />}>
        Query
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Stack direction='row' spacing={2}>
          <Box>
            <Typography align='center' sx={{ mt: '10px' }}>
              Query Builder
            </Typography>
            <DropdownAdminQry
              allProjects={data}
              project={project}
              setProject={setProject}
              handleChange={handleChange}
              startQry={startQry}
              handleStart={handleStart}
              endQry={endQry}
              handleEnd={handleEnd}
              sixWkBkDesc={sixWkBkDesc}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </Box>
        </Stack>
      </Popover>
    </div>
  );
};

export default QryPopOut;
