import axios from 'axios';

export const axiosdPutReq = async (url, user, id, token, body) => {
  console.log('Working Put Request');
  console.log('body of put request', body);
  console.log('tc id of put request', id);
  console.log('url of put request', url + id);
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(url + id, body, headers);
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
