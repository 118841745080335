import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import ViewPayrollDataGrid from '../components/ViewPayrollDataGrid';

const ViewPayrollPopOver = ({
  idPopOut,
  openPopOut,
  anchorElPopOut,
  setAnchorElPopOut,
  message,
}) => {
  const handlePopOutClose = () => {
    setAnchorElPopOut(null);
  };

  return (
    <Box sx={{ height: 100 }}>
      <Popover
        id={idPopOut}
        open={openPopOut}
        anchorEl={anchorElPopOut}
        onClose={handlePopOutClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box>
          <ViewPayrollDataGrid details={message} />
        </Box>
      </Popover>
    </Box>
  );
};

export default ViewPayrollPopOver;
