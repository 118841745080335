import { Box, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridFooterContainer,
  GridFooter,
} from '@mui/x-data-grid';
import { tokens } from '../theme';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Header from '../components/Header';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OutlinedInput from '@mui/material/OutlinedInput';
import {
  FormControl,
  InputLabel,
  Select,
  Modal,
  MenuItem,
} from '@mui/material';
import AddUserForm from '../components/AddUserForm';
import usePath from '../hooks/fetchPath';
import { useAuth } from '../context/AuthProvider';
import axios from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Modal Stuff
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  // p: 4,
  overflowY: 'auto',
  p: 1,
};

const Team = ({
  pageState,
  setPageState,
  deleteManyUsers,
  projectList,
  setselectedDataID,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { fullPath, baseURL } = usePath('/api/v1/auth/updatedetails/admin');
  const [personName, setPersonName] = useState([]);
  const [openUser, setOpenUser] = useState(false);
  const handleOpenUser = () => setOpenUser(true);
  const handleCloseUser = () => setOpenUser(false);
  const { auth, user } = useAuth();
  const token = auth.accessToken;
  const [errMsg, setErrMsg] = useState('');

  // const array = [{project: '74 - Ellen Fletcher'}, {project: '75 - Palo Alto HS'}]
  const handleChangeMultiple = (event, row) => {
    const valueArray = event.target.value;
    let value;
    if (valueArray.length === 0) {
      value = '';
    } else if (valueArray.length > 0) {
      value = valueArray[valueArray.length - 1];
    }

    let newManagementArray = row.management;

    if (row.management.filter((e) => e.project === value).length > 0) {
      return;
    } else {
      newManagementArray.push({ project: value });
    }

    const update = { management: newManagementArray, _id: row.id };

    //insert _id
    let replacementObject = { ...row, ...update };
    //drop id
    delete replacementObject.id;

    //axios request / state update
    updateBackEndByID(replacementObject);
  };

  const updateBackEndByID = async (updateObj) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const id = updateObj._id;
      // Use the imported function
      const response = await axios.put(fullPath, updateObj, headers);

      setPageState((old) => ({
        ...old,
        data: [...old.data.map((p) => (p._id === id ? updateObj : p))],
      }));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const columns = [
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 0.75,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 0.75,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.75,
    },
    {
      field: 'role',
      headerName: 'Access Level',
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width='60%'
            m='0 auto'
            p='5px'
            display='flex'
            justifyContent='center'
            backgroundColor={
              role === 'Admin'
                ? colors.greenAccent[600]
                : colors.blueAccent[700]
            }
            borderRadius='4px'>
            {role === 'Admin' && <AdminPanelSettingsOutlinedIcon />}
            {role === 'Project Manager' && <SecurityOutlinedIcon />}
            {role === 'Taper' && <LockOpenOutlinedIcon />}
            {role === 'Carpenter' && <LockOpenOutlinedIcon />}
            <Typography>{role}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'classification',
      headerName: 'Classification',
      flex: 0.75,
    },
    {
      field: 'management',
      headerName: 'Management',
      flex: 1.25,
      renderCell: ({ row }) => {
        const commaSeparatedInitalValue = row.management.map((elem) => {
          return elem.project;
        });
        return (
          <div>
            <FormControl sx={{ width: '200px' }}>
              <InputLabel
                id='demo-multiple-name-label'
                sx={{ color: colors.grey[500] }}>
                Please Enter A Project
              </InputLabel>
              <Select
                labelId='demo-multiple-name-label'
                id='demo-multiple-name'
                multiple
                size='small'
                value={commaSeparatedInitalValue}
                onChange={(event) => handleChangeMultiple(event, row)}
                input={<OutlinedInput label='Name' />}
                MenuProps={MenuProps}>
                {projectList.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
  ];

  const hoverStyles = [
    {
      '&:hover': {
        color: 'red',
        backgroundColor: colors.greenAccent[600],
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PersonAddIcon />}
          onClick={handleOpenUser}>
          Add User
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<DeleteForeverIcon />}
          onClick={deleteManyUsers}>
          Delete
        </Button>
        <Modal
          open={openUser}
          onClose={handleCloseUser}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <AddUserForm />
          </Box>
        </Modal>
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    let count;
    if (pageState.data.length) {
      count = pageState.data.length;
    }
    return (
      <GridFooterContainer>
        {/* Add what you want here */}
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          Count: {count}
        </Typography>

        <GridFooter
          sx={{
            border: 'none', // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  }

  const renderedData = pageState.data.map((datum) => ({
    id: datum._id,
    firstName: datum.firstName,
    lastName: datum.lastName,
    role: datum.role,
    classification: datum.classification,
    email: datum.email,
    management: datum.management,
    // actions: { payroll, handleSubmit },
  }));

  return (
    <Box m='10px 20px 20px 20px'>
      <Header title='User Accounts' subtitle='Managing the Team Members' />
      <Box
        m='0 20px 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .name=column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          rows={renderedData}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          pagination
          page={pageState.page}
          pageSize={pageState.pageSize}
          pageSizeOptions={[50, 100]}
          paginationMode='server'
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          onSelectionModelChange={(ids) => {
            setselectedDataID(ids);
          }}
          checkboxSelection
          sx={{
            '& .MuiCheckbox-colorPrimary': {
              color: colors.grey[100],
            },
          }}
          disableSelectionOnClick
        />
        {errMsg}
      </Box>
    </Box>
  );
};

export default Team;
