import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useAuth } from '../../context/AuthProvider';

const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().min(6).max(16).label('Password'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
});

const Settings = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const { api } = useAuth();

  const [userData, setUserData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  let formik = useFormik({
    initialValues: userData,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      setIsProcessing(true);
      const { password } = values;
      api
        .put(`/api/v1/auth/updatepassword`, { newPassword: password })
        .then(({ data }) => {
          setUserData({
            ...data.data,
            password: '',
            passwordConfirmation: '',
          });
        })
        .catch((err) => {
          console.log('FAIL', err);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password } = formik.values;
    formik.handleSubmit({ password: password });
  };

  return (
    <Card raised>
      <CardHeader title='Change Password'></CardHeader>
      <Divider sx={{ marginBottom: '16px' }} />
      <CardContent>
        <FormControl sx={{ width: '100%' }}>
          <Stack spacing={4}>
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              gap='32px'>
              <TextField
                id='password'
                label='Password'
                variant='outlined'
                name='password'
                type={showPassword ? 'text' : 'password'}
                disabled={isProcessing}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                width='100%'
                sx={{ flexGrow: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id='password-confirmation'
                label='Confirm Password'
                variant='outlined'
                name='passwordConfirmation'
                type={showPasswordConfirmation ? 'text' : 'password'}
                disabled={isProcessing}
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                width='100%'
                sx={{ flexGrow: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password confirmation visibility'
                        onClick={handleClickShowPasswordConfirmation}
                        onMouseDown={handleMouseDownPassword}>
                        {showPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  'data-1p-ignore': true,
                }}
              />
            </Box>
            <Button
              onClick={handleSubmit}
              disabled={isProcessing || !formik.dirty || !formik.isValid}
              variant='contained'
              color='primary'
              type='submit'
              size='large'>
              {isProcessing ? (
                <CircularProgress size={24} color='primary' />
              ) : (
                'Update Password'
              )}
            </Button>
          </Stack>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Settings;
