import { Alert, AlertTitle, Box, Chip, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { getBillingSchedule } from '../utils/subscription';

const SubscriptionInformation = ({ subscription }) => {
  const { api, user, token } = useAuth();
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (token && user.company?.id) {
      api
        .get(`/api/v1/company/${user.company.id}`)
        .then(({ data }) => {
          setCompany(data.data);
        })
        .catch((err) => {
          setCompany({});
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, user.company?.id]);

  const plan = subscription?.plan;
  const billingSchedule = getBillingSchedule(plan);
  const product = subscription?.plan?.product;

  const maxSeats = ['-1', 'unlimited'].includes(product?.metadata?.max_seats)
    ? 'Unlimited'
    : product?.metadata?.max_seats;

  const canSetupSubscription = ['Company Admin'].includes(user?.role);
  const isActiveSubscription = subscription?.status === 'active';
  const isCancelingSubscription =
    subscription?.status === 'active' && subscription?.cancel_at_period_end;
  const isCanceledSubscription = ['canceled', 'incomplete_expired'].includes(
    subscription?.status
  );
  const isTrialSubscription = subscription?.status === 'trialing';
  const isActiveOrTrialSubscription =
    isActiveSubscription || isTrialSubscription;

  const card = subscription?.default_payment_method?.card;
  let paymentInfo = null;
  if (!isActiveOrTrialSubscription) {
    paymentInfo = 'No active subscription.';
  } else if (subscription.default_payment_method) {
    paymentInfo = (
      <span>
        Default Payment Method:{' '}
        {card
          ? `${card.display_brand} ending in ${card.last4}, expiring ${card.exp_month}/${card.exp_year}`
          : null}
      </span>
    );
  } else {
    paymentInfo = 'No default payment method set.';
  }

  const statusColor = {
    active: 'primary',
    trialing: 'secondary',
    incomplete: 'info',
    canceling: 'warning',
    canceled: 'error',
  };

  let renewalDate =
    subscription &&
    dayjs.unix(subscription.current_period_end).format('MMMM D, YYYY');
  let expirationDate;
  let trialEndDate;
  let status = subscription?.status;
  if (isCanceledSubscription) {
    status = 'canceled';
  } else if (isCancelingSubscription) {
    renewalDate = null;
    status = 'canceling';
    expirationDate = dayjs
      .unix(subscription.current_period_end)
      .format('MMMM D, YYYY');
  } else if (isTrialSubscription) {
    renewalDate = null;
    expirationDate = null;
    trialEndDate = dayjs.unix(subscription.trial_end).format('MMMM D, YYYY');
  }

  let subscriptionAlert;
  if (isTrialSubscription) {
    subscriptionAlert = (
      <Alert severity='info'>
        <AlertTitle>You are currently on a trial subscription plan.</AlertTitle>
        Your trial subscription will expire on {trialEndDate}. You will be
        automatically charged ${subscription.plan.amount / 100} at the end of
        the trial period.
      </Alert>
    );
  } else if (!isActiveSubscription) {
    subscriptionAlert = (
      <Alert severity='error'>
        <AlertTitle>Your company have no active subscription plan.</AlertTitle>
        {canSetupSubscription
          ? 'Please select a subscription plan from the options below to continue using the service.'
          : 'Please contact your company administrator to setup a subscription plan for you company.'}
      </Alert>
    );
  }

  return (
    <Stack direction='column' spacing={2}>
      {subscriptionAlert}

      {isActiveOrTrialSubscription && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <div>
              Subscription Plan:{' '}
              <Typography component='span' sx={{ fontWeight: 'bold' }}>
                {product?.name}
              </Typography>
            </div>
            <div>
              Status:{' '}
              <Chip
                size='small'
                label={status}
                color={statusColor[status]}
                sx={{ ml: 1 }}
              />
            </div>
            {!isCanceledSubscription && (
              <>
                <div>Subscription Schedule: {billingSchedule.label}</div>
                <div>
                  Usage: {company?.users?.length} / {maxSeats}
                </div>
                <div>
                  {renewalDate && 'Renews on: ' + renewalDate}
                  {expirationDate && 'Expires on: ' + expirationDate}
                  {trialEndDate && 'Trial ends on: ' + trialEndDate}
                </div>
                <div>{paymentInfo}</div>
              </>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default SubscriptionInformation;
