import { tokens } from '../theme';

export const mockDataTeam = [
  {
    id: 1,
    name: 'Jon Snow',
    email: 'jonsnow@gmail.com',
    age: 35,
    phone: '(665)121-5454',
    role: 'Admin',
  },
  {
    id: 2,
    name: 'Ron Jeremy',
    email: 'ronjeremy@gmail.com',
    age: 35,
    phone: '(665)131-5458',
    role: 'Project Manager',
  },
  {
    id: 3,
    name: 'Cersei Lannister',
    email: 'cerseilannister@gmail.com',
    age: 35,
    phone: '(643)231-5754',
    role: 'Taper',
  },
  {
    id: 4,
    name: 'Jaime Lannister',
    email: 'jaimelannister@gmail.com',
    age: 35,
    phone: '(433)982-6764',
    role: 'Carpenter',
  },
];

export const userData = [
  {
    firstName: 'Chris',
    lastName: 'Chan',
    email: 'chris@gmail.com',
    password: '123456',
    role: 'Admin',
    classification: 'Admin',
    createdAt: '2022-12-04T07:59:10.659+00:00',
  },
  {
    firstName: 'John',
    lastName: 'Doe',
    email: 'jonh@gmail.com',
    password: 'BidenSucks',
    role: 'Project Manager',
    classification: 'Project Manager',
    createdAt: '2022-12-04T07:59:10.659+00:00',
  },
];

export const timeCardData = [
  {
    name: 'Christopher Timecard 12.03.2022',
    firstName: 'Christopher',
    lastName: 'Chan',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-13T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: true,
    user: {
      _id: '638e6ca467e05f8ad51293ab',
      name: 'Chris Chan',
      email: 'jonh@gmail.com',
      role: 'publisher',
      password: '$2a$10$rk45aPKZe2Y8uyQdn2N12OFgvNIU3atHx67.aYmyGTEefUdBazmCK',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    name: 'Tung Po Timecard 12.03.2022',
    firstName: 'Tung',
    lastName: 'Po',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: true,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'John Doe',
      email: 'jonh@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
];

export const mockBarData = [
  {
    date: '4/15/23',
    Carpenter: 13,
    CarpenterColor: 'hsl(229, 70%, 50%)',
    Electrician: 5,
    ElectricianColor: 'hsl(296, 70%, 50%)',
    Laborer: 1,
    LaborerColor: 'hsl(97, 70%, 50%)',
    Taper: 0,
    TaperColor: 'hsl(340, 70%, 50%)',
    CementMason: 7,
    CementMasonColor: 'hsl(120, 70%, 50%)',
  },
  {
    date: '4/22/23',
    Carpenter: 15,
    CarpenterColor: 'hsl(307, 70%, 50%)',
    Electrician: 5,
    ElectricianColor: 'hsl(111, 70%, 50%)',
    Laborer: 1,
    LaborerColor: 'hsl(273, 70%, 50%)',
    Taper: 1,
    TaperColor: 'hsl(275, 70%, 50%)',
    CementMason: 4,
    CementMasonColor: 'hsl(120, 70%, 50%)',
  },
  {
    date: '4/29/23',
    Carpenter: 10,
    CarpenterColor: 'hsl(72, 70%, 50%)',
    Electrician: 6,
    ElectricianColor: 'hsl(96, 70%, 50%)',
    Laborer: 1,
    LaborerColor: 'hsl(106, 70%, 50%)',
    Taper: 0,
    TaperColor: 'hsl(256, 70%, 50%)',
    CementMason: 2,
    CementMasonColor: 'hsl(120, 70%, 50%)',
  },
  {
    date: '5/6/23',
    Carpenter: 18,
    CarpenterColor: 'hsl(257, 70%, 50%)',
    Electrician: 5,
    ElectricianColor: 'hsl(326, 70%, 50%)',
    Laborer: 2,
    LaborerColor: 'hsl(110, 70%, 50%)',
    Taper: 0,
    TaperColor: 'hsl(9, 70%, 50%)',
    CementMason: 2,
    CementMasonColor: 'hsl(120, 70%, 50%)',
  },
  {
    date: '5/13/23',
    Carpenter: 21,
    CarpenterColor: 'hsl(190, 70%, 50%)',
    Electrician: 6,
    ElectricianColor: 'hsl(325, 70%, 50%)',
    Laborer: 1,
    LaborerColor: 'hsl(54, 70%, 50%)',
    Taper: 0,
    TaperColor: 'hsl(285, 70%, 50%)',
    CementMason: 3,
    CementMasonColor: 'hsl(120, 70%, 50%)',
  },
  {
    date: '5/20/23',
    Carpenter: 16,
    CarpenterColor: 'hsl(208, 70%, 50%)',
    Electrician: 4,
    ElectricianColor: 'hsl(334, 70%, 50%)',
    Laborer: 2,
    LaborerColor: 'hsl(182, 70%, 50%)',
    Taper: 0,
    TaperColor: 'hsl(76, 70%, 50%)',
    CementMason: 2,
    CementMasonColor: 'hsl(120, 70%, 50%)',
  },
  {
    date: '5/27/23',
    Carpenter: 14,
    CarpenterColor: 'hsl(87, 70%, 50%)',
    Electrician: 5,
    ElectricianColor: 'hsl(141, 70%, 50%)',
    Laborer: 1,
    LaborerColor: 'hsl(224, 70%, 50%)',
    Taper: 1,
    TaperColor: 'hsl(274, 70%, 50%)',
    CementMason: 1,
    CementMasonColor: 'hsl(120, 70%, 50%)',
  },
];

export const mockLineData = [
  {
    id: 'Net Asset Value (%)',
    color: tokens('dark').greenAccent[500],
    data: [
      {
        x: '7/1/23',
        y: 104,
      },
      {
        x: '7/8/23',
        y: 110,
      },
      {
        x: '7/15/23',
        y: 120,
      },
      {
        x: '7/22/23',
        y: 145,
      },
      {
        x: '7/29/23',
        y: 165,
      },
      {
        x: '8/5/23',
        y: 180,
      },
      {
        x: '8/12/23',
        y: 195,
      },
      {
        x: '8/19/23',
        y: 205,
      },
      {
        x: '8/26/23',
        y: 220,
      },
      {
        x: '9/2/23',
        y: 230,
      },
      {
        x: '9/9/23',
        y: 246,
      },
      {
        x: '9/16/23',
        y: 250,
      },
    ],
  },
  {
    id: 'Return On Capital (%)',
    color: tokens('dark').blueAccent[300],
    data: [
      {
        x: '7/1/23',
        y: 105,
      },
      {
        x: '7/8/23',
        y: 110,
      },
      {
        x: '7/15/23',
        y: 108,
      },
      {
        x: '7/22/23',
        y: 120,
      },
      {
        x: '7/29/23',
        y: 145,
      },
      {
        x: '8/5/23',
        y: 160,
      },
      {
        x: '8/12/23',
        y: 176,
      },
      {
        x: '8/19/23',
        y: 190,
      },
      {
        x: '8/26/23',
        y: 200,
      },
      {
        x: '9/2/23',
        y: 225,
      },
      {
        x: '9/16/23',
        y: 250,
      },
    ],
  },
  {
    id: 'Inventory',
    color: tokens('dark').redAccent[200],
    data: [
      {
        x: '7/1/23',
        y: 100,
      },
      {
        x: '7/8/23',
        y: 90,
      },
      {
        x: '7/15/23',
        y: 88,
      },
      {
        x: '7/22/23',
        y: 83,
      },
      {
        x: '7/29/23',
        y: 75,
      },
      {
        x: '8/5/23',
        y: 70,
      },
      {
        x: '8/12/23',
        y: 64,
      },
      {
        x: '8/19/23',
        y: 50,
      },
      {
        x: '8/26/23',
        y: 33,
      },
      {
        x: '9/2/23',
        y: 20,
      },
      {
        x: '9/9/23',
        y: 8,
      },
      {
        x: '9/16/23',
        y: 0,
      },
    ],
  },
];

export const mockTransactions = [
  {
    txId: '74 - Ellen Fletcher',
    user: 'johndoe',
    date: '2021-09-01',
    cost: '40 hours',
  },
  {
    txId: '0315dsaa',
    user: 'jackdower',
    date: '2022-04-01',
    cost: '133.45',
  },
  {
    txId: '01e4dsa',
    user: 'aberdohnny',
    date: '2021-09-01',
    cost: '43.95',
  },
  {
    txId: '51034szv',
    user: 'goodmanave',
    date: '2022-11-05',
    cost: '200.95',
  },
  {
    txId: '0a123sb',
    user: 'stevebower',
    date: '2022-11-02',
    cost: '13.55',
  },
  {
    txId: '01e4dsa',
    user: 'aberdohnny',
    date: '2021-09-01',
    cost: '43.95',
  },
  {
    txId: '120s51a',
    user: 'wootzifer',
    date: '2019-04-15',
    cost: '24.20',
  },
  {
    txId: '0315dsaa',
    user: 'jackdower',
    date: '2022-04-01',
    cost: '133.45',
  },
];

export const mockDataTimecard = [
  {
    id: 1,
    name: 'Christopher Timecard 12.03.2022',
    firstName: 'Christopher',
    lastName: 'Chan',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-13T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: true,
    user: {
      _id: '638e6ca467e05f8ad51293ab',
      name: 'Chris Chan',
      email: 'jonh@gmail.com',
      role: 'publisher',
      password: '$2a$10$rk45aPKZe2Y8uyQdn2N12OFgvNIU3atHx67.aYmyGTEefUdBazmCK',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 2,
    name: 'Tung Po Timecard 12.03.2022',
    firstName: 'Tung',
    lastName: 'Po',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: true,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'John Doe',
      email: 'jonh@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 3,
    name: 'Frido Timecard 12.03.2022',
    firstName: 'Frido',
    lastName: 'True',
    project: '73 - Pacifica 6',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: true,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'Frido True',
      email: 'jonh@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 4,
    name: 'Jeff Timecard 12.03.2022',
    firstName: 'Jeff',
    lastName: 'Run',
    project: '73 - Pacifica 6',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: true,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'Jeff Run',
      email: 'jonh@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 5,
    name: 'Kelly Timecard 12.03.2022',
    firstName: 'Kelly',
    lastName: 'Last',
    project: '74 - Ellen Fletcher',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: true,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'Ellen Fletcher',
      email: 'ellen@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 6,
    name: 'Lazy Timecard 12.03.2022',
    firstName: 'Lazy',
    lastName: 'Rick',
    project: '74 - Ellen Fletcher',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'Lazy Rick',
      email: 'lazy@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 7,
    name: 'Belly Timecard 12.03.2022',
    firstName: 'Belly',
    lastName: 'Blue',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'Belly Blue',
      email: 'belly@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 8,
    name: 'Belly Timecard 12.03.2022',
    firstName: 'John',
    lastName: 'Doe',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2022-11-28T07:30:23-07:00',
    mondayEnd: '2022-11-28T16:00:23-07:00',
    tuesdayStart: '2022-11-29T07:30:23-07:00',
    tuesdayEnd: '2022-11-29T16:00:23-07:00',
    wednesdayStart: '2022-11-30T07:30:23-07:00',
    wednesdayEnd: '2022-11-30T16:00:23-07:00',
    thursdayStart: '2022-12-01T07:30:23-07:00',
    thursdayEnd: '2022-12-01T16:00:23-07:00',
    fridayStart: '2022-12-02T07:30:23-07:00',
    fridayEnd: '2022-12-02T16:00:23-07:00',
    saturdayStart: '2022-12-03T07:30:23-07:00',
    saturdayEnd: '2022-12-03T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-11-28T07:36:23-07:00',
    weekEnd: '2022-12-03T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'Belly Blue',
      email: 'belly@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 9,
    name: 'John Doe Timecard 12.10.2022',
    firstName: 'John',
    lastName: 'Doe',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2022-12-05T08:30:23-07:00',
    mondayEnd: '2022-12-05T16:00:23-07:00',
    tuesdayStart: '2022-12-06T07:30:23-07:00',
    tuesdayEnd: '2022-12-06T16:00:23-07:00',
    wednesdayStart: '2022-12-07T07:30:23-07:00',
    wednesdayEnd: '2022-12-07T16:00:23-07:00',
    thursdayStart: '2022-12-08T07:30:23-07:00',
    thursdayEnd: '2022-12-08T16:00:23-07:00',
    fridayStart: '2022-12-09T07:30:23-07:00',
    fridayEnd: '2022-12-09T16:00:23-07:00',
    saturdayStart: '2022-12-10T07:30:23-07:00',
    saturdayEnd: '2022-12-10T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2022-12-04T07:36:23-07:00',
    weekEnd: '2022-12-10T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2022-11-29T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'Belly Blue',
      email: 'belly@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2022-12-04T07:59:10.659+00:00',
    },
  },
  {
    id: 10,
    name: 'John Doe Timecard 02.04.2023',
    firstName: 'John',
    lastName: 'Doe',
    project: '75 - Palo Alto HS Tower',
    mondayStart: '2023-01-30T09:30:23-07:00',
    mondayEnd: '2023-01-30T16:00:23-07:00',
    tuesdayStart: '2023-01-31T07:30:23-07:00',
    tuesdayEnd: '2023-01-31T16:00:23-07:00',
    wednesdayStart: '2023-02-01T07:30:23-07:00',
    wednesdayEnd: '2023-02-01T16:00:23-07:00',
    thursdayStart: '2023-02-02T07:30:23-07:00',
    thursdayEnd: '2023-02-02T16:00:23-07:00',
    fridayStart: '2023-02-03T07:30:23-07:00',
    fridayEnd: '2023-02-03T16:00:23-07:00',
    saturdayStart: '2023-02-04T07:30:23-07:00',
    saturdayEnd: '2023-02-04T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2023-01-30T07:36:23-07:00',
    weekEnd: '2023-02-04T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2023-02-04T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'John Doe',
      email: 'jonh@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2023-02-04T07:59:10.659+00:00',
    },
  },
  {
    id: 11,
    name: 'John Doe - 73 - Pacifica 6 - Timecard 02.04.2023',
    firstName: 'John',
    lastName: 'Doe',
    project: '73 - Pacifica 6',
    mondayStart: '2023-01-30T07:30:23-07:00',
    mondayEnd: '2023-01-30T16:00:23-07:00',
    tuesdayStart: '2023-01-31T07:30:23-07:00',
    tuesdayEnd: '2023-01-31T16:00:23-07:00',
    wednesdayStart: '2023-02-01T07:30:23-07:00',
    wednesdayEnd: '2023-02-01T16:00:23-07:00',
    thursdayStart: '2023-02-02T07:30:23-07:00',
    thursdayEnd: '2023-02-02T16:00:23-07:00',
    fridayStart: '2023-02-03T07:30:23-07:00',
    fridayEnd: '2023-02-03T16:00:23-07:00',
    saturdayStart: '2023-02-04T07:30:23-07:00',
    saturdayEnd: '2023-02-04T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2023-01-30T07:36:23-07:00',
    weekEnd: '2023-02-04T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2023-02-04T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'John Doe',
      email: 'jonh@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2023-02-04T07:59:10.659+00:00',
    },
  },
  {
    id: 12,
    name: 'John Doe - 74 - Ellen Fletcher - Timecard 02.04.2023',
    firstName: 'John',
    lastName: 'Doe',
    project: '74 - Ellen Fletcher',
    mondayStart: '2023-01-30T07:30:23-07:00',
    mondayEnd: '2023-01-30T16:00:23-07:00',
    tuesdayStart: '2023-01-31T07:30:23-07:00',
    tuesdayEnd: '2023-01-31T16:00:23-07:00',
    wednesdayStart: '2023-02-01T07:30:23-07:00',
    wednesdayEnd: '2023-02-01T16:00:23-07:00',
    thursdayStart: '2023-02-02T07:30:23-07:00',
    thursdayEnd: '2023-02-02T16:00:23-07:00',
    fridayStart: '2023-02-03T07:30:23-07:00',
    fridayEnd: '2023-02-03T16:00:23-07:00',
    saturdayStart: '2023-02-04T07:30:23-07:00',
    saturdayEnd: '2023-02-04T16:00:23-07:00',
    sundayStart: null,
    sundayEnd: null,
    MonOTStart: '2022-11-28T22:30:23.000Z',
    TueOTStart: '2022-11-28T22:30:23.000Z',
    WedOTStart: '2022-11-28T22:30:23.000Z',
    ThurOTStart: '2022-11-28T22:30:23.000Z',
    FriOTStart: '2022-11-28T22:30:23.000Z',
    weekStart: '2023-01-30T07:36:23-07:00',
    weekEnd: '2023-02-04T15:36:23-07:00',
    weekNumber: 48,
    createdAt: '2023-02-04T07:36:23-07:00',
    weeklyHours: 40,
    OTHours: 0,
    mondayHours: 1,
    monOTHours: 1,
    tuesdayHours: 1,
    tueOTHours: 1,
    wednesdayHours: 1,
    wedOTHours: 1,
    thursdayHours: 1,
    thurOTHours: 1,
    fridayHours: 1,
    friOTHours: 1,
    saturdayHours: 0,
    sundayHours: 0,
    approved: false,
    user: {
      _id: '638c534e624c4b7d4dc2ad11',
      name: 'John Doe',
      email: 'jonh@gmail.com',
      role: 'user',
      password: '$2a$10$Y7QHhnr4ltbS0hQ4/o352eePIZyjgtRo4doPl802X9z9wxoUHEE2y',
      createdAt: '2023-02-04T07:59:10.659+00:00',
    },
  },
];

export const mockDataProject = [
  {
    project: '75 - Palo Alto HS',
    city: 'Palo Alto',
    county: 'Santa Clara County',
    projectcode: 75,
    projectmanager: 'Felix',
    biddate: '2023-04-15T07:00:00.000Z',
    book: 'SC-2022-1',
    address: '50 Embarcadero Road',
    id: '647417e5a987c54cab4c3e11',
    start: '2023-05-29T03:11:33.440Z',
    end: '2023-05-29T03:11:33.440Z',
    __v: 0,
  },
  {
    project: '74 - Ellen',
    city: 'Palo Alto',
    county: 'Santa Clara County',
    projectcode: 74,
    projectmanager: 'Carros',
    biddate: '2023-02-02T08:00:00.000Z',
    book: 'SC-2022-1',
    address: '655 Fake Road',
    id: '64741b0fa987c54cab4c3e14',
    start: '2023-05-29T03:25:03.348Z',
    end: '2023-05-29T03:25:03.348Z',
    __v: 0,
  },
];

export const fakeRevenue = [
  { date: '08/04/2023', revenue: 50000, expenses: 80000 },
  { date: '09/04/2023', revenue: 100000, expenses: 89000 },
  { date: '10/04/2023', revenue: 90000, expenses: 68000 },
  { date: '11/04/2023', revenue: 100000, expenses: 80000 },
];

export const fakePayrollCosts = [
  { date: 'may', revenue: 45000, expenses: 80000 },
  { date: 'jun', revenue: 90000, expenses: 89000 },
  { date: 'jul', revenue: 90000, expenses: 68000 },
  { date: 'aug', revenue: 100000, expenses: 105000 },
  { date: 'sep', revenue: 75000, expenses: 95000 },
  { date: 'oct', revenue: 130000, expenses: 110000 },
];
