import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
} from '@mui/material';
import SubscriptionChangeModal from '../SubscriptionChangeModal';
import SubscriptionInformation from '../SubscriptionInformation';
import SubscriptionCheckoutModal from '../SubscriptionCheckoutModal';
import { useAuth } from '../../context/AuthProvider';

const Subscription = () => {
  const { api, subscription, setSubscription, user } = useAuth();
  const [clientSecret, setClientSecret] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const isActiveSubscription = subscription?.status === 'active';
  const isCancelingSubscription =
    subscription?.status === 'active' && subscription?.cancel_at_period_end;
  const isTrialSubscription = subscription?.status === 'trialing';
  const hasPaymentMethod = subscription?.default_payment_method;

  const canSetupSubscription =
    ['Company Admin'].includes(user?.role) && user.company;

  const handleSubscriptionSelect = (price) => {
    setIsProcessing(true);
    if (
      subscription?.id &&
      ['trial', 'active'].includes(subscription?.status)
    ) {
      handleSubscriptionUpdate(price);
    } else if (subscription.id && !subscription.status !== 'canceled') {
      handleSubscriptionCreate(price);
    } else {
      handleSubscriptionCreate(price);
    }
  };

  const handleSubscriptionCreate = async (price) => {
    setClientSecret(null);
    const priceId = price.id;
    return api.post('/api/v1/subscription', { priceId }).then((res) => {
      setSubscription(res.data.subscription);
      setClientSecret(
        res.data.subscription.latest_invoice?.payment_intent?.client_secret
      );
      setIsProcessing(false);
    });
  };

  const handleSubscriptionUpdate = async (price) => {
    const priceId = price.id;
    return api.put('/api/v1/subscription', { priceId }).then((res) => {
      setSubscription(res.data.subscription);
      setIsProcessing(false);
    });
  };

  const handleCancelSubscription = async () => {
    if (subscription?.status === 'trialing') {
      setClientSecret(null);
      setIsProcessing(false);
    } else {
      return api.delete('/api/v1/subscription').then((res) => {
        setSubscription(res.data.subscription);
        setClientSecret(null);
        setIsProcessing(false);
      });
    }
  };

  const handleRenewSubscription = async () => {
    return handleSubscriptionUpdate(subscription?.plan?.id);
  };

  const handleAddPaymentMethod = () => {
    console.log(subscription?.pending_setup_intent?.client_secret);
    setClientSecret(subscription?.pending_setup_intent?.client_secret);
  };

  useEffect(() => {
    if (subscription?.status === 'canceled') {
      setClientSecret(null);
    } else if (subscription?.status === 'incomplete') {
      setClientSecret(
        subscription.latest_invoice?.payment_intent?.client_secret
      );
    } else if (subscription?.status === 'trialing') {
      // setClientSecret(subscription?.pending_setup_intent?.client_secret);
    } else {
      setClientSecret(null);
    }
  }, [
    subscription?.status,
    subscription?.latest_invoice?.payment_intent?.client_secret,
    subscription?.pending_setup_intent?.client_secret,
    subscription?.pending_setup_intent?.status,
  ]);

  return (
    subscription && (
      <Card raised>
        <CardHeader title='Company Subscription'></CardHeader>
        <Divider sx={{ marginBottom: '16px' }} />
        <CardContent>
          <Stack spacing={3} direction='column'>
            <SubscriptionInformation
              subscription={subscription}
              onCancelSubscription={handleCancelSubscription}
              onRenewSubscription={handleRenewSubscription}
              onAddPaymentMethod={handleAddPaymentMethod}
            />

            {isTrialSubscription && !hasPaymentMethod && (
              <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={handleAddPaymentMethod}>
                Add Payment Method
              </Button>
            )}

            {canSetupSubscription && !isCancelingSubscription && (
              // <Card>
              //   <CardHeader
              //     title='Subscription Options'
              //     titleTypographyProps={{ variant: 'h3' }}
              //   />
              //   <CardContent>
              //     <SubscriptionOptions
              //       currentSubscription={subscription}
              //       onSelect={handleSubscriptionSelect}
              //       isProcessing={isProcessing}
              //     />
              //   </CardContent>
              // </Card>
              <SubscriptionChangeModal
                clientSecret={clientSecret}
                onSelect={handleSubscriptionSelect}
                isProcessing={isProcessing}
              />
            )}

            {isActiveSubscription && !isCancelingSubscription && (
              <Button
                variant='outlined'
                color='error'
                size='large'
                onClick={handleCancelSubscription}>
                Cancel Subscription
              </Button>
            )}
            {isCancelingSubscription && (
              <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={handleRenewSubscription}>
                Renew Subscription
              </Button>
            )}
          </Stack>

          {clientSecret && (
            <SubscriptionCheckoutModal
              subscription={subscription}
              clientSecret={clientSecret}
              onCancel={handleCancelSubscription}
            />
          )}
        </CardContent>
      </Card>
    )
  );
};

export default Subscription;
