export const counties = [
  { state: 'AL', county: 'Autauga County' },
  { state: 'AL', county: 'Baldwin County' },
  { state: 'AL', county: 'Barbour County' },
  { state: 'AL', county: 'Bibb County' },
  { state: 'AL', county: 'Blount County' },
  { state: 'AL', county: 'Bullock County' },
  { state: 'AL', county: 'Butler County' },
  { state: 'AL', county: 'Calhoun County' },
  { state: 'AL', county: 'Chambers County' },
  { state: 'AL', county: 'Cherokee County' },
  { state: 'AL', county: 'Chilton County' },
  { state: 'AL', county: 'Choctaw County' },
  { state: 'AL', county: 'Clarke County' },
  { state: 'AL', county: 'Clay County' },
  { state: 'AL', county: 'Cleburne County' },
  { state: 'AL', county: 'Coffee County' },
  { state: 'AL', county: 'Colbert County' },
  { state: 'AL', county: 'Conecuh County' },
  { state: 'AL', county: 'Coosa County' },
  { state: 'AL', county: 'Covington County' },
  { state: 'AL', county: 'Crenshaw County' },
  { state: 'AL', county: 'Cullman County' },
  { state: 'AL', county: 'Dale County' },
  { state: 'AL', county: 'Dallas County' },
  { state: 'AL', county: 'DeKalb County' },
  { state: 'AL', county: 'Elmore County' },
  { state: 'AL', county: 'Escambia County' },
  { state: 'AL', county: 'Etowah County' },
  { state: 'AL', county: 'Fayette County' },
  { state: 'AL', county: 'Franklin County' },
  { state: 'AL', county: 'Geneva County' },
  { state: 'AL', county: 'Greene County' },
  { state: 'AL', county: 'Hale County' },
  { state: 'AL', county: 'Henry County' },
  { state: 'AL', county: 'Houston County' },
  { state: 'AL', county: 'Jackson County' },
  { state: 'AL', county: 'Jefferson County' },
  { state: 'AL', county: 'Lamar County' },
  { state: 'AL', county: 'Lauderdale County' },
  { state: 'AL', county: 'Lawrence County' },
  { state: 'AL', county: 'Lee County' },
  { state: 'AL', county: 'Limestone County' },
  { state: 'AL', county: 'Lowndes County' },
  { state: 'AL', county: 'Macon County' },
  { state: 'AL', county: 'Madison County' },
  { state: 'AL', county: 'Marengo County' },
  { state: 'AL', county: 'Marion County' },
  { state: 'AL', county: 'Marshall County' },
  { state: 'AL', county: 'Mobile County' },
  { state: 'AL', county: 'Monroe County' },
  { state: 'AL', county: 'Montgomery County' },
  { state: 'AL', county: 'Morgan County' },
  { state: 'AL', county: 'Perry County' },
  { state: 'AL', county: 'Pickens County' },
  { state: 'AL', county: 'Pike County' },
  { state: 'AL', county: 'Randolph County' },
  { state: 'AL', county: 'Russell County' },
  { state: 'AL', county: 'St. Clair County' },
  { state: 'AL', county: 'Shelby County' },
  { state: 'AL', county: 'Sumter County' },
  { state: 'AL', county: 'Talladega County' },
  { state: 'AL', county: 'Tallapoosa County' },
  { state: 'AL', county: 'Tuscaloosa County' },
  { state: 'AL', county: 'Walker County' },
  { state: 'AL', county: 'Washington County' },
  { state: 'AL', county: 'Wilcox County' },
  { state: 'AL', county: 'Winston County' },
  { state: 'AK', county: 'Aleutians East Borough' },
  { state: 'AK', county: 'Aleutians West Census Area' },
  { state: 'AK', county: 'Anchorage Municipality' },
  { state: 'AK', county: 'Bethel Census Area' },
  { state: 'AK', county: 'Bristol Bay Borough' },
  { state: 'AK', county: 'Denali Borough' },
  { state: 'AK', county: 'Dillingham Census Area' },
  { state: 'AK', county: 'Fairbanks North Star Borough' },
  { state: 'AK', county: 'Haines Borough' },
  { state: 'AK', county: 'Hoonah-Angoon Census Area' },
  { state: 'AK', county: 'Juneau City and Borough' },
  { state: 'AK', county: 'Kenai Peninsula Borough' },
  { state: 'AK', county: 'Ketchikan Gateway Borough' },
  { state: 'AK', county: 'Kodiak Island Borough' },
  { state: 'AK', county: 'Lake and Peninsula Borough' },
  { state: 'AK', county: 'Matanuska-Susitna Borough' },
  { state: 'AK', county: 'Nome Census Area' },
  { state: 'AK', county: 'North Slope Borough' },
  { state: 'AK', county: 'Northwest Arctic Borough' },
  { state: 'AK', county: 'Petersburg Census Area' },
  { state: 'AK', county: 'Prince of Wales-Hyder Census Area' },
  { state: 'AK', county: 'Sitka City and Borough' },
  { state: 'AK', county: 'Skagway Municipality' },
  { state: 'AK', county: 'Southeast Fairbanks Census Area' },
  { state: 'AK', county: 'Valdez-Cordova Census Area' },
  { state: 'AK', county: 'Wade Hampton Census Area' },
  { state: 'AK', county: 'Wrangell City and Borough' },
  { state: 'AK', county: 'Yakutat City and Borough' },
  { state: 'AK', county: 'Yukon-Koyukuk Census Area' },
  { state: 'AZ', county: 'Apache County' },
  { state: 'AZ', county: 'Cochise County' },
  { state: 'AZ', county: 'Coconino County' },
  { state: 'AZ', county: 'Gila County' },
  { state: 'AZ', county: 'Graham County' },
  { state: 'AZ', county: 'Greenlee County' },
  { state: 'AZ', county: 'La Paz County' },
  { state: 'AZ', county: 'Maricopa County' },
  { state: 'AZ', county: 'Mohave County' },
  { state: 'AZ', county: 'Navajo County' },
  { state: 'AZ', county: 'Pima County' },
  { state: 'AZ', county: 'Pinal County' },
  { state: 'AZ', county: 'Santa Cruz County' },
  { state: 'AZ', county: 'Yavapai County' },
  { state: 'AZ', county: 'Yuma County' },
  { state: 'AR', county: 'Arkansas County' },
  { state: 'AR', county: 'Ashley County' },
  { state: 'AR', county: 'Baxter County' },
  { state: 'AR', county: 'Benton County' },
  { state: 'AR', county: 'Boone County' },
  { state: 'AR', county: 'Bradley County' },
  { state: 'AR', county: 'Calhoun County' },
  { state: 'AR', county: 'Carroll County' },
  { state: 'AR', county: 'Chicot County' },
  { state: 'AR', county: 'Clark County' },
  { state: 'AR', county: 'Clay County' },
  { state: 'AR', county: 'Cleburne County' },
  { state: 'AR', county: 'Cleveland County' },
  { state: 'AR', county: 'Columbia County' },
  { state: 'AR', county: 'Conway County' },
  { state: 'AR', county: 'Craighead County' },
  { state: 'AR', county: 'Crawford County' },
  { state: 'AR', county: 'Crittenden County' },
  { state: 'AR', county: 'Cross County' },
  { state: 'AR', county: 'Dallas County' },
  { state: 'AR', county: 'Desha County' },
  { state: 'AR', county: 'Drew County' },
  { state: 'AR', county: 'Faulkner County' },
  { state: 'AR', county: 'Franklin County' },
  { state: 'AR', county: 'Fulton County' },
  { state: 'AR', county: 'Garland County' },
  { state: 'AR', county: 'Grant County' },
  { state: 'AR', county: 'Greene County' },
  { state: 'AR', county: 'Hempstead County' },
  { state: 'AR', county: 'Hot Spring County' },
  { state: 'AR', county: 'Howard County' },
  { state: 'AR', county: 'Independence County' },
  { state: 'AR', county: 'Izard County' },
  { state: 'AR', county: 'Jackson County' },
  { state: 'AR', county: 'Jefferson County' },
  { state: 'AR', county: 'Johnson County' },
  { state: 'AR', county: 'Lafayette County' },
  { state: 'AR', county: 'Lawrence County' },
  { state: 'AR', county: 'Lee County' },
  { state: 'AR', county: 'Lincoln County' },
  { state: 'AR', county: 'Little River County' },
  { state: 'AR', county: 'Logan County' },
  { state: 'AR', county: 'Lonoke County' },
  { state: 'AR', county: 'Madison County' },
  { state: 'AR', county: 'Marion County' },
  { state: 'AR', county: 'Miller County' },
  { state: 'AR', county: 'Mississippi County' },
  { state: 'AR', county: 'Monroe County' },
  { state: 'AR', county: 'Montgomery County' },
  { state: 'AR', county: 'Nevada County' },
  { state: 'AR', county: 'Newton County' },
  { state: 'AR', county: 'Ouachita County' },
  { state: 'AR', county: 'Perry County' },
  { state: 'AR', county: 'Phillips County' },
  { state: 'AR', county: 'Pike County' },
  { state: 'AR', county: 'Poinsett County' },
  { state: 'AR', county: 'Polk County' },
  { state: 'AR', county: 'Pope County' },
  { state: 'AR', county: 'Prairie County' },
  { state: 'AR', county: 'Pulaski County' },
  { state: 'AR', county: 'Randolph County' },
  { state: 'AR', county: 'St. Francis County' },
  { state: 'AR', county: 'Saline County' },
  { state: 'AR', county: 'Scott County' },
  { state: 'AR', county: 'Searcy County' },
  { state: 'AR', county: 'Sebastian County' },
  { state: 'AR', county: 'Sevier County' },
  { state: 'AR', county: 'Sharp County' },
  { state: 'AR', county: 'Stone County' },
  { state: 'AR', county: 'Union County' },
  { state: 'AR', county: 'Van Buren County' },
  { state: 'AR', county: 'Washington County' },
  { state: 'AR', county: 'White County' },
  { state: 'AR', county: 'Woodruff County' },
  { state: 'AR', county: 'Yell County' },
  { state: 'CA', county: 'Alameda County' },
  { state: 'CA', county: 'Alpine County' },
  { state: 'CA', county: 'Amador County' },
  { state: 'CA', county: 'Butte County' },
  { state: 'CA', county: 'Calaveras County' },
  { state: 'CA', county: 'Colusa County' },
  { state: 'CA', county: 'Contra Costa County' },
  { state: 'CA', county: 'Del Norte County' },
  { state: 'CA', county: 'El Dorado County' },
  { state: 'CA', county: 'Fresno County' },
  { state: 'CA', county: 'Glenn County' },
  { state: 'CA', county: 'Humboldt County' },
  { state: 'CA', county: 'Imperial County' },
  { state: 'CA', county: 'Inyo County' },
  { state: 'CA', county: 'Kern County' },
  { state: 'CA', county: 'Kings County' },
  { state: 'CA', county: 'Lake County' },
  { state: 'CA', county: 'Lassen County' },
  { state: 'CA', county: 'Los Angeles County' },
  { state: 'CA', county: 'Madera County' },
  { state: 'CA', county: 'Marin County' },
  { state: 'CA', county: 'Mariposa County' },
  { state: 'CA', county: 'Mendocino County' },
  { state: 'CA', county: 'Merced County' },
  { state: 'CA', county: 'Modoc County' },
  { state: 'CA', county: 'Mono County' },
  { state: 'CA', county: 'Monterey County' },
  { state: 'CA', county: 'Napa County' },
  { state: 'CA', county: 'Nevada County' },
  { state: 'CA', county: 'Orange County' },
  { state: 'CA', county: 'Placer County' },
  { state: 'CA', county: 'Plumas County' },
  { state: 'CA', county: 'Riverside County' },
  { state: 'CA', county: 'Sacramento County' },
  { state: 'CA', county: 'San Benito County' },
  { state: 'CA', county: 'San Bernardino County' },
  { state: 'CA', county: 'San Diego County' },
  { state: 'CA', county: 'San Francisco County' },
  { state: 'CA', county: 'San Joaquin County' },
  { state: 'CA', county: 'San Luis Obispo County' },
  { state: 'CA', county: 'San Mateo County' },
  { state: 'CA', county: 'Santa Barbara County' },
  { state: 'CA', county: 'Santa Clara County' },
  { state: 'CA', county: 'Santa Cruz County' },
  { state: 'CA', county: 'Shasta County' },
  { state: 'CA', county: 'Sierra County' },
  { state: 'CA', county: 'Siskiyou County' },
  { state: 'CA', county: 'Solano County' },
  { state: 'CA', county: 'Sonoma County' },
  { state: 'CA', county: 'Stanislaus County' },
  { state: 'CA', county: 'Sutter County' },
  { state: 'CA', county: 'Tehama County' },
  { state: 'CA', county: 'Trinity County' },
  { state: 'CA', county: 'Tulare County' },
  { state: 'CA', county: 'Tuolumne County' },
  { state: 'CA', county: 'Ventura County' },
  { state: 'CA', county: 'Yolo County' },
  { state: 'CA', county: 'Yuba County' },
  { state: 'CO', county: 'Adams County' },
  { state: 'CO', county: 'Alamosa County' },
  { state: 'CO', county: 'Arapahoe County' },
  { state: 'CO', county: 'Archuleta County' },
  { state: 'CO', county: 'Baca County' },
  { state: 'CO', county: 'Bent County' },
  { state: 'CO', county: 'Boulder County' },
  { state: 'CO', county: 'Broomfield County' },
  { state: 'CO', county: 'Chaffee County' },
  { state: 'CO', county: 'Cheyenne County' },
  { state: 'CO', county: 'Clear Creek County' },
  { state: 'CO', county: 'Conejos County' },
  { state: 'CO', county: 'Costilla County' },
  { state: 'CO', county: 'Crowley County' },
  { state: 'CO', county: 'Custer County' },
  { state: 'CO', county: 'Delta County' },
  { state: 'CO', county: 'Denver County' },
  { state: 'CO', county: 'Dolores County' },
  { state: 'CO', county: 'Douglas County' },
  { state: 'CO', county: 'Eagle County' },
  { state: 'CO', county: 'Elbert County' },
  { state: 'CO', county: 'El Paso County' },
  { state: 'CO', county: 'Fremont County' },
  { state: 'CO', county: 'Garfield County' },
  { state: 'CO', county: 'Gilpin County' },
  { state: 'CO', county: 'Grand County' },
  { state: 'CO', county: 'Gunnison County' },
  { state: 'CO', county: 'Hinsdale County' },
  { state: 'CO', county: 'Huerfano County' },
  { state: 'CO', county: 'Jackson County' },
  { state: 'CO', county: 'Jefferson County' },
  { state: 'CO', county: 'Kiowa County' },
  { state: 'CO', county: 'Kit Carson County' },
  { state: 'CO', county: 'Lake County' },
  { state: 'CO', county: 'La Plata County' },
  { state: 'CO', county: 'Larimer County' },
  { state: 'CO', county: 'Las Animas County' },
  { state: 'CO', county: 'Lincoln County' },
  { state: 'CO', county: 'Logan County' },
  { state: 'CO', county: 'Mesa County' },
  { state: 'CO', county: 'Mineral County' },
  { state: 'CO', county: 'Moffat County' },
  { state: 'CO', county: 'Montezuma County' },
  { state: 'CO', county: 'Montrose County' },
  { state: 'CO', county: 'Morgan County' },
  { state: 'CO', county: 'Otero County' },
  { state: 'CO', county: 'Ouray County' },
  { state: 'CO', county: 'Park County' },
  { state: 'CO', county: 'Phillips County' },
  { state: 'CO', county: 'Pitkin County' },
  { state: 'CO', county: 'Prowers County' },
  { state: 'CO', county: 'Pueblo County' },
  { state: 'CO', county: 'Rio Blanco County' },
  { state: 'CO', county: 'Rio Grande County' },
  { state: 'CO', county: 'Routt County' },
  { state: 'CO', county: 'Saguache County' },
  { state: 'CO', county: 'San Juan County' },
  { state: 'CO', county: 'San Miguel County' },
  { state: 'CO', county: 'Sedgwick County' },
  { state: 'CO', county: 'Summit County' },
  { state: 'CO', county: 'Teller County' },
  { state: 'CO', county: 'Washington County' },
  { state: 'CO', county: 'Weld County' },
  { state: 'CO', county: 'Yuma County' },
  { state: 'CT', county: 'Fairfield County' },
  { state: 'CT', county: 'Hartford County' },
  { state: 'CT', county: 'Litchfield County' },
  { state: 'CT', county: 'Middlesex County' },
  { state: 'CT', county: 'New Haven County' },
  { state: 'CT', county: 'New London County' },
  { state: 'CT', county: 'Tolland County' },
  { state: 'CT', county: 'Windham County' },
  { state: 'DE', county: 'Kent County' },
  { state: 'DE', county: 'New Castle County' },
  { state: 'DE', county: 'Sussex County' },
  { state: 'DC', county: 'District of Columbia' },
  { state: 'FL', county: 'Alachua County' },
  { state: 'FL', county: 'Baker County' },
  { state: 'FL', county: 'Bay County' },
  { state: 'FL', county: 'Bradford County' },
  { state: 'FL', county: 'Brevard County' },
  { state: 'FL', county: 'Broward County' },
  { state: 'FL', county: 'Calhoun County' },
  { state: 'FL', county: 'Charlotte County' },
  { state: 'FL', county: 'Citrus County' },
  { state: 'FL', county: 'Clay County' },
  { state: 'FL', county: 'Collier County' },
  { state: 'FL', county: 'Columbia County' },
  { state: 'FL', county: 'DeSoto County' },
  { state: 'FL', county: 'Dixie County' },
  { state: 'FL', county: 'Duval County' },
  { state: 'FL', county: 'Escambia County' },
  { state: 'FL', county: 'Flagler County' },
  { state: 'FL', county: 'Franklin County' },
  { state: 'FL', county: 'Gadsden County' },
  { state: 'FL', county: 'Gilchrist County' },
  { state: 'FL', county: 'Glades County' },
  { state: 'FL', county: 'Gulf County' },
  { state: 'FL', county: 'Hamilton County' },
  { state: 'FL', county: 'Hardee County' },
  { state: 'FL', county: 'Hendry County' },
  { state: 'FL', county: 'Hernando County' },
  { state: 'FL', county: 'Highlands County' },
  { state: 'FL', county: 'Hillsborough County' },
  { state: 'FL', county: 'Holmes County' },
  { state: 'FL', county: 'Indian River County' },
  { state: 'FL', county: 'Jackson County' },
  { state: 'FL', county: 'Jefferson County' },
  { state: 'FL', county: 'Lafayette County' },
  { state: 'FL', county: 'Lake County' },
  { state: 'FL', county: 'Lee County' },
  { state: 'FL', county: 'Leon County' },
  { state: 'FL', county: 'Levy County' },
  { state: 'FL', county: 'Liberty County' },
  { state: 'FL', county: 'Madison County' },
  { state: 'FL', county: 'Manatee County' },
  { state: 'FL', county: 'Marion County' },
  { state: 'FL', county: 'Martin County' },
  { state: 'FL', county: 'Miami-Dade County' },
  { state: 'FL', county: 'Monroe County' },
  { state: 'FL', county: 'Nassau County' },
  { state: 'FL', county: 'Okaloosa County' },
  { state: 'FL', county: 'Okeechobee County' },
  { state: 'FL', county: 'Orange County' },
  { state: 'FL', county: 'Osceola County' },
  { state: 'FL', county: 'Palm Beach County' },
  { state: 'FL', county: 'Pasco County' },
  { state: 'FL', county: 'Pinellas County' },
  { state: 'FL', county: 'Polk County' },
  { state: 'FL', county: 'Putnam County' },
  { state: 'FL', county: 'St. Johns County' },
  { state: 'FL', county: 'St. Lucie County' },
  { state: 'FL', county: 'Santa Rosa County' },
  { state: 'FL', county: 'Sarasota County' },
  { state: 'FL', county: 'Seminole County' },
  { state: 'FL', county: 'Sumter County' },
  { state: 'FL', county: 'Suwannee County' },
  { state: 'FL', county: 'Taylor County' },
  { state: 'FL', county: 'Union County' },
  { state: 'FL', county: 'Volusia County' },
  { state: 'FL', county: 'Wakulla County' },
  { state: 'FL', county: 'Walton County' },
  { state: 'FL', county: 'Washington County' },
  { state: 'GA', county: 'Appling County' },
  { state: 'GA', county: 'Atkinson County' },
  { state: 'GA', county: 'Bacon County' },
  { state: 'GA', county: 'Baker County' },
  { state: 'GA', county: 'Baldwin County' },
  { state: 'GA', county: 'Banks County' },
  { state: 'GA', county: 'Barrow County' },
  { state: 'GA', county: 'Bartow County' },
  { state: 'GA', county: 'Ben Hill County' },
  { state: 'GA', county: 'Berrien County' },
  { state: 'GA', county: 'Bibb County' },
  { state: 'GA', county: 'Bleckley County' },
  { state: 'GA', county: 'Brantley County' },
  { state: 'GA', county: 'Brooks County' },
  { state: 'GA', county: 'Bryan County' },
  { state: 'GA', county: 'Bulloch County' },
  { state: 'GA', county: 'Burke County' },
  { state: 'GA', county: 'Butts County' },
  { state: 'GA', county: 'Calhoun County' },
  { state: 'GA', county: 'Camden County' },
  { state: 'GA', county: 'Candler County' },
  { state: 'GA', county: 'Carroll County' },
  { state: 'GA', county: 'Catoosa County' },
  { state: 'GA', county: 'Charlton County' },
  { state: 'GA', county: 'Chatham County' },
  { state: 'GA', county: 'Chattahoochee County' },
  { state: 'GA', county: 'Chattooga County' },
  { state: 'GA', county: 'Cherokee County' },
  { state: 'GA', county: 'Clarke County' },
  { state: 'GA', county: 'Clay County' },
  { state: 'GA', county: 'Clayton County' },
  { state: 'GA', county: 'Clinch County' },
  { state: 'GA', county: 'Cobb County' },
  { state: 'GA', county: 'Coffee County' },
  { state: 'GA', county: 'Colquitt County' },
  { state: 'GA', county: 'Columbia County' },
  { state: 'GA', county: 'Cook County' },
  { state: 'GA', county: 'Coweta County' },
  { state: 'GA', county: 'Crawford County' },
  { state: 'GA', county: 'Crisp County' },
  { state: 'GA', county: 'Dade County' },
  { state: 'GA', county: 'Dawson County' },
  { state: 'GA', county: 'Decatur County' },
  { state: 'GA', county: 'DeKalb County' },
  { state: 'GA', county: 'Dodge County' },
  { state: 'GA', county: 'Dooly County' },
  { state: 'GA', county: 'Dougherty County' },
  { state: 'GA', county: 'Douglas County' },
  { state: 'GA', county: 'Early County' },
  { state: 'GA', county: 'Echols County' },
  { state: 'GA', county: 'Effingham County' },
  { state: 'GA', county: 'Elbert County' },
  { state: 'GA', county: 'Emanuel County' },
  { state: 'GA', county: 'Evans County' },
  { state: 'GA', county: 'Fannin County' },
  { state: 'GA', county: 'Fayette County' },
  { state: 'GA', county: 'Floyd County' },
  { state: 'GA', county: 'Forsyth County' },
  { state: 'GA', county: 'Franklin County' },
  { state: 'GA', county: 'Fulton County' },
  { state: 'GA', county: 'Gilmer County' },
  { state: 'GA', county: 'Glascock County' },
  { state: 'GA', county: 'Glynn County' },
  { state: 'GA', county: 'Gordon County' },
  { state: 'GA', county: 'Grady County' },
  { state: 'GA', county: 'Greene County' },
  { state: 'GA', county: 'Gwinnett County' },
  { state: 'GA', county: 'Habersham County' },
  { state: 'GA', county: 'Hall County' },
  { state: 'GA', county: 'Hancock County' },
  { state: 'GA', county: 'Haralson County' },
  { state: 'GA', county: 'Harris County' },
  { state: 'GA', county: 'Hart County' },
  { state: 'GA', county: 'Heard County' },
  { state: 'GA', county: 'Henry County' },
  { state: 'GA', county: 'Houston County' },
  { state: 'GA', county: 'Irwin County' },
  { state: 'GA', county: 'Jackson County' },
  { state: 'GA', county: 'Jasper County' },
  { state: 'GA', county: 'Jeff Davis County' },
  { state: 'GA', county: 'Jefferson County' },
  { state: 'GA', county: 'Jenkins County' },
  { state: 'GA', county: 'Johnson County' },
  { state: 'GA', county: 'Jones County' },
  { state: 'GA', county: 'Lamar County' },
  { state: 'GA', county: 'Lanier County' },
  { state: 'GA', county: 'Laurens County' },
  { state: 'GA', county: 'Lee County' },
  { state: 'GA', county: 'Liberty County' },
  { state: 'GA', county: 'Lincoln County' },
  { state: 'GA', county: 'Long County' },
  { state: 'GA', county: 'Lowndes County' },
  { state: 'GA', county: 'Lumpkin County' },
  { state: 'GA', county: 'McDuffie County' },
  { state: 'GA', county: 'McIntosh County' },
  { state: 'GA', county: 'Macon County' },
  { state: 'GA', county: 'Madison County' },
  { state: 'GA', county: 'Marion County' },
  { state: 'GA', county: 'Meriwether County' },
  { state: 'GA', county: 'Miller County' },
  { state: 'GA', county: 'Mitchell County' },
  { state: 'GA', county: 'Monroe County' },
  { state: 'GA', county: 'Montgomery County' },
  { state: 'GA', county: 'Morgan County' },
  { state: 'GA', county: 'Murray County' },
  { state: 'GA', county: 'Muscogee County' },
  { state: 'GA', county: 'Newton County' },
  { state: 'GA', county: 'Oconee County' },
  { state: 'GA', county: 'Oglethorpe County' },
  { state: 'GA', county: 'Paulding County' },
  { state: 'GA', county: 'Peach County' },
  { state: 'GA', county: 'Pickens County' },
  { state: 'GA', county: 'Pierce County' },
  { state: 'GA', county: 'Pike County' },
  { state: 'GA', county: 'Polk County' },
  { state: 'GA', county: 'Pulaski County' },
  { state: 'GA', county: 'Putnam County' },
  { state: 'GA', county: 'Quitman County' },
  { state: 'GA', county: 'Rabun County' },
  { state: 'GA', county: 'Randolph County' },
  { state: 'GA', county: 'Richmond County' },
  { state: 'GA', county: 'Rockdale County' },
  { state: 'GA', county: 'Schley County' },
  { state: 'GA', county: 'Screven County' },
  { state: 'GA', county: 'Seminole County' },
  { state: 'GA', county: 'Spalding County' },
  { state: 'GA', county: 'Stephens County' },
  { state: 'GA', county: 'Stewart County' },
  { state: 'GA', county: 'Sumter County' },
  { state: 'GA', county: 'Talbot County' },
  { state: 'GA', county: 'Taliaferro County' },
  { state: 'GA', county: 'Tattnall County' },
  { state: 'GA', county: 'Taylor County' },
  { state: 'GA', county: 'Telfair County' },
  { state: 'GA', county: 'Terrell County' },
  { state: 'GA', county: 'Thomas County' },
  { state: 'GA', county: 'Tift County' },
  { state: 'GA', county: 'Toombs County' },
  { state: 'GA', county: 'Towns County' },
  { state: 'GA', county: 'Treutlen County' },
  { state: 'GA', county: 'Troup County' },
  { state: 'GA', county: 'Turner County' },
  { state: 'GA', county: 'Twiggs County' },
  { state: 'GA', county: 'Union County' },
  { state: 'GA', county: 'Upson County' },
  { state: 'GA', county: 'Walker County' },
  { state: 'GA', county: 'Walton County' },
  { state: 'GA', county: 'Ware County' },
  { state: 'GA', county: 'Warren County' },
  { state: 'GA', county: 'Washington County' },
  { state: 'GA', county: 'Wayne County' },
  { state: 'GA', county: 'Webster County' },
  { state: 'GA', county: 'Wheeler County' },
  { state: 'GA', county: 'White County' },
  { state: 'GA', county: 'Whitfield County' },
  { state: 'GA', county: 'Wilcox County' },
  { state: 'GA', county: 'Wilkes County' },
  { state: 'GA', county: 'Wilkinson County' },
  { state: 'GA', county: 'Worth County' },
  { state: 'HI', county: 'Hawaii County' },
  { state: 'HI', county: 'Honolulu County' },
  { state: 'HI', county: 'Kalawao County' },
  { state: 'HI', county: 'Kauai County' },
  { state: 'HI', county: 'Maui County' },
  { state: 'ID', county: 'Ada County' },
  { state: 'ID', county: 'Adams County' },
  { state: 'ID', county: 'Bannock County' },
  { state: 'ID', county: 'Bear Lake County' },
  { state: 'ID', county: 'Benewah County' },
  { state: 'ID', county: 'Bingham County' },
  { state: 'ID', county: 'Blaine County' },
  { state: 'ID', county: 'Boise County' },
  { state: 'ID', county: 'Bonner County' },
  { state: 'ID', county: 'Bonneville County' },
  { state: 'ID', county: 'Boundary County' },
  { state: 'ID', county: 'Butte County' },
  { state: 'ID', county: 'Camas County' },
  { state: 'ID', county: 'Canyon County' },
  { state: 'ID', county: 'Caribou County' },
  { state: 'ID', county: 'Cassia County' },
  { state: 'ID', county: 'Clark County' },
  { state: 'ID', county: 'Clearwater County' },
  { state: 'ID', county: 'Custer County' },
  { state: 'ID', county: 'Elmore County' },
  { state: 'ID', county: 'Franklin County' },
  { state: 'ID', county: 'Fremont County' },
  { state: 'ID', county: 'Gem County' },
  { state: 'ID', county: 'Gooding County' },
  { state: 'ID', county: 'Idaho County' },
  { state: 'ID', county: 'Jefferson County' },
  { state: 'ID', county: 'Jerome County' },
  { state: 'ID', county: 'Kootenai County' },
  { state: 'ID', county: 'Latah County' },
  { state: 'ID', county: 'Lemhi County' },
  { state: 'ID', county: 'Lewis County' },
  { state: 'ID', county: 'Lincoln County' },
  { state: 'ID', county: 'Madison County' },
  { state: 'ID', county: 'Minidoka County' },
  { state: 'ID', county: 'Nez Perce County' },
  { state: 'ID', county: 'Oneida County' },
  { state: 'ID', county: 'Owyhee County' },
  { state: 'ID', county: 'Payette County' },
  { state: 'ID', county: 'Power County' },
  { state: 'ID', county: 'Shoshone County' },
  { state: 'ID', county: 'Teton County' },
  { state: 'ID', county: 'Twin Falls County' },
  { state: 'ID', county: 'Valley County' },
  { state: 'ID', county: 'Washington County' },
  { state: 'IL', county: 'Adams County' },
  { state: 'IL', county: 'Alexander County' },
  { state: 'IL', county: 'Bond County' },
  { state: 'IL', county: 'Boone County' },
  { state: 'IL', county: 'Brown County' },
  { state: 'IL', county: 'Bureau County' },
  { state: 'IL', county: 'Calhoun County' },
  { state: 'IL', county: 'Carroll County' },
  { state: 'IL', county: 'Cass County' },
  { state: 'IL', county: 'Champaign County' },
  { state: 'IL', county: 'Christian County' },
  { state: 'IL', county: 'Clark County' },
  { state: 'IL', county: 'Clay County' },
  { state: 'IL', county: 'Clinton County' },
  { state: 'IL', county: 'Coles County' },
  { state: 'IL', county: 'Cook County' },
  { state: 'IL', county: 'Crawford County' },
  { state: 'IL', county: 'Cumberland County' },
  { state: 'IL', county: 'DeKalb County' },
  { state: 'IL', county: 'De Witt County' },
  { state: 'IL', county: 'Douglas County' },
  { state: 'IL', county: 'DuPage County' },
  { state: 'IL', county: 'Edgar County' },
  { state: 'IL', county: 'Edwards County' },
  { state: 'IL', county: 'Effingham County' },
  { state: 'IL', county: 'Fayette County' },
  { state: 'IL', county: 'Ford County' },
  { state: 'IL', county: 'Franklin County' },
  { state: 'IL', county: 'Fulton County' },
  { state: 'IL', county: 'Gallatin County' },
  { state: 'IL', county: 'Greene County' },
  { state: 'IL', county: 'Grundy County' },
  { state: 'IL', county: 'Hamilton County' },
  { state: 'IL', county: 'Hancock County' },
  { state: 'IL', county: 'Hardin County' },
  { state: 'IL', county: 'Henderson County' },
  { state: 'IL', county: 'Henry County' },
  { state: 'IL', county: 'Iroquois County' },
  { state: 'IL', county: 'Jackson County' },
  { state: 'IL', county: 'Jasper County' },
  { state: 'IL', county: 'Jefferson County' },
  { state: 'IL', county: 'Jersey County' },
  { state: 'IL', county: 'Jo Daviess County' },
  { state: 'IL', county: 'Johnson County' },
  { state: 'IL', county: 'Kane County' },
  { state: 'IL', county: 'Kankakee County' },
  { state: 'IL', county: 'Kendall County' },
  { state: 'IL', county: 'Knox County' },
  { state: 'IL', county: 'Lake County' },
  { state: 'IL', county: 'LaSalle County' },
  { state: 'IL', county: 'Lawrence County' },
  { state: 'IL', county: 'Lee County' },
  { state: 'IL', county: 'Livingston County' },
  { state: 'IL', county: 'Logan County' },
  { state: 'IL', county: 'McDonough County' },
  { state: 'IL', county: 'McHenry County' },
  { state: 'IL', county: 'McLean County' },
  { state: 'IL', county: 'Macon County' },
  { state: 'IL', county: 'Macoupin County' },
  { state: 'IL', county: 'Madison County' },
  { state: 'IL', county: 'Marion County' },
  { state: 'IL', county: 'Marshall County' },
  { state: 'IL', county: 'Mason County' },
  { state: 'IL', county: 'Massac County' },
  { state: 'IL', county: 'Menard County' },
  { state: 'IL', county: 'Mercer County' },
  { state: 'IL', county: 'Monroe County' },
  { state: 'IL', county: 'Montgomery County' },
  { state: 'IL', county: 'Morgan County' },
  { state: 'IL', county: 'Moultrie County' },
  { state: 'IL', county: 'Ogle County' },
  { state: 'IL', county: 'Peoria County' },
  { state: 'IL', county: 'Perry County' },
  { state: 'IL', county: 'Piatt County' },
  { state: 'IL', county: 'Pike County' },
  { state: 'IL', county: 'Pope County' },
  { state: 'IL', county: 'Pulaski County' },
  { state: 'IL', county: 'Putnam County' },
  { state: 'IL', county: 'Randolph County' },
  { state: 'IL', county: 'Richland County' },
  { state: 'IL', county: 'Rock Island County' },
  { state: 'IL', county: 'St. Clair County' },
  { state: 'IL', county: 'Saline County' },
  { state: 'IL', county: 'Sangamon County' },
  { state: 'IL', county: 'Schuyler County' },
  { state: 'IL', county: 'Scott County' },
  { state: 'IL', county: 'Shelby County' },
  { state: 'IL', county: 'Stark County' },
  { state: 'IL', county: 'Stephenson County' },
  { state: 'IL', county: 'Tazewell County' },
  { state: 'IL', county: 'Union County' },
  { state: 'IL', county: 'Vermilion County' },
  { state: 'IL', county: 'Wabash County' },
  { state: 'IL', county: 'Warren County' },
  { state: 'IL', county: 'Washington County' },
  { state: 'IL', county: 'Wayne County' },
  { state: 'IL', county: 'White County' },
  { state: 'IL', county: 'Whiteside County' },
  { state: 'IL', county: 'Will County' },
  { state: 'IL', county: 'Williamson County' },
  { state: 'IL', county: 'Winnebago County' },
  { state: 'IL', county: 'Woodford County' },
  { state: 'IN', county: 'Adams County' },
  { state: 'IN', county: 'Allen County' },
  { state: 'IN', county: 'Bartholomew County' },
  { state: 'IN', county: 'Benton County' },
  { state: 'IN', county: 'Blackford County' },
  { state: 'IN', county: 'Boone County' },
  { state: 'IN', county: 'Brown County' },
  { state: 'IN', county: 'Carroll County' },
  { state: 'IN', county: 'Cass County' },
  { state: 'IN', county: 'Clark County' },
  { state: 'IN', county: 'Clay County' },
  { state: 'IN', county: 'Clinton County' },
  { state: 'IN', county: 'Crawford County' },
  { state: 'IN', county: 'Daviess County' },
  { state: 'IN', county: 'Dearborn County' },
  { state: 'IN', county: 'Decatur County' },
  { state: 'IN', county: 'DeKalb County' },
  { state: 'IN', county: 'Delaware County' },
  { state: 'IN', county: 'Dubois County' },
  { state: 'IN', county: 'Elkhart County' },
  { state: 'IN', county: 'Fayette County' },
  { state: 'IN', county: 'Floyd County' },
  { state: 'IN', county: 'Fountain County' },
  { state: 'IN', county: 'Franklin County' },
  { state: 'IN', county: 'Fulton County' },
  { state: 'IN', county: 'Gibson County' },
  { state: 'IN', county: 'Grant County' },
  { state: 'IN', county: 'Greene County' },
  { state: 'IN', county: 'Hamilton County' },
  { state: 'IN', county: 'Hancock County' },
  { state: 'IN', county: 'Harrison County' },
  { state: 'IN', county: 'Hendricks County' },
  { state: 'IN', county: 'Henry County' },
  { state: 'IN', county: 'Howard County' },
  { state: 'IN', county: 'Huntington County' },
  { state: 'IN', county: 'Jackson County' },
  { state: 'IN', county: 'Jasper County' },
  { state: 'IN', county: 'Jay County' },
  { state: 'IN', county: 'Jefferson County' },
  { state: 'IN', county: 'Jennings County' },
  { state: 'IN', county: 'Johnson County' },
  { state: 'IN', county: 'Knox County' },
  { state: 'IN', county: 'Kosciusko County' },
  { state: 'IN', county: 'LaGrange County' },
  { state: 'IN', county: 'Lake County' },
  { state: 'IN', county: 'LaPorte County' },
  { state: 'IN', county: 'Lawrence County' },
  { state: 'IN', county: 'Madison County' },
  { state: 'IN', county: 'Marion County' },
  { state: 'IN', county: 'Marshall County' },
  { state: 'IN', county: 'Martin County' },
  { state: 'IN', county: 'Miami County' },
  { state: 'IN', county: 'Monroe County' },
  { state: 'IN', county: 'Montgomery County' },
  { state: 'IN', county: 'Morgan County' },
  { state: 'IN', county: 'Newton County' },
  { state: 'IN', county: 'Noble County' },
  { state: 'IN', county: 'Ohio County' },
  { state: 'IN', county: 'Orange County' },
  { state: 'IN', county: 'Owen County' },
  { state: 'IN', county: 'Parke County' },
  { state: 'IN', county: 'Perry County' },
  { state: 'IN', county: 'Pike County' },
  { state: 'IN', county: 'Porter County' },
  { state: 'IN', county: 'Posey County' },
  { state: 'IN', county: 'Pulaski County' },
  { state: 'IN', county: 'Putnam County' },
  { state: 'IN', county: 'Randolph County' },
  { state: 'IN', county: 'Ripley County' },
  { state: 'IN', county: 'Rush County' },
  { state: 'IN', county: 'St. Joseph County' },
  { state: 'IN', county: 'Scott County' },
  { state: 'IN', county: 'Shelby County' },
  { state: 'IN', county: 'Spencer County' },
  { state: 'IN', county: 'Starke County' },
  { state: 'IN', county: 'Steuben County' },
  { state: 'IN', county: 'Sullivan County' },
  { state: 'IN', county: 'Switzerland County' },
  { state: 'IN', county: 'Tippecanoe County' },
  { state: 'IN', county: 'Tipton County' },
  { state: 'IN', county: 'Union County' },
  { state: 'IN', county: 'Vanderburgh County' },
  { state: 'IN', county: 'Vermillion County' },
  { state: 'IN', county: 'Vigo County' },
  { state: 'IN', county: 'Wabash County' },
  { state: 'IN', county: 'Warren County' },
  { state: 'IN', county: 'Warrick County' },
  { state: 'IN', county: 'Washington County' },
  { state: 'IN', county: 'Wayne County' },
  { state: 'IN', county: 'Wells County' },
  { state: 'IN', county: 'White County' },
  { state: 'IN', county: 'Whitley County' },
  { state: 'IA', county: 'Adair County' },
  { state: 'IA', county: 'Adams County' },
  { state: 'IA', county: 'Allamakee County' },
  { state: 'IA', county: 'Appanoose County' },
  { state: 'IA', county: 'Audubon County' },
  { state: 'IA', county: 'Benton County' },
  { state: 'IA', county: 'Black Hawk County' },
  { state: 'IA', county: 'Boone County' },
  { state: 'IA', county: 'Bremer County' },
  { state: 'IA', county: 'Buchanan County' },
  { state: 'IA', county: 'Buena Vista County' },
  { state: 'IA', county: 'Butler County' },
  { state: 'IA', county: 'Calhoun County' },
  { state: 'IA', county: 'Carroll County' },
  { state: 'IA', county: 'Cass County' },
  { state: 'IA', county: 'Cedar County' },
  { state: 'IA', county: 'Cerro Gordo County' },
  { state: 'IA', county: 'Cherokee County' },
  { state: 'IA', county: 'Chickasaw County' },
  { state: 'IA', county: 'Clarke County' },
  { state: 'IA', county: 'Clay County' },
  { state: 'IA', county: 'Clayton County' },
  { state: 'IA', county: 'Clinton County' },
  { state: 'IA', county: 'Crawford County' },
  { state: 'IA', county: 'Dallas County' },
  { state: 'IA', county: 'Davis County' },
  { state: 'IA', county: 'Decatur County' },
  { state: 'IA', county: 'Delaware County' },
  { state: 'IA', county: 'Des Moines County' },
  { state: 'IA', county: 'Dickinson County' },
  { state: 'IA', county: 'Dubuque County' },
  { state: 'IA', county: 'Emmet County' },
  { state: 'IA', county: 'Fayette County' },
  { state: 'IA', county: 'Floyd County' },
  { state: 'IA', county: 'Franklin County' },
  { state: 'IA', county: 'Fremont County' },
  { state: 'IA', county: 'Greene County' },
  { state: 'IA', county: 'Grundy County' },
  { state: 'IA', county: 'Guthrie County' },
  { state: 'IA', county: 'Hamilton County' },
  { state: 'IA', county: 'Hancock County' },
  { state: 'IA', county: 'Hardin County' },
  { state: 'IA', county: 'Harrison County' },
  { state: 'IA', county: 'Henry County' },
  { state: 'IA', county: 'Howard County' },
  { state: 'IA', county: 'Humboldt County' },
  { state: 'IA', county: 'Ida County' },
  { state: 'IA', county: 'Iowa County' },
  { state: 'IA', county: 'Jackson County' },
  { state: 'IA', county: 'Jasper County' },
  { state: 'IA', county: 'Jefferson County' },
  { state: 'IA', county: 'Johnson County' },
  { state: 'IA', county: 'Jones County' },
  { state: 'IA', county: 'Keokuk County' },
  { state: 'IA', county: 'Kossuth County' },
  { state: 'IA', county: 'Lee County' },
  { state: 'IA', county: 'Linn County' },
  { state: 'IA', county: 'Louisa County' },
  { state: 'IA', county: 'Lucas County' },
  { state: 'IA', county: 'Lyon County' },
  { state: 'IA', county: 'Madison County' },
  { state: 'IA', county: 'Mahaska County' },
  { state: 'IA', county: 'Marion County' },
  { state: 'IA', county: 'Marshall County' },
  { state: 'IA', county: 'Mills County' },
  { state: 'IA', county: 'Mitchell County' },
  { state: 'IA', county: 'Monona County' },
  { state: 'IA', county: 'Monroe County' },
  { state: 'IA', county: 'Montgomery County' },
  { state: 'IA', county: 'Muscatine County' },
  { state: 'IA', county: "O'Brien County" },
  { state: 'IA', county: 'Osceola County' },
  { state: 'IA', county: 'Page County' },
  { state: 'IA', county: 'Palo Alto County' },
  { state: 'IA', county: 'Plymouth County' },
  { state: 'IA', county: 'Pocahontas County' },
  { state: 'IA', county: 'Polk County' },
  { state: 'IA', county: 'Pottawattamie County' },
  { state: 'IA', county: 'Poweshiek County' },
  { state: 'IA', county: 'Ringgold County' },
  { state: 'IA', county: 'Sac County' },
  { state: 'IA', county: 'Scott County' },
  { state: 'IA', county: 'Shelby County' },
  { state: 'IA', county: 'Sioux County' },
  { state: 'IA', county: 'Story County' },
  { state: 'IA', county: 'Tama County' },
  { state: 'IA', county: 'Taylor County' },
  { state: 'IA', county: 'Union County' },
  { state: 'IA', county: 'Van Buren County' },
  { state: 'IA', county: 'Wapello County' },
  { state: 'IA', county: 'Warren County' },
  { state: 'IA', county: 'Washington County' },
  { state: 'IA', county: 'Wayne County' },
  { state: 'IA', county: 'Webster County' },
  { state: 'IA', county: 'Winnebago County' },
  { state: 'IA', county: 'Winneshiek County' },
  { state: 'IA', county: 'Woodbury County' },
  { state: 'IA', county: 'Worth County' },
  { state: 'IA', county: 'Wright County' },
  { state: 'KS', county: 'Allen County' },
  { state: 'KS', county: 'Anderson County' },
  { state: 'KS', county: 'Atchison County' },
  { state: 'KS', county: 'Barber County' },
  { state: 'KS', county: 'Barton County' },
  { state: 'KS', county: 'Bourbon County' },
  { state: 'KS', county: 'Brown County' },
  { state: 'KS', county: 'Butler County' },
  { state: 'KS', county: 'Chase County' },
  { state: 'KS', county: 'Chautauqua County' },
  { state: 'KS', county: 'Cherokee County' },
  { state: 'KS', county: 'Cheyenne County' },
  { state: 'KS', county: 'Clark County' },
  { state: 'KS', county: 'Clay County' },
  { state: 'KS', county: 'Cloud County' },
  { state: 'KS', county: 'Coffey County' },
  { state: 'KS', county: 'Comanche County' },
  { state: 'KS', county: 'Cowley County' },
  { state: 'KS', county: 'Crawford County' },
  { state: 'KS', county: 'Decatur County' },
  { state: 'KS', county: 'Dickinson County' },
  { state: 'KS', county: 'Doniphan County' },
  { state: 'KS', county: 'Douglas County' },
  { state: 'KS', county: 'Edwards County' },
  { state: 'KS', county: 'Elk County' },
  { state: 'KS', county: 'Ellis County' },
  { state: 'KS', county: 'Ellsworth County' },
  { state: 'KS', county: 'Finney County' },
  { state: 'KS', county: 'Ford County' },
  { state: 'KS', county: 'Franklin County' },
  { state: 'KS', county: 'Geary County' },
  { state: 'KS', county: 'Gove County' },
  { state: 'KS', county: 'Graham County' },
  { state: 'KS', county: 'Grant County' },
  { state: 'KS', county: 'Gray County' },
  { state: 'KS', county: 'Greeley County' },
  { state: 'KS', county: 'Greenwood County' },
  { state: 'KS', county: 'Hamilton County' },
  { state: 'KS', county: 'Harper County' },
  { state: 'KS', county: 'Harvey County' },
  { state: 'KS', county: 'Haskell County' },
  { state: 'KS', county: 'Hodgeman County' },
  { state: 'KS', county: 'Jackson County' },
  { state: 'KS', county: 'Jefferson County' },
  { state: 'KS', county: 'Jewell County' },
  { state: 'KS', county: 'Johnson County' },
  { state: 'KS', county: 'Kearny County' },
  { state: 'KS', county: 'Kingman County' },
  { state: 'KS', county: 'Kiowa County' },
  { state: 'KS', county: 'Labette County' },
  { state: 'KS', county: 'Lane County' },
  { state: 'KS', county: 'Leavenworth County' },
  { state: 'KS', county: 'Lincoln County' },
  { state: 'KS', county: 'Linn County' },
  { state: 'KS', county: 'Logan County' },
  { state: 'KS', county: 'Lyon County' },
  { state: 'KS', county: 'McPherson County' },
  { state: 'KS', county: 'Marion County' },
  { state: 'KS', county: 'Marshall County' },
  { state: 'KS', county: 'Meade County' },
  { state: 'KS', county: 'Miami County' },
  { state: 'KS', county: 'Mitchell County' },
  { state: 'KS', county: 'Montgomery County' },
  { state: 'KS', county: 'Morris County' },
  { state: 'KS', county: 'Morton County' },
  { state: 'KS', county: 'Nemaha County' },
  { state: 'KS', county: 'Neosho County' },
  { state: 'KS', county: 'Ness County' },
  { state: 'KS', county: 'Norton County' },
  { state: 'KS', county: 'Osage County' },
  { state: 'KS', county: 'Osborne County' },
  { state: 'KS', county: 'Ottawa County' },
  { state: 'KS', county: 'Pawnee County' },
  { state: 'KS', county: 'Phillips County' },
  { state: 'KS', county: 'Pottawatomie County' },
  { state: 'KS', county: 'Pratt County' },
  { state: 'KS', county: 'Rawlins County' },
  { state: 'KS', county: 'Reno County' },
  { state: 'KS', county: 'Republic County' },
  { state: 'KS', county: 'Rice County' },
  { state: 'KS', county: 'Riley County' },
  { state: 'KS', county: 'Rooks County' },
  { state: 'KS', county: 'Rush County' },
  { state: 'KS', county: 'Russell County' },
  { state: 'KS', county: 'Saline County' },
  { state: 'KS', county: 'Scott County' },
  { state: 'KS', county: 'Sedgwick County' },
  { state: 'KS', county: 'Seward County' },
  { state: 'KS', county: 'Shawnee County' },
  { state: 'KS', county: 'Sheridan County' },
  { state: 'KS', county: 'Sherman County' },
  { state: 'KS', county: 'Smith County' },
  { state: 'KS', county: 'Stafford County' },
  { state: 'KS', county: 'Stanton County' },
  { state: 'KS', county: 'Stevens County' },
  { state: 'KS', county: 'Sumner County' },
  { state: 'KS', county: 'Thomas County' },
  { state: 'KS', county: 'Trego County' },
  { state: 'KS', county: 'Wabaunsee County' },
  { state: 'KS', county: 'Wallace County' },
  { state: 'KS', county: 'Washington County' },
  { state: 'KS', county: 'Wichita County' },
  { state: 'KS', county: 'Wilson County' },
  { state: 'KS', county: 'Woodson County' },
  { state: 'KS', county: 'Wyandotte County' },
  { state: 'KY', county: 'Adair County' },
  { state: 'KY', county: 'Allen County' },
  { state: 'KY', county: 'Anderson County' },
  { state: 'KY', county: 'Ballard County' },
  { state: 'KY', county: 'Barren County' },
  { state: 'KY', county: 'Bath County' },
  { state: 'KY', county: 'Bell County' },
  { state: 'KY', county: 'Boone County' },
  { state: 'KY', county: 'Bourbon County' },
  { state: 'KY', county: 'Boyd County' },
  { state: 'KY', county: 'Boyle County' },
  { state: 'KY', county: 'Bracken County' },
  { state: 'KY', county: 'Breathitt County' },
  { state: 'KY', county: 'Breckinridge County' },
  { state: 'KY', county: 'Bullitt County' },
  { state: 'KY', county: 'Butler County' },
  { state: 'KY', county: 'Caldwell County' },
  { state: 'KY', county: 'Calloway County' },
  { state: 'KY', county: 'Campbell County' },
  { state: 'KY', county: 'Carlisle County' },
  { state: 'KY', county: 'Carroll County' },
  { state: 'KY', county: 'Carter County' },
  { state: 'KY', county: 'Casey County' },
  { state: 'KY', county: 'Christian County' },
  { state: 'KY', county: 'Clark County' },
  { state: 'KY', county: 'Clay County' },
  { state: 'KY', county: 'Clinton County' },
  { state: 'KY', county: 'Crittenden County' },
  { state: 'KY', county: 'Cumberland County' },
  { state: 'KY', county: 'Daviess County' },
  { state: 'KY', county: 'Edmonson County' },
  { state: 'KY', county: 'Elliott County' },
  { state: 'KY', county: 'Estill County' },
  { state: 'KY', county: 'Fayette County' },
  { state: 'KY', county: 'Fleming County' },
  { state: 'KY', county: 'Floyd County' },
  { state: 'KY', county: 'Franklin County' },
  { state: 'KY', county: 'Fulton County' },
  { state: 'KY', county: 'Gallatin County' },
  { state: 'KY', county: 'Garrard County' },
  { state: 'KY', county: 'Grant County' },
  { state: 'KY', county: 'Graves County' },
  { state: 'KY', county: 'Grayson County' },
  { state: 'KY', county: 'Green County' },
  { state: 'KY', county: 'Greenup County' },
  { state: 'KY', county: 'Hancock County' },
  { state: 'KY', county: 'Hardin County' },
  { state: 'KY', county: 'Harlan County' },
  { state: 'KY', county: 'Harrison County' },
  { state: 'KY', county: 'Hart County' },
  { state: 'KY', county: 'Henderson County' },
  { state: 'KY', county: 'Henry County' },
  { state: 'KY', county: 'Hickman County' },
  { state: 'KY', county: 'Hopkins County' },
  { state: 'KY', county: 'Jackson County' },
  { state: 'KY', county: 'Jefferson County' },
  { state: 'KY', county: 'Jessamine County' },
  { state: 'KY', county: 'Johnson County' },
  { state: 'KY', county: 'Kenton County' },
  { state: 'KY', county: 'Knott County' },
  { state: 'KY', county: 'Knox County' },
  { state: 'KY', county: 'Larue County' },
  { state: 'KY', county: 'Laurel County' },
  { state: 'KY', county: 'Lawrence County' },
  { state: 'KY', county: 'Lee County' },
  { state: 'KY', county: 'Leslie County' },
  { state: 'KY', county: 'Letcher County' },
  { state: 'KY', county: 'Lewis County' },
  { state: 'KY', county: 'Lincoln County' },
  { state: 'KY', county: 'Livingston County' },
  { state: 'KY', county: 'Logan County' },
  { state: 'KY', county: 'Lyon County' },
  { state: 'KY', county: 'McCracken County' },
  { state: 'KY', county: 'McCreary County' },
  { state: 'KY', county: 'McLean County' },
  { state: 'KY', county: 'Madison County' },
  { state: 'KY', county: 'Magoffin County' },
  { state: 'KY', county: 'Marion County' },
  { state: 'KY', county: 'Marshall County' },
  { state: 'KY', county: 'Martin County' },
  { state: 'KY', county: 'Mason County' },
  { state: 'KY', county: 'Meade County' },
  { state: 'KY', county: 'Menifee County' },
  { state: 'KY', county: 'Mercer County' },
  { state: 'KY', county: 'Metcalfe County' },
  { state: 'KY', county: 'Monroe County' },
  { state: 'KY', county: 'Montgomery County' },
  { state: 'KY', county: 'Morgan County' },
  { state: 'KY', county: 'Muhlenberg County' },
  { state: 'KY', county: 'Nelson County' },
  { state: 'KY', county: 'Nicholas County' },
  { state: 'KY', county: 'Ohio County' },
  { state: 'KY', county: 'Oldham County' },
  { state: 'KY', county: 'Owen County' },
  { state: 'KY', county: 'Owsley County' },
  { state: 'KY', county: 'Pendleton County' },
  { state: 'KY', county: 'Perry County' },
  { state: 'KY', county: 'Pike County' },
  { state: 'KY', county: 'Powell County' },
  { state: 'KY', county: 'Pulaski County' },
  { state: 'KY', county: 'Robertson County' },
  { state: 'KY', county: 'Rockcastle County' },
  { state: 'KY', county: 'Rowan County' },
  { state: 'KY', county: 'Russell County' },
  { state: 'KY', county: 'Scott County' },
  { state: 'KY', county: 'Shelby County' },
  { state: 'KY', county: 'Simpson County' },
  { state: 'KY', county: 'Spencer County' },
  { state: 'KY', county: 'Taylor County' },
  { state: 'KY', county: 'Todd County' },
  { state: 'KY', county: 'Trigg County' },
  { state: 'KY', county: 'Trimble County' },
  { state: 'KY', county: 'Union County' },
  { state: 'KY', county: 'Warren County' },
  { state: 'KY', county: 'Washington County' },
  { state: 'KY', county: 'Wayne County' },
  { state: 'KY', county: 'Webster County' },
  { state: 'KY', county: 'Whitley County' },
  { state: 'KY', county: 'Wolfe County' },
  { state: 'KY', county: 'Woodford County' },
  { state: 'LA', county: 'Acadia Parish' },
  { state: 'LA', county: 'Allen Parish' },
  { state: 'LA', county: 'Ascension Parish' },
  { state: 'LA', county: 'Assumption Parish' },
  { state: 'LA', county: 'Avoyelles Parish' },
  { state: 'LA', county: 'Beauregard Parish' },
  { state: 'LA', county: 'Bienville Parish' },
  { state: 'LA', county: 'Bossier Parish' },
  { state: 'LA', county: 'Caddo Parish' },
  { state: 'LA', county: 'Calcasieu Parish' },
  { state: 'LA', county: 'Caldwell Parish' },
  { state: 'LA', county: 'Cameron Parish' },
  { state: 'LA', county: 'Catahoula Parish' },
  { state: 'LA', county: 'Claiborne Parish' },
  { state: 'LA', county: 'Concordia Parish' },
  { state: 'LA', county: 'De Soto Parish' },
  { state: 'LA', county: 'East Baton Rouge Parish' },
  { state: 'LA', county: 'East Carroll Parish' },
  { state: 'LA', county: 'East Feliciana Parish' },
  { state: 'LA', county: 'Evangeline Parish' },
  { state: 'LA', county: 'Franklin Parish' },
  { state: 'LA', county: 'Grant Parish' },
  { state: 'LA', county: 'Iberia Parish' },
  { state: 'LA', county: 'Iberville Parish' },
  { state: 'LA', county: 'Jackson Parish' },
  { state: 'LA', county: 'Jefferson Parish' },
  { state: 'LA', county: 'Jefferson Davis Parish' },
  { state: 'LA', county: 'Lafayette Parish' },
  { state: 'LA', county: 'Lafourche Parish' },
  { state: 'LA', county: 'La Salle Parish' },
  { state: 'LA', county: 'Lincoln Parish' },
  { state: 'LA', county: 'Livingston Parish' },
  { state: 'LA', county: 'Madison Parish' },
  { state: 'LA', county: 'Morehouse Parish' },
  { state: 'LA', county: 'Natchitoches Parish' },
  { state: 'LA', county: 'Orleans Parish' },
  { state: 'LA', county: 'Ouachita Parish' },
  { state: 'LA', county: 'Plaquemines Parish' },
  { state: 'LA', county: 'Pointe Coupee Parish' },
  { state: 'LA', county: 'Rapides Parish' },
  { state: 'LA', county: 'Red River Parish' },
  { state: 'LA', county: 'Richland Parish' },
  { state: 'LA', county: 'Sabine Parish' },
  { state: 'LA', county: 'St. Bernard Parish' },
  { state: 'LA', county: 'St. Charles Parish' },
  { state: 'LA', county: 'St. Helena Parish' },
  { state: 'LA', county: 'St. James Parish' },
  { state: 'LA', county: 'St. John the Baptist Parish' },
  { state: 'LA', county: 'St. Landry Parish' },
  { state: 'LA', county: 'St. Martin Parish' },
  { state: 'LA', county: 'St. Mary Parish' },
  { state: 'LA', county: 'St. Tammany Parish' },
  { state: 'LA', county: 'Tangipahoa Parish' },
  { state: 'LA', county: 'Tensas Parish' },
  { state: 'LA', county: 'Terrebonne Parish' },
  { state: 'LA', county: 'Union Parish' },
  { state: 'LA', county: 'Vermilion Parish' },
  { state: 'LA', county: 'Vernon Parish' },
  { state: 'LA', county: 'Washington Parish' },
  { state: 'LA', county: 'Webster Parish' },
  { state: 'LA', county: 'West Baton Rouge Parish' },
  { state: 'LA', county: 'West Carroll Parish' },
  { state: 'LA', county: 'West Feliciana Parish' },
  { state: 'LA', county: 'Winn Parish' },
  { state: 'ME', county: 'Androscoggin County' },
  { state: 'ME', county: 'Aroostook County' },
  { state: 'ME', county: 'Cumberland County' },
  { state: 'ME', county: 'Franklin County' },
  { state: 'ME', county: 'Hancock County' },
  { state: 'ME', county: 'Kennebec County' },
  { state: 'ME', county: 'Knox County' },
  { state: 'ME', county: 'Lincoln County' },
  { state: 'ME', county: 'Oxford County' },
  { state: 'ME', county: 'Penobscot County' },
  { state: 'ME', county: 'Piscataquis County' },
  { state: 'ME', county: 'Sagadahoc County' },
  { state: 'ME', county: 'Somerset County' },
  { state: 'ME', county: 'Waldo County' },
  { state: 'ME', county: 'Washington County' },
  { state: 'ME', county: 'York County' },
  { state: 'MD', county: 'Allegany County' },
  { state: 'MD', county: 'Anne Arundel County' },
  { state: 'MD', county: 'Baltimore County' },
  { state: 'MD', county: 'Calvert County' },
  { state: 'MD', county: 'Caroline County' },
  { state: 'MD', county: 'Carroll County' },
  { state: 'MD', county: 'Cecil County' },
  { state: 'MD', county: 'Charles County' },
  { state: 'MD', county: 'Dorchester County' },
  { state: 'MD', county: 'Frederick County' },
  { state: 'MD', county: 'Garrett County' },
  { state: 'MD', county: 'Harford County' },
  { state: 'MD', county: 'Howard County' },
  { state: 'MD', county: 'Kent County' },
  { state: 'MD', county: 'Montgomery County' },
  { state: 'MD', county: "Prince George's County" },
  { state: 'MD', county: "Queen Anne's County" },
  { state: 'MD', county: "St. Mary's County" },
  { state: 'MD', county: 'Somerset County' },
  { state: 'MD', county: 'Talbot County' },
  { state: 'MD', county: 'Washington County' },
  { state: 'MD', county: 'Wicomico County' },
  { state: 'MD', county: 'Worcester County' },
  { state: 'MD', county: 'Baltimore city' },
  { state: 'MA', county: 'Barnstable County' },
  { state: 'MA', county: 'Berkshire County' },
  { state: 'MA', county: 'Bristol County' },
  { state: 'MA', county: 'Dukes County' },
  { state: 'MA', county: 'Essex County' },
  { state: 'MA', county: 'Franklin County' },
  { state: 'MA', county: 'Hampden County' },
  { state: 'MA', county: 'Hampshire County' },
  { state: 'MA', county: 'Middlesex County' },
  { state: 'MA', county: 'Nantucket County' },
  { state: 'MA', county: 'Norfolk County' },
  { state: 'MA', county: 'Plymouth County' },
  { state: 'MA', county: 'Suffolk County' },
  { state: 'MA', county: 'Worcester County' },
  { state: 'MI', county: 'Alcona County' },
  { state: 'MI', county: 'Alger County' },
  { state: 'MI', county: 'Allegan County' },
  { state: 'MI', county: 'Alpena County' },
  { state: 'MI', county: 'Antrim County' },
  { state: 'MI', county: 'Arenac County' },
  { state: 'MI', county: 'Baraga County' },
  { state: 'MI', county: 'Barry County' },
  { state: 'MI', county: 'Bay County' },
  { state: 'MI', county: 'Benzie County' },
  { state: 'MI', county: 'Berrien County' },
  { state: 'MI', county: 'Branch County' },
  { state: 'MI', county: 'Calhoun County' },
  { state: 'MI', county: 'Cass County' },
  { state: 'MI', county: 'Charlevoix County' },
  { state: 'MI', county: 'Cheboygan County' },
  { state: 'MI', county: 'Chippewa County' },
  { state: 'MI', county: 'Clare County' },
  { state: 'MI', county: 'Clinton County' },
  { state: 'MI', county: 'Crawford County' },
  { state: 'MI', county: 'Delta County' },
  { state: 'MI', county: 'Dickinson County' },
  { state: 'MI', county: 'Eaton County' },
  { state: 'MI', county: 'Emmet County' },
  { state: 'MI', county: 'Genesee County' },
  { state: 'MI', county: 'Gladwin County' },
  { state: 'MI', county: 'Gogebic County' },
  { state: 'MI', county: 'Grand Traverse County' },
  { state: 'MI', county: 'Gratiot County' },
  { state: 'MI', county: 'Hillsdale County' },
  { state: 'MI', county: 'Houghton County' },
  { state: 'MI', county: 'Huron County' },
  { state: 'MI', county: 'Ingham County' },
  { state: 'MI', county: 'Ionia County' },
  { state: 'MI', county: 'Iosco County' },
  { state: 'MI', county: 'Iron County' },
  { state: 'MI', county: 'Isabella County' },
  { state: 'MI', county: 'Jackson County' },
  { state: 'MI', county: 'Kalamazoo County' },
  { state: 'MI', county: 'Kalkaska County' },
  { state: 'MI', county: 'Kent County' },
  { state: 'MI', county: 'Keweenaw County' },
  { state: 'MI', county: 'Lake County' },
  { state: 'MI', county: 'Lapeer County' },
  { state: 'MI', county: 'Leelanau County' },
  { state: 'MI', county: 'Lenawee County' },
  { state: 'MI', county: 'Livingston County' },
  { state: 'MI', county: 'Luce County' },
  { state: 'MI', county: 'Mackinac County' },
  { state: 'MI', county: 'Macomb County' },
  { state: 'MI', county: 'Manistee County' },
  { state: 'MI', county: 'Marquette County' },
  { state: 'MI', county: 'Mason County' },
  { state: 'MI', county: 'Mecosta County' },
  { state: 'MI', county: 'Menominee County' },
  { state: 'MI', county: 'Midland County' },
  { state: 'MI', county: 'Missaukee County' },
  { state: 'MI', county: 'Monroe County' },
  { state: 'MI', county: 'Montcalm County' },
  { state: 'MI', county: 'Montmorency County' },
  { state: 'MI', county: 'Muskegon County' },
  { state: 'MI', county: 'Newaygo County' },
  { state: 'MI', county: 'Oakland County' },
  { state: 'MI', county: 'Oceana County' },
  { state: 'MI', county: 'Ogemaw County' },
  { state: 'MI', county: 'Ontonagon County' },
  { state: 'MI', county: 'Osceola County' },
  { state: 'MI', county: 'Oscoda County' },
  { state: 'MI', county: 'Otsego County' },
  { state: 'MI', county: 'Ottawa County' },
  { state: 'MI', county: 'Presque Isle County' },
  { state: 'MI', county: 'Roscommon County' },
  { state: 'MI', county: 'Saginaw County' },
  { state: 'MI', county: 'St. Clair County' },
  { state: 'MI', county: 'St. Joseph County' },
  { state: 'MI', county: 'Sanilac County' },
  { state: 'MI', county: 'Schoolcraft County' },
  { state: 'MI', county: 'Shiawassee County' },
  { state: 'MI', county: 'Tuscola County' },
  { state: 'MI', county: 'Van Buren County' },
  { state: 'MI', county: 'Washtenaw County' },
  { state: 'MI', county: 'Wayne County' },
  { state: 'MI', county: 'Wexford County' },
  { state: 'MN', county: 'Aitkin County' },
  { state: 'MN', county: 'Anoka County' },
  { state: 'MN', county: 'Becker County' },
  { state: 'MN', county: 'Beltrami County' },
  { state: 'MN', county: 'Benton County' },
  { state: 'MN', county: 'Big Stone County' },
  { state: 'MN', county: 'Blue Earth County' },
  { state: 'MN', county: 'Brown County' },
  { state: 'MN', county: 'Carlton County' },
  { state: 'MN', county: 'Carver County' },
  { state: 'MN', county: 'Cass County' },
  { state: 'MN', county: 'Chippewa County' },
  { state: 'MN', county: 'Chisago County' },
  { state: 'MN', county: 'Clay County' },
  { state: 'MN', county: 'Clearwater County' },
  { state: 'MN', county: 'Cook County' },
  { state: 'MN', county: 'Cottonwood County' },
  { state: 'MN', county: 'Crow Wing County' },
  { state: 'MN', county: 'Dakota County' },
  { state: 'MN', county: 'Dodge County' },
  { state: 'MN', county: 'Douglas County' },
  { state: 'MN', county: 'Faribault County' },
  { state: 'MN', county: 'Fillmore County' },
  { state: 'MN', county: 'Freeborn County' },
  { state: 'MN', county: 'Goodhue County' },
  { state: 'MN', county: 'Grant County' },
  { state: 'MN', county: 'Hennepin County' },
  { state: 'MN', county: 'Houston County' },
  { state: 'MN', county: 'Hubbard County' },
  { state: 'MN', county: 'Isanti County' },
  { state: 'MN', county: 'Itasca County' },
  { state: 'MN', county: 'Jackson County' },
  { state: 'MN', county: 'Kanabec County' },
  { state: 'MN', county: 'Kandiyohi County' },
  { state: 'MN', county: 'Kittson County' },
  { state: 'MN', county: 'Koochiching County' },
  { state: 'MN', county: 'Lac qui Parle County' },
  { state: 'MN', county: 'Lake County' },
  { state: 'MN', county: 'Lake of the Woods County' },
  { state: 'MN', county: 'Le Sueur County' },
  { state: 'MN', county: 'Lincoln County' },
  { state: 'MN', county: 'Lyon County' },
  { state: 'MN', county: 'McLeod County' },
  { state: 'MN', county: 'Mahnomen County' },
  { state: 'MN', county: 'Marshall County' },
  { state: 'MN', county: 'Martin County' },
  { state: 'MN', county: 'Meeker County' },
  { state: 'MN', county: 'Mille Lacs County' },
  { state: 'MN', county: 'Morrison County' },
  { state: 'MN', county: 'Mower County' },
  { state: 'MN', county: 'Murray County' },
  { state: 'MN', county: 'Nicollet County' },
  { state: 'MN', county: 'Nobles County' },
  { state: 'MN', county: 'Norman County' },
  { state: 'MN', county: 'Olmsted County' },
  { state: 'MN', county: 'Otter Tail County' },
  { state: 'MN', county: 'Pennington County' },
  { state: 'MN', county: 'Pine County' },
  { state: 'MN', county: 'Pipestone County' },
  { state: 'MN', county: 'Polk County' },
  { state: 'MN', county: 'Pope County' },
  { state: 'MN', county: 'Ramsey County' },
  { state: 'MN', county: 'Red Lake County' },
  { state: 'MN', county: 'Redwood County' },
  { state: 'MN', county: 'Renville County' },
  { state: 'MN', county: 'Rice County' },
  { state: 'MN', county: 'Rock County' },
  { state: 'MN', county: 'Roseau County' },
  { state: 'MN', county: 'St. Louis County' },
  { state: 'MN', county: 'Scott County' },
  { state: 'MN', county: 'Sherburne County' },
  { state: 'MN', county: 'Sibley County' },
  { state: 'MN', county: 'Stearns County' },
  { state: 'MN', county: 'Steele County' },
  { state: 'MN', county: 'Stevens County' },
  { state: 'MN', county: 'Swift County' },
  { state: 'MN', county: 'Todd County' },
  { state: 'MN', county: 'Traverse County' },
  { state: 'MN', county: 'Wabasha County' },
  { state: 'MN', county: 'Wadena County' },
  { state: 'MN', county: 'Waseca County' },
  { state: 'MN', county: 'Washington County' },
  { state: 'MN', county: 'Watonwan County' },
  { state: 'MN', county: 'Wilkin County' },
  { state: 'MN', county: 'Winona County' },
  { state: 'MN', county: 'Wright County' },
  { state: 'MN', county: 'Yellow Medicine County' },
  { state: 'MS', county: 'Adams County' },
  { state: 'MS', county: 'Alcorn County' },
  { state: 'MS', county: 'Amite County' },
  { state: 'MS', county: 'Attala County' },
  { state: 'MS', county: 'Benton County' },
  { state: 'MS', county: 'Bolivar County' },
  { state: 'MS', county: 'Calhoun County' },
  { state: 'MS', county: 'Carroll County' },
  { state: 'MS', county: 'Chickasaw County' },
  { state: 'MS', county: 'Choctaw County' },
  { state: 'MS', county: 'Claiborne County' },
  { state: 'MS', county: 'Clarke County' },
  { state: 'MS', county: 'Clay County' },
  { state: 'MS', county: 'Coahoma County' },
  { state: 'MS', county: 'Copiah County' },
  { state: 'MS', county: 'Covington County' },
  { state: 'MS', county: 'DeSoto County' },
  { state: 'MS', county: 'Forrest County' },
  { state: 'MS', county: 'Franklin County' },
  { state: 'MS', county: 'George County' },
  { state: 'MS', county: 'Greene County' },
  { state: 'MS', county: 'Grenada County' },
  { state: 'MS', county: 'Hancock County' },
  { state: 'MS', county: 'Harrison County' },
  { state: 'MS', county: 'Hinds County' },
  { state: 'MS', county: 'Holmes County' },
  { state: 'MS', county: 'Humphreys County' },
  { state: 'MS', county: 'Issaquena County' },
  { state: 'MS', county: 'Itawamba County' },
  { state: 'MS', county: 'Jackson County' },
  { state: 'MS', county: 'Jasper County' },
  { state: 'MS', county: 'Jefferson County' },
  { state: 'MS', county: 'Jefferson Davis County' },
  { state: 'MS', county: 'Jones County' },
  { state: 'MS', county: 'Kemper County' },
  { state: 'MS', county: 'Lafayette County' },
  { state: 'MS', county: 'Lamar County' },
  { state: 'MS', county: 'Lauderdale County' },
  { state: 'MS', county: 'Lawrence County' },
  { state: 'MS', county: 'Leake County' },
  { state: 'MS', county: 'Lee County' },
  { state: 'MS', county: 'Leflore County' },
  { state: 'MS', county: 'Lincoln County' },
  { state: 'MS', county: 'Lowndes County' },
  { state: 'MS', county: 'Madison County' },
  { state: 'MS', county: 'Marion County' },
  { state: 'MS', county: 'Marshall County' },
  { state: 'MS', county: 'Monroe County' },
  { state: 'MS', county: 'Montgomery County' },
  { state: 'MS', county: 'Neshoba County' },
  { state: 'MS', county: 'Newton County' },
  { state: 'MS', county: 'Noxubee County' },
  { state: 'MS', county: 'Oktibbeha County' },
  { state: 'MS', county: 'Panola County' },
  { state: 'MS', county: 'Pearl River County' },
  { state: 'MS', county: 'Perry County' },
  { state: 'MS', county: 'Pike County' },
  { state: 'MS', county: 'Pontotoc County' },
  { state: 'MS', county: 'Prentiss County' },
  { state: 'MS', county: 'Quitman County' },
  { state: 'MS', county: 'Rankin County' },
  { state: 'MS', county: 'Scott County' },
  { state: 'MS', county: 'Sharkey County' },
  { state: 'MS', county: 'Simpson County' },
  { state: 'MS', county: 'Smith County' },
  { state: 'MS', county: 'Stone County' },
  { state: 'MS', county: 'Sunflower County' },
  { state: 'MS', county: 'Tallahatchie County' },
  { state: 'MS', county: 'Tate County' },
  { state: 'MS', county: 'Tippah County' },
  { state: 'MS', county: 'Tishomingo County' },
  { state: 'MS', county: 'Tunica County' },
  { state: 'MS', county: 'Union County' },
  { state: 'MS', county: 'Walthall County' },
  { state: 'MS', county: 'Warren County' },
  { state: 'MS', county: 'Washington County' },
  { state: 'MS', county: 'Wayne County' },
  { state: 'MS', county: 'Webster County' },
  { state: 'MS', county: 'Wilkinson County' },
  { state: 'MS', county: 'Winston County' },
  { state: 'MS', county: 'Yalobusha County' },
  { state: 'MS', county: 'Yazoo County' },
  { state: 'MO', county: 'Adair County' },
  { state: 'MO', county: 'Andrew County' },
  { state: 'MO', county: 'Atchison County' },
  { state: 'MO', county: 'Audrain County' },
  { state: 'MO', county: 'Barry County' },
  { state: 'MO', county: 'Barton County' },
  { state: 'MO', county: 'Bates County' },
  { state: 'MO', county: 'Benton County' },
  { state: 'MO', county: 'Bollinger County' },
  { state: 'MO', county: 'Boone County' },
  { state: 'MO', county: 'Buchanan County' },
  { state: 'MO', county: 'Butler County' },
  { state: 'MO', county: 'Caldwell County' },
  { state: 'MO', county: 'Callaway County' },
  { state: 'MO', county: 'Camden County' },
  { state: 'MO', county: 'Cape Girardeau County' },
  { state: 'MO', county: 'Carroll County' },
  { state: 'MO', county: 'Carter County' },
  { state: 'MO', county: 'Cass County' },
  { state: 'MO', county: 'Cedar County' },
  { state: 'MO', county: 'Chariton County' },
  { state: 'MO', county: 'Christian County' },
  { state: 'MO', county: 'Clark County' },
  { state: 'MO', county: 'Clay County' },
  { state: 'MO', county: 'Clinton County' },
  { state: 'MO', county: 'Cole County' },
  { state: 'MO', county: 'Cooper County' },
  { state: 'MO', county: 'Crawford County' },
  { state: 'MO', county: 'Dade County' },
  { state: 'MO', county: 'Dallas County' },
  { state: 'MO', county: 'Daviess County' },
  { state: 'MO', county: 'DeKalb County' },
  { state: 'MO', county: 'Dent County' },
  { state: 'MO', county: 'Douglas County' },
  { state: 'MO', county: 'Dunklin County' },
  { state: 'MO', county: 'Franklin County' },
  { state: 'MO', county: 'Gasconade County' },
  { state: 'MO', county: 'Gentry County' },
  { state: 'MO', county: 'Greene County' },
  { state: 'MO', county: 'Grundy County' },
  { state: 'MO', county: 'Harrison County' },
  { state: 'MO', county: 'Henry County' },
  { state: 'MO', county: 'Hickory County' },
  { state: 'MO', county: 'Holt County' },
  { state: 'MO', county: 'Howard County' },
  { state: 'MO', county: 'Howell County' },
  { state: 'MO', county: 'Iron County' },
  { state: 'MO', county: 'Jackson County' },
  { state: 'MO', county: 'Jasper County' },
  { state: 'MO', county: 'Jefferson County' },
  { state: 'MO', county: 'Johnson County' },
  { state: 'MO', county: 'Knox County' },
  { state: 'MO', county: 'Laclede County' },
  { state: 'MO', county: 'Lafayette County' },
  { state: 'MO', county: 'Lawrence County' },
  { state: 'MO', county: 'Lewis County' },
  { state: 'MO', county: 'Lincoln County' },
  { state: 'MO', county: 'Linn County' },
  { state: 'MO', county: 'Livingston County' },
  { state: 'MO', county: 'McDonald County' },
  { state: 'MO', county: 'Macon County' },
  { state: 'MO', county: 'Madison County' },
  { state: 'MO', county: 'Maries County' },
  { state: 'MO', county: 'Marion County' },
  { state: 'MO', county: 'Mercer County' },
  { state: 'MO', county: 'Miller County' },
  { state: 'MO', county: 'Mississippi County' },
  { state: 'MO', county: 'Moniteau County' },
  { state: 'MO', county: 'Monroe County' },
  { state: 'MO', county: 'Montgomery County' },
  { state: 'MO', county: 'Morgan County' },
  { state: 'MO', county: 'New Madrid County' },
  { state: 'MO', county: 'Newton County' },
  { state: 'MO', county: 'Nodaway County' },
  { state: 'MO', county: 'Oregon County' },
  { state: 'MO', county: 'Osage County' },
  { state: 'MO', county: 'Ozark County' },
  { state: 'MO', county: 'Pemiscot County' },
  { state: 'MO', county: 'Perry County' },
  { state: 'MO', county: 'Pettis County' },
  { state: 'MO', county: 'Phelps County' },
  { state: 'MO', county: 'Pike County' },
  { state: 'MO', county: 'Platte County' },
  { state: 'MO', county: 'Polk County' },
  { state: 'MO', county: 'Pulaski County' },
  { state: 'MO', county: 'Putnam County' },
  { state: 'MO', county: 'Ralls County' },
  { state: 'MO', county: 'Randolph County' },
  { state: 'MO', county: 'Ray County' },
  { state: 'MO', county: 'Reynolds County' },
  { state: 'MO', county: 'Ripley County' },
  { state: 'MO', county: 'St. Charles County' },
  { state: 'MO', county: 'St. Clair County' },
  { state: 'MO', county: 'Ste. Genevieve County' },
  { state: 'MO', county: 'St. Francois County' },
  { state: 'MO', county: 'St. Louis County' },
  { state: 'MO', county: 'Saline County' },
  { state: 'MO', county: 'Schuyler County' },
  { state: 'MO', county: 'Scotland County' },
  { state: 'MO', county: 'Scott County' },
  { state: 'MO', county: 'Shannon County' },
  { state: 'MO', county: 'Shelby County' },
  { state: 'MO', county: 'Stoddard County' },
  { state: 'MO', county: 'Stone County' },
  { state: 'MO', county: 'Sullivan County' },
  { state: 'MO', county: 'Taney County' },
  { state: 'MO', county: 'Texas County' },
  { state: 'MO', county: 'Vernon County' },
  { state: 'MO', county: 'Warren County' },
  { state: 'MO', county: 'Washington County' },
  { state: 'MO', county: 'Wayne County' },
  { state: 'MO', county: 'Webster County' },
  { state: 'MO', county: 'Worth County' },
  { state: 'MO', county: 'Wright County' },
  { state: 'MO', county: 'St. Louis city' },
  { state: 'MT', county: 'Beaverhead County' },
  { state: 'MT', county: 'Big Horn County' },
  { state: 'MT', county: 'Blaine County' },
  { state: 'MT', county: 'Broadwater County' },
  { state: 'MT', county: 'Carbon County' },
  { state: 'MT', county: 'Carter County' },
  { state: 'MT', county: 'Cascade County' },
  { state: 'MT', county: 'Chouteau County' },
  { state: 'MT', county: 'Custer County' },
  { state: 'MT', county: 'Daniels County' },
  { state: 'MT', county: 'Dawson County' },
  { state: 'MT', county: 'Deer Lodge County' },
  { state: 'MT', county: 'Fallon County' },
  { state: 'MT', county: 'Fergus County' },
  { state: 'MT', county: 'Flathead County' },
  { state: 'MT', county: 'Gallatin County' },
  { state: 'MT', county: 'Garfield County' },
  { state: 'MT', county: 'Glacier County' },
  { state: 'MT', county: 'Golden Valley County' },
  { state: 'MT', county: 'Granite County' },
  { state: 'MT', county: 'Hill County' },
  { state: 'MT', county: 'Jefferson County' },
  { state: 'MT', county: 'Judith Basin County' },
  { state: 'MT', county: 'Lake County' },
  { state: 'MT', county: 'Lewis and Clark County' },
  { state: 'MT', county: 'Liberty County' },
  { state: 'MT', county: 'Lincoln County' },
  { state: 'MT', county: 'McCone County' },
  { state: 'MT', county: 'Madison County' },
  { state: 'MT', county: 'Meagher County' },
  { state: 'MT', county: 'Mineral County' },
  { state: 'MT', county: 'Missoula County' },
  { state: 'MT', county: 'Musselshell County' },
  { state: 'MT', county: 'Park County' },
  { state: 'MT', county: 'Petroleum County' },
  { state: 'MT', county: 'Phillips County' },
  { state: 'MT', county: 'Pondera County' },
  { state: 'MT', county: 'Powder River County' },
  { state: 'MT', county: 'Powell County' },
  { state: 'MT', county: 'Prairie County' },
  { state: 'MT', county: 'Ravalli County' },
  { state: 'MT', county: 'Richland County' },
  { state: 'MT', county: 'Roosevelt County' },
  { state: 'MT', county: 'Rosebud County' },
  { state: 'MT', county: 'Sanders County' },
  { state: 'MT', county: 'Sheridan County' },
  { state: 'MT', county: 'Silver Bow County' },
  { state: 'MT', county: 'Stillwater County' },
  { state: 'MT', county: 'Sweet Grass County' },
  { state: 'MT', county: 'Teton County' },
  { state: 'MT', county: 'Toole County' },
  { state: 'MT', county: 'Treasure County' },
  { state: 'MT', county: 'Valley County' },
  { state: 'MT', county: 'Wheatland County' },
  { state: 'MT', county: 'Wibaux County' },
  { state: 'MT', county: 'Yellowstone County' },
  { state: 'NE', county: 'Adams County' },
  { state: 'NE', county: 'Antelope County' },
  { state: 'NE', county: 'Arthur County' },
  { state: 'NE', county: 'Banner County' },
  { state: 'NE', county: 'Blaine County' },
  { state: 'NE', county: 'Boone County' },
  { state: 'NE', county: 'Box Butte County' },
  { state: 'NE', county: 'Boyd County' },
  { state: 'NE', county: 'Brown County' },
  { state: 'NE', county: 'Buffalo County' },
  { state: 'NE', county: 'Burt County' },
  { state: 'NE', county: 'Butler County' },
  { state: 'NE', county: 'Cass County' },
  { state: 'NE', county: 'Cedar County' },
  { state: 'NE', county: 'Chase County' },
  { state: 'NE', county: 'Cherry County' },
  { state: 'NE', county: 'Cheyenne County' },
  { state: 'NE', county: 'Clay County' },
  { state: 'NE', county: 'Colfax County' },
  { state: 'NE', county: 'Cuming County' },
  { state: 'NE', county: 'Custer County' },
  { state: 'NE', county: 'Dakota County' },
  { state: 'NE', county: 'Dawes County' },
  { state: 'NE', county: 'Dawson County' },
  { state: 'NE', county: 'Deuel County' },
  { state: 'NE', county: 'Dixon County' },
  { state: 'NE', county: 'Dodge County' },
  { state: 'NE', county: 'Douglas County' },
  { state: 'NE', county: 'Dundy County' },
  { state: 'NE', county: 'Fillmore County' },
  { state: 'NE', county: 'Franklin County' },
  { state: 'NE', county: 'Frontier County' },
  { state: 'NE', county: 'Furnas County' },
  { state: 'NE', county: 'Gage County' },
  { state: 'NE', county: 'Garden County' },
  { state: 'NE', county: 'Garfield County' },
  { state: 'NE', county: 'Gosper County' },
  { state: 'NE', county: 'Grant County' },
  { state: 'NE', county: 'Greeley County' },
  { state: 'NE', county: 'Hall County' },
  { state: 'NE', county: 'Hamilton County' },
  { state: 'NE', county: 'Harlan County' },
  { state: 'NE', county: 'Hayes County' },
  { state: 'NE', county: 'Hitchcock County' },
  { state: 'NE', county: 'Holt County' },
  { state: 'NE', county: 'Hooker County' },
  { state: 'NE', county: 'Howard County' },
  { state: 'NE', county: 'Jefferson County' },
  { state: 'NE', county: 'Johnson County' },
  { state: 'NE', county: 'Kearney County' },
  { state: 'NE', county: 'Keith County' },
  { state: 'NE', county: 'Keya Paha County' },
  { state: 'NE', county: 'Kimball County' },
  { state: 'NE', county: 'Knox County' },
  { state: 'NE', county: 'Lancaster County' },
  { state: 'NE', county: 'Lincoln County' },
  { state: 'NE', county: 'Logan County' },
  { state: 'NE', county: 'Loup County' },
  { state: 'NE', county: 'McPherson County' },
  { state: 'NE', county: 'Madison County' },
  { state: 'NE', county: 'Merrick County' },
  { state: 'NE', county: 'Morrill County' },
  { state: 'NE', county: 'Nance County' },
  { state: 'NE', county: 'Nemaha County' },
  { state: 'NE', county: 'Nuckolls County' },
  { state: 'NE', county: 'Otoe County' },
  { state: 'NE', county: 'Pawnee County' },
  { state: 'NE', county: 'Perkins County' },
  { state: 'NE', county: 'Phelps County' },
  { state: 'NE', county: 'Pierce County' },
  { state: 'NE', county: 'Platte County' },
  { state: 'NE', county: 'Polk County' },
  { state: 'NE', county: 'Red Willow County' },
  { state: 'NE', county: 'Richardson County' },
  { state: 'NE', county: 'Rock County' },
  { state: 'NE', county: 'Saline County' },
  { state: 'NE', county: 'Sarpy County' },
  { state: 'NE', county: 'Saunders County' },
  { state: 'NE', county: 'Scotts Bluff County' },
  { state: 'NE', county: 'Seward County' },
  { state: 'NE', county: 'Sheridan County' },
  { state: 'NE', county: 'Sherman County' },
  { state: 'NE', county: 'Sioux County' },
  { state: 'NE', county: 'Stanton County' },
  { state: 'NE', county: 'Thayer County' },
  { state: 'NE', county: 'Thomas County' },
  { state: 'NE', county: 'Thurston County' },
  { state: 'NE', county: 'Valley County' },
  { state: 'NE', county: 'Washington County' },
  { state: 'NE', county: 'Wayne County' },
  { state: 'NE', county: 'Webster County' },
  { state: 'NE', county: 'Wheeler County' },
  { state: 'NE', county: 'York County' },
  { state: 'NV', county: 'Churchill County' },
  { state: 'NV', county: 'Clark County' },
  { state: 'NV', county: 'Douglas County' },
  { state: 'NV', county: 'Elko County' },
  { state: 'NV', county: 'Esmeralda County' },
  { state: 'NV', county: 'Eureka County' },
  { state: 'NV', county: 'Humboldt County' },
  { state: 'NV', county: 'Lander County' },
  { state: 'NV', county: 'Lincoln County' },
  { state: 'NV', county: 'Lyon County' },
  { state: 'NV', county: 'Mineral County' },
  { state: 'NV', county: 'Nye County' },
  { state: 'NV', county: 'Pershing County' },
  { state: 'NV', county: 'Storey County' },
  { state: 'NV', county: 'Washoe County' },
  { state: 'NV', county: 'White Pine County' },
  { state: 'NV', county: 'Carson City' },
  { state: 'NH', county: 'Belknap County' },
  { state: 'NH', county: 'Carroll County' },
  { state: 'NH', county: 'Cheshire County' },
  { state: 'NH', county: 'Coos County' },
  { state: 'NH', county: 'Grafton County' },
  { state: 'NH', county: 'Hillsborough County' },
  { state: 'NH', county: 'Merrimack County' },
  { state: 'NH', county: 'Rockingham County' },
  { state: 'NH', county: 'Strafford County' },
  { state: 'NH', county: 'Sullivan County' },
  { state: 'NJ', county: 'Atlantic County' },
  { state: 'NJ', county: 'Bergen County' },
  { state: 'NJ', county: 'Burlington County' },
  { state: 'NJ', county: 'Camden County' },
  { state: 'NJ', county: 'Cape May County' },
  { state: 'NJ', county: 'Cumberland County' },
  { state: 'NJ', county: 'Essex County' },
  { state: 'NJ', county: 'Gloucester County' },
  { state: 'NJ', county: 'Hudson County' },
  { state: 'NJ', county: 'Hunterdon County' },
  { state: 'NJ', county: 'Mercer County' },
  { state: 'NJ', county: 'Middlesex County' },
  { state: 'NJ', county: 'Monmouth County' },
  { state: 'NJ', county: 'Morris County' },
  { state: 'NJ', county: 'Ocean County' },
  { state: 'NJ', county: 'Passaic County' },
  { state: 'NJ', county: 'Salem County' },
  { state: 'NJ', county: 'Somerset County' },
  { state: 'NJ', county: 'Sussex County' },
  { state: 'NJ', county: 'Union County' },
  { state: 'NJ', county: 'Warren County' },
  { state: 'NM', county: 'Bernalillo County' },
  { state: 'NM', county: 'Catron County' },
  { state: 'NM', county: 'Chaves County' },
  { state: 'NM', county: 'Cibola County' },
  { state: 'NM', county: 'Colfax County' },
  { state: 'NM', county: 'Curry County' },
  { state: 'NM', county: 'De Baca County' },
  { state: 'NM', county: 'Dona Ana County' },
  { state: 'NM', county: 'Eddy County' },
  { state: 'NM', county: 'Grant County' },
  { state: 'NM', county: 'Guadalupe County' },
  { state: 'NM', county: 'Harding County' },
  { state: 'NM', county: 'Hidalgo County' },
  { state: 'NM', county: 'Lea County' },
  { state: 'NM', county: 'Lincoln County' },
  { state: 'NM', county: 'Los Alamos County' },
  { state: 'NM', county: 'Luna County' },
  { state: 'NM', county: 'McKinley County' },
  { state: 'NM', county: 'Mora County' },
  { state: 'NM', county: 'Otero County' },
  { state: 'NM', county: 'Quay County' },
  { state: 'NM', county: 'Rio Arriba County' },
  { state: 'NM', county: 'Roosevelt County' },
  { state: 'NM', county: 'Sandoval County' },
  { state: 'NM', county: 'San Juan County' },
  { state: 'NM', county: 'San Miguel County' },
  { state: 'NM', county: 'Santa Fe County' },
  { state: 'NM', county: 'Sierra County' },
  { state: 'NM', county: 'Socorro County' },
  { state: 'NM', county: 'Taos County' },
  { state: 'NM', county: 'Torrance County' },
  { state: 'NM', county: 'Union County' },
  { state: 'NM', county: 'Valencia County' },
  { state: 'NY', county: 'Albany County' },
  { state: 'NY', county: 'Allegany County' },
  { state: 'NY', county: 'Bronx County' },
  { state: 'NY', county: 'Broome County' },
  { state: 'NY', county: 'Cattaraugus County' },
  { state: 'NY', county: 'Cayuga County' },
  { state: 'NY', county: 'Chautauqua County' },
  { state: 'NY', county: 'Chemung County' },
  { state: 'NY', county: 'Chenango County' },
  { state: 'NY', county: 'Clinton County' },
  { state: 'NY', county: 'Columbia County' },
  { state: 'NY', county: 'Cortland County' },
  { state: 'NY', county: 'Delaware County' },
  { state: 'NY', county: 'Dutchess County' },
  { state: 'NY', county: 'Erie County' },
  { state: 'NY', county: 'Essex County' },
  { state: 'NY', county: 'Franklin County' },
  { state: 'NY', county: 'Fulton County' },
  { state: 'NY', county: 'Genesee County' },
  { state: 'NY', county: 'Greene County' },
  { state: 'NY', county: 'Hamilton County' },
  { state: 'NY', county: 'Herkimer County' },
  { state: 'NY', county: 'Jefferson County' },
  { state: 'NY', county: 'Kings County' },
  { state: 'NY', county: 'Lewis County' },
  { state: 'NY', county: 'Livingston County' },
  { state: 'NY', county: 'Madison County' },
  { state: 'NY', county: 'Monroe County' },
  { state: 'NY', county: 'Montgomery County' },
  { state: 'NY', county: 'Nassau County' },
  { state: 'NY', county: 'New York County' },
  { state: 'NY', county: 'Niagara County' },
  { state: 'NY', county: 'Oneida County' },
  { state: 'NY', county: 'Onondaga County' },
  { state: 'NY', county: 'Ontario County' },
  { state: 'NY', county: 'Orange County' },
  { state: 'NY', county: 'Orleans County' },
  { state: 'NY', county: 'Oswego County' },
  { state: 'NY', county: 'Otsego County' },
  { state: 'NY', county: 'Putnam County' },
  { state: 'NY', county: 'Queens County' },
  { state: 'NY', county: 'Rensselaer County' },
  { state: 'NY', county: 'Richmond County' },
  { state: 'NY', county: 'Rockland County' },
  { state: 'NY', county: 'St. Lawrence County' },
  { state: 'NY', county: 'Saratoga County' },
  { state: 'NY', county: 'Schenectady County' },
  { state: 'NY', county: 'Schoharie County' },
  { state: 'NY', county: 'Schuyler County' },
  { state: 'NY', county: 'Seneca County' },
  { state: 'NY', county: 'Steuben County' },
  { state: 'NY', county: 'Suffolk County' },
  { state: 'NY', county: 'Sullivan County' },
  { state: 'NY', county: 'Tioga County' },
  { state: 'NY', county: 'Tompkins County' },
  { state: 'NY', county: 'Ulster County' },
  { state: 'NY', county: 'Warren County' },
  { state: 'NY', county: 'Washington County' },
  { state: 'NY', county: 'Wayne County' },
  { state: 'NY', county: 'Westchester County' },
  { state: 'NY', county: 'Wyoming County' },
  { state: 'NY', county: 'Yates County' },
  { state: 'NC', county: 'Alamance County' },
  { state: 'NC', county: 'Alexander County' },
  { state: 'NC', county: 'Alleghany County' },
  { state: 'NC', county: 'Anson County' },
  { state: 'NC', county: 'Ashe County' },
  { state: 'NC', county: 'Avery County' },
  { state: 'NC', county: 'Beaufort County' },
  { state: 'NC', county: 'Bertie County' },
  { state: 'NC', county: 'Bladen County' },
  { state: 'NC', county: 'Brunswick County' },
  { state: 'NC', county: 'Buncombe County' },
  { state: 'NC', county: 'Burke County' },
  { state: 'NC', county: 'Cabarrus County' },
  { state: 'NC', county: 'Caldwell County' },
  { state: 'NC', county: 'Camden County' },
  { state: 'NC', county: 'Carteret County' },
  { state: 'NC', county: 'Caswell County' },
  { state: 'NC', county: 'Catawba County' },
  { state: 'NC', county: 'Chatham County' },
  { state: 'NC', county: 'Cherokee County' },
  { state: 'NC', county: 'Chowan County' },
  { state: 'NC', county: 'Clay County' },
  { state: 'NC', county: 'Cleveland County' },
  { state: 'NC', county: 'Columbus County' },
  { state: 'NC', county: 'Craven County' },
  { state: 'NC', county: 'Cumberland County' },
  { state: 'NC', county: 'Currituck County' },
  { state: 'NC', county: 'Dare County' },
  { state: 'NC', county: 'Davidson County' },
  { state: 'NC', county: 'Davie County' },
  { state: 'NC', county: 'Duplin County' },
  { state: 'NC', county: 'Durham County' },
  { state: 'NC', county: 'Edgecombe County' },
  { state: 'NC', county: 'Forsyth County' },
  { state: 'NC', county: 'Franklin County' },
  { state: 'NC', county: 'Gaston County' },
  { state: 'NC', county: 'Gates County' },
  { state: 'NC', county: 'Graham County' },
  { state: 'NC', county: 'Granville County' },
  { state: 'NC', county: 'Greene County' },
  { state: 'NC', county: 'Guilford County' },
  { state: 'NC', county: 'Halifax County' },
  { state: 'NC', county: 'Harnett County' },
  { state: 'NC', county: 'Haywood County' },
  { state: 'NC', county: 'Henderson County' },
  { state: 'NC', county: 'Hertford County' },
  { state: 'NC', county: 'Hoke County' },
  { state: 'NC', county: 'Hyde County' },
  { state: 'NC', county: 'Iredell County' },
  { state: 'NC', county: 'Jackson County' },
  { state: 'NC', county: 'Johnston County' },
  { state: 'NC', county: 'Jones County' },
  { state: 'NC', county: 'Lee County' },
  { state: 'NC', county: 'Lenoir County' },
  { state: 'NC', county: 'Lincoln County' },
  { state: 'NC', county: 'McDowell County' },
  { state: 'NC', county: 'Macon County' },
  { state: 'NC', county: 'Madison County' },
  { state: 'NC', county: 'Martin County' },
  { state: 'NC', county: 'Mecklenburg County' },
  { state: 'NC', county: 'Mitchell County' },
  { state: 'NC', county: 'Montgomery County' },
  { state: 'NC', county: 'Moore County' },
  { state: 'NC', county: 'Nash County' },
  { state: 'NC', county: 'New Hanover County' },
  { state: 'NC', county: 'Northampton County' },
  { state: 'NC', county: 'Onslow County' },
  { state: 'NC', county: 'Orange County' },
  { state: 'NC', county: 'Pamlico County' },
  { state: 'NC', county: 'Pasquotank County' },
  { state: 'NC', county: 'Pender County' },
  { state: 'NC', county: 'Perquimans County' },
  { state: 'NC', county: 'Person County' },
  { state: 'NC', county: 'Pitt County' },
  { state: 'NC', county: 'Polk County' },
  { state: 'NC', county: 'Randolph County' },
  { state: 'NC', county: 'Richmond County' },
  { state: 'NC', county: 'Robeson County' },
  { state: 'NC', county: 'Rockingham County' },
  { state: 'NC', county: 'Rowan County' },
  { state: 'NC', county: 'Rutherford County' },
  { state: 'NC', county: 'Sampson County' },
  { state: 'NC', county: 'Scotland County' },
  { state: 'NC', county: 'Stanly County' },
  { state: 'NC', county: 'Stokes County' },
  { state: 'NC', county: 'Surry County' },
  { state: 'NC', county: 'Swain County' },
  { state: 'NC', county: 'Transylvania County' },
  { state: 'NC', county: 'Tyrrell County' },
  { state: 'NC', county: 'Union County' },
  { state: 'NC', county: 'Vance County' },
  { state: 'NC', county: 'Wake County' },
  { state: 'NC', county: 'Warren County' },
  { state: 'NC', county: 'Washington County' },
  { state: 'NC', county: 'Watauga County' },
  { state: 'NC', county: 'Wayne County' },
  { state: 'NC', county: 'Wilkes County' },
  { state: 'NC', county: 'Wilson County' },
  { state: 'NC', county: 'Yadkin County' },
  { state: 'NC', county: 'Yancey County' },
  { state: 'ND', county: 'Adams County' },
  { state: 'ND', county: 'Barnes County' },
  { state: 'ND', county: 'Benson County' },
  { state: 'ND', county: 'Billings County' },
  { state: 'ND', county: 'Bottineau County' },
  { state: 'ND', county: 'Bowman County' },
  { state: 'ND', county: 'Burke County' },
  { state: 'ND', county: 'Burleigh County' },
  { state: 'ND', county: 'Cass County' },
  { state: 'ND', county: 'Cavalier County' },
  { state: 'ND', county: 'Dickey County' },
  { state: 'ND', county: 'Divide County' },
  { state: 'ND', county: 'Dunn County' },
  { state: 'ND', county: 'Eddy County' },
  { state: 'ND', county: 'Emmons County' },
  { state: 'ND', county: 'Foster County' },
  { state: 'ND', county: 'Golden Valley County' },
  { state: 'ND', county: 'Grand Forks County' },
  { state: 'ND', county: 'Grant County' },
  { state: 'ND', county: 'Griggs County' },
  { state: 'ND', county: 'Hettinger County' },
  { state: 'ND', county: 'Kidder County' },
  { state: 'ND', county: 'LaMoure County' },
  { state: 'ND', county: 'Logan County' },
  { state: 'ND', county: 'McHenry County' },
  { state: 'ND', county: 'McIntosh County' },
  { state: 'ND', county: 'McKenzie County' },
  { state: 'ND', county: 'McLean County' },
  { state: 'ND', county: 'Mercer County' },
  { state: 'ND', county: 'Morton County' },
  { state: 'ND', county: 'Mountrail County' },
  { state: 'ND', county: 'Nelson County' },
  { state: 'ND', county: 'Oliver County' },
  { state: 'ND', county: 'Pembina County' },
  { state: 'ND', county: 'Pierce County' },
  { state: 'ND', county: 'Ramsey County' },
  { state: 'ND', county: 'Ransom County' },
  { state: 'ND', county: 'Renville County' },
  { state: 'ND', county: 'Richland County' },
  { state: 'ND', county: 'Rolette County' },
  { state: 'ND', county: 'Sargent County' },
  { state: 'ND', county: 'Sheridan County' },
  { state: 'ND', county: 'Sioux County' },
  { state: 'ND', county: 'Slope County' },
  { state: 'ND', county: 'Stark County' },
  { state: 'ND', county: 'Steele County' },
  { state: 'ND', county: 'Stutsman County' },
  { state: 'ND', county: 'Towner County' },
  { state: 'ND', county: 'Traill County' },
  { state: 'ND', county: 'Walsh County' },
  { state: 'ND', county: 'Ward County' },
  { state: 'ND', county: 'Wells County' },
  { state: 'ND', county: 'Williams County' },
  { state: 'OH', county: 'Adams County' },
  { state: 'OH', county: 'Allen County' },
  { state: 'OH', county: 'Ashland County' },
  { state: 'OH', county: 'Ashtabula County' },
  { state: 'OH', county: 'Athens County' },
  { state: 'OH', county: 'Auglaize County' },
  { state: 'OH', county: 'Belmont County' },
  { state: 'OH', county: 'Brown County' },
  { state: 'OH', county: 'Butler County' },
  { state: 'OH', county: 'Carroll County' },
  { state: 'OH', county: 'Champaign County' },
  { state: 'OH', county: 'Clark County' },
  { state: 'OH', county: 'Clermont County' },
  { state: 'OH', county: 'Clinton County' },
  { state: 'OH', county: 'Columbiana County' },
  { state: 'OH', county: 'Coshocton County' },
  { state: 'OH', county: 'Crawford County' },
  { state: 'OH', county: 'Cuyahoga County' },
  { state: 'OH', county: 'Darke County' },
  { state: 'OH', county: 'Defiance County' },
  { state: 'OH', county: 'Delaware County' },
  { state: 'OH', county: 'Erie County' },
  { state: 'OH', county: 'Fairfield County' },
  { state: 'OH', county: 'Fayette County' },
  { state: 'OH', county: 'Franklin County' },
  { state: 'OH', county: 'Fulton County' },
  { state: 'OH', county: 'Gallia County' },
  { state: 'OH', county: 'Geauga County' },
  { state: 'OH', county: 'Greene County' },
  { state: 'OH', county: 'Guernsey County' },
  { state: 'OH', county: 'Hamilton County' },
  { state: 'OH', county: 'Hancock County' },
  { state: 'OH', county: 'Hardin County' },
  { state: 'OH', county: 'Harrison County' },
  { state: 'OH', county: 'Henry County' },
  { state: 'OH', county: 'Highland County' },
  { state: 'OH', county: 'Hocking County' },
  { state: 'OH', county: 'Holmes County' },
  { state: 'OH', county: 'Huron County' },
  { state: 'OH', county: 'Jackson County' },
  { state: 'OH', county: 'Jefferson County' },
  { state: 'OH', county: 'Knox County' },
  { state: 'OH', county: 'Lake County' },
  { state: 'OH', county: 'Lawrence County' },
  { state: 'OH', county: 'Licking County' },
  { state: 'OH', county: 'Logan County' },
  { state: 'OH', county: 'Lorain County' },
  { state: 'OH', county: 'Lucas County' },
  { state: 'OH', county: 'Madison County' },
  { state: 'OH', county: 'Mahoning County' },
  { state: 'OH', county: 'Marion County' },
  { state: 'OH', county: 'Medina County' },
  { state: 'OH', county: 'Meigs County' },
  { state: 'OH', county: 'Mercer County' },
  { state: 'OH', county: 'Miami County' },
  { state: 'OH', county: 'Monroe County' },
  { state: 'OH', county: 'Montgomery County' },
  { state: 'OH', county: 'Morgan County' },
  { state: 'OH', county: 'Morrow County' },
  { state: 'OH', county: 'Muskingum County' },
  { state: 'OH', county: 'Noble County' },
  { state: 'OH', county: 'Ottawa County' },
  { state: 'OH', county: 'Paulding County' },
  { state: 'OH', county: 'Perry County' },
  { state: 'OH', county: 'Pickaway County' },
  { state: 'OH', county: 'Pike County' },
  { state: 'OH', county: 'Portage County' },
  { state: 'OH', county: 'Preble County' },
  { state: 'OH', county: 'Putnam County' },
  { state: 'OH', county: 'Richland County' },
  { state: 'OH', county: 'Ross County' },
  { state: 'OH', county: 'Sandusky County' },
  { state: 'OH', county: 'Scioto County' },
  { state: 'OH', county: 'Seneca County' },
  { state: 'OH', county: 'Shelby County' },
  { state: 'OH', county: 'Stark County' },
  { state: 'OH', county: 'Summit County' },
  { state: 'OH', county: 'Trumbull County' },
  { state: 'OH', county: 'Tuscarawas County' },
  { state: 'OH', county: 'Union County' },
  { state: 'OH', county: 'Van Wert County' },
  { state: 'OH', county: 'Vinton County' },
  { state: 'OH', county: 'Warren County' },
  { state: 'OH', county: 'Washington County' },
  { state: 'OH', county: 'Wayne County' },
  { state: 'OH', county: 'Williams County' },
  { state: 'OH', county: 'Wood County' },
  { state: 'OH', county: 'Wyandot County' },
  { state: 'OK', county: 'Adair County' },
  { state: 'OK', county: 'Alfalfa County' },
  { state: 'OK', county: 'Atoka County' },
  { state: 'OK', county: 'Beaver County' },
  { state: 'OK', county: 'Beckham County' },
  { state: 'OK', county: 'Blaine County' },
  { state: 'OK', county: 'Bryan County' },
  { state: 'OK', county: 'Caddo County' },
  { state: 'OK', county: 'Canadian County' },
  { state: 'OK', county: 'Carter County' },
  { state: 'OK', county: 'Cherokee County' },
  { state: 'OK', county: 'Choctaw County' },
  { state: 'OK', county: 'Cimarron County' },
  { state: 'OK', county: 'Cleveland County' },
  { state: 'OK', county: 'Coal County' },
  { state: 'OK', county: 'Comanche County' },
  { state: 'OK', county: 'Cotton County' },
  { state: 'OK', county: 'Craig County' },
  { state: 'OK', county: 'Creek County' },
  { state: 'OK', county: 'Custer County' },
  { state: 'OK', county: 'Delaware County' },
  { state: 'OK', county: 'Dewey County' },
  { state: 'OK', county: 'Ellis County' },
  { state: 'OK', county: 'Garfield County' },
  { state: 'OK', county: 'Garvin County' },
  { state: 'OK', county: 'Grady County' },
  { state: 'OK', county: 'Grant County' },
  { state: 'OK', county: 'Greer County' },
  { state: 'OK', county: 'Harmon County' },
  { state: 'OK', county: 'Harper County' },
  { state: 'OK', county: 'Haskell County' },
  { state: 'OK', county: 'Hughes County' },
  { state: 'OK', county: 'Jackson County' },
  { state: 'OK', county: 'Jefferson County' },
  { state: 'OK', county: 'Johnston County' },
  { state: 'OK', county: 'Kay County' },
  { state: 'OK', county: 'Kingfisher County' },
  { state: 'OK', county: 'Kiowa County' },
  { state: 'OK', county: 'Latimer County' },
  { state: 'OK', county: 'Le Flore County' },
  { state: 'OK', county: 'Lincoln County' },
  { state: 'OK', county: 'Logan County' },
  { state: 'OK', county: 'Love County' },
  { state: 'OK', county: 'McClain County' },
  { state: 'OK', county: 'McCurtain County' },
  { state: 'OK', county: 'McIntosh County' },
  { state: 'OK', county: 'Major County' },
  { state: 'OK', county: 'Marshall County' },
  { state: 'OK', county: 'Mayes County' },
  { state: 'OK', county: 'Murray County' },
  { state: 'OK', county: 'Muskogee County' },
  { state: 'OK', county: 'Noble County' },
  { state: 'OK', county: 'Nowata County' },
  { state: 'OK', county: 'Okfuskee County' },
  { state: 'OK', county: 'Oklahoma County' },
  { state: 'OK', county: 'Okmulgee County' },
  { state: 'OK', county: 'Osage County' },
  { state: 'OK', county: 'Ottawa County' },
  { state: 'OK', county: 'Pawnee County' },
  { state: 'OK', county: 'Payne County' },
  { state: 'OK', county: 'Pittsburg County' },
  { state: 'OK', county: 'Pontotoc County' },
  { state: 'OK', county: 'Pottawatomie County' },
  { state: 'OK', county: 'Pushmataha County' },
  { state: 'OK', county: 'Roger Mills County' },
  { state: 'OK', county: 'Rogers County' },
  { state: 'OK', county: 'Seminole County' },
  { state: 'OK', county: 'Sequoyah County' },
  { state: 'OK', county: 'Stephens County' },
  { state: 'OK', county: 'Texas County' },
  { state: 'OK', county: 'Tillman County' },
  { state: 'OK', county: 'Tulsa County' },
  { state: 'OK', county: 'Wagoner County' },
  { state: 'OK', county: 'Washington County' },
  { state: 'OK', county: 'Washita County' },
  { state: 'OK', county: 'Woods County' },
  { state: 'OK', county: 'Woodward County' },
  { state: 'OR', county: 'Baker County' },
  { state: 'OR', county: 'Benton County' },
  { state: 'OR', county: 'Clackamas County' },
  { state: 'OR', county: 'Clatsop County' },
  { state: 'OR', county: 'Columbia County' },
  { state: 'OR', county: 'Coos County' },
  { state: 'OR', county: 'Crook County' },
  { state: 'OR', county: 'Curry County' },
  { state: 'OR', county: 'Deschutes County' },
  { state: 'OR', county: 'Douglas County' },
  { state: 'OR', county: 'Gilliam County' },
  { state: 'OR', county: 'Grant County' },
  { state: 'OR', county: 'Harney County' },
  { state: 'OR', county: 'Hood River County' },
  { state: 'OR', county: 'Jackson County' },
  { state: 'OR', county: 'Jefferson County' },
  { state: 'OR', county: 'Josephine County' },
  { state: 'OR', county: 'Klamath County' },
  { state: 'OR', county: 'Lake County' },
  { state: 'OR', county: 'Lane County' },
  { state: 'OR', county: 'Lincoln County' },
  { state: 'OR', county: 'Linn County' },
  { state: 'OR', county: 'Malheur County' },
  { state: 'OR', county: 'Marion County' },
  { state: 'OR', county: 'Morrow County' },
  { state: 'OR', county: 'Multnomah County' },
  { state: 'OR', county: 'Polk County' },
  { state: 'OR', county: 'Sherman County' },
  { state: 'OR', county: 'Tillamook County' },
  { state: 'OR', county: 'Umatilla County' },
  { state: 'OR', county: 'Union County' },
  { state: 'OR', county: 'Wallowa County' },
  { state: 'OR', county: 'Wasco County' },
  { state: 'OR', county: 'Washington County' },
  { state: 'OR', county: 'Wheeler County' },
  { state: 'OR', county: 'Yamhill County' },
  { state: 'PA', county: 'Adams County' },
  { state: 'PA', county: 'Allegheny County' },
  { state: 'PA', county: 'Armstrong County' },
  { state: 'PA', county: 'Beaver County' },
  { state: 'PA', county: 'Bedford County' },
  { state: 'PA', county: 'Berks County' },
  { state: 'PA', county: 'Blair County' },
  { state: 'PA', county: 'Bradford County' },
  { state: 'PA', county: 'Bucks County' },
  { state: 'PA', county: 'Butler County' },
  { state: 'PA', county: 'Cambria County' },
  { state: 'PA', county: 'Cameron County' },
  { state: 'PA', county: 'Carbon County' },
  { state: 'PA', county: 'Centre County' },
  { state: 'PA', county: 'Chester County' },
  { state: 'PA', county: 'Clarion County' },
  { state: 'PA', county: 'Clearfield County' },
  { state: 'PA', county: 'Clinton County' },
  { state: 'PA', county: 'Columbia County' },
  { state: 'PA', county: 'Crawford County' },
  { state: 'PA', county: 'Cumberland County' },
  { state: 'PA', county: 'Dauphin County' },
  { state: 'PA', county: 'Delaware County' },
  { state: 'PA', county: 'Elk County' },
  { state: 'PA', county: 'Erie County' },
  { state: 'PA', county: 'Fayette County' },
  { state: 'PA', county: 'Forest County' },
  { state: 'PA', county: 'Franklin County' },
  { state: 'PA', county: 'Fulton County' },
  { state: 'PA', county: 'Greene County' },
  { state: 'PA', county: 'Huntingdon County' },
  { state: 'PA', county: 'Indiana County' },
  { state: 'PA', county: 'Jefferson County' },
  { state: 'PA', county: 'Juniata County' },
  { state: 'PA', county: 'Lackawanna County' },
  { state: 'PA', county: 'Lancaster County' },
  { state: 'PA', county: 'Lawrence County' },
  { state: 'PA', county: 'Lebanon County' },
  { state: 'PA', county: 'Lehigh County' },
  { state: 'PA', county: 'Luzerne County' },
  { state: 'PA', county: 'Lycoming County' },
  { state: 'PA', county: 'McKean County' },
  { state: 'PA', county: 'Mercer County' },
  { state: 'PA', county: 'Mifflin County' },
  { state: 'PA', county: 'Monroe County' },
  { state: 'PA', county: 'Montgomery County' },
  { state: 'PA', county: 'Montour County' },
  { state: 'PA', county: 'Northampton County' },
  { state: 'PA', county: 'Northumberland County' },
  { state: 'PA', county: 'Perry County' },
  { state: 'PA', county: 'Philadelphia County' },
  { state: 'PA', county: 'Pike County' },
  { state: 'PA', county: 'Potter County' },
  { state: 'PA', county: 'Schuylkill County' },
  { state: 'PA', county: 'Snyder County' },
  { state: 'PA', county: 'Somerset County' },
  { state: 'PA', county: 'Sullivan County' },
  { state: 'PA', county: 'Susquehanna County' },
  { state: 'PA', county: 'Tioga County' },
  { state: 'PA', county: 'Union County' },
  { state: 'PA', county: 'Venango County' },
  { state: 'PA', county: 'Warren County' },
  { state: 'PA', county: 'Washington County' },
  { state: 'PA', county: 'Wayne County' },
  { state: 'PA', county: 'Westmoreland County' },
  { state: 'PA', county: 'Wyoming County' },
  { state: 'PA', county: 'York County' },
  { state: 'RI', county: 'Bristol County' },
  { state: 'RI', county: 'Kent County' },
  { state: 'RI', county: 'Newport County' },
  { state: 'RI', county: 'Providence County' },
  { state: 'RI', county: 'Washington County' },
  { state: 'SC', county: 'Abbeville County' },
  { state: 'SC', county: 'Aiken County' },
  { state: 'SC', county: 'Allendale County' },
  { state: 'SC', county: 'Anderson County' },
  { state: 'SC', county: 'Bamberg County' },
  { state: 'SC', county: 'Barnwell County' },
  { state: 'SC', county: 'Beaufort County' },
  { state: 'SC', county: 'Berkeley County' },
  { state: 'SC', county: 'Calhoun County' },
  { state: 'SC', county: 'Charleston County' },
  { state: 'SC', county: 'Cherokee County' },
  { state: 'SC', county: 'Chester County' },
  { state: 'SC', county: 'Chesterfield County' },
  { state: 'SC', county: 'Clarendon County' },
  { state: 'SC', county: 'Colleton County' },
  { state: 'SC', county: 'Darlington County' },
  { state: 'SC', county: 'Dillon County' },
  { state: 'SC', county: 'Dorchester County' },
  { state: 'SC', county: 'Edgefield County' },
  { state: 'SC', county: 'Fairfield County' },
  { state: 'SC', county: 'Florence County' },
  { state: 'SC', county: 'Georgetown County' },
  { state: 'SC', county: 'Greenville County' },
  { state: 'SC', county: 'Greenwood County' },
  { state: 'SC', county: 'Hampton County' },
  { state: 'SC', county: 'Horry County' },
  { state: 'SC', county: 'Jasper County' },
  { state: 'SC', county: 'Kershaw County' },
  { state: 'SC', county: 'Lancaster County' },
  { state: 'SC', county: 'Laurens County' },
  { state: 'SC', county: 'Lee County' },
  { state: 'SC', county: 'Lexington County' },
  { state: 'SC', county: 'McCormick County' },
  { state: 'SC', county: 'Marion County' },
  { state: 'SC', county: 'Marlboro County' },
  { state: 'SC', county: 'Newberry County' },
  { state: 'SC', county: 'Oconee County' },
  { state: 'SC', county: 'Orangeburg County' },
  { state: 'SC', county: 'Pickens County' },
  { state: 'SC', county: 'Richland County' },
  { state: 'SC', county: 'Saluda County' },
  { state: 'SC', county: 'Spartanburg County' },
  { state: 'SC', county: 'Sumter County' },
  { state: 'SC', county: 'Union County' },
  { state: 'SC', county: 'Williamsburg County' },
  { state: 'SC', county: 'York County' },
  { state: 'SD', county: 'Aurora County' },
  { state: 'SD', county: 'Beadle County' },
  { state: 'SD', county: 'Bennett County' },
  { state: 'SD', county: 'Bon Homme County' },
  { state: 'SD', county: 'Brookings County' },
  { state: 'SD', county: 'Brown County' },
  { state: 'SD', county: 'Brule County' },
  { state: 'SD', county: 'Buffalo County' },
  { state: 'SD', county: 'Butte County' },
  { state: 'SD', county: 'Campbell County' },
  { state: 'SD', county: 'Charles Mix County' },
  { state: 'SD', county: 'Clark County' },
  { state: 'SD', county: 'Clay County' },
  { state: 'SD', county: 'Codington County' },
  { state: 'SD', county: 'Corson County' },
  { state: 'SD', county: 'Custer County' },
  { state: 'SD', county: 'Davison County' },
  { state: 'SD', county: 'Day County' },
  { state: 'SD', county: 'Deuel County' },
  { state: 'SD', county: 'Dewey County' },
  { state: 'SD', county: 'Douglas County' },
  { state: 'SD', county: 'Edmunds County' },
  { state: 'SD', county: 'Fall River County' },
  { state: 'SD', county: 'Faulk County' },
  { state: 'SD', county: 'Grant County' },
  { state: 'SD', county: 'Gregory County' },
  { state: 'SD', county: 'Haakon County' },
  { state: 'SD', county: 'Hamlin County' },
  { state: 'SD', county: 'Hand County' },
  { state: 'SD', county: 'Hanson County' },
  { state: 'SD', county: 'Harding County' },
  { state: 'SD', county: 'Hughes County' },
  { state: 'SD', county: 'Hutchinson County' },
  { state: 'SD', county: 'Hyde County' },
  { state: 'SD', county: 'Jackson County' },
  { state: 'SD', county: 'Jerauld County' },
  { state: 'SD', county: 'Jones County' },
  { state: 'SD', county: 'Kingsbury County' },
  { state: 'SD', county: 'Lake County' },
  { state: 'SD', county: 'Lawrence County' },
  { state: 'SD', county: 'Lincoln County' },
  { state: 'SD', county: 'Lyman County' },
  { state: 'SD', county: 'McCook County' },
  { state: 'SD', county: 'McPherson County' },
  { state: 'SD', county: 'Marshall County' },
  { state: 'SD', county: 'Meade County' },
  { state: 'SD', county: 'Mellette County' },
  { state: 'SD', county: 'Miner County' },
  { state: 'SD', county: 'Minnehaha County' },
  { state: 'SD', county: 'Moody County' },
  { state: 'SD', county: 'Pennington County' },
  { state: 'SD', county: 'Perkins County' },
  { state: 'SD', county: 'Potter County' },
  { state: 'SD', county: 'Roberts County' },
  { state: 'SD', county: 'Sanborn County' },
  { state: 'SD', county: 'Shannon County' },
  { state: 'SD', county: 'Spink County' },
  { state: 'SD', county: 'Stanley County' },
  { state: 'SD', county: 'Sully County' },
  { state: 'SD', county: 'Todd County' },
  { state: 'SD', county: 'Tripp County' },
  { state: 'SD', county: 'Turner County' },
  { state: 'SD', county: 'Union County' },
  { state: 'SD', county: 'Walworth County' },
  { state: 'SD', county: 'Yankton County' },
  { state: 'SD', county: 'Ziebach County' },
  { state: 'TN', county: 'Anderson County' },
  { state: 'TN', county: 'Bedford County' },
  { state: 'TN', county: 'Benton County' },
  { state: 'TN', county: 'Bledsoe County' },
  { state: 'TN', county: 'Blount County' },
  { state: 'TN', county: 'Bradley County' },
  { state: 'TN', county: 'Campbell County' },
  { state: 'TN', county: 'Cannon County' },
  { state: 'TN', county: 'Carroll County' },
  { state: 'TN', county: 'Carter County' },
  { state: 'TN', county: 'Cheatham County' },
  { state: 'TN', county: 'Chester County' },
  { state: 'TN', county: 'Claiborne County' },
  { state: 'TN', county: 'Clay County' },
  { state: 'TN', county: 'Cocke County' },
  { state: 'TN', county: 'Coffee County' },
  { state: 'TN', county: 'Crockett County' },
  { state: 'TN', county: 'Cumberland County' },
  { state: 'TN', county: 'Davidson County' },
  { state: 'TN', county: 'Decatur County' },
  { state: 'TN', county: 'DeKalb County' },
  { state: 'TN', county: 'Dickson County' },
  { state: 'TN', county: 'Dyer County' },
  { state: 'TN', county: 'Fayette County' },
  { state: 'TN', county: 'Fentress County' },
  { state: 'TN', county: 'Franklin County' },
  { state: 'TN', county: 'Gibson County' },
  { state: 'TN', county: 'Giles County' },
  { state: 'TN', county: 'Grainger County' },
  { state: 'TN', county: 'Greene County' },
  { state: 'TN', county: 'Grundy County' },
  { state: 'TN', county: 'Hamblen County' },
  { state: 'TN', county: 'Hamilton County' },
  { state: 'TN', county: 'Hancock County' },
  { state: 'TN', county: 'Hardeman County' },
  { state: 'TN', county: 'Hardin County' },
  { state: 'TN', county: 'Hawkins County' },
  { state: 'TN', county: 'Haywood County' },
  { state: 'TN', county: 'Henderson County' },
  { state: 'TN', county: 'Henry County' },
  { state: 'TN', county: 'Hickman County' },
  { state: 'TN', county: 'Houston County' },
  { state: 'TN', county: 'Humphreys County' },
  { state: 'TN', county: 'Jackson County' },
  { state: 'TN', county: 'Jefferson County' },
  { state: 'TN', county: 'Johnson County' },
  { state: 'TN', county: 'Knox County' },
  { state: 'TN', county: 'Lake County' },
  { state: 'TN', county: 'Lauderdale County' },
  { state: 'TN', county: 'Lawrence County' },
  { state: 'TN', county: 'Lewis County' },
  { state: 'TN', county: 'Lincoln County' },
  { state: 'TN', county: 'Loudon County' },
  { state: 'TN', county: 'McMinn County' },
  { state: 'TN', county: 'McNairy County' },
  { state: 'TN', county: 'Macon County' },
  { state: 'TN', county: 'Madison County' },
  { state: 'TN', county: 'Marion County' },
  { state: 'TN', county: 'Marshall County' },
  { state: 'TN', county: 'Maury County' },
  { state: 'TN', county: 'Meigs County' },
  { state: 'TN', county: 'Monroe County' },
  { state: 'TN', county: 'Montgomery County' },
  { state: 'TN', county: 'Moore County' },
  { state: 'TN', county: 'Morgan County' },
  { state: 'TN', county: 'Obion County' },
  { state: 'TN', county: 'Overton County' },
  { state: 'TN', county: 'Perry County' },
  { state: 'TN', county: 'Pickett County' },
  { state: 'TN', county: 'Polk County' },
  { state: 'TN', county: 'Putnam County' },
  { state: 'TN', county: 'Rhea County' },
  { state: 'TN', county: 'Roane County' },
  { state: 'TN', county: 'Robertson County' },
  { state: 'TN', county: 'Rutherford County' },
  { state: 'TN', county: 'Scott County' },
  { state: 'TN', county: 'Sequatchie County' },
  { state: 'TN', county: 'Sevier County' },
  { state: 'TN', county: 'Shelby County' },
  { state: 'TN', county: 'Smith County' },
  { state: 'TN', county: 'Stewart County' },
  { state: 'TN', county: 'Sullivan County' },
  { state: 'TN', county: 'Sumner County' },
  { state: 'TN', county: 'Tipton County' },
  { state: 'TN', county: 'Trousdale County' },
  { state: 'TN', county: 'Unicoi County' },
  { state: 'TN', county: 'Union County' },
  { state: 'TN', county: 'Van Buren County' },
  { state: 'TN', county: 'Warren County' },
  { state: 'TN', county: 'Washington County' },
  { state: 'TN', county: 'Wayne County' },
  { state: 'TN', county: 'Weakley County' },
  { state: 'TN', county: 'White County' },
  { state: 'TN', county: 'Williamson County' },
  { state: 'TN', county: 'Wilson County' },
  { state: 'TX', county: 'Anderson County' },
  { state: 'TX', county: 'Andrews County' },
  { state: 'TX', county: 'Angelina County' },
  { state: 'TX', county: 'Aransas County' },
  { state: 'TX', county: 'Archer County' },
  { state: 'TX', county: 'Armstrong County' },
  { state: 'TX', county: 'Atascosa County' },
  { state: 'TX', county: 'Austin County' },
  { state: 'TX', county: 'Bailey County' },
  { state: 'TX', county: 'Bandera County' },
  { state: 'TX', county: 'Bastrop County' },
  { state: 'TX', county: 'Baylor County' },
  { state: 'TX', county: 'Bee County' },
  { state: 'TX', county: 'Bell County' },
  { state: 'TX', county: 'Bexar County' },
  { state: 'TX', county: 'Blanco County' },
  { state: 'TX', county: 'Borden County' },
  { state: 'TX', county: 'Bosque County' },
  { state: 'TX', county: 'Bowie County' },
  { state: 'TX', county: 'Brazoria County' },
  { state: 'TX', county: 'Brazos County' },
  { state: 'TX', county: 'Brewster County' },
  { state: 'TX', county: 'Briscoe County' },
  { state: 'TX', county: 'Brooks County' },
  { state: 'TX', county: 'Brown County' },
  { state: 'TX', county: 'Burleson County' },
  { state: 'TX', county: 'Burnet County' },
  { state: 'TX', county: 'Caldwell County' },
  { state: 'TX', county: 'Calhoun County' },
  { state: 'TX', county: 'Callahan County' },
  { state: 'TX', county: 'Cameron County' },
  { state: 'TX', county: 'Camp County' },
  { state: 'TX', county: 'Carson County' },
  { state: 'TX', county: 'Cass County' },
  { state: 'TX', county: 'Castro County' },
  { state: 'TX', county: 'Chambers County' },
  { state: 'TX', county: 'Cherokee County' },
  { state: 'TX', county: 'Childress County' },
  { state: 'TX', county: 'Clay County' },
  { state: 'TX', county: 'Cochran County' },
  { state: 'TX', county: 'Coke County' },
  { state: 'TX', county: 'Coleman County' },
  { state: 'TX', county: 'Collin County' },
  { state: 'TX', county: 'Collingsworth County' },
  { state: 'TX', county: 'Colorado County' },
  { state: 'TX', county: 'Comal County' },
  { state: 'TX', county: 'Comanche County' },
  { state: 'TX', county: 'Concho County' },
  { state: 'TX', county: 'Cooke County' },
  { state: 'TX', county: 'Coryell County' },
  { state: 'TX', county: 'Cottle County' },
  { state: 'TX', county: 'Crane County' },
  { state: 'TX', county: 'Crockett County' },
  { state: 'TX', county: 'Crosby County' },
  { state: 'TX', county: 'Culberson County' },
  { state: 'TX', county: 'Dallam County' },
  { state: 'TX', county: 'Dallas County' },
  { state: 'TX', county: 'Dawson County' },
  { state: 'TX', county: 'Deaf Smith County' },
  { state: 'TX', county: 'Delta County' },
  { state: 'TX', county: 'Denton County' },
  { state: 'TX', county: 'DeWitt County' },
  { state: 'TX', county: 'Dickens County' },
  { state: 'TX', county: 'Dimmit County' },
  { state: 'TX', county: 'Donley County' },
  { state: 'TX', county: 'Duval County' },
  { state: 'TX', county: 'Eastland County' },
  { state: 'TX', county: 'Ector County' },
  { state: 'TX', county: 'Edwards County' },
  { state: 'TX', county: 'Ellis County' },
  { state: 'TX', county: 'El Paso County' },
  { state: 'TX', county: 'Erath County' },
  { state: 'TX', county: 'Falls County' },
  { state: 'TX', county: 'Fannin County' },
  { state: 'TX', county: 'Fayette County' },
  { state: 'TX', county: 'Fisher County' },
  { state: 'TX', county: 'Floyd County' },
  { state: 'TX', county: 'Foard County' },
  { state: 'TX', county: 'Fort Bend County' },
  { state: 'TX', county: 'Franklin County' },
  { state: 'TX', county: 'Freestone County' },
  { state: 'TX', county: 'Frio County' },
  { state: 'TX', county: 'Gaines County' },
  { state: 'TX', county: 'Galveston County' },
  { state: 'TX', county: 'Garza County' },
  { state: 'TX', county: 'Gillespie County' },
  { state: 'TX', county: 'Glasscock County' },
  { state: 'TX', county: 'Goliad County' },
  { state: 'TX', county: 'Gonzales County' },
  { state: 'TX', county: 'Gray County' },
  { state: 'TX', county: 'Grayson County' },
  { state: 'TX', county: 'Gregg County' },
  { state: 'TX', county: 'Grimes County' },
  { state: 'TX', county: 'Guadalupe County' },
  { state: 'TX', county: 'Hale County' },
  { state: 'TX', county: 'Hall County' },
  { state: 'TX', county: 'Hamilton County' },
  { state: 'TX', county: 'Hansford County' },
  { state: 'TX', county: 'Hardeman County' },
  { state: 'TX', county: 'Hardin County' },
  { state: 'TX', county: 'Harris County' },
  { state: 'TX', county: 'Harrison County' },
  { state: 'TX', county: 'Hartley County' },
  { state: 'TX', county: 'Haskell County' },
  { state: 'TX', county: 'Hays County' },
  { state: 'TX', county: 'Hemphill County' },
  { state: 'TX', county: 'Henderson County' },
  { state: 'TX', county: 'Hidalgo County' },
  { state: 'TX', county: 'Hill County' },
  { state: 'TX', county: 'Hockley County' },
  { state: 'TX', county: 'Hood County' },
  { state: 'TX', county: 'Hopkins County' },
  { state: 'TX', county: 'Houston County' },
  { state: 'TX', county: 'Howard County' },
  { state: 'TX', county: 'Hudspeth County' },
  { state: 'TX', county: 'Hunt County' },
  { state: 'TX', county: 'Hutchinson County' },
  { state: 'TX', county: 'Irion County' },
  { state: 'TX', county: 'Jack County' },
  { state: 'TX', county: 'Jackson County' },
  { state: 'TX', county: 'Jasper County' },
  { state: 'TX', county: 'Jeff Davis County' },
  { state: 'TX', county: 'Jefferson County' },
  { state: 'TX', county: 'Jim Hogg County' },
  { state: 'TX', county: 'Jim Wells County' },
  { state: 'TX', county: 'Johnson County' },
  { state: 'TX', county: 'Jones County' },
  { state: 'TX', county: 'Karnes County' },
  { state: 'TX', county: 'Kaufman County' },
  { state: 'TX', county: 'Kendall County' },
  { state: 'TX', county: 'Kenedy County' },
  { state: 'TX', county: 'Kent County' },
  { state: 'TX', county: 'Kerr County' },
  { state: 'TX', county: 'Kimble County' },
  { state: 'TX', county: 'King County' },
  { state: 'TX', county: 'Kinney County' },
  { state: 'TX', county: 'Kleberg County' },
  { state: 'TX', county: 'Knox County' },
  { state: 'TX', county: 'Lamar County' },
  { state: 'TX', county: 'Lamb County' },
  { state: 'TX', county: 'Lampasas County' },
  { state: 'TX', county: 'La Salle County' },
  { state: 'TX', county: 'Lavaca County' },
  { state: 'TX', county: 'Lee County' },
  { state: 'TX', county: 'Leon County' },
  { state: 'TX', county: 'Liberty County' },
  { state: 'TX', county: 'Limestone County' },
  { state: 'TX', county: 'Lipscomb County' },
  { state: 'TX', county: 'Live Oak County' },
  { state: 'TX', county: 'Llano County' },
  { state: 'TX', county: 'Loving County' },
  { state: 'TX', county: 'Lubbock County' },
  { state: 'TX', county: 'Lynn County' },
  { state: 'TX', county: 'McCulloch County' },
  { state: 'TX', county: 'McLennan County' },
  { state: 'TX', county: 'McMullen County' },
  { state: 'TX', county: 'Madison County' },
  { state: 'TX', county: 'Marion County' },
  { state: 'TX', county: 'Martin County' },
  { state: 'TX', county: 'Mason County' },
  { state: 'TX', county: 'Matagorda County' },
  { state: 'TX', county: 'Maverick County' },
  { state: 'TX', county: 'Medina County' },
  { state: 'TX', county: 'Menard County' },
  { state: 'TX', county: 'Midland County' },
  { state: 'TX', county: 'Milam County' },
  { state: 'TX', county: 'Mills County' },
  { state: 'TX', county: 'Mitchell County' },
  { state: 'TX', county: 'Montague County' },
  { state: 'TX', county: 'Montgomery County' },
  { state: 'TX', county: 'Moore County' },
  { state: 'TX', county: 'Morris County' },
  { state: 'TX', county: 'Motley County' },
  { state: 'TX', county: 'Nacogdoches County' },
  { state: 'TX', county: 'Navarro County' },
  { state: 'TX', county: 'Newton County' },
  { state: 'TX', county: 'Nolan County' },
  { state: 'TX', county: 'Nueces County' },
  { state: 'TX', county: 'Ochiltree County' },
  { state: 'TX', county: 'Oldham County' },
  { state: 'TX', county: 'Orange County' },
  { state: 'TX', county: 'Palo Pinto County' },
  { state: 'TX', county: 'Panola County' },
  { state: 'TX', county: 'Parker County' },
  { state: 'TX', county: 'Parmer County' },
  { state: 'TX', county: 'Pecos County' },
  { state: 'TX', county: 'Polk County' },
  { state: 'TX', county: 'Potter County' },
  { state: 'TX', county: 'Presidio County' },
  { state: 'TX', county: 'Rains County' },
  { state: 'TX', county: 'Randall County' },
  { state: 'TX', county: 'Reagan County' },
  { state: 'TX', county: 'Real County' },
  { state: 'TX', county: 'Red River County' },
  { state: 'TX', county: 'Reeves County' },
  { state: 'TX', county: 'Refugio County' },
  { state: 'TX', county: 'Roberts County' },
  { state: 'TX', county: 'Robertson County' },
  { state: 'TX', county: 'Rockwall County' },
  { state: 'TX', county: 'Runnels County' },
  { state: 'TX', county: 'Rusk County' },
  { state: 'TX', county: 'Sabine County' },
  { state: 'TX', county: 'San Augustine County' },
  { state: 'TX', county: 'San Jacinto County' },
  { state: 'TX', county: 'San Patricio County' },
  { state: 'TX', county: 'San Saba County' },
  { state: 'TX', county: 'Schleicher County' },
  { state: 'TX', county: 'Scurry County' },
  { state: 'TX', county: 'Shackelford County' },
  { state: 'TX', county: 'Shelby County' },
  { state: 'TX', county: 'Sherman County' },
  { state: 'TX', county: 'Smith County' },
  { state: 'TX', county: 'Somervell County' },
  { state: 'TX', county: 'Starr County' },
  { state: 'TX', county: 'Stephens County' },
  { state: 'TX', county: 'Sterling County' },
  { state: 'TX', county: 'Stonewall County' },
  { state: 'TX', county: 'Sutton County' },
  { state: 'TX', county: 'Swisher County' },
  { state: 'TX', county: 'Tarrant County' },
  { state: 'TX', county: 'Taylor County' },
  { state: 'TX', county: 'Terrell County' },
  { state: 'TX', county: 'Terry County' },
  { state: 'TX', county: 'Throckmorton County' },
  { state: 'TX', county: 'Titus County' },
  { state: 'TX', county: 'Tom Green County' },
  { state: 'TX', county: 'Travis County' },
  { state: 'TX', county: 'Trinity County' },
  { state: 'TX', county: 'Tyler County' },
  { state: 'TX', county: 'Upshur County' },
  { state: 'TX', county: 'Upton County' },
  { state: 'TX', county: 'Uvalde County' },
  { state: 'TX', county: 'Val Verde County' },
  { state: 'TX', county: 'Van Zandt County' },
  { state: 'TX', county: 'Victoria County' },
  { state: 'TX', county: 'Walker County' },
  { state: 'TX', county: 'Waller County' },
  { state: 'TX', county: 'Ward County' },
  { state: 'TX', county: 'Washington County' },
  { state: 'TX', county: 'Webb County' },
  { state: 'TX', county: 'Wharton County' },
  { state: 'TX', county: 'Wheeler County' },
  { state: 'TX', county: 'Wichita County' },
  { state: 'TX', county: 'Wilbarger County' },
  { state: 'TX', county: 'Willacy County' },
  { state: 'TX', county: 'Williamson County' },
  { state: 'TX', county: 'Wilson County' },
  { state: 'TX', county: 'Winkler County' },
  { state: 'TX', county: 'Wise County' },
  { state: 'TX', county: 'Wood County' },
  { state: 'TX', county: 'Yoakum County' },
  { state: 'TX', county: 'Young County' },
  { state: 'TX', county: 'Zapata County' },
  { state: 'TX', county: 'Zavala County' },
  { state: 'UT', county: 'Beaver County' },
  { state: 'UT', county: 'Box Elder County' },
  { state: 'UT', county: 'Cache County' },
  { state: 'UT', county: 'Carbon County' },
  { state: 'UT', county: 'Daggett County' },
  { state: 'UT', county: 'Davis County' },
  { state: 'UT', county: 'Duchesne County' },
  { state: 'UT', county: 'Emery County' },
  { state: 'UT', county: 'Garfield County' },
  { state: 'UT', county: 'Grand County' },
  { state: 'UT', county: 'Iron County' },
  { state: 'UT', county: 'Juab County' },
  { state: 'UT', county: 'Kane County' },
  { state: 'UT', county: 'Millard County' },
  { state: 'UT', county: 'Morgan County' },
  { state: 'UT', county: 'Piute County' },
  { state: 'UT', county: 'Rich County' },
  { state: 'UT', county: 'Salt Lake County' },
  { state: 'UT', county: 'San Juan County' },
  { state: 'UT', county: 'Sanpete County' },
  { state: 'UT', county: 'Sevier County' },
  { state: 'UT', county: 'Summit County' },
  { state: 'UT', county: 'Tooele County' },
  { state: 'UT', county: 'Uintah County' },
  { state: 'UT', county: 'Utah County' },
  { state: 'UT', county: 'Wasatch County' },
  { state: 'UT', county: 'Washington County' },
  { state: 'UT', county: 'Wayne County' },
  { state: 'UT', county: 'Weber County' },
  { state: 'VT', county: 'Addison County' },
  { state: 'VT', county: 'Bennington County' },
  { state: 'VT', county: 'Caledonia County' },
  { state: 'VT', county: 'Chittenden County' },
  { state: 'VT', county: 'Essex County' },
  { state: 'VT', county: 'Franklin County' },
  { state: 'VT', county: 'Grand Isle County' },
  { state: 'VT', county: 'Lamoille County' },
  { state: 'VT', county: 'Orange County' },
  { state: 'VT', county: 'Orleans County' },
  { state: 'VT', county: 'Rutland County' },
  { state: 'VT', county: 'Washington County' },
  { state: 'VT', county: 'Windham County' },
  { state: 'VT', county: 'Windsor County' },
  { state: 'VA', county: 'Accomack County' },
  { state: 'VA', county: 'Albemarle County' },
  { state: 'VA', county: 'Alleghany County' },
  { state: 'VA', county: 'Amelia County' },
  { state: 'VA', county: 'Amherst County' },
  { state: 'VA', county: 'Appomattox County' },
  { state: 'VA', county: 'Arlington County' },
  { state: 'VA', county: 'Augusta County' },
  { state: 'VA', county: 'Bath County' },
  { state: 'VA', county: 'Bedford County' },
  { state: 'VA', county: 'Bland County' },
  { state: 'VA', county: 'Botetourt County' },
  { state: 'VA', county: 'Brunswick County' },
  { state: 'VA', county: 'Buchanan County' },
  { state: 'VA', county: 'Buckingham County' },
  { state: 'VA', county: 'Campbell County' },
  { state: 'VA', county: 'Caroline County' },
  { state: 'VA', county: 'Carroll County' },
  { state: 'VA', county: 'Charles City County' },
  { state: 'VA', county: 'Charlotte County' },
  { state: 'VA', county: 'Chesterfield County' },
  { state: 'VA', county: 'Clarke County' },
  { state: 'VA', county: 'Craig County' },
  { state: 'VA', county: 'Culpeper County' },
  { state: 'VA', county: 'Cumberland County' },
  { state: 'VA', county: 'Dickenson County' },
  { state: 'VA', county: 'Dinwiddie County' },
  { state: 'VA', county: 'Essex County' },
  { state: 'VA', county: 'Fairfax County' },
  { state: 'VA', county: 'Fauquier County' },
  { state: 'VA', county: 'Floyd County' },
  { state: 'VA', county: 'Fluvanna County' },
  { state: 'VA', county: 'Franklin County' },
  { state: 'VA', county: 'Frederick County' },
  { state: 'VA', county: 'Giles County' },
  { state: 'VA', county: 'Gloucester County' },
  { state: 'VA', county: 'Goochland County' },
  { state: 'VA', county: 'Grayson County' },
  { state: 'VA', county: 'Greene County' },
  { state: 'VA', county: 'Greensville County' },
  { state: 'VA', county: 'Halifax County' },
  { state: 'VA', county: 'Hanover County' },
  { state: 'VA', county: 'Henrico County' },
  { state: 'VA', county: 'Henry County' },
  { state: 'VA', county: 'Highland County' },
  { state: 'VA', county: 'Isle of Wight County' },
  { state: 'VA', county: 'James City County' },
  { state: 'VA', county: 'King and Queen County' },
  { state: 'VA', county: 'King George County' },
  { state: 'VA', county: 'King William County' },
  { state: 'VA', county: 'Lancaster County' },
  { state: 'VA', county: 'Lee County' },
  { state: 'VA', county: 'Loudoun County' },
  { state: 'VA', county: 'Louisa County' },
  { state: 'VA', county: 'Lunenburg County' },
  { state: 'VA', county: 'Madison County' },
  { state: 'VA', county: 'Mathews County' },
  { state: 'VA', county: 'Mecklenburg County' },
  { state: 'VA', county: 'Middlesex County' },
  { state: 'VA', county: 'Montgomery County' },
  { state: 'VA', county: 'Nelson County' },
  { state: 'VA', county: 'New Kent County' },
  { state: 'VA', county: 'Northampton County' },
  { state: 'VA', county: 'Northumberland County' },
  { state: 'VA', county: 'Nottoway County' },
  { state: 'VA', county: 'Orange County' },
  { state: 'VA', county: 'Page County' },
  { state: 'VA', county: 'Patrick County' },
  { state: 'VA', county: 'Pittsylvania County' },
  { state: 'VA', county: 'Powhatan County' },
  { state: 'VA', county: 'Prince Edward County' },
  { state: 'VA', county: 'Prince George County' },
  { state: 'VA', county: 'Prince William County' },
  { state: 'VA', county: 'Pulaski County' },
  { state: 'VA', county: 'Rappahannock County' },
  { state: 'VA', county: 'Richmond County' },
  { state: 'VA', county: 'Roanoke County' },
  { state: 'VA', county: 'Rockbridge County' },
  { state: 'VA', county: 'Rockingham County' },
  { state: 'VA', county: 'Russell County' },
  { state: 'VA', county: 'Scott County' },
  { state: 'VA', county: 'Shenandoah County' },
  { state: 'VA', county: 'Smyth County' },
  { state: 'VA', county: 'Southampton County' },
  { state: 'VA', county: 'Spotsylvania County' },
  { state: 'VA', county: 'Stafford County' },
  { state: 'VA', county: 'Surry County' },
  { state: 'VA', county: 'Sussex County' },
  { state: 'VA', county: 'Tazewell County' },
  { state: 'VA', county: 'Warren County' },
  { state: 'VA', county: 'Washington County' },
  { state: 'VA', county: 'Westmoreland County' },
  { state: 'VA', county: 'Wise County' },
  { state: 'VA', county: 'Wythe County' },
  { state: 'VA', county: 'York County' },
  { state: 'VA', county: 'Alexandria city' },
  { state: 'VA', county: 'Bedford city' },
  { state: 'VA', county: 'Bristol city' },
  { state: 'VA', county: 'Buena Vista city' },
  { state: 'VA', county: 'Charlottesville city' },
  { state: 'VA', county: 'Chesapeake city' },
  { state: 'VA', county: 'Colonial Heights city' },
  { state: 'VA', county: 'Covington city' },
  { state: 'VA', county: 'Danville city' },
  { state: 'VA', county: 'Emporia city' },
  { state: 'VA', county: 'Fairfax city' },
  { state: 'VA', county: 'Falls Church city' },
  { state: 'VA', county: 'Franklin city' },
  { state: 'VA', county: 'Fredericksburg city' },
  { state: 'VA', county: 'Galax city' },
  { state: 'VA', county: 'Hampton city' },
  { state: 'VA', county: 'Harrisonburg city' },
  { state: 'VA', county: 'Hopewell city' },
  { state: 'VA', county: 'Lexington city' },
  { state: 'VA', county: 'Lynchburg city' },
  { state: 'VA', county: 'Manassas city' },
  { state: 'VA', county: 'Manassas Park city' },
  { state: 'VA', county: 'Martinsville city' },
  { state: 'VA', county: 'Newport News city' },
  { state: 'VA', county: 'Norfolk city' },
  { state: 'VA', county: 'Norton city' },
  { state: 'VA', county: 'Petersburg city' },
  { state: 'VA', county: 'Poquoson city' },
  { state: 'VA', county: 'Portsmouth city' },
  { state: 'VA', county: 'Radford city' },
  { state: 'VA', county: 'Richmond city' },
  { state: 'VA', county: 'Roanoke city' },
  { state: 'VA', county: 'Salem city' },
  { state: 'VA', county: 'Staunton city' },
  { state: 'VA', county: 'Suffolk city' },
  { state: 'VA', county: 'Virginia Beach city' },
  { state: 'VA', county: 'Waynesboro city' },
  { state: 'VA', county: 'Williamsburg city' },
  { state: 'VA', county: 'Winchester city' },
  { state: 'WA', county: 'Adams County' },
  { state: 'WA', county: 'Asotin County' },
  { state: 'WA', county: 'Benton County' },
  { state: 'WA', county: 'Chelan County' },
  { state: 'WA', county: 'Clallam County' },
  { state: 'WA', county: 'Clark County' },
  { state: 'WA', county: 'Columbia County' },
  { state: 'WA', county: 'Cowlitz County' },
  { state: 'WA', county: 'Douglas County' },
  { state: 'WA', county: 'Ferry County' },
  { state: 'WA', county: 'Franklin County' },
  { state: 'WA', county: 'Garfield County' },
  { state: 'WA', county: 'Grant County' },
  { state: 'WA', county: 'Grays Harbor County' },
  { state: 'WA', county: 'Island County' },
  { state: 'WA', county: 'Jefferson County' },
  { state: 'WA', county: 'King County' },
  { state: 'WA', county: 'Kitsap County' },
  { state: 'WA', county: 'Kittitas County' },
  { state: 'WA', county: 'Klickitat County' },
  { state: 'WA', county: 'Lewis County' },
  { state: 'WA', county: 'Lincoln County' },
  { state: 'WA', county: 'Mason County' },
  { state: 'WA', county: 'Okanogan County' },
  { state: 'WA', county: 'Pacific County' },
  { state: 'WA', county: 'Pend Oreille County' },
  { state: 'WA', county: 'Pierce County' },
  { state: 'WA', county: 'San Juan County' },
  { state: 'WA', county: 'Skagit County' },
  { state: 'WA', county: 'Skamania County' },
  { state: 'WA', county: 'Snohomish County' },
  { state: 'WA', county: 'Spokane County' },
  { state: 'WA', county: 'Stevens County' },
  { state: 'WA', county: 'Thurston County' },
  { state: 'WA', county: 'Wahkiakum County' },
  { state: 'WA', county: 'Walla Walla County' },
  { state: 'WA', county: 'Whatcom County' },
  { state: 'WA', county: 'Whitman County' },
  { state: 'WA', county: 'Yakima County' },
  { state: 'WV', county: 'Barbour County' },
  { state: 'WV', county: 'Berkeley County' },
  { state: 'WV', county: 'Boone County' },
  { state: 'WV', county: 'Braxton County' },
  { state: 'WV', county: 'Brooke County' },
  { state: 'WV', county: 'Cabell County' },
  { state: 'WV', county: 'Calhoun County' },
  { state: 'WV', county: 'Clay County' },
  { state: 'WV', county: 'Doddridge County' },
  { state: 'WV', county: 'Fayette County' },
  { state: 'WV', county: 'Gilmer County' },
  { state: 'WV', county: 'Grant County' },
  { state: 'WV', county: 'Greenbrier County' },
  { state: 'WV', county: 'Hampshire County' },
  { state: 'WV', county: 'Hancock County' },
  { state: 'WV', county: 'Hardy County' },
  { state: 'WV', county: 'Harrison County' },
  { state: 'WV', county: 'Jackson County' },
  { state: 'WV', county: 'Jefferson County' },
  { state: 'WV', county: 'Kanawha County' },
  { state: 'WV', county: 'Lewis County' },
  { state: 'WV', county: 'Lincoln County' },
  { state: 'WV', county: 'Logan County' },
  { state: 'WV', county: 'McDowell County' },
  { state: 'WV', county: 'Marion County' },
  { state: 'WV', county: 'Marshall County' },
  { state: 'WV', county: 'Mason County' },
  { state: 'WV', county: 'Mercer County' },
  { state: 'WV', county: 'Mineral County' },
  { state: 'WV', county: 'Mingo County' },
  { state: 'WV', county: 'Monongalia County' },
  { state: 'WV', county: 'Monroe County' },
  { state: 'WV', county: 'Morgan County' },
  { state: 'WV', county: 'Nicholas County' },
  { state: 'WV', county: 'Ohio County' },
  { state: 'WV', county: 'Pendleton County' },
  { state: 'WV', county: 'Pleasants County' },
  { state: 'WV', county: 'Pocahontas County' },
  { state: 'WV', county: 'Preston County' },
  { state: 'WV', county: 'Putnam County' },
  { state: 'WV', county: 'Raleigh County' },
  { state: 'WV', county: 'Randolph County' },
  { state: 'WV', county: 'Ritchie County' },
  { state: 'WV', county: 'Roane County' },
  { state: 'WV', county: 'Summers County' },
  { state: 'WV', county: 'Taylor County' },
  { state: 'WV', county: 'Tucker County' },
  { state: 'WV', county: 'Tyler County' },
  { state: 'WV', county: 'Upshur County' },
  { state: 'WV', county: 'Wayne County' },
  { state: 'WV', county: 'Webster County' },
  { state: 'WV', county: 'Wetzel County' },
  { state: 'WV', county: 'Wirt County' },
  { state: 'WV', county: 'Wood County' },
  { state: 'WV', county: 'Wyoming County' },
  { state: 'WI', county: 'Adams County' },
  { state: 'WI', county: 'Ashland County' },
  { state: 'WI', county: 'Barron County' },
  { state: 'WI', county: 'Bayfield County' },
  { state: 'WI', county: 'Brown County' },
  { state: 'WI', county: 'Buffalo County' },
  { state: 'WI', county: 'Burnett County' },
  { state: 'WI', county: 'Calumet County' },
  { state: 'WI', county: 'Chippewa County' },
  { state: 'WI', county: 'Clark County' },
  { state: 'WI', county: 'Columbia County' },
  { state: 'WI', county: 'Crawford County' },
  { state: 'WI', county: 'Dane County' },
  { state: 'WI', county: 'Dodge County' },
  { state: 'WI', county: 'Door County' },
  { state: 'WI', county: 'Douglas County' },
  { state: 'WI', county: 'Dunn County' },
  { state: 'WI', county: 'Eau Claire County' },
  { state: 'WI', county: 'Florence County' },
  { state: 'WI', county: 'Fond du Lac County' },
  { state: 'WI', county: 'Forest County' },
  { state: 'WI', county: 'Grant County' },
  { state: 'WI', county: 'Green County' },
  { state: 'WI', county: 'Green Lake County' },
  { state: 'WI', county: 'Iowa County' },
  { state: 'WI', county: 'Iron County' },
  { state: 'WI', county: 'Jackson County' },
  { state: 'WI', county: 'Jefferson County' },
  { state: 'WI', county: 'Juneau County' },
  { state: 'WI', county: 'Kenosha County' },
  { state: 'WI', county: 'Kewaunee County' },
  { state: 'WI', county: 'La Crosse County' },
  { state: 'WI', county: 'Lafayette County' },
  { state: 'WI', county: 'Langlade County' },
  { state: 'WI', county: 'Lincoln County' },
  { state: 'WI', county: 'Manitowoc County' },
  { state: 'WI', county: 'Marathon County' },
  { state: 'WI', county: 'Marinette County' },
  { state: 'WI', county: 'Marquette County' },
  { state: 'WI', county: 'Menominee County' },
  { state: 'WI', county: 'Milwaukee County' },
  { state: 'WI', county: 'Monroe County' },
  { state: 'WI', county: 'Oconto County' },
  { state: 'WI', county: 'Oneida County' },
  { state: 'WI', county: 'Outagamie County' },
  { state: 'WI', county: 'Ozaukee County' },
  { state: 'WI', county: 'Pepin County' },
  { state: 'WI', county: 'Pierce County' },
  { state: 'WI', county: 'Polk County' },
  { state: 'WI', county: 'Portage County' },
  { state: 'WI', county: 'Price County' },
  { state: 'WI', county: 'Racine County' },
  { state: 'WI', county: 'Richland County' },
  { state: 'WI', county: 'Rock County' },
  { state: 'WI', county: 'Rusk County' },
  { state: 'WI', county: 'St. Croix County' },
  { state: 'WI', county: 'Sauk County' },
  { state: 'WI', county: 'Sawyer County' },
  { state: 'WI', county: 'Shawano County' },
  { state: 'WI', county: 'Sheboygan County' },
  { state: 'WI', county: 'Taylor County' },
  { state: 'WI', county: 'Trempealeau County' },
  { state: 'WI', county: 'Vernon County' },
  { state: 'WI', county: 'Vilas County' },
  { state: 'WI', county: 'Walworth County' },
  { state: 'WI', county: 'Washburn County' },
  { state: 'WI', county: 'Washington County' },
  { state: 'WI', county: 'Waukesha County' },
  { state: 'WI', county: 'Waupaca County' },
  { state: 'WI', county: 'Waushara County' },
  { state: 'WI', county: 'Winnebago County' },
  { state: 'WI', county: 'Wood County' },
  { state: 'WY', county: 'Albany County' },
  { state: 'WY', county: 'Big Horn County' },
  { state: 'WY', county: 'Campbell County' },
  { state: 'WY', county: 'Carbon County' },
  { state: 'WY', county: 'Converse County' },
  { state: 'WY', county: 'Crook County' },
  { state: 'WY', county: 'Fremont County' },
  { state: 'WY', county: 'Goshen County' },
  { state: 'WY', county: 'Hot Springs County' },
  { state: 'WY', county: 'Johnson County' },
  { state: 'WY', county: 'Laramie County' },
  { state: 'WY', county: 'Lincoln County' },
  { state: 'WY', county: 'Natrona County' },
  { state: 'WY', county: 'Niobrara County' },
  { state: 'WY', county: 'Park County' },
  { state: 'WY', county: 'Platte County' },
  { state: 'WY', county: 'Sheridan County' },
  { state: 'WY', county: 'Sublette County' },
  { state: 'WY', county: 'Sweetwater County' },
  { state: 'WY', county: 'Teton County' },
  { state: 'WY', county: 'Uinta County' },
  { state: 'WY', county: 'Washakie County' },
  { state: 'WY', county: 'Weston County' },
];
