import { Box } from '@mui/material';
import { styled } from '@mui/system';

const DashBoardBox = styled(Box)(({ theme, colors }) => ({
  backgroundColor: colors.primary[700],
  borderRadius: '1rem',
  boxShadow: '0.15rem 0.2rem .15rem .1rem rgba(0, 0, 0, .8)',
}));

export default DashBoardBox;
