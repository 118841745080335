import { Box, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { tokens } from '../theme';
import Header from '../components/Header';
import AddProjectForm from '../components/AddProjectForm';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from 'dayjs';
import { useState } from 'react';
import Modal from '@mui/material/Modal';

const Projects = ({
  selectedDataID,
  setselectedDataID,
  data,
  setAllProjects,
  deleteManyProjects,
  pageState,
  setPageState
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //modal
  const [openBook, setOpenBook] = useState(false);
  const handleOpenBook = () => setOpenBook(true);
  const handleCloseBook = () => setOpenBook(false);

  //Modal Stuff
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    // p: 4,
    overflowY: 'auto',
    p: 1,
  };

  const columns = [
    {
      field: 'project',
      headerName: 'Project',
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'City',
    },
    {
      field: 'county',
      headerName: 'county',
      flex: 1,
    },
    {
      field: 'state',
      headerName: 'state',
      flex: 1,
    },
    {
      field: 'biddate',
      headerName: 'Bid Date',
      flex: 1,
    },
    {
      field: 'book',
      headerName: 'Book',
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
    },
    {
      field: 'projectcode',
      headerName: 'Code',
      width: 25,
    },
  ];

  const hoverStyles = [
    {
      '&:hover': {
        color: 'red',
        backgroundColor: colors.greenAccent[600],
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={handleOpenBook}>
          Add Project
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<DeleteForeverIcon />}
          onClick={deleteManyProjects}>
          Delete
        </Button>
        <Modal
          open={openBook}
          onClose={handleCloseBook}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <div className='center'>
              <AddProjectForm
                handleCloseBook={handleCloseBook}
                setAllProjects={setAllProjects}
              />
            </div>
          </Box>
        </Modal>
      </GridToolbarContainer>
    );
  }

  const renderedData = data.map((project) => ({
    id: project._id,
    project: project.project,
    city: project.city,
    county: project.county,
    state: project.state,
    projectcode: project.projectcode,
    biddate: dayjs(project.biddate).format('MM-DD-YYYY'),
    book: project.book,
    address: project.address,
    start: dayjs(project.start).format('MM-DD-YYYY'),
    end: dayjs(project.end).format('MM-DD-YYYY'),
  }));

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      renderedData.find((row) => row.id === id)
    );
  };

  return (
    <Box m='10px 20px 20px 20px'>
      <Header title='Project' subtitle='Managing the Project Members' />
      <Box
        m='20px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .name=column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          rows={renderedData}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            onRowsSelectionHandler(ids);
            setselectedDataID(ids);
          }}
          sx={{
            '& .MuiCheckbox-colorPrimary': {
              color: colors.grey[100],
            },
          }}
          disableSelectionOnClick
          rowCount={pageState?.total}
          loading={pageState.isLoading}
          pagination
          page={pageState.page}
          pageSize={pageState.pageSize}
          pageSizeOptions={[50, 100]}
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          paginationMode='server'
        />
        <p> {selectedDataID} </p>
      </Box>
    </Box>
  );
};

export default Projects;
