import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Row1 from '../dashboard/Row1';
import Row2 from '../dashboard/Row2';
import Row3 from '../dashboard/Row3';
import dashboardConfig from '../fakedata/dashboardConfig';
import useFetch from '../hooks/fetchAPI';
import threeWeekLookBack from '../hooks/three-week-lookback';
import filteredBarGraphData from '../hooks/fx-create-bar-graph-array';
import { useEffect, useState } from 'react';
import LoadingCircle from '../components/LoadingCircle';
import dayjs from 'dayjs';
import { useAuth } from '../context/AuthProvider';
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

const Dashboard2 = () => {
  const { gridTemplateLargeScreens, gridTemplateSmallScreens } =
    dashboardConfig();
  const { sixWkBkDesc } = threeWeekLookBack();
  const isAboveMediumScreens = useMediaQuery('(min-width: 1200px)');
  const PrevWeekNumber = dayjs().day(-1).week();
  const { data, loading, error } = useFetch(
    `/api/v1/timecardsv2/?weekNumber=${PrevWeekNumber}`
  );
  console.log(data);
  const { api } = useAuth();
  const [progress, setProgress] = useState(10);
  const [isLoaded, setisLoaded] = useState(false);
  const [listData, setListData] = useState([]);
  const [COProject, setCOProject] = useState('All Projects');
  const [RFIProject, setRFIProject] = useState('All Projects');
  const [TimecardRange, setTimecardRange] = useState([]);
  console.log(TimecardRange);
  const weeksObject = threeWeekLookBack();
  const [startDate, setStartDate] = useState(
    new Date(weeksObject.sixWkBkDesc[0])
  );
  const [endDate, setEndDate] = useState(new Date(weeksObject.sixWkBkDesc[6]));

  console.log(startDate, endDate);

  useEffect(() => {
    let timer;
    if (progress < 100) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 10
        );
      }, 100);
    }

    if (progress === 100) {
      setisLoaded(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    async function fetchMultipleDataStreams(...prevPayroll) {
      await Promise.all([
        api.get(`/api/v1/rfi/?page=1&limit=20&project=${RFIProject}`),
        api.get(`/api/v1/changeorder/?page=1&limit=20&project=${COProject}`),
        api.get('/api/v1/projects/list'),
        api.get('/api/v1/payroll/weekly'),
      ])
        .then(
          checkFailed(([rfi, co, projectList, payroll]) => {
            setListData((prev) => ({
              ...prev,
              rfi,
              co,
              projectList,
              payroll,
            }));
          })
        )
        .catch((err) => {
          console.log('FAIL', err);
        });
    }

    if (api.authenticated) {
      fetchMultipleDataStreams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated, COProject, RFIProject]);

  useEffect(() => {
    async function fetchMultipleDataStreams() {
      await Promise.all([
        api.get(
          `/api/v1/timecardsv2/range?page=1&limit=100&mode=range&start=${startDate}&end=${endDate}`
        ),
      ])
        .then(
          checkFailed(([timecards]) => {
            setTimecardRange((prev) => ({
              ...prev,
              timecards,
            }));
          })
        )
        .catch((err) => {
          console.log('FAIL', err);
        });
    }

    if (api.authenticated) {
      fetchMultipleDataStreams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated]);

  const result = filteredBarGraphData(sixWkBkDesc, data);
  return (
    <Box width='100%'>
      {isLoaded ? (
        <Box
          width='100%'
          display='grid'
          gap='1.5rem'
          sx={
            isAboveMediumScreens
              ? {
                  gridTemplateColumns: 'repeat(3, minmax(360px, 1fr))',
                  gridTemplateRows: 'repeat(10, minmax(60px, 1fr))',
                  gridTemplateAreas: gridTemplateLargeScreens,
                  p: 0,
                }
              : {
                  gridAutoColumns: '1fr',
                  gridAutoRows: '50px',
                  gridTemplateAreas: gridTemplateSmallScreens,
                  p: 2,
                }
          }>
          <Row1
            timecardData={result}
            error={error}
            unfilteredData={data}
            label={PrevWeekNumber}
          />
          <Row2 />
          <Row3
            COProject={COProject}
            setCOProject={setCOProject}
            RFIProject={RFIProject}
            setRFIProject={setRFIProject}
            listData={listData}
          />
        </Box>
      ) : (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'>
          <Grid
            item
            xs={3}
            sx={{
              mt: '20%',
            }}
            elevation={3}>
            <LoadingCircle
              progress={progress}
              setProgress={setProgress}
              isLoaded={isLoaded}
              setisLoaded={setisLoaded}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard2;
