//returns 1) boolean for loading 2) data 3) error
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import usePath from "./fetchPath";

function useFetch(path) {
	const { fullPath, baseURL } = usePath(path);
	const { api } = useAuth();
	const [url] = useState(fullPath);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (api.authenticated) {
			setLoading(true);
			api
				.get(url)
				.then((response) => {
					//console.log('response', response);
					setData(response.data.data);
					setLoading(false);
				})
				.catch((err) => {
					setError(err.message);
					setData([]);
					//console.log(err);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseURL, url, api?.authenticated]);
	//console.log('Data:', data);
	return { data, loading, error };
}

export default useFetch;
