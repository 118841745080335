import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import LandingPageNavBar from '../components/LandingPageNavBar';
import LandingPageFooter from '../components/LandingPageFooter';
import { Grid, useMediaQuery } from '@mui/material';
import { tokens } from '../theme';

const TermsAndAgreementPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box>
      <Box>
        <LandingPageNavBar />
      </Box>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'>
        <Grid
          item
          xs={3}
          sx={{
            width: isMobile ? "80%":'50%',
            mt: '75px',
            mb: '35px',
          }}
          elevation={3}>
          <Box
            sx={{
              width: '90%',
              minHeight: '65dvh',
              height: '90%',
              mt: '0.5rem',
              p: '0 0.5rem',
            }}>
            <Typography variant='h3' sx={{ color: colors.greenAccent[500] }}>
              Terms and Agreements
            </Typography>
            <Typography>
              {' '}
              This agreement outlines the terms of use for services provided by
              a company, which you or a third party (such as an employer) have
              subscribed to access. By using these services, you agree to abide
              by the terms of the agreement. If you're agreeing on behalf of
              another legal entity, you confirm that you have the authority to
              bind that entity to these terms. You are not permitted to access
              the services if you are a direct competitor of the company, unless
              you have obtained prior written consent. Furthermore, you may not
              access the services for purposes of monitoring their performance
              or for competitive benchmarking. The agreement was last updated on
              July 15, 2013, and it becomes effective between you and the
              company upon your acceptance of its terms.
            </Typography>

            <Typography
              variant='h3'
              sx={{ color: colors.greenAccent[500], mt: '20px' }}>
              Definitions
            </Typography>
            <Typography
              variant='h5'
              sx={{ color: colors.grey[500], mt: '20px' }}>
              Prevailing Wage
            </Typography>
            <Typography>
              The hourly regular wage, overtime wage, saturday wage rate, and
              sunday wage rates as well as fringe benefits as set forth by the
              Department of Industrial Relations.
            </Typography>
            <Typography
              variant='h5'
              sx={{ color: colors.grey[500], mt: '20px' }}>
              Services
            </Typography>
            <Typography>
              The products and services that are available online at
              dailytimecard.com
            </Typography>
            <Typography
              variant='h5'
              sx={{ color: colors.grey[500], mt: '20px' }}>
              Users
            </Typography>
            <Typography>
              "Users" refers to individuals authorized by you to utilize the
              services. These individuals have subscriptions ordered for them,
              have been provided with user identifications and passwords by you
              (or by the company at your request). Users can include, but are
              not limited to, your employees, consultants, contractors, agents,
              and third parties with whom you conduct business.
            </Typography>
            <Typography
              variant='h3'
              sx={{ color: colors.greenAccent[500], mt: '20px' }}>
              Provision of Services
            </Typography>
            <Typography>
              We will provide you with access to the services for which you have
              subscribed, including the Free Services. Upon opening an account,
              you can create business or personal workspaces and grant access to
              others. As new features are developed within your subscription
              area, you'll have access for your business needs. If additional
              subscription services become available, you'll be informed and can
              expand your subscription accordingly. If you choose to discontinue
              any services, you'll be given a timeframe to retrieve your data
              from the system. Your use of the services is not dependent on the
              delivery of future functionality or features, nor on any public
              comments made by us regarding future developments.
            </Typography>
            <Typography
              variant='h3'
              sx={{ color: colors.greenAccent[500], mt: '20px' }}>
              Use of Services
            </Typography>
            <Typography
              variant='h5'
              sx={{ color: colors.grey[500], mt: '20px' }}>
              Our Responsibilities
            </Typography>

            <Typography>
              We are responsible for: (i) providing basic support for the
              Services at no charge, (ii) offering paid services as outlined on
              the website with corresponding charges, (iii) making commercially
              reasonable efforts to ensure 24/7 availability of the Services,
              with exceptions for planned downtime or circumstances beyond our
              control, and (iv) delivering the Services in compliance with
              applicable laws and regulations.
            </Typography>

            <Typography
              variant='h5'
              sx={{ color: colors.grey[500], mt: '20px' }}>
              Protecting Data
            </Typography>
            <Typography>
              We will safeguard the security, confidentiality, and integrity of
              your data with appropriate measures. We will not modify or
              disclose your data except as required by law or permitted by you
              in writing. Access to your data is limited to providing services,
              addressing technical issues, or at your request for support. Any
              data entered into the services and customizations made by or for
              you will be permanently lost if you discontinue our services
              without taking steps to retain your data. If you downgrade your
              service, you must export your data beforehand to prevent permanent
              loss. We are not responsible for user errors resulting in data
              loss leading to legal issues or lawsuits.
            </Typography>
            <Typography
              variant='h5'
              sx={{ color: colors.grey[500], mt: '20px' }}>
              Your Responsibilities
            </Typography>
            <Typography>
              You are responsible for ensuring that users comply with this
              agreement. This entails requiring each user to open an account and
              adhere to the terms. You are also accountable for the accuracy,
              quality, and legality of your data, as well as preventing
              unauthorized access to the services and promptly reporting any
              such incidents. Additionally, you must use the services in
              accordance with the information provided on the website, user
              guides, and applicable laws. You are not permitted to make the
              services available to non-users, engage in certain prohibited
              activities such as selling or leasing the services, or use the
              services for unlawful purposes. You must not use the services to
              upload/transmit malicious code or disrupt the services rendered.
            </Typography>
            <Typography
              variant='h3'
              sx={{ color: colors.greenAccent[500], mt: '20px' }}>
              Proprietary Rights
            </Typography>
            <Typography>
              We reserve all rights, title, and interest in the Services,
              including intellectual property rights, except for those expressly
              granted to you. You are restricted from allowing third-party
              access, creating derivative works, copying or reverse engineering
              the Services, or using them to build competitive products. If you
              or a third party create applications or code using the Services,
              we have the right to host, copy, and adapt them for service
              provision. However, we do not acquire any ownership rights to your
              applications, code, or data. Any feedback you provide may be used
              by us without royalties. For federal government end use, standard
              commercial license terms apply unless negotiated otherwise.
            </Typography>
            <Typography
              variant='h3'
              sx={{ color: colors.greenAccent[500], mt: '20px' }}>
              Confidentiality
            </Typography>
            <Typography>
              Confidential Information includes data disclosed by either party
              that is designated as confidential or should reasonably be
              understood as such. This encompasses Your Data (excluding data in
              others' workspaces), Our Services, and terms of this Agreement.
              However, it does not include information already in the public
              domain, previously known to the Receiving Party, received from a
              third party without obligation, or independently developed. The
              Receiving Party agrees to safeguard Confidential Information using
              reasonable care, not to use it beyond the agreement's scope, and
              limit access to authorized personnel. Disclosure to third parties
              is restricted without prior written consent. If compelled by law
              to disclose, the Receiving Party must notify the Disclosing Party,
              provide assistance if contested, and seek reimbursement for costs
              in certain circumstances during civil proceedings.
            </Typography>

            <Typography
              variant='h3'
              sx={{ color: colors.greenAccent[500], mt: '20px' }}>
              Warranties and Dislcaimers
            </Typography>
            <Typography sx={{ mb: '1rem' }}>
              We warrant that we have validly entered into this Agreement and
              will not transmit Malicious Code to you, unless it is uploaded by
              you or a user and later downloaded. Your exclusive remedy for any
              breach of these warranties is outlined in the termination section.
              You warrant that you have validly entered into this Agreement.
              Except as expressly stated, neither party makes any warranties of
              any kind, and both parties specifically disclaim all implied
              warranties, including those of merchantability or fitness for a
              particular purpose, to the maximum extent permitted by law.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <LandingPageFooter />
    </Box>
  );
};

export default TermsAndAgreementPage;
