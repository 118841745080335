import { useRef, useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import { Stack, Button, Grid, Box, Typography } from '@mui/material';
import { TextField, useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { tokens } from '../theme';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const theme = useTheme();
  const { api, setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const colors = tokens(theme.palette.mode);
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [sentEmail, setSentEmail] = useState(false);

  useEffect(() => {
    setErrMsg('');
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(user);

    api
      .post('/api/v1/auth/forgotpassword', JSON.stringify({ email: email }))
      .then((response) => {
        // console.log(JSON.stringify(response?.data));
        // const accessToken = response?.data?.token
        //const roles = response?.data?.roles;
        setAuth({ user });
        setUser('');
        // setPwd('');
        console.log(response);
        setSentEmail(true);
      })
      .catch((err) => {
        console.log(err);
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
          setErrMsg('UnAuthorized');
        } else if (err.response?.status === 404) {
          setErrMsg(err.response.data.error);
        } else {
          setErrMsg('Login Failed');
        }
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.primary[400],
        width: '300px',
        justifyContent: 'center',
        textAlign: 'center',
        m: 'auto',
        p: 2,
      }}
    >
      {sentEmail ? (
        <section>
          <h1> Email Sent. Please Check Your Email for Reset Link. </h1>
          <br></br>
          <Link component='button' variant='body2' key='Home' to={`/`}>
            <Typography
              sx={{
                pt: '10px',
                color: colors.blueAccent[500],
                textDecoration: 'underline',
              }}
            >
              Home
            </Typography>
          </Link>
        </section>
      ) : (
        <section>
          <form onSubmit={handleSubmit}>
            <Grid
              sx={{
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <h1>
                {' '}
                <b>Forgot Password? </b>
                <br />
                <b>Find Your Account </b>
              </h1>
              <Grid item xs={3}>
                <Stack
                  direction='column'
                  spacing={1}
                  sx={{
                    m: 'auto',
                  }}
                >
                  <TextField
                    id='input-with-icon-textfield'
                    label='Email'
                    ref={userRef}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    variant='standard'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />

                  <Typography
                    ref={errRef}
                    sx={{ color: colors.redAccent[500] }}
                    className={errMsg ? 'errmsg' : 'offscreen'}
                    aria-live='assertive'
                  >
                    {errMsg}
                  </Typography>

                  <Button
                    sx={{
                      color: colors.greenAccent[500],
                      backgroundColor: colors.primary[300],
                    }}
                    onClick={handleSubmit}
                  >
                    <b>Send Email</b>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </section>
      )}
    </Box>
  );
};

export default ForgotPassword;
