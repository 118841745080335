import { Card, Modal, useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionCheckoutForm from './SubscriptionCheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionPaymentInformation = ({ clientSecret, onCancel }) => {
  const theme = useTheme();
  const elementOptions = {
    clientSecret,
    appearance: {},
  };

  useEffect(() => {
    elementOptions.appearance.theme = theme.palette.mode === 'dark' ? 'night' : 'stripe';
  }, [elementOptions.appearance, theme.palette.mode]);

  const style = {
    position: 'absolute',
    top: '100px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 500,
    boxShadow: 24,
    p: 1,
  };

  const [showModal, setShowModal] = useState(true);
  const closeModal = () => setShowModal(false);

  const handleSuccess = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
    onCancel();
  };

  return (
    clientSecret && (
      <Modal open={showModal} onClose={closeModal}>
        <Card raised id='checkout' sx={{ ...style, flexDirection: 'col', justifyContent: 'space-between' }}>
          <Elements stripe={stripePromise} options={elementOptions}>
            <SubscriptionCheckoutForm onSuccess={handleSuccess} onCancel={handleCancel} />
          </Elements>
        </Card>
      </Modal>
    )
  );
};

export default SubscriptionPaymentInformation;
