import {
	Box,
	FormControl,
	useTheme,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	Paper,
	Stack,
	Grid,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { tokens } from "../theme";
import Header from "../components/Header";
import AddMappingForm from "../components/AddMappingForm";
import AddBookForm from "../components/AddBookForm";
import { counties } from "../assets/counties";
import ForwardIcon from "@mui/icons-material/Forward";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import PostAddIcon from "@mui/icons-material/PostAdd";
import EditIcon from "@mui/icons-material/Edit";
import { searchArrayAndUpdateFieldByID } from "../utils/setStateInArray";
import { useAuth } from "../context/AuthProvider";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../components/no_data/norowsoverlay";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import FaceIcon from "@mui/icons-material/Face";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import SaveIcon from "@mui/icons-material/Save";

const filteredCounties = counties.filter((county) => county.state === "CA");
const typesList = ["By Trade", "Custom"];
const booLean = [
	{ name: "true", value: true },
	{ name: "false", value: false },
];

const MappingDraggable = ({
	pageState,
	setPageState,
	deleteManyUsers,
	listData,
	setsentMapping,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [open, setOpen] = useState(false);
	const [renderedMappings, setRenderedMapping] = useState([]);
	const [errMsg, setErrMsg] = useState("");
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [openBook, setOpenBook] = useState(false);
	const handleOpenBook = () => setOpenBook(true);
	const handleCloseBook = () => setOpenBook(false);
	const { api } = useAuth();
	const hoverStyles = [
		{
			"&:hover": {
				backgroundColor: colors.greenAccent[600],
			},
		},
	];
	//Modal Stuff
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 390,
		bgcolor: "background.paper",
		border: "1px solid #000",
		borderRadius: 1,
		boxShadow: 24,
		// p: 4,
		overflowY: "auto",
		p: 1,
	};

	useEffect(() => {
		if (pageState.data) {
			const copy = [...pageState.data];
			const sorted = copy.sort(
				(a, b) => parseFloat(a.order) - parseFloat(b.order)
			);
			setRenderedMapping(sorted);
		}
	}, [pageState.data]);

	const uniqueNumbers = [...new Set(pageState.data.map((item) => item.order))];

	const updatePageState = (id, update) => {
		const data = searchArrayAndUpdateFieldByID(pageState.data, id, update);
		setPageState((old) => ({ ...old, data }));
	};

	const updateTrade = (id, update) => {
		if (update.mappingType === "Custom") {
			const newUpdate = {
				mappingType: "Custom",
				isCustom: true,
				trade: "",
				classification: "",
				project: "",
				county: "",
			};
			const data = searchArrayAndUpdateFieldByID(pageState.data, id, newUpdate);

			setPageState((old) => ({ ...old, data }));
		} else if (update.mappingType === "By Trade") {
			const newUpdate = {
				mappingType: "By Trade",
				isCustom: false,
				fullName: "",
			};
			const data = searchArrayAndUpdateFieldByID(pageState.data, id, newUpdate);

			setPageState((old) => ({ ...old, data }));
		}
	};

	const reorder = (id, newIndex) => {
		//create shallow copy
		const copy = [...renderedMappings];
		var oldIndex = renderedMappings.findIndex((obj) => obj.id === id);
		//select old data
		const update = renderedMappings[oldIndex];
		//update based on index
		if (newIndex > oldIndex) {
			//insert data
			copy.splice(newIndex, 0, update);
			//delete old copy
			for (let i = 0; i < copy.length; i++) {
				if (copy[i].id === id && i !== newIndex - 1) {
					copy.splice(i, 1);
					break;
				}
			}
		} else if (newIndex <= oldIndex) {
			//insert data
			copy.splice(newIndex - 1, 0, update);
			//delete data
			for (let i = 0; i < copy.length; i++) {
				if (copy[i].id === id && i !== newIndex - 1) {
					copy.splice(i, 1);
					break;
				}
			}
		}

		//ReOrder
		for (let i = 0; i < copy.length; i++) {
			copy[i].order = i + 1;
		}
		console.log("copy", copy);

		//updateMapping(copy);
	};

	//update mapping
	const updateMapping = async (body) => {
		//e.preventDefault();

		api
			.put("/api/v1/mapping/many", body)
			.then((response) => {
				//Update State
				// setPageState((old) => ({ ...old, data: response.data.data }));
			})
			.catch((err) => {
				console.log(err);
				if (!err?.response) {
					setErrMsg("Could Not Update");
				}
			});
	};

	const handleSave = () => {
		console.log("saved");
		const body = {
			renderedMappings,
		};
		updateMapping(body);
	};

	//datagrid
	const columns = [
		{
			field: "order",
			headerName: "Order",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 0.1,
		},
		{
			field: "mappingType",
			headerName: "Mapping Type",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 1,
			renderCell: ({ row: { id, mappingType, isEditable } }) => {
				return isEditable ? (
					<FormControl sx={{ pt: 1, width: "80%" }}>
						<Select
							labelId="mappingTypeLabel"
							size="small"
							id="mappingType"
							value={mappingType}
							onChange={(e) =>
								updateTrade(id, {
									mappingType: e.target.value,
								})
							}
						>
							{typesList.map((obj, index) => (
								<MenuItem key={index} value={obj}>
									{obj}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : mappingType === "Custom" ? (
					<Chip
						icon={<FaceIcon />}
						label={mappingType}
						color="primary"
						variant="outlined"
					/>
				) : (
					<Chip
						icon={<WorkOutlineIcon />}
						label={mappingType}
						color="secondary"
						variant="outlined"
					/>
				);
			},
		},
		{
			field: "fullName",
			headerName: "Full Name",
			headerClassName: "mui-data-grid-pro-headers",
			width: 100,
			cellClassName: "name-column--cell",
			renderCell: ({ row: { id, fullName, isCustom, isEditable } }) => {
				return isCustom && isEditable ? (
					<FormControl sx={{ pt: 1, width: "80%" }}>
						<InputLabel id="fullNameLabel">Full Name</InputLabel>
						<Select
							labelId="fullNameLabel"
							size="small"
							id="fullName"
							value={fullName}
							onChange={(e) =>
								updatePageState(id, { fullName: e.target.value })
							}
						>
							{listData?.personnel?.data.data.map((personnel, index) => (
								<MenuItem key={index} value={personnel.name}>
									{personnel.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					fullName
				);
			},
		},
		{
			field: "trade",
			headerName: "Trade",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 1,
			renderCell: ({ row: { id, trade, isCustom, isEditable } }) => {
				return !isCustom && !isEditable ? (
					trade
				) : !isCustom && isEditable ? (
					<FormControl sx={{ pt: 1, width: "80%" }}>
						<Select
							labelId="tradeLabel"
							size="small"
							id="trade"
							value={trade}
							onChange={(e) => updatePageState(id, { trade: e.target.value })}
						>
							{listData?.craft?.data.data.map((name, index) => (
								<MenuItem key={index} value={name}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : isCustom && isEditable ? (
					trade
				) : (
					trade
				);
			},
		},
		{
			field: "classification",
			headerName: "Classification",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 1.5,
			renderCell: ({ row: { id, classification, isCustom, isEditable } }) => {
				return isCustom ? (
					classification
				) : isEditable ? (
					<FormControl sx={{ pt: 1, width: "150px" }}>
						<Select
							labelId="classificationLabel"
							size="small"
							id="classification"
							value={classification}
							sx={{ width: "150px" }}
							onChange={(e) =>
								updatePageState(id, { classification: e.target.value })
							}
						>
							{listData?.classification?.data.data.map((name, index) => (
								<MenuItem key={index} value={name}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					classification
				);
			},
		},
		{
			field: "county",
			headerName: "County",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 1,
			renderCell: ({ row: { id, county, isCustom, isEditable } }) => {
				return isCustom ? (
					county
				) : isEditable ? (
					<FormControl sx={{ pt: 1, width: "80%" }}>
						<Select
							labelId="countyLabel"
							size="small"
							id="county"
							value={county}
							onChange={(e) => updatePageState(id, { county: e.target.value })}
						>
							{filteredCounties.map((county, index) => (
								<MenuItem key={index} value={county.county}>
									{county.county}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					county
				);
			},
		},
		{
			field: "project",
			headerName: "Project",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 1,
			renderCell: ({ row: { id, project, isCustom, isEditable } }) => {
				return isCustom ? null : isEditable ? (
					<FormControl sx={{ pt: 1, width: "80%" }}>
						<Select
							labelId="projectLabel"
							size="small"
							id="project"
							value={project}
							onChange={(e) => updatePageState(id, { project: e.target.value })}
						>
							{listData?.project?.data.data.map((obj, index) => (
								<MenuItem key={index} value={obj}>
									{obj}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : null;
			},
		},
		{
			field: "arrow",
			headerName: "Maps To",
			headerClassName: "mui-data-grid-pro-headers",
			width: 50,
			renderCell: () => {
				return <ForwardIcon />;
			},
		},
		{
			field: "book",
			headerName: "Book",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 1,
			renderCell: ({ row: { id, book, isEditable } }) => {
				return isEditable ? (
					<FormControl sx={{ pt: 1, width: "99%" }}>
						<Select
							labelId="book Label"
							size="small"
							id="book"
							value={book}
							onChange={(e) => updatePageState(id, { book: e.target.value })}
							onPageChange={(newPage) =>
								setPageState((old) => ({ ...old, book: book }))
							}
						>
							{listData?.book?.data.data.map((book, index) => (
								<MenuItem key={index} value={book}>
									{book}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					book
				);
			},
		},
		{
			field: "edit",
			headerName: "Edit",
			headerClassName: "mui-data-grid-pro-headers",
			flex: 1,
			renderCell: ({ row: { isEditable, id } }) => {
				return (
					<FormControl sx={{ pt: 1, width: "99%" }}>
						<Select
							labelId="edit Label"
							size="small"
							id="isEditable"
							value={isEditable}
							onChange={(e) =>
								updatePageState(id, { isEditable: e.target.value })
							}
						>
							{booLean.map((truth, index) => (
								<MenuItem key={index} value={truth.value}>
									{truth.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			},
		},
	];

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton sx={hoverStyles} />
				<GridToolbarFilterButton sx={hoverStyles} />
				<GridToolbarDensitySelector sx={hoverStyles} />
				<GridToolbarExport sx={hoverStyles} />
				<Button
					sx={hoverStyles}
					startIcon={<PostAddIcon />}
					onClick={handleOpen}
				>
					Create Map
				</Button>
				<Button
					sx={hoverStyles}
					startIcon={<PostAddIcon />}
					onClick={handleOpenBook}
				>
					Create Book
				</Button>
				<Button sx={hoverStyles} startIcon={<SaveIcon />} onClick={handleSave}>
					Save
				</Button>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						{listData.project &&
						listData.craft &&
						listData.classification &&
						listData.book ? (
							<div className="center">
								<AddMappingForm
									listData={listData}
									filteredCounties={filteredCounties}
									pageState={pageState}
									setsentMapping={setsentMapping}
									setOpen={setOpen}
								/>
							</div>
						) : null}
					</Box>
				</Modal>
				<Modal
					open={openBook}
					onClose={handleCloseBook}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						{listData.project &&
						listData.craft &&
						listData.classification &&
						listData.book ? (
							<div className="center">
								<AddBookForm
									listData={listData}
									filteredCounties={filteredCounties}
								/>
							</div>
						) : null}
					</Box>
				</Modal>
			</GridToolbarContainer>
		);
	}

	//row reordering
	function updateRowPosition(initialIndex, newIndex, rows) {
		return new Promise((resolve) => {
			setTimeout(() => {
				const rowsClone = [...rows];
				const row = rowsClone.splice(initialIndex, 1)[0];
				rowsClone.splice(newIndex, 0, row);
				resolve(rowsClone);
			}, Math.random() * 500 + 100); // simulate network latency
		});
	}

	// useEffect(() => {
	// 	setLoading(initialLoadingState);
	// }, [initialLoadingState]);

	const handleRowOrderChange = async (params) => {
		const oldOrder = params.oldIndex + 1;
		const newOrder = params.targetIndex + 1;

		console.log(`Converting ${oldOrder} order to ${newOrder}`);
		// setLoading(true);
		const newRows = await updateRowPosition(
			params.oldIndex,
			params.targetIndex,
			renderedMappings
		);

		for (let i = 0; i < newRows.length; i++) {
			newRows[i].order = i + 1;
		}

		setRenderedMapping(newRows);
		// setLoading(false);
	};

	//Detail Panel
	function DetailPanelContent({ row: rowProp }) {
		let columns;

		columns = [
			{ field: "key", headerName: "Key", flex: 1 },
			{
				field: "value",
				headerName: "details",

				flex: 1,
			},
		];

		let array = [];
		for (const [key, value] of Object.entries(rowProp)) {
			const id = Math.random();
			array.push({ id, key, value });
		}
		console.log("array", array);
		const rowPropArray = [rowProp];
		return (
			<Stack
				sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
				direction="column"
			>
				<Paper sx={{ flex: 1, mx: "auto", width: "50%", p: 1 }}>
					<Stack direction="column" spacing={1} sx={{ height: 1 }}>
						<Typography variant="h6">{`Mapping ID #${rowProp.id}`}</Typography>
						<Grid container>
							<Grid item md={6}>
								<Typography variant="body2" color="textSecondary">
									Mapping Order
								</Typography>
								<Typography variant="body1">{rowProp.order}</Typography>
								<Typography variant="body1">{rowProp.project}</Typography>
							</Grid>
							<Grid item md={6}>
								<Typography variant="body2" align="right" color="textSecondary">
									Mapping Type
								</Typography>
								<Typography variant="body1" align="right">
									{rowProp.mappingType}
								</Typography>
							</Grid>
						</Grid>
						<DataGridPro
							density="compact"
							columns={columns}
							rows={array}
							sx={{ flex: 1 }}
							hideFooter
						/>
					</Stack>
				</Paper>
			</Stack>
		);
	}

	const getDetailPanelContent = useCallback(
		({ row }) => <DetailPanelContent row={row} />,
		[]
	);

	const getDetailPanelHeight = useCallback(() => 400, []);

	return (
		<Box m="10px 20px 20px 20px">
			<Header title="Mapping" subtitle="Managing The Maps" />
			<Box
				m="0 20px 0 0"
				height="75vh"
				sx={{
					"& .MuiDataGridPro-root": {
						border: "none",
					},
					"& .MuiDataGridPro-cell": {
						borderBottom: "none",
					},
					"& .name-column--cell": {
						color: colors.greenAccent[300],
					},
					"& .name=column--cell": {
						color: colors.greenAccent[300],
					},
					"& .MuiDataGridPro-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGridPro-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
					"& .MuiDataGridPro-footerContainer": {
						borderTop: "none",
						backgroundColor: colors.blueAccent[700],
					},
					"& .MuiDataGridPro-toolbarContainer .MuiButton-text": {
						color: `${colors.grey[100]} !important`,
					},
					"& .mui-data-grid-pro-headers": {
						backgroundColor: colors.primary[400],
					},
				}}
			>
				<DataGridPro
					rows={renderedMappings}
					rowReordering={true}
					onRowOrderChange={handleRowOrderChange}
					//   rowCount={pageState.total}
					//   loading={pageState.isLoading}
					//   pagination
					//   page={pageState.page}
					//   pageSize={pageState.pageSize}
					//   pageSizeOptions={[50, 100]}
					//   paginationMode='server'
					//   onPageChange={(newPage) =>
					//     setPageState((old) => ({ ...old, page: newPage }))
					//   }
					//   onPageSizeChange={(newPageSize) =>
					//     setPageState((old) => ({ ...old, pageSize: newPageSize }))
					//   }
					columns={columns}
					slots={{
						toolbar: CustomToolbar,
						noRowsOverlay: CustomNoRowsOverlay,
						loadingOverlay: LinearProgress,
					}}
					loading={pageState.loading}
					getDetailPanelHeight={getDetailPanelHeight}
					getDetailPanelContent={getDetailPanelContent}
					//   checkboxSelection={CheckBox}
					//   onSelectionModelChange={(ids) => {
					//     console.log(ids);
					//   }}
					//   sx={{
					//     '& .MuiCheckbox-colorPrimary': {
					//       color: colors.grey[100],
					//     },
					//   }}
				/>
				<Typography variant="h6" color={colors.grey[100]}>
					{" "}
					Will process payroll in order of operations specified.{" "}
				</Typography>
				<Typography variant="h6" color={colors.grey[100]}>
					{" "}
					{errMsg}{" "}
				</Typography>
			</Box>
		</Box>
	);
};

export default MappingDraggable;
