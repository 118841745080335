//loading circle muicircularprogress
import { useEffect } from 'react';
import { CircularProgress, Stack, Box } from '@mui/material';

const LoadingCircle = ({ progress, setProgress, isLoaded, setisLoaded }) => {
  return (
    <Box>
      {isLoaded ? null : (
        <Stack spacing={2} direction='row'>
          <CircularProgress variant='determinate' value={progress} />
        </Stack>
      )}
    </Box>
  );
};

export default LoadingCircle;
