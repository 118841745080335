//calculate the Analytics in [ {obj1}, .... ]
//argument 1 = provide the field for analytics
//argument 2 = provide the property of the objects

export const projectAnalytics = (array) => {
  const arrayCopy = [...array];
  console.log(arrayCopy);
  if (arrayCopy) {
    let newArray = [];
    for (let i = 0; i < arrayCopy.length; i++) {
      let elem = arrayCopy[i].analytics;
      for (let j = 0; j < elem.length; j++) {
        newArray.push(elem[j]);
      }
    }

    var holder = {};

    newArray.forEach(function (d) {
      if (holder.hasOwnProperty(d.project)) {
        holder[d.project] =
          Number(holder[d.project]) +
          Number(d.regTotal) +
          Number(d.satTotal) +
          Number(d.OTTotal) +
          Number(d.sunTotal);
      } else {
        holder[d.project] =
          Number(d.regTotal) +
          Number(d.satTotal) +
          Number(d.OTTotal) +
          Number(d.sunTotal);
      }
    });

    var outputArray = [];

    for (var prop in holder) {
      outputArray.push({ project: prop, totalHours: holder[prop] });
    }
    console.log(outputArray);
    return outputArray;
  }
};
