import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';

function DateBox({ name, value, field, label, callBack }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MobileDateTimePicker
      name={name}
      label={label}
      ampmm={true}
      value={value || null}
      onChange={callBack}
      sx={{
        background: `${colors.primary[400]} !important`,
        '& .MuiTabs-root': {
          color: colors.redAccent[100],
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiInputBase-input': {
              // background: `${colors.primary[200]} !important`,
              color: colors.primary[100],
              p: 0.5,
              m: 0,
            },
            '& .MuiFormLabel-root': {
              color: colors.greenAccent[400],
            },
            '&:hover': {
              color: colors.primary[100],
            },
          }}
        />
      )}
    />
  );
}

export default DateBox;
