import Popover from "@mui/material/Popover";
import { Box, Typography } from "@mui/material";

const NotificationsPopover = ({
	idPopOut,
	openPopOut,
	anchorElPopOut,
	setAnchorElPopOut,
	message,
}) => {
	const handlePopOutClose = () => {
		setAnchorElPopOut(null);
	};

	//   const id = open ? 'simple-popover' : undefined;
	return (
		<Box>
			{/* <Button aria-describedby={id} onClick={handleClick}>
        Query
      </Button> */}
			<Popover
				id={idPopOut}
				open={openPopOut}
				anchorEl={anchorElPopOut}
				onClose={handlePopOutClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box>
					<Typography align="center" sx={{ m: "10px" }}>
						{`You have ${message} unapproved timecard`}
					</Typography>
				</Box>
			</Popover>
		</Box>
	);
};

export default NotificationsPopover;
