import dayjs from 'dayjs';
//first we must order the objects in array
//import rules
//check for errors
//calculate the differences and sum together
export function calculateObjectsInArray(log) {
  //rules subtract break after 5 hours
  const breakHour = 5.5;
  //between 8 and 12
  const OTHourLimit = 8.5;
  const breakDuration = 0.5;

  //case 1: less than 5
  // const sortedLog = log.sort((t1, t2) =>
  //   t1.start < t2.start ? 1 : t1.start > t2.start ? -1 : 0
  // );

  const grossHours = [];
  for (let i = 0; i < log.length; i++) {
    const diff = log[i].end - log[i].start;
    //diff in milliseconds

    //divide time by 1/3600000 for hours
    const hours = diff / 3600000;
    if (hours > 0) {
      grossHours.push(hours);
    }
  }
  const sum = grossHours.reduce((partialSum, a) => partialSum + a, 0);
  let reg;
  let OT;

  if (sum < breakHour) {
    reg = sum;
    console.log(sum);
    OT = 0;
  } else if (OTHourLimit >= sum && sum >= breakHour) {
    reg = sum - breakDuration;
    OT = 0;
    console.log(sum);
  } else if (sum > OTHourLimit) {
    reg = OTHourLimit - breakDuration;
    OT = sum - OTHourLimit;
  }

  //console.log(reg, OT);
  //spread operator creates  shallow copy to avoid mutating log
  const sortedLog = [...log].sort(function (a, b) {
    return dayjs(a.start) - dayjs(b.start);
  });
  //console.log('S O R T E D', dayjs(sortedLog[0].start).unix());
  //console.log('S O R T E D', dayjs(sortedLog[1].start).unix());
  // console.log('S O R T E D', sortedLog);
  //console.log('debugging', reg);
  //const reg, OT hours calculation settings
  const calcSettings = {
    regHours: reg,
    OTHours: OT,
    grossHours: sum,
    grossHoursArray: grossHours,
    breakHour: breakHour,
    breakDuration: breakDuration,
    OTHourLimit: OTHourLimit,
    creditedHours: 0,
  };

  // console.log(calcSettings);

  for (let i = 0; i < sortedLog.length; i++) {
    const elem = sortedLog[i];
    var reg1 = applyChangeToOrig(i, elem, calcSettings, log);
  }

  // console.log('T S E T I N G', reg1);

  //check credited hours match gross
  const err = checkFinal(calcSettings);
  return [sum, reg, OT, log, err];
}

// --------------------------------------------------------//

//tasks grab mapping labels
//calculate hours
//apply changes to start and end of original log
//i element from for loop
function applyChangeToOrig(i, elem, calcSettings, log) {
  const diff = elem.end - elem.start;
  const hours = diff / 3600000;
  let delta;
  if (hours > 0) {
    delta = hours;
  } else if (hours <= 0) {
    delta = 0;
  }
  let regHours;
  let OTHours;
  const newTotal = calcSettings.creditedHours + delta;

  if (delta === 0) {
    return;
  }
  //the gross hours will tell us if there are OT or not
  //case 1: no breaks, all reg hours, eg [2 2 1]
  else if (newTotal < calcSettings.breakHour) {
    regHours = delta;
    OTHours = 0;
    console.log(
      `Case #1 no breaks, all reg hours with Reg: ${regHours} + OT: ${OTHours}`
    );
    //apply changes
    applyChangesToElem(elem, log, regHours, OTHours, calcSettings);
  }
  //case 2: 1 break, some reg hours, no OT, eg [5 2]
  else if (
    newTotal > calcSettings.breakHour &&
    newTotal <= calcSettings.OTHourLimit &&
    calcSettings.creditedHours < calcSettings.breakHour
  ) {
    //check if hours occur before break time
    if (newTotal < calcSettings.breakHour) {
      regHours = delta;
      OTHours = 0;
      console.log(
        `Case #2 [No break, No OT] - Reg: ${regHours} + OT: ${OTHours}`
      );
      applyChangesToElem(elem, log, regHours, OTHours, calcSettings);
    } else if (newTotal >= calcSettings.breakHour) {
      regHours = delta - calcSettings.breakDuration;
      OTHours = 0;
      console.log(
        `Case #2 [1 break, No OT] - Reg: ${regHours} + OT: ${OTHours}`
      );
      applyChangesToElem(elem, log, regHours, OTHours, calcSettings);
    }
  }
  //case 3: 1 break, this element will have some reg hours, expecting some OT
  //break down the reg and ot to each element passed in
  else if (
    calcSettings.creditedHours <= calcSettings.breakHour &&
    newTotal > calcSettings.OTHourLimit
  ) {
    if (delta > 0) {
      regHours = delta >= calcSettings.OTHourLimit ? 8 : 0;
      // calcSettings.OTHourLimit -
      // calcSettings.breakDuration -
      // calcSettings.creditedHours;
      OTHours = delta - calcSettings.OTHourLimit;
      console.log(
        `Case #3 [1 break, some reg, some OT] - Reg: ${regHours} + OT: ${OTHours}`
      );
      applyChangesToElem(elem, log, regHours, OTHours, calcSettings);
    }
  }

  //case 4: break has already been applied
  // some regular, no OT
  else if (
    calcSettings.creditedHours >= calcSettings.breakHour &&
    newTotal <= calcSettings.OTHourLimit
  ) {
    if (delta > 0) {
      regHours = calcSettings.OTHourLimit - calcSettings.creditedHours;
      OTHours = 0;
      console.log(
        `Case #4 [ break already applied, No OT] - Reg: ${regHours} + OT: ${OTHours}`
      );
      applyChangesToElem(elem, log, regHours, OTHours, calcSettings);
    }
  }
  //case 5: break has already been applied
  // some regular, some OT
  else if (
    calcSettings.creditedHours >= calcSettings.breakHour &&
    newTotal > calcSettings.OTHourLimit
  ) {
    if (delta > 0) {
      regHours =
        calcSettings.OTHourLimit -
        calcSettings.creditedHours -
        calcSettings.breakDuration;
      OTHours = delta - regHours;
      console.log(
        `Case #5 [break already applied, Some OT] - Reg: ${regHours} + OT: ${OTHours}`
      );
      applyChangesToElem(elem, log, regHours, OTHours, calcSettings);
    }
  }

  //case 6: break already applied, all OT
  else if (
    calcSettings.creditedHours >= calcSettings.OTHourLimit &&
    newTotal > calcSettings.OTHourLimit
  ) {
    if (delta > 0) {
      regHours = 0;
      OTHours = delta;
      console.log(`Case #6 [All OT] - Reg: ${regHours} + OT: ${OTHours}`);
      applyChangesToElem(elem, log, regHours, OTHours, calcSettings);
    }
  }

  // console.log('F I N A L L O G', log);
  return [log, regHours, OTHours];
}

// --------------------------------------------------------//
//Helper Function

//We are applying changes to original array, not sorted
function applyChangesToElem(elem, log, regHours, OTHours, calcSettings) {
  const oldlabel = elem.id;
  const oldIndex = oldlabel.slice(oldlabel.length - 1);
  log[oldIndex - 1].reg = regHours;
  log[oldIndex - 1].OT = OTHours;
  calcSettings.creditedHours = calcSettings.creditedHours + regHours + OTHours;
}

function checkFinal(calcSettings) {
  let err;
  if (calcSettings.creditedHours === calcSettings.grossHours) {
    err = false;
    return err;
  } else {
    err = true;
    return err;
  }
}

// --------------------------------------------------------//

// --------------------------------------------------------//

export function calculateWeekendHoursArray(log, setWeekend) {
  const arrayCopy = [...log];
  console.log('arrayCopy', arrayCopy);
  const breakHour = 5.5;
  //between 8 and 12
  const breakDuration = 0.5;
  const grossHours = [];
  for (let i = 0; i < arrayCopy.length; i++) {
    console.log('arrayCopy[i]', arrayCopy[i]);
    const diff = arrayCopy[i].end - arrayCopy[i].start;
    //diff in milliseconds

    if (typeof diff !== 'number') {
      console.error('Error: diff is not a number');
    }

    //divide time by 1/3600000 for hours
    const hours = diff / 3600000;
    console.log('hours', hours);
    if (hours > 0) {
      grossHours.push(hours);
      arrayCopy[i].reg = hours;
    }
  }
  //update log
  let weekendHours = grossHours.reduce((partialSum, a) => partialSum + a, 0);
  if (weekendHours >= breakHour) {
    weekendHours = weekendHours - breakDuration;
  }

  //update final hours

  const finalWeekEndLog = arrayCopy;
  console.log('finalWeekendLog', finalWeekEndLog);
  return [weekendHours, finalWeekEndLog];
}
