//returns 1) boolean for loading 2) data 3) error
import { useMemo, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import usePath from './fetchPath';

//project [string]
//start [datetime]
//end [datetime]
function useFetch(path, project, start, end) {
  const { api } = useAuth();
  let { fullPath } = usePath(path);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let query1;
  if (project) {
    query1 = `&project=${project}`;
  } else {
    query1 = '';
  }

  let query2;
  if (start) {
    query2 = `&weekStart[gte]=${start}`;
    console.log(query2);
  } else {
    query2 = '';
  }

  let query3;
  if (end) {
    query3 = `&weekEnd[lte]=${end}`;
    console.log(query3);
  } else {
    query3 = '';
  }

  fullPath = path + query1 + query2 + query3;

  useMemo(() => {
    if (api.authenticated) {
      setLoading(true);
      api
        .get(fullPath)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((err) => {
          setError(err);
          setData([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullPath, api?.authenticated]);

  return { data, loading, error };
}

export default useFetch;
