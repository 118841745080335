import {
  Box,
  Typography,
  useTheme,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import {
  DataGrid,
  GridFooter,
  GridFooterContainer,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { tokens } from '../theme';
import { useState, useEffect } from 'react';
import Header from '../components/Header';
import { List, ListItem, TextField } from '@mui/material';
import { searchArrayAndUpdateFieldByID } from '../utils/setStateInArray';
import SaveIcon from '@mui/icons-material/Save';
import { useAuth } from '../context/AuthProvider';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddMappingForm from '../components/AddMappingForm';
import AddBookForm from '../components/AddBookForm';
import { IOSSwitch } from '../components/IOSSwitch';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { counties } from '../assets/counties';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
var weekday = require('dayjs/plugin/weekday');
dayjs.extend(weekday);

const PayrollProcess = ({
  pageState,
  personnel,
  listData,
  updatedTimecards,
}) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [mode, setMode] = useState(dayjs().weekday(-1).format('MM/DD/YY'));
  const { api } = useAuth();
  const booLean = [
    { name: 'true', value: true },
    { name: 'false', value: false },
  ];
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openBook, setOpenBook] = useState(false);
  const handleOpenBook = () => setOpenBook(true);
  const handleCloseBook = () => setOpenBook(false);
  const filteredCounties = counties.filter((county) => county.state === 'CA');
  const [selectedDataID, setselectedDataID] = useState([]);
  useEffect(() => {
    if (updatedTimecards?.length > 0) {
      setData(updatedTimecards);
    }
  }, [updatedTimecards]);

  async function handleMappingChange(mappingID, timecardID, timecard) {
    if (listData.mapping) {
      //searcj mapping array for mappingID
      //this should run without error
      const foundOne = listData.mapping.data.data.find(
        (element) => element._id === mappingID
      );

      //success case
      try {
        //query ratebook for new rates
        await queryRateBookAndUpdateData(timecard, foundOne);
      } catch (error) {
        setErr(true);
      }
    }
  }

  //return an query ratebook
  const queryRateBookAndUpdateData = async (timecard, map) => {
    const bookName = map.book;
    let output;
    if (api.authenticated) {
      await api
        .get(`/api/v1/ratebook?book=${bookName}`)
        .then((response) => {
          const consolidated = {
            regRate: response.data.data[0].regRate,
            OTRate: response.data.data[0].OTRate,
            satRate: response.data.data[0].satRate,
            sunRate: response.data.data[0].sunRate,
            trainingRate: response.data.data[0].training,
            mapping: map,
          };
          const final = { ...timecard, ...consolidated };
          const dataCopy = [...data];
          const updated = searchArrayAndUpdateFieldByID(
            dataCopy,
            timecard.id,
            final
          );

          setData(updated);
          return (output = final);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return output;
  };

  const updatePageState = (id, update) => {
    const dataCopy = [...data];
    const updated = searchArrayAndUpdateFieldByID(dataCopy, id, update);
    setData(updated);
  };

  function savePayroll() {
    console.log('Saving Payroll');
  }

  function findByProperty(searchByID, property) {
    if (personnel) {
      const foundOne = personnel.find(
        (element) => element.employeeID === searchByID
      );
      if (foundOne) {
        return foundOne[property];
      }
    }
  }

  const hoverStyles = [
    {
      '&:hover': {
        backgroundColor: colors.greenAccent[600],
      },
    },
  ];
  //Modal Stuff
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    // p: 4,
    overflowY: 'auto',
    p: 1,
  };

  function handleClick() {
    console.info('You clicked the Chip.');
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={handleOpen}>
          Create Map
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={handleOpenBook}>
          Create Book
        </Button>
        <Button sx={hoverStyles} startIcon={<SaveIcon />} onClick={savePayroll}>
          Save
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            {listData.project &&
            listData.craft &&
            listData.classification &&
            listData.book ? (
              <div className='center'>
                <AddMappingForm
                  listData={listData}
                  filteredCounties={filteredCounties}
                  pageState={pageState}
                />
              </div>
            ) : null}
          </Box>
        </Modal>
        <Modal
          open={openBook}
          onClose={handleCloseBook}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            {listData.project &&
            listData.craft &&
            listData.classification &&
            listData.book ? (
              <div className='center'>
                <AddBookForm
                  listData={listData}
                  filteredCounties={filteredCounties}
                />
              </div>
            ) : null}
          </Box>
        </Modal>
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    let count, reg, OT, sat, sun, total, totalWages;

    if (data.length > 0) {
      count = data.length;
      reg = data.reduce((n, { regularHours }) => n + regularHours, 0);
      OT = data.reduce((n, { OTHours }) => n + OTHours, 0);
      sat = data.reduce((n, { saturdayHours }) => n + saturdayHours, 0);
      sun = data.reduce((n, { sundayHours }) => n + sundayHours, 0);
      total = reg + OT + sat + sun;

      const dataCopy = [...data];
      //calculate total wages
      dataCopy.forEach((row) => {
        const { regRate, regularHours } = row;
        //totalWages += regRate * regularHours;
        if (regRate >= 0 && regularHours >= 0) {
          row.wages = regRate * regularHours;
        } else {
          row.wages = 0;
        }
      });

      totalWages = dataCopy.reduce((n, { wages }) => n + wages, 0);
    }

    function handleModeChange(e) {
      setMode(e.target.value);
    }

    return (
      <GridFooterContainer>
        {/* Add what you want here */}
        <Box sx={{ p: 1, width: '150px' }}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Payroll For</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              size='small'
              value={mode}
              onChange={handleModeChange}>
              <MenuItem value={dayjs().weekday(-8).format('MM/DD/YY')}>
                {dayjs().weekday(-8).format('MM/DD/YY')}
              </MenuItem>
              <MenuItem value={dayjs().weekday(-1).format('MM/DD/YY')}>
                {dayjs().weekday(-1).format('MM/DD/YY')}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          Count: {count}
        </Typography>
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          Reg: {reg}
        </Typography>
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          OT: {OT}
        </Typography>
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          Sat: {sat}
        </Typography>
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          Sun: {sun}
        </Typography>
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          Total Hours: {total}
        </Typography>
        <Typography variant='H6' sx={{ ml: '10px' }}>
          {' '}
          Total Wages: ${totalWages}
        </Typography>

        <GridFooter
          sx={{
            border: 'none', // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      field: 'employeeID',
      headerName: 'E. ID',
      width: 5,
      renderCell: ({ row: { employeeID } }) => {
        return (
          <Box display='flex' justifyContent='center' borderRadius='4px'>
            <Typography sx={{ fontSize: 12 }}>{employeeID}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 0.2,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 0.2,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'trade',
      headerName: 'Role',
      flex: 0.2,
      renderCell: ({ row: { employeeID, isEditable } }) => {
        let role = findByProperty(employeeID, 'trade');
        return isEditable ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='tradeTypeLabel'>Role</InputLabel>
            <Select
              labelId='tradeTypeLabel'
              size='small'
              id='tradeType'
              value={role}
              onChange={(e) => console.log('trade', e.target.value)}>
              {listData?.craft.data.data.map((obj, index) => (
                <MenuItem key={index} name='mappingType' value={obj}>
                  {obj}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          role
        );
      },
    },
    {
      field: 'classification',
      headerName: 'Classification',
      flex: 0.2,
      renderCell: ({ row: { employeeID, isEditable } }) => {
        let classification = findByProperty(employeeID, 'classification');
        return isEditable ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='classificationTypeLabel'>Classification</InputLabel>
            <Select
              labelId='classificationTypeLabel'
              size='small'
              id='classificationType'
              value={classification}
              onChange={(e) => console.log('classification', e.target.value)}>
              {listData?.classification.data.data.map((obj, index) => (
                <MenuItem key={index} name='classificationType' value={obj}>
                  {obj}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null;
      },
    },
    {
      field: 'project',
      headerName: 'Project',
      width: 100,
      renderCell: ({ row: { project, weekEnd } }, i) => {
        return (
          <List sx={{ alignItems: 'left' }}>
            {project.map((elem, i) => {
              const id = elem.project + weekEnd;
              return (
                <ListItem key={i + { id }} sx={{ p: 0, alignItems: 'left' }}>
                  {' '}
                  {elem.project}{' '}
                </ListItem>
              );
            })}
          </List>
        );
      },
    },
    { field: 'regularHours', headerName: ' Reg', width: 10 },
    { field: 'OTHours', headerName: 'OT', width: 10 },
    { field: 'saturdayHours', headerName: 'Sat', width: 10 },
    { field: 'sundayHours', headerName: 'Sun', width: 10 },
    {
      field: 'weekEnd',
      headerName: 'Week End',
      width: 90,
      renderCell: ({ row: { project, weekEnd } }, i) => {
        return weekEnd;
      },
    },
    {
      field: 'mappingID',
      headerName: ' Map ID',
      width: 50,
      renderCell: ({ row: { mapping } }) => {
        return mapping ? (
          <Typography size='small'> {mapping?.mappingID} </Typography>
        ) : null;
      },
    },
    {
      field: 'mapping',
      headerName: ' Mapping',
      width: 100,
      renderCell: ({ row }) => {
        const timecard = row;
        const { mapping, isEditable, id } = row;
        // console.log(row);
        let mappingValue;
        if (mapping) {
          mappingValue = mapping.id;
        } else {
          mappingValue = null;
        }

        return mapping ? (
          <Typography size='small'> {mapping.book} </Typography>
        ) : isEditable ? (
          <FormControl sx={{ width: '99%' }}>
            <InputLabel id='mappingTypeLabel'>Mapping</InputLabel>
            <Select
              labelId='mappingTypeLabel'
              size='small'
              id='mappingType'
              value={mappingValue}
              onChange={(e) => {
                const mappingID = e.target.value;
                const timecardID = id;
                handleMappingChange(mappingID, timecardID, timecard);
                //updatePageState(id, { mapping: e.target.value });
              }}>
              {listData?.mapping.data.data.map((obj, index) => (
                <MenuItem key={index} name='mappingType' value={obj.id}>
                  {obj.book}
                </MenuItem>
              ))}
              <MenuItem value='null'>Choose A Value</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Chip label='Add Map' onClick={handleClick} />
        );
      },
    },
    {
      field: 'regRate',
      headerName: ' RegRate',
      width: 75,
      renderCell: ({ row: { id, mapping, regRate, isEditable } }, i) => {
        return mapping && regRate && isEditable ? (
          <TextField
            id='regRate'
            size='small'
            value={regRate}
            onChange={(e) => {
              updatePageState(id, { regRate: e.target.value });
            }}
          />
        ) : isEditable ? (
          <TextField size='small' defaultValue={0} />
        ) : null;
      },
    },
    {
      field: 'OTRate',
      headerName: 'OTRate',
      width: 75,
      renderCell: ({ row: { id, mapping, isEditable, OTRate } }, i) => {
        return mapping && OTRate && isEditable ? (
          <TextField
            id='OTRate'
            size='small'
            value={OTRate}
            onChange={(e) => {
              updatePageState(id, { OTRate: e.target.value });
            }}
          />
        ) : isEditable ? (
          <TextField size='small' defaultValue={0} />
        ) : null;
      },
    },
    {
      field: 'satRate',
      headerName: 'SatRate',
      width: 75,
      renderCell: ({ row: { id, mapping, isEditable, satRate } }, i) => {
        return satRate >= 0 && isEditable ? (
          <TextField
            id='satRate'
            size='small'
            value={satRate}
            onChange={(e) => {
              updatePageState(id, { satRate: e.target.value });
            }}
          />
        ) : isEditable ? (
          <TextField size='small' defaultValue={0} />
        ) : null;
      },
    },
    {
      field: 'sunRate',
      headerName: 'SunRate',
      width: 75,
      renderCell: ({ row: { id, mapping, isEditable, sunRate } }, i) => {
        return mapping && isEditable ? (
          <TextField
            id='sunRate'
            size='small'
            value={sunRate}
            onChange={(e) => {
              updatePageState(id, { sunRate: e.target.value });
            }}
          />
        ) : isEditable ? (
          <TextField size='small' defaultValue={0} />
        ) : null;
      },
    },
    {
      field: 'trainingRate',
      headerName: 'TrainRate',
      width: 75,
      renderCell: ({ row: { id, mapping, isEditable, trainingRate } }, i) => {
        return isEditable ? (
          <TextField
            id='trainingRate'
            size='small'
            value={trainingRate}
            onChange={(e) => {
              updatePageState(id, { trainingRate: e.target.value });
            }}></TextField>
        ) : null;
      },
    },
    {
      field: 'deductions',
      headerName: 'Deductions',
      width: 75,
      renderCell: ({ row: { regularHours, trainingRate, isEditable } }, i) => {
        const calculation = (regularHours * trainingRate).toFixed(2);
        return regularHours && trainingRate >= 0 ? (
          <Typography> ${calculation} </Typography>
        ) : isEditable ? (
          <TextField size='small' defaultValue={0} />
        ) : null;
      },
    },
    {
      field: 'wages',
      headerName: 'Wages',
      width: 75,
      renderCell: ({ row: { regRate, regularHours, isEditable, id } }, i) => {
        const wages = (regRate * regularHours).toFixed(2);
        //updatePageState(id, { wages: wages });
        return regRate && regularHours ? (
          <Typography> ${wages} </Typography>
        ) : isEditable ? (
          <TextField size='small' defaultValue={0} />
        ) : null;
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 0.2,
      renderCell: ({ row: { isEditable, id } }) => {
        return (
          <IOSSwitch
            checked={isEditable}
            id='isEditable'
            onChange={(e) => {
              updatePageState(id, { isEditable: e.target.checked });
            }}
          />
        );
      },
    },
  ];

  return (
    <Box m='10px 20px 20px 20px'>
      <Header title='PAYROLL' subtitle='Processing the Payroll' />
      {err ? <Alert severity='error'>This is an error Alert.</Alert> : null}
      <Box
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .name=column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
          disableSelectionOnClick
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setselectedDataID(ids);
          }}
        />
      </Box>
    </Box>
  );
};

export default PayrollProcess;
