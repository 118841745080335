import React from 'react';
import TextField from '@mui/material/TextField';
import {
  Box,
  Typography,
  useTheme,
  Stack,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { tokens } from '../theme';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import Header from '../components/Header';
import SendIcon from '@mui/icons-material/Send';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';

const details = {
  requestDate: dayjs(),
  respondByDate: dayjs().add(21, 'day'),
  to: '',
  from: '',
  project: '',
  projectLocation: '',
  summary: '',
  question: '',
  response: '',
  approved: false,
};

const AddRFISchema = Yup.object().shape({
  requestDate: Yup.date().required('Required'),
  respondByDate: Yup.date().required('Required'),
  to: Yup.string().required('Required'),
  from: Yup.string().required('Required'),
  project: Yup.string().required('Required'),
  projectLocation: Yup.string().required('Required'),
  summary: Yup.string().required('Required'),
  question: Yup.string().required('Required'),
  response: Yup.string().required('Required'),
  approved: Yup.boolean(),
});

const AddRFIForm = ({ open, handleCloseBook, setAllProjects, listData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { api } = useAuth();
  const [changeOrderDetails] = useState(details);
  const [statusMessage, setStatusMessage] = useState(null);
  const [isErr, setIsErr] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const formik = useFormik({
    initialValues: changeOrderDetails,
    validationSchema: AddRFISchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      createRFI(values);
    },
  });

  // Change Order Create Stuff
  const createRFI = async (details) => {
    setIsErr(false);
    setIsSent(false);
    await api
      .post('/api/v1/rfi', JSON.stringify(details))
      .then(() => {
        setStatusMessage('Success!');
        setIsSent(true);
        handleCloseBook();
      })
      .catch((error) => {
        setStatusMessage('Error Occurred');
        setIsErr(true);
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Header title={'Create A New RFI'} subtitle={'Enter Below'} />
      <Typography variant='h6' sx={{ mt: 2, color: colors.greenAccent[400] }}>
        {' '}
        Required Now
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ width: '33.3%' }}>
          <InputLabel id='toLabel'>To</InputLabel>
          <Select
            labelId='toLabel'
            size='small'
            id='to'
            value={formik.values.to}
            onChange={(e) => {
              formik.setFieldValue('to', e.target.value);
            }}
            error={formik.touched.to && Boolean(formik.errors.to)}
            // helperText={formik.touched.to && formik.errors.to}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {listData?.personnel.data.data.map((elem, index) => (
              <MenuItem key={index} value={elem._id}>
                {elem.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '33.3%' }}>
          <InputLabel id='fromLabel'>From</InputLabel>
          <Select
            labelId='fromLabel'
            size='small'
            id='from'
            value={formik.values.from}
            onChange={(e) => {
              formik.setFieldValue('from', e.target.value);
            }}
            format='MM-DD-YYYY'
            error={formik.touched.from && Boolean(formik.errors.from)}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {listData?.personnel.data.data.map((elem, index) => (
              <MenuItem key={index} value={elem._id}>
                {elem.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '33.3%' }}>
          <InputLabel id='projectLabel'>Project</InputLabel>
          <Select
            labelId='projectLabel'
            size='small'
            id='project'
            value={formik.values.project}
            onChange={(e) => {
              formik.setFieldValue('project', e.target.value);
            }}
            format='MM-DD-YYYY'
            error={formik.touched.project && Boolean(formik.errors.project)}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {listData?.projects.data.data.map((project, index) => (
              <MenuItem key={index} value={project}>
                {project}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '33.3%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label='Request Date'
              slotProps={{
                textField: { size: 'small' },
              }}
              value={formik.values.requestDate}
              onChange={formik.handleChange}
              format='MM-DD-YYYY'
              error={Boolean(formik.errors.requestDate)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ width: '33.3%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label='Respond By Date'
              slotProps={{
                textField: { size: 'small' },
              }}
              value={formik.values.respondByDate}
              onChange={formik.handleChange}
              format='MM-DD-YYYY'
              error={Boolean(formik.errors.respondByDate)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            id='outlined-basic'
            label='Enter Project Location'
            name='projectLocation'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.projectLocation}
            error={
              formik.touched.projectLocation &&
              Boolean(formik.errors.projectLocation)
            }
            helperText={
              formik.touched.projectLocation && formik.errors.projectLocation
            }
          />
        </FormControl>
        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='summary'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Summary'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.summary}
            error={formik.touched.summary && Boolean(formik.errors.summary)}
            helperText={formik.touched.summary && formik.errors.summary}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='scope'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Scope'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.scope}
            error={formik.touched.scope && Boolean(formik.errors.scope)}
            helperText={formik.touched.scope && formik.errors.scope}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='question'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Question'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.question}
            error={formik.touched.question && Boolean(formik.errors.question)}
            helperText={formik.touched.question && formik.errors.question}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            name='response'
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Response'
            multiline
            maxRows={6}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.response}
            error={formik.touched.response && Boolean(formik.errors.response)}
            helperText={formik.touched.response && formik.errors.response}
          />
        </FormControl>
      </form>
      <Box sx={{ margin: 'auto', width: '25%', mt: '10px' }}>
        <Button
          variant='contained'
          sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
          onClick={formik.handleSubmit}
          endIcon={<SendIcon />}>
          Send
        </Button>
        {isSent ? (
          <Box>
            <Stack direction='row'>
              <DoneOutlineIcon sx={{ color: colors.greenAccent[500] }} />
              <Typography> {statusMessage} </Typography>
            </Stack>
          </Box>
        ) : null}
        {isErr ? (
          <Stack direction='row' textAlign='center'>
            <ClearIcon sx={{ color: colors.redAccent[500] }} />
            <Typography> {statusMessage} </Typography>
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};

export default AddRFIForm;
