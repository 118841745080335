import { useMemo, useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import AnalyticsBoxHeader from "../components/AnalyticsBoxHeader";
import { useTheme, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Alert from "@mui/material/Alert";
import {
	ResponsiveContainer,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Line,
	LineChart,
	Legend,
	Bar,
	BarChart,
} from "recharts";
import useFetch from "../hooks/fetchAPI";
import { fakePayrollCosts } from "../fakedata/mockData";
import { useAuth } from "../context/AuthProvider";

const Row2 = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const { data, loading, error } = useFetch(
		"/api/v1/timecardsv2/training-stats"
	);
	const [output, setOutput] = useState(data);
	const [output2, setOutput2] = useState([]);
	const [options, setOptions] = useState([]);
	const [errMsg, setErrMsg] = useState(error);
	const { api } = useAuth();

	const rendered = useMemo(() => {
		return (
			data &&
			data?.map(
				({
					trade,
					month,
					regHours,
					overtimeHours,
					satHours,
					sunHours,
					count,
				}) => {
					return {
						id: `${month + trade + count}`,
						trade,
						month,
						regHours,
						overtimeHours,
						satHours,
						sunHours,
						count,
					};
				}
			)
		);
	}, [data]);

	const renderedColumns = useMemo(() => {
		const headers = [
			"month",
			"trade",
			"regHours",
			"overtimeHours",
			"satHours",
			"sunHours",
			"count",
		];
		//create columns for each trade
		const columns = headers.map((elem) => {
			return {
				field: elem,
				headerName: elem,
				flex: 0.4,
			};
		});
		return columns;
	}, []);

	useEffect(() => {
		if (api.authenticated) {
			api
				.get(`/api/v1/timecardsv2/s-and-w-stats`)
				.then((response) => {
					console.log("SUCCESS", { response });
					const data = response.data.data;
					setOutput2(data);
					let newArr = [];
					let renderedData = [];
					// create list, remove duplicates
					if (data) {
						data.map((element, i) => {
							const add = element.list;
							add.map((el) => {
								return newArr.push(el);
							});
							return newArr;
						});
						const uniq = [...new Set(newArr)];
						setOptions(uniq);

						//remove nested structure and flatten data array
						data.map((element, i) => {
							const tradesObj = element.trades;
							const month = element.month;
							for (var e in tradesObj) {
								renderedData.push({ month, [e]: tradesObj[e] });
							}
							setOutput(renderedData);
						});
					}
				})

				.catch((err) => {
					console.log("FAILED", { err });
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api?.authenticated]);

	// const weeklyHours = useMemo(() => {
	//   return (
	//     data &&
	//     data?.map(({ weekEnd, regHours }) => {
	//       console.log(dayjs(weekEnd).format('MM/DD/YYYY'));
	//       return {
	//         weekEnd: dayjs(weekEnd).format('MM/DD/YYYY'),
	//         regHours: regHours,
	//       };
	//     })
	//   );
	// }, [data]);

	// useEffect(() => {
	//   setErrMsg(error);
	//   if (data) {
	//     settcDataRendered(weeklyHours);
	//     setErrMsg(null);
	//     console.log('Data:', data);
	//   }
	// }, [data, errMsg, error, weeklyHours]);

	// const analytics = useMemo(() => {
	//   return (
	//     data &&
	//     data.map(({ analytics }) => {
	//       console.log(analytics[0]);
	//       let res = analytics.reduce(
	//         (a, b) =>
	//           a.set(b.project, (a.get(b.project) || 0) + Number(b.regTotal)),
	//         new Map()
	//       );
	//       return {
	//         project: res.keys().next().value,
	//         hours: res.values().next().value,
	//       };
	//     })
	//   );
	// }, [data]);
	// console.log(analytics);
	// const revenue = useMemo(() => {
	//   return (
	//     data &&
	//     data[0]?.monthlyData.map(({ month, revenue }) => {
	//       return {
	//         name: month.substring(0, 3),
	//         revenue: revenue,
	//       };
	//     })
	//   );
	// }, [data]);
	const COLORS = [
		colors.greenAccent[500],
		colors.redAccent[500],
		colors.blueAccent[500],
		colors.grey[500],
	];
	return (
		<>
			<DashBoardBox gridArea="d" theme={theme} colors={colors}>
				<AnalyticsBoxHeader
					title="Training Funds"
					subtitle="(Trailing 6 Months)"
					sidetext="+0%"
				/>
				{errMsg ? <Alert severity="error">{errMsg}</Alert> : null}
				<ResponsiveContainer width="95%" height="80%">
					<Box
						p="0 0.5rem"
						height="85%"
						sx={{
							"& .MuiDataGrid-root": {
								color: `${colors.grey[300]}`,
								border: "none",
							},
							"& .MuiDataGrid-cell": {
								borderBottom: `1px solid ${colors.grey[800]} !important`,
							},
							"& .MuiDataGrid-columnSeparator": {
								visibility: "hidden",
							},
						}}
					>
						<DataGrid
							rowHeight={25}
							columnHeaderHeight={25}
							hideFooter={true}
							rows={rendered || []}
							columns={renderedColumns || []}
						/>
					</Box>
				</ResponsiveContainer>
			</DashBoardBox>
			<DashBoardBox gridArea="e" theme={theme} colors={colors}>
				<AnalyticsBoxHeader
					title="Payroll Costs"
					subtitle="(Trailing 6 Months)"
					sidetext="+4%"
				/>
				{errMsg ? <Alert severity="error">{errMsg}</Alert> : null}
				<ResponsiveContainer width="100%" height="85%">
					<LineChart
						width={500}
						height={300}
						data={fakePayrollCosts}
						margin={{
							top: 15,
							right: 0,
							left: 0,
							bottom: 20,
						}}
					>
						<CartesianGrid vertical={false} stroke={colors.grey[700]} />
						<XAxis dataKey="date" stroke={colors.grey[100]} />
						<YAxis
							yAxisId="left"
							tickLine={false}
							axisLine={false}
							stroke={colors.grey[100]}
						/>
						<YAxis
							yAxisId="right"
							orientation="right"
							tickLine={false}
							axisLine={false}
							stroke={colors.grey[100]}
						/>
						<Tooltip />
						<Legend
							height={20}
							wrapperStyle={{
								margin: "0 0 10px 0",
							}}
						/>
						<Line
							yAxisId="left"
							type="monotone"
							dataKey="revenue"
							stroke={colors.greenAccent[500]}
						/>
						<Line
							yAxisId="right"
							type="monotone"
							dataKey="expenses"
							stroke={colors.redAccent[500]}
						/>
					</LineChart>
				</ResponsiveContainer>
			</DashBoardBox>
			<DashBoardBox gridArea="f" theme={theme} colors={colors}>
				<AnalyticsBoxHeader
					title="S and W"
					subtitle="(Trailing 3 Months)"
					sidetext="+0%"
				/>
				<ResponsiveContainer width="100%" height="85%">
					<BarChart
						width={500}
						height={300}
						//data={fakedata}
						data={output}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey={"month"} />
						<YAxis />
						<Tooltip />
						<Legend />
						{options.map((entry, index) => {
							return (
								<Bar
									key={`bar-${index}`}
									dataKey={entry}
									stackId="a"
									fill={COLORS[index % COLORS.length]}
								/>
							);
						})}
					</BarChart>
				</ResponsiveContainer>
			</DashBoardBox>
		</>
	);
};

export default Row2;
