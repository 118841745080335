import { useState, useEffect } from 'react';
import TopBarAdmin from '../components/TopBarAdmin';
import { useAuth } from '../context/AuthProvider';

const StickyHeader = () => {
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add('is-sticky')
      : header.classList.remove('is-sticky');
  };

  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const { api } = useAuth();

  useEffect(() => {
    async function GetResults(data) {
      try {
        const response = await api.get(
          `/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
        );
        console.log(response);
        setNotificationsNumber(response.data.data[0].results.length);
      } catch (err) {
        console.log('Failed', { err });
      }
    }
    GetResults();
  }, []);

  return (
    <>
      <header className='header-section d-none d-xl-block'>
        <TopBarAdmin notificationsNumber={notificationsNumber} />
      </header>
    </>
  );
};

export default StickyHeader;
