//future - get user information from axios request
//
function createInitialObj(user) {
  //Dropdown Solution
  const projectOptions = [
    {
      id: '2022 - Select',
      value: '',
      label: 'Select a project',
    },
    {
      id: '2022 - 73 - Pacifica 6',
      value: '73 - Pacifica 6',
      label: '73 - Pacifica 6',
    },
    {
      id: '2021 - 74 - Ellen Fletcher',
      value: '74 - Ellen Fletcher',
      label: '74 - Ellen Fletcher',
    },
    {
      id: '2022 - 75 - Palo Alto HS Tower',
      value: '75 - Palo Alto HS Tower',
      label: '75 - Palo Alto HS Tower',
    },
  ];

  return {
    projectOptions,
  };
}

export default createInitialObj;
