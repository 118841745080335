import { useAuth } from '../context/AuthProvider';
import Login from '../components/Login';
import { tokens } from '../theme';
import { Box, useTheme } from '@mui/material';

function HomePage() {
  //success means logged in
  const { success } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (success) {
    return (
      <div className='vertical-center'>
        <img src={require('../img/calpacific.png')} alt='calpacific logo' />
      </div>
    );
  }

  return (
    <div>
      <Box
        sx={{
          backgroundColor: colors.blueAccent[700],
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          width: '350px',
          minHeight: '30vh',
        }}
      >
        {' '}
        <Login />{' '}
      </Box>
      {/* </Paper> */}
    </div>
  );
}

export default HomePage;
